import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import * as React from 'react';
import NakladnayaTableContent from './NakladnayaTableContent';
import SignatureTableNakladnaya from './SignatureTableNakladnaya';



const NakladnayaPDF = ({ application, compony, myCompony, addressDelivery, nakladnaya }) => {
    const contentAmount = nakladnaya.map((con) => con.amount)
    const contentSumm = nakladnaya.map((con) => con.total)
    const sumWithContentAmount = contentAmount.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    const sumWithContentSumm = contentSumm.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    const styles = StyleSheet.create({
        page: {
            padding: '15px',
            fontFamily: "Calibri",
            paddingLeft: '20px',
            paddingRight: '20px'
        },
        table: {
            display: "table",
            width: "auto",
        },
        tableRow: {
            flexDirection: "row"
        },
        tableRowHeader: {
            flexDirection: "row",
            marginBottom: '10px'
        },
        tableCol70: {
            width: "70%",
        },
        tableCol75: {
            width: "75%",
        },
        tableCol80: {
            width: "80%",
        },
        tableCol100: {
            width: "100%",
        },
        tableColNo30: {
            width: "30%",
        },
        tableColNo45: {
            width: "45%",
        },
        tableColNo10: {
            width: "10%",
        },
        tableColNo20: {
            width: "20%",
        },
        tableCol25: {
            width: "25%",
        },
        tableColNo5: {
            width: "5%",
        },
        tableColNo9: {
            width: "9%",
            borderWidth: 1,
            borderBottomWidth: 0,
        },
        tableColNo9Right: {
            width: "9%",
            borderWidth: 1,
            borderBottomWidth: 0,
            borderLeftWidth: 0,
        },
        tableColNo82: {
            width: "82%",
        },

        tableColNo24: {
            width: "24%",
            margin: 0,
            borderWidth: 1,
            borderRightWidth: 0,
            borderTopWidth: 0,
        },
        tableColNo19: {
            width: "19%",
            borderWidth: 1,
            borderRightWidth: 0,
            borderTopWidth: 0,
            margin: 0
        },
        tableColNo17: {
            width: "17%",
            borderWidth: 1,
            borderRightWidth: 0,
            borderTopWidth: 0,
            margin: 0
        },
        tableColNo21: {
            width: "21%",
            borderWidth: 1,
            borderRightWidth: 0,
            borderTopWidth: 0,
            margin: 0
        },
        tableCell: {
            width: '100%',
            textAlign: 'center',
            fontSize: 10,
            marginTop: 3,
        },
        tableCellLeft: {
            fontSize: 10,
            marginTop: 3,
            fontWeight: 'bold',
        },
        tableText12: {
            fontWeight: 'bold',
            marginTop: 5,
            fontSize: 12,
            borderBottom: 1,
            margin: 'auto'
        },
        tableText11Line: {
            fontWeight: 'bold',
            fontSize: 11,
            borderBottom: 1,
            textAlign: 'center',
            width: '100%',
            padding: 0
        },
        tableText11Right: {
            fontWeight: 'bold',
            fontSize: 11,
            textAlign: 'right',
            width: '100%',
            paddingTop: '2px',
            paddingRight: '2px'
        },
        tableText11CenterBorder: {
            fontWeight: 'bold',
            fontSize: 11,
            textAlign: 'center',
            border: 1,
            width: '100%',
            paddingTop: '2px'
        },
        tableText10: {
            fontSize: 10,
            marginTop: 1,
            margin: 'auto'
        },
        tableText9Italy: {
            fontSize: 9,
            margin: 'auto',
            fontStyle: 'Italy'
        },
        tableText9ItalyRight: {
            fontSize: 9,
            width: '100%',
            textAlign: 'right',
            marginTop: '10px'
        },

        tableInvoceBotton: {
            borderWidth: 1,
            borderRightWidth: 0,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableTextClient: {
            fontWeight: 'bold',
            marginTop: 20,
            fontSize: 12,
        },
        tableTextAddress: {
            marginTop: 2,
            fontSize: 12,
            maxWidth: '40%'
        },

        tableRowHeaderNumber: {
            flexDirection: "row",
        },
        tableCol50: {
            width: "50%",
            margin: 0,
        },
        tableCol15: {
            width: "15%",
            borderWidth: 1,
            margin: 0
        },
        tableColNo15: {
            width: "15%",
            margin: 0
        },
        tableText9Center: {
            fontSize: 9,
            margin: "auto",
            marginTop: 1,
        },
        tableText14Center: {
            fontSize: 14,
            margin: "auto",
            marginTop: 1,
            fontWeight: 'bold',
            textDecoration: 'underline'
        },
        tableText16CenterNoDecoration: {
            fontSize: 16,
            margin: "auto",
            marginTop: 1,
            fontWeight: 'bold',
        },
        tableText: {            
            fontSize: 13,
            margin: "auto",
            fontWeight: 'bold',
        },
        tableColTableText: {
            marginBottom: '10px',
            width: '100%'
        },
        tableInvoceTop: {
            borderWidth: 1,
            borderLeftWidth: 0,            
            borderBottomWidth: 0,
            marginBottom: '10px',
        }
    })

    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page} >
                <View style={styles.table}>

                    <View style={styles.tableRowHeader}>
                        <View style={styles.tableCol75}>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableText9Italy}>Приложение 26</Text>
                            <Text style={styles.tableText9Italy}>к приказу Министра финансов</Text>
                            <Text style={styles.tableText9Italy}>Республики Казахстан</Text>
                            <Text style={styles.tableText9Italy}>от 20 декабря 2012 года №562</Text>
                            <Text style={styles.tableText9ItalyRight}>Форма 3-2</Text>
                        </View>
                    </View>

                    <View style={styles.tableRowHeader}>
                        <View style={styles.tableColNo30}>
                            <Text style={styles.tableText10}>Организация (индивидуальный предприниматель)</Text>
                        </View>
                        <View style={styles.tableColNo45}>
                            <Text style={styles.tableText11Line}>{myCompony.name}</Text>
                        </View>
                        <View style={styles.tableColNo10}>
                            <Text style={styles.tableText11Right}>ИИН/БИН</Text>
                        </View>
                        <View style={styles.tableColNo15}>
                            <Text style={styles.tableText11CenterBorder}>{myCompony.bin}</Text>
                        </View>
                    </View>

                    <View style={styles.tableRowHeaderNumber}>
                        <View style={styles.tableColNo82}>

                        </View>
                        <View style={styles.tableColNo9}>
                            <Text style={styles.tableText9Center}>Номер документа</Text>
                        </View>
                        <View style={styles.tableColNo9Right}>
                            <Text style={styles.tableText9Center}>Дата составления</Text>
                        </View>
                    </View>

                    <View style={styles.tableRowHeaderNumber}>
                        <View style={styles.tableColNo82}>

                        </View>
                        <View style={styles.tableColNo9}>
                            <View style={styles.tableInvoceBotton}>
                                <Text style={styles.tableText9Center}>{application.number}</Text>
                            </View>
                        </View>
                        <View style={styles.tableColNo9Right}>
                            <View style={styles.tableInvoceBotton}>
                                <Text style={styles.tableText9Center}>{application.date}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.tableColTableText}>
                        <Text style={styles.tableText}>НАКЛАДНАЯ НА ОТПУСК ЗАПАСОВ НА СТОРОНУ</Text>
                    </View>

                    <View style={styles.tableInvoceTop}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColNo24}>
                                <Text style={styles.tableCell}>Организация (индивидуальный предприниматель) - отправитель</Text>
                            </View>
                            <View style={styles.tableColNo21}>
                                <Text style={styles.tableCell}>Организация (индивидуальный предприниматель) - получатель</Text>
                            </View>
                            <View style={styles.tableColNo19}>
                                <Text style={styles.tableCell}>Ответственный за поставку (Ф.И.О.)</Text>
                            </View>
                            <View style={styles.tableColNo17}>
                                <Text style={styles.tableCell}>Транспортная организация</Text>
                            </View>
                            <View style={styles.tableColNo19}>
                                <Text style={styles.tableCell}>Товаро-транспортная накладная (номер, дата)</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColNo24}>
                                <Text style={styles.tableCell}>{myCompony.name}</Text>                                
                            </View>
                            <View style={styles.tableColNo21}>
                                <Text style={styles.tableCell}>{compony.name}</Text>
                            </View>
                            <View style={styles.tableColNo19}>
                                <Text style={styles.tableCell}>{myCompony.director}</Text>
                            </View>
                            <View style={styles.tableColNo17}>
                                <Text style={styles.tableCell}></Text>
                            </View>
                            <View style={styles.tableColNo19}>
                                <Text style={styles.tableCell}></Text>
                            </View>
                        </View>
                    </View>

                    <NakladnayaTableContent nakladnaya={nakladnaya} sumWithContentAmount={sumWithContentAmount} sumWithContentSumm={sumWithContentSumm} />
                    <SignatureTableNakladnaya sumWithContentAmount={sumWithContentAmount} sumWithContentSumm={sumWithContentSumm} addressDelivery={addressDelivery} myCompony={myCompony} />
                </View>
            </Page>
        </Document>
    );
}

export default NakladnayaPDF;