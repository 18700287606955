import ConnectToServer from "../API/ConnectToServer";
import { getOldApplicationRequest } from "./oldApplicationsReducer"

//Action type
const SET_ALL_APPLICATIONS = 'APPLICATION/SET_ALL_APPLICATIONS';
const ADD_APPLICATION = 'APPLICATION/ADD_APPLICATION';

//Started props
let initialState = {
    isFetching: false,
    allApplications: []
};

//Reducers functions
const applicationsReducer = (state = initialState, action) => {
    
    switch (action.type) {
        
        case ADD_APPLICATION:
            return {
                ...state,
                allApplications: action.newApplication
            }        
        case SET_ALL_APPLICATIONS:
            return {
                ...state,
                allApplications: action.applications
            }
        default:
            return state;
    }
}

//Action Creators functions
// const setNewApplication = (newApplication) => {
//     console.log(newApplication);
//     return { type: ADD_APPLICATION, newApplication }
// }

const setAllApplication = (applications) => {
    return { type: SET_ALL_APPLICATIONS, applications }
}

//Thunk functions
export const getApplicationRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true)); 
        const data = await ConnectToServer.getALLApplication(obj)
        if (data.success === 1) {
            const allAppl = data.allApplications.map((appl)=> ({...appl, content: appl.content.map((cont)=>({...cont, amount: Number(cont.amount), price: Number(cont.price), total: Number(cont.total) }))}))
            dispatch(setAllApplication(allAppl.reverse()))                  
        } else {
            console.log(data.message)
            dispatch(setAllApplication([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const setApplicationRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.setNewApplication(obj)        
        if (data.success === 1) {
            
            dispatch(getApplicationRequest({ mycompany: [obj.companyId] }))
            //console.log(data.applications) 
            //dispatch(setAllApplication(data.allApplications))            
        } else {
            console.log(data.message)            
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const updateApplicationRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.updateApplication(obj)        
        if (data.success === 1) {
            dispatch(getApplicationRequest({ mycompany: [obj.companyId] }))
            dispatch(getOldApplicationRequest({ mycompany: [obj.companyId] }))
            //console.log(data) 
            //dispatch(setAllApplication(data.allApplications))            
        } else {
            console.log(data.message)            
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const updatePaidRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.updatePaidApplication(obj)        
        if (data.success === 1) {
            dispatch(getApplicationRequest({ mycompany: [obj.myCompony] }))
            //console.log(data) 
            //dispatch(setAllApplication(data.allApplications))            
        } else {
            console.log(data.message)            
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const updateAVRRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.updateAVRApplication(obj)        
        if (data.success === 1) {
            dispatch(getApplicationRequest({ mycompany: [obj.myCompony] }))
            //console.log(data) 
            //dispatch(setAllApplication(data.allApplications))            
        } else {
            console.log(data.message)            
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const updateEsfRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.updateEsfApplication(obj)        
        if (data.success === 1) {
            dispatch(getApplicationRequest({ mycompany: [obj.myCompony] }))
            //console.log(data) 
            //dispatch(setAllApplication(data.allApplications))            
        } else {
            console.log(data.message)            
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const updateEsfDoneRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.updateEsfDoneApplication(obj)        
        if (data.success === 1) {
            dispatch(getApplicationRequest({ mycompany: [obj.myCompony] }))
            //console.log(data) 
            //dispatch(setAllApplication(data.allApplications))            
        } else {
            console.log(data.message)            
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const deleteApplicationRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true));
        //console.log(obj);
        const data = await ConnectToServer.deleteApplication(obj)        
        if (data.success === 1) {
            dispatch(getApplicationRequest({ mycompany: [obj.companyId] }))
            //console.log(data) 
            //dispatch(setAllApplication(data.allApplications))            
        } else {
            console.log(data.message)            
            //dispatch(toggleIsFetching(false))
        }
    }
}

export default applicationsReducer;