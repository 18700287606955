import  {applyMiddleware, combineReducers, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import applicationsReducer from './applicationsReducer';
import contentReducer from './contentReducer';
import authReducer from './authReducer';
import mycompanyReducer from './mycompanyReducer';
import companiesReducer from './companiesReducer';
import groupReducer from './groupReducer'
import usersReducer from './usersReducer';
import addrDeliveryReducer from './addrDeliveryReducer';
import oldApplicationsReducer from './oldApplicationsReducer';
import langReducer from './langReducer';
import invoceReducer from './invoceReducer';
import globalSattingsReducer from './globalSattingsReducer';
import wagesReducer from './wagesReducer';
import expensGroupsReducer from './expensGroupsReducer';
import expensesReducer from './expensesReducer';
import fno910Reducer from './fno910Reducer';
import pointsReducer from './pointsReducer';
import fno913Reducer from './fno913Reducer';
import chatReducer from './chatReducer';
import taskReducer from './taskReducer';
import shopReducer from './shopReducer';

let reducers = combineReducers({  
    applicaions: applicationsReducer,    
    сontents: contentReducer,
    companies: companiesReducer,    
    auth: authReducer,
    myCompany: mycompanyReducer,
    userGroups: groupReducer,
    users: usersReducer,
    addressDelivery: addrDeliveryReducer,
    oldApplications: oldApplicationsReducer,
    language: langReducer,
    invoce: invoceReducer,
    globalSattings: globalSattingsReducer,
    wages: wagesReducer,
    expensGroups: expensGroupsReducer,
    expenses: expensesReducer,
    fno910: fno910Reducer,
    fno913: fno913Reducer,
    pointsCompany: pointsReducer,
    chat: chatReducer,
    task: taskReducer,
    shop: shopReducer,
});

//подключение devtools chrome - выявление ошибок через браузер хром
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)));
let store = createStore(reducers, applyMiddleware(thunkMiddleware));
//window.store = store;// глобальная переменная для проверки store
export default store;