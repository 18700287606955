//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import PanelBriefInfo from './PanelBriefInfo';


class PanelBriefInfoContainer extends React.PureComponent {

    render() {
        return (<>            
                <PanelBriefInfo
                    
                />           
        </>)
    }
}

const mapStateToProps = (state) => {return {}};

export default connect(mapStateToProps, { 
})(PanelBriefInfoContainer)
