import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useSelector } from 'react-redux';

const monthNames = [
  "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
  "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
];

const UpdateWageForm = ({ handleCloseFormWage, updateExpenses, updateWages, updWages }) => {
  const [openError, setOpenError] = React.useState(false);
  const mzpSattings = useSelector(state => state.globalSattings.mzp)
  const myCompany = useSelector(state => state.myCompany)

  const [state] = React.useState({
    vertical: 'center',
    horizontal: 'top',
  });

  const { vertical, horizontal } = state;

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };


  const formSchema = Yup.object().shape({
    newWages: Yup.string()
      .required('Заполните поле')
      .min(5, 'Минимум 5 символов'),
  })
  const formOptions = { resolver: yupResolver(formSchema) }

  const { control, handleSubmit, formState } = useForm({ ...formOptions, reValidateMode: "onBlur" });
  const { errors } = formState

  const handleOnSubmit = (evt) => {
    if (updWages.wagesId.year === 2024) {
    if (evt.newWages < mzpSattings) {
      setOpenError(true)
    } else {
          updateWages({id: updWages.wagesId.id, wage: evt.newWages}, myCompany.id)
          updateExpenses({id: updWages.expensesId, total: evt.newWages}, myCompany.id)
          handleCloseFormWage()
      }  
    } else {
      if (evt.newWages < 70000) {
        setOpenError(true)
      } else {
            updateWages({id: updWages.wagesId.id, wage: evt.newWages}, myCompany.id)
            updateExpenses({id: updWages.expensesId, total: evt.newWages}, myCompany.id)
            handleCloseFormWage()
        }  
    }  
  };

  return (
    <div className="App">
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="hoverline" gutterBottom display="block" color="#07139e" sx={{ textAlign: 'center', fontWeight: "600", fontSize: "18px" }}>
            Установите Заработную плату на период {monthNames[updWages.wagesId.month-1] + " " + updWages.wagesId.year + "г."}, с нее будут подсчитаны платежи по налогам. Минимальная заработная плата в Казахстане {mzpSattings}тг.
            </Typography>
            <Divider sx={{ borderColor: '#07139e' }} />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="newWages"
              defaultValue={updWages.total}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  fullWidth
                  size='small'
                  label="Заработная плата"
                  error={errors.newWages !== undefined}
                  helperText={errors.newWages?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="outlined">Сохранить</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleCloseFormWage} type="button" variant="outlined">Отмена</Button>
          </Grid>
        </Grid>
      </Box>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop> */}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openError}
        autoHideDuration={2000}
        onClose={handleCloseError}
        sx={{ top: { xs: 90, sm: 0 } }}
      >
        <Alert onClose={handleCloseError} severity="error">
          <AlertTitle>Минимальная заработная плата в Казахстане {mzpSattings}тг.</AlertTitle>
        </Alert>
      </Snackbar>

    </div>
  );
}

export default UpdateWageForm