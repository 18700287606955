//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import { getAuthUser } from '../Redux/authSelectors';
import { authUserRequest } from '../Redux/authReducer'
import { myCompanyRequest } from '../Redux/mycompanyReducer'
import { getCompaniesRequest } from '../Redux/companiesReducer'
import { contentsRequest } from '../Redux/contentReducer'
import { getApplicationRequest } from '../Redux/applicationsReducer'
import { getOldApplicationRequest } from '../Redux/oldApplicationsReducer'
import { getAddrDeliveryRequest } from '../Redux/addrDeliveryReducer'
import { getUsersRequst } from '../Redux/usersReducer'
import { getAllWagesRequst } from '../Redux/wagesReducer'
import { getAllExpensGroupsRequst } from '../Redux/expensGroupsReducer'
import { getAllExpensesRequest } from '../Redux/expensesReducer'
import { getAllFNO910Requst } from '../Redux/fno910Reducer'
import { getAllFNO913Requst } from '../Redux/fno913Reducer'
import { pointsRequest } from '../Redux/pointsReducer'
import MainApp from './MainApp';
import { chatsRequest } from '../Redux/chatReducer';
import { getAllOrdersClientsRequest, shopGroupsRequest, shopProductsRequest } from '../Redux/shopReducer';

class MainAppContainer extends React.PureComponent {

    render() {
        return (<>
            <div style={{maxWidth: "412px", margin: "0 auto"}} >
                <MainApp                    
                    authUser={this.props.authUser}
                    authUserRequest={this.props.authUserRequest}
                    myCompanyRequest={this.props.myCompanyRequest}
                    getCompaniesRequest={this.props.getCompaniesRequest}
                    contentsRequest = {this.props.contentsRequest}
                    getApplicationRequest = {this.props.getApplicationRequest}
                    getAddrDeliveryRequest={this.props.getAddrDeliveryRequest}
                    getOldApplicationRequest={this.props.getOldApplicationRequest}         
                    getUsersRequst={this.props.getUsersRequst}
                    getAllWagesRequst={this.props.getAllWagesRequst}
                    getAllExpensGroupsRequst={this.props.getAllExpensGroupsRequst}
                    getAllExpensesRequest={this.props.getAllExpensesRequest}
                    getAllFNO910Requst={this.props.getAllFNO910Requst}
                    pointsRequest={this.props.pointsRequest}
                    getAllFNO913Requst={this.props.getAllFNO913Requst}
                    chatsRequest={this.props.chatsRequest}
                    shopProductsRequest={this.props.shopProductsRequest}
                    shopGroupsRequest={this.props.shopGroupsRequest}
                    getAllOrdersClientsRequest={this.props.getAllOrdersClientsRequest}
                />
            </div>
        </>)
    }
}

const mapStateToProps = (state) => {
    return {    
        authUser: getAuthUser(state),
    }
};

export default connect(mapStateToProps, {         
    authUserRequest, 
    myCompanyRequest, 
    getCompaniesRequest, 
    contentsRequest, 
    getApplicationRequest, 
    getAddrDeliveryRequest,
    getOldApplicationRequest,  
    getUsersRequst,
    getAllWagesRequst,
    getAllExpensGroupsRequst,
    getAllExpensesRequest,
    getAllFNO910Requst,
    pointsRequest,
    getAllFNO913Requst,
    chatsRequest,
    shopProductsRequest,
    shopGroupsRequest,
    getAllOrdersClientsRequest
})(MainAppContainer)
