import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_FNO913 = 'FNO913/SET_WAGES';
const TOGGLE_IS_FETCHING = 'FNO913/TOGGLE_IS_FETCHING';

//Started props
let initialState = {
    fno913: [],
    isFetching: false,
};

//Reducers functions
const fno913Reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FNO913:
            return {
                ...state,
                fno913: action.data
            }
        case TOGGLE_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching
            }
    
        default:
            return state;
    }
}

//Action Creators functions
const setAllFNO913 = (data) => {
    return { type: SET_FNO913, data }
}

const toggleIsFetching = (isFetching) => {
    return { type: TOGGLE_IS_FETCHING, isFetching }
}

//Thunk functions

export const getAllFNO913Requst = (obj) => {
    return async (dispatch) => {
            dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.getAllFNO913(obj)
        if (data.success === 1) {
            dispatch(setAllFNO913(data.allFno913))
            dispatch(toggleIsFetching(false))
        } else {
            dispatch(toggleIsFetching(false))
            dispatch(setAllFNO913([]))
        }
    }
}

export const updateFNO913 = (obj, idCompony) => {
    return async (dispatch) => {
        const data = await ConnectToServer.putFNO913(obj)
        if (data.success === 1) {
            dispatch(getAllFNO913Requst({ mycompany: [idCompony] }))
            //dispatch(toggleBeForm(false))
        } else {
            console.log(data);
        }
    }
}

export const addNewFNO913 = (obj, idCompony) => {    
    return async (dispatch) => {
        const data = await ConnectToServer.addFNO913(obj)
        if (data.success === 1) {
            dispatch(getAllFNO913Requst({ mycompany: [idCompony] }))
            //dispatch(toggleBeForm(false))
        } else {
            console.log(data);
        }
    }
}

export default fno913Reducer;