import React from 'react';
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from 'react-redux';
import { useState } from 'react';

const myHelper = {
  text: {
    required: "Заполните поле"
  }
};

const NewCompanyClient = ({ control, Controller }) => {

  return (
        <Grid sx={{mt: 0}} container spacing={2}>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              defaultValue={''}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  size='small'
                  label="Название компании"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="bin"
              defaultValue={''}
              rules={{
                required: false
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="ИИН/БИН"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="addressUyr"
              defaultValue={''}
              rules={{
                required: false
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Адрес"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="phone"
              defaultValue={''}
              rules={{
                required: false
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Телефон"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid> 

          <Grid item xs={12}>
            <Controller
              control={control}

              name="email"
              defaultValue={''}
              rules={{
                required: false
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="email"
                  size='small'
                  fullWidth
                  label="Почта"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="bik"
              defaultValue={''}
              rules={{
                required: false
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="БИК Банка"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="nameBank"
              defaultValue={''}
              rules={{
                required: false
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Название Банка"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="iban"
              defaultValue={''}
              rules={{
                required: false
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Номер счета"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>
              
          <Grid item xs={12}>
            <Controller
              control={control}
              name="kbe"
              defaultValue={''}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  size='small'
                  fullWidth
                  label="КБе"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

        </Grid>
  );
}

export default NewCompanyClient