//import s from './MainPanelAdministration.module.css';
import React from 'react';
import CompanyProfilePanel from './CompanyProfilePanel';
import { connect } from 'react-redux';
import { getAuthUser } from '../../../Redux/authSelectors';
import { updateMyCompanyRequest, uploadSignatureImgRequest, uploadSealImgRequest } from '../../../Redux/mycompanyReducer'




class CompanyProfilePanelContainer extends React.PureComponent {

    render() {
        return (<>         
        <div style={{maxWidth: "412px", }} >   
                <CompanyProfilePanel                   
                    authUser={this.props.authUser}   
                    updateMyCompanyRequest={this.props.updateMyCompanyRequest}                     
                    uploadSealImgRequest={this.props.uploadSealImgRequest}
                    uploadSignatureImgRequest={this.props.uploadSignatureImgRequest}                      
                    selectCompany={this.props.selectCompany}
                />         
                </div>     
        </>)
    }
}

const mapStateToProps = (state) => {
    return {        
        authUser: getAuthUser(state),
    }
};

export default connect(mapStateToProps, {   
    updateMyCompanyRequest,
    uploadSealImgRequest,
    uploadSignatureImgRequest
})(CompanyProfilePanelContainer)
