import React from 'react';
import { Box, Chip, Grid } from '@mui/material';
import ChartsPia from '../../Components/Charts/ChartsPia';

const BriefInfoExpenses = ({dataExpenses}) => {

    const data = dataExpenses.reduce((result, currentItem) => {
        const existingItem = result.find(item => item.label === currentItem.label);
    
        if (existingItem) {
            existingItem.value += currentItem.value;
        } else {
            result.push({
                value: currentItem.value,
                label: currentItem.label,
                color: currentItem.color,
            });
        }
    
        return result;
    }, []);    

    const totalExpenses = data.map((exp, index) =>
        <Grid key={index} item xs={6} sm={6} md={4} lg={2}>
            <Chip variant="outlined" label={exp.label + " - " + exp.value + "тг."} sx={{ backgroundColor: exp.color, color: '#07139e', fontWeight: 500, width: '100%' }} />
        </Grid>)

    return <Box>        
        <div style={{ position: 'relative' }}>
            <div style={{ paddingLeft: `calc(100% - ${360}px)` }}>
                <ChartsPia data={data} />
            </div>
        </div>
        <Grid container spacing={1} sx={{ pl: '15px', pr: '15px' }}>
            {totalExpenses}
        </Grid>
    </Box>
}

export default BriefInfoExpenses;
