//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import UsersPanel from './UsersPanel';
import { updateAuthUserRequest, updateUserPwdRequest } from '../../../../Redux/authReducer';

class UsersPanelContainer extends React.PureComponent {

    render() {
        return (<UsersPanel                   
                    updateAuthUserRequest={this.props.updateAuthUserRequest}
                    updateUserPwdRequest={this.props.updateUserPwdRequest}             
                />)
    }
}

const mapStateToProps = (state) => {
    return {        
    }
};

export default connect(mapStateToProps, { 
    updateAuthUserRequest, 
    updateUserPwdRequest 
})(UsersPanelContainer)
