import ConnectToServer from "../API/ConnectToServer";
import { getUsersRequst } from "./usersReducer";

//Action type

const SET_MY_COMPANY = 'MYCOMPONY/SET_MY_COMPANY';
const SET_SEVERAL_MY_COMPANY = 'MYCOMPONY/SET_SEVERAL_MY_COMPANY';
const SET_BACKDROP = 'MYCOMPONY/SET_BACKDROP';
const SET_OPEN_FORM = 'MYCOMPONY/SET_OPEN_FORM';
const UPLOAD_SIGNATURE_IMG = 'MYCOMPONY/UPLOAD_SIGNATURE_IMG';
const UPLOAD_SEAL_IMG = 'MYCOMPONY/UPLOAD_SEAL_IMG';

//Started props
let initialState = {
    backdrop: false,
    formOpen: false,
    allCompany: [],
    id: 0,//получаем с сервера
    // type: 1,
    name: "",
    // idUserOwner: 1,
    // users: [1],
    // idGroup: [0],
    // addressUyr: "Aveny street 1",
    // addressFiz: "Aveny street 1",
    bin: "",
    // city: "Astana",
    // phone: "",
    // email: "alex@hotcoffee.kz",
    // rejym: 1,
    // activity: 2,
    // nds: false,
    // ndsAmount: 12,
    // workers: [1],
    // workerLimit: 5,
    // operationLimit: 50,
    // fiscal: 0,
    // ved: false,
    // nextCountDoc: 11,
    // program1c: false,
    // bankClient: false,
    // lizing: false,
    // delivery: false,
    // bik: "CASPKZKA",
    // iban: "KZ23722S000024358262",
    // nameBank: "АО Kaspi Bank",
    // kbe: 19,
    // dataRegistration: "23.06.2022",
    // img: "https://fitnessapp.kz/Images/Alex.png"
};

//Reducers functions SET_LOGOUT
const mycompanyReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_MY_COMPANY:
            return {
                ...state,
                ...action.obj[0]
            }

        case SET_SEVERAL_MY_COMPANY:
            return {
                ...state,
                allCompany: action.data
            }

        case SET_BACKDROP:
            return {
                ...state,
                backdrop: action.data
            }

        case SET_OPEN_FORM:
            return {
                ...state,
                formOpen: action.data
            }

        case UPLOAD_SEAL_IMG:
            return {
                ...state,
                seal: action.img
            }

        case UPLOAD_SIGNATURE_IMG:
            return {
                ...state,
                signature: action.img
            }

        default:
            return state;
    }
}

//Action Creators functions

const setMyCompany = (obj) => {
    return { type: SET_MY_COMPANY, obj }
}

const setSeveralMyCompany = (data) => {
    return { type: SET_SEVERAL_MY_COMPANY, data }
}

const setBackdrop = (data) => {
    return { type: SET_BACKDROP, data }
}

const setOpenForm = (data) => {
    return { type: SET_OPEN_FORM, data }
}

const setSignatureImg = (img) => {
    return { type: UPLOAD_SIGNATURE_IMG, img }
}

const setSealImg = (img) => {
    return { type: UPLOAD_SEAL_IMG, img }
}


// //Thunk functions

export const myCompanyRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(setErrorAuth(""))
        //dispatch(setBackdrop(true))
        const data = await ConnectToServer.getMyCompany(obj)
        if (data.success === 1) {
            console.log(data.mycompany);
            
            dispatch(setMyCompany(data.mycompany))
            if (data.mycompany.length > 1) {dispatch(setSeveralMyCompany(data.mycompany))}
            dispatch(getUsersRequst({ users: data.mycompany[0].users }))
            // dispatch(setBackdrop(false))            
            // dispatch(setOpenForm(false))            
        } else {
            console.log(data.message)
            // dispatch(setBackdrop(false))
        }

    }
}

export const updateMyCompanyRequest = (obj) => {
    return async (dispatch) => {
        dispatch(setBackdrop(true))
        console.log(obj);
        // dispatch(setErrorAuth(""))
        const data = await ConnectToServer.putMyCompany(obj)
        if (data.success === 0) {
            console.log(data.message)
            // dispatch(setUnRegisteredEmail(true))????????
            // dispatch(setErrorAuth(data.message))????????            
            dispatch(setBackdrop(false))

        } else {
            if (data.success === 1) {
                //dispatch(setUpdateAuthUserForm(false))
                //console.log(data.message)
                dispatch(myCompanyRequest({ mycompany: obj.uploadCompany }))
                dispatch(setBackdrop(false))
                dispatch(setOpenForm(false))
            }
        }

    }
}

export const uploadSealImgRequest = (img) => {
    return async (dispatch) => {
        dispatch(setBackdrop(true))
        const data = await ConnectToServer.putSealImg(img)
        if (data.success === 0) {
            dispatch(setBackdrop(false))
        } else {
            if (data.success === 1) {
                dispatch(setSealImg(data.img))
                dispatch(setBackdrop(false))
            }
        }
    }
}

export const uploadSignatureImgRequest = (img) => {
    return async (dispatch) => {
        dispatch(setBackdrop(true))
        const data = await ConnectToServer.putSignatureImg(img)
        if (data.success === 0) {
            dispatch(setBackdrop(false))
        } else {
            if (data.success === 1) {
                dispatch(setSignatureImg(data.img))
                dispatch(setBackdrop(false))
            }
        }
    }
}

export default mycompanyReducer;