//import s from './MainPanelAdministration.module.css';
import React from 'react';
import ProductPanel from './ProductPanel';
import { connect } from 'react-redux';
import { contentsUpdate, contentsDelete } from '../../../Redux/contentReducer'

class ProductPanelContainer extends React.PureComponent {

    render() {
        return (<>            
                <ProductPanel
                    contentsUpdate={this.props.contentsUpdate}
                    contentsDelete={this.props.contentsDelete}
                    myCompany={this.props.myCompany}
                />           
        </>)
    }
}

const mapStateToProps = (state) => {
    return {        
    }
};

export default connect(mapStateToProps, { 
    contentsUpdate,
    contentsDelete,
})(ProductPanelContainer)
