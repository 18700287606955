//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import { getAuthUser } from '../../../Redux/authSelectors';
import FNOReportsPanel from './FNOReportsPanel';



class FNOReportsPanelContainer extends React.PureComponent {

    render() {
        return (
            <FNOReportsPanel
                authUser={this.props.authUser}
                selectCompany={this.props.selectCompany}
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authUser: getAuthUser(state),
    }
};

export default connect(mapStateToProps, {

})(FNOReportsPanelContainer)
