//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import { clientsUpdate } from '../Redux/companiesReducer'
import DialogNewData from './DialogNewData';
class DialogNewDataContainer extends React.PureComponent {

    render() {
        return (<>            
                <DialogNewData                                                  
                    open={this.props.open}
                    handleClose={this.props.handleClose}
                    idComponent={this.props.idComponent}
                    textTitle={this.props.textTitle}
                    clientsUpdate={this.props.clientsUpdate}                      
                />           
        </>)
    }
}

const mapStateToProps = (state) => {
    return {    
       
    }
};

export default connect(mapStateToProps, {           
    clientsUpdate,
})(DialogNewDataContainer)
