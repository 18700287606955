import React from 'react';
import { useForm} from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  Grid,
  NativeSelect,
} from "@mui/material";
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import Wages from './Wages';
import NewExpensesForm from '../../Forms/NewExpensesForm';
import dayjs from 'dayjs';


const myHelper = {
  text: {
    required: "Заполните поле"
  }
};

const NewExpenses = ({ submitCloseForm, addNewWages, newExpenses, uploadImgRequest, myCompany }) => {
  
  const expensGroups = useSelector(state => state.expensGroups.groups)
  const img = useSelector(state => state.expenses.img)

  const [optExpGr, setOptExpGr] = useState(1);
  const [selectNewRecord, setSelectNewRecord] = useState(<></>)
  const [data, setData] = useState({})
  const [dateNewExpenses, setDdateNewExpenses] = useState(dayjs(new Date()))
  const nowDate = dayjs(new Date())

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  })

  const [component, setComponent] = useState(<Wages control={control} myHelper={myHelper} setData={setData} />)


  //const backdrop = useSelector(state => state.auth.backdrop)

  const handleOnSubmit = (evt) => {
    if (optExpGr === 1) {
      if (Object.keys(data).length !== 0) {  
      addNewWages({...evt, ...data, idIP : myCompany.id}, myCompany.id)
      newExpenses(
        {
        ...data, 
        groupeId: 1, 
        total: evt.wage, 
        myCompanyId : myCompany.id, 
        expensesTitel: 'Зарплата за ' + data.label, 
        providerId: 0, 
        photo: '', 
        date: nowDate.$D+'.'+(data.month<10?'0'+data.month:data.month)+'.'+data.year
      }, myCompany.id)
      submitCloseForm()
      } else {
        //сообщение что поле пустое
      }     
    } else {      
      newExpenses(
        {
        month: dateNewExpenses.$M+1<10?dateNewExpenses.$M+1:dateNewExpenses.$M+1,
        year: dateNewExpenses.$y,
        groupeId: optExpGr, 
        total: evt.total, 
        myCompanyId : myCompany.id, 
        expensesTitel: evt.expensesTitel, 
        providerId: 0, 
        photo: img, 
        date: dateNewExpenses.$D+'.'+(dateNewExpenses.$M+1<10?"0"+(dateNewExpenses.$M+1):dateNewExpenses.$M+1)+'.'+dateNewExpenses.$y
      }, myCompany.id)
      submitCloseForm()      
    }
  }; 

  useEffect(() => {    
    setSelectNewRecord(expensGroups.map((exp) => <option key={exp.id} value={parseInt(exp.id)}>{exp.name}</option>))
  }, [expensGroups])

  const selectExpGr = (evt) => {
    
    setOptExpGr(parseInt(evt.target.value)) 

    switch (parseInt(evt.target.value)) {
      case 1:
          return setComponent(<Wages control={control} myHelper={myHelper} setData={setData} />);

      
      default:
          return setComponent(<NewExpensesForm control={control} myHelper={myHelper} setDdateNewExpenses={setDdateNewExpenses} uploadImgRequest={uploadImgRequest} />);
  }
  }

  return (
    <div className="App">
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl>
              <NativeSelect
                value={optExpGr}
                inputProps={{
                  name: 'month',
                  id: 'uncontrolled-native',
                }}
                onChange={selectExpGr}
              >
                {selectNewRecord}
              </NativeSelect>
            </FormControl>
          </Grid>

          {component}         

          <Grid item xs={6}>
            <Button type="submit" variant="outlined">Сохранить</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={()=>submitCloseForm()} type="button" variant="outlined">Отмена</Button>
          </Grid>

        </Grid>
      </Box>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop> */}
    </div>
  );
}

export default NewExpenses