import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,

} from '@mui/x-data-grid';

import { Chip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useEffect } from 'react';

const CustomNoRowsOverlay = () => {
  return (
    <Typography variant="hoverline" gutterBottom display="block" color="#000000" sx={{ pt: "15px", textAlign: "center" }}>
      Нет записи
    </Typography>
  )
}

export default function TableAllTask({handleOpenFormViewTask, handleOpenFormNewTask, deleteTaskRequest, authUser}) {

  function EditToolbar(props) {
    // const { setRows, setRowModesModel } = props;
    // const idMyCompany = useSelector(state => state.myCompany.id)
    // const handleClick = () => {
    //   const id = randomId();
    //   setRows((oldRows) => [{ id, idDelivAddr: 0, isNew: true, idMyCompany, update: true }, ...oldRows]);
    //   setRowModesModel((oldModel) => ({
    //     ...oldModel,
    //     [id]: { mode: GridRowModes.Edit, fieldToFocus: 'plu' },
    //   }));

    // };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={()=>handleOpenFormNewTask(0)} >
          Добавить задачу
        </Button>
      </GridToolbarContainer>
    );
  }  
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const allTask = useSelector(state => state.task.allTask)
  const allUsers = useSelector(state => state.users.users)
  const myCompany = useSelector(state => state.myCompany)

  useEffect(()=>{setRows(allTask)},[allTask, setRows])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    const task = allTask.filter((task)=>task.id===id)[0]
    handleOpenFormViewTask({...task, company: myCompany.allCompany.filter(comp => comp.id === task.idCompany)[0]})
  };

  const deletTask = (id) => () => {
    deleteTaskRequest({idUser: authUser.id, idTask: id})
  };
 
  const processRowUpdate = (newRow) => {
    //setAllTotal(AllTotal + newTotal)    
    const updatedRow = { ...newRow, isNew: false, update: true };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'id',
      headerName: '№',
      width: 10,
      type: 'number',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'idUserCreator',
      headerName: 'Создал',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        const user = allUsers.filter(us => us.id === params.value)[0]
        return <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mt: 0, mb: 0, pt: 0, pb: 0 }} textAlign="center" >{user.firstName + " " + user.lastName}</Typography>        
      }
    },
    {
      field: 'idUser',
      headerName: 'Исполнитель',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        const user = allUsers.filter(us => us.id === params.value)[0]
        return <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mt: 0, mb: 0, pt: 0, pb: 0 }} textAlign="center" >{user.firstName + " " + user.lastName}</Typography>        
      }
    },
    {
      field: 'idCompany',
      headerName: 'Компания',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        const compony = myCompany.allCompany.filter(comp => comp.id === params.value)[0]
        return <div>
        <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mb: 0, pb: 0, lineHeight: '1' }} color='secondary' textAlign="center" >{compony.name}</Typography>
        <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} textAlign="center" >БИН/ИИН: {compony.bin}</Typography>
      </div>
      }
    },
    {
      field: 'title',
      headerName: 'Описание',
      width: 250,
      type: 'text',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'dataCreate',
      headerName: 'Создали',
      width: 130,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <div>
        <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mb: 0, pb: 0, lineHeight: '1' }} color='secondary' textAlign="center" >{params.row.timeCreate}</Typography>
        <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} textAlign="center" >{params.row.dataCreate}</Typography>
      </div>
      }
    },
    {
      field: 'dataFinish',
      headerName: 'Срок выполнения',
      width: 130,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return <div>
        <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mb: 0, pb: 0, lineHeight: '1' }} color='error' textAlign="center" >{params.row.timeFinish}</Typography>
        <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} textAlign="center" >{params.row.dataFinish}</Typography>
        </div>
      }
    },
    {
      field: 'dataStart',
      headerName: 'Начало выполнения',
      width: 130,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.row.state === 0? <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }}  textAlign="left" >Новая</Typography> :<div>
        <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mb: 0, pb: 0, lineHeight: '1', color: 'green' }} textAlign="center" >{params.row.timeStart}</Typography>
        <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} textAlign="center" >{params.row.dataStart}</Typography>
        </div>
      }
    },
    {
      field: 'dataUpdate',
      headerName: 'Редактировали',
      width: 130,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        //const compony = allCompanies.filter(comp => comp.id === params.value)
        return params.row.dataUpdate === ""? <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }}  textAlign="left" >Нет</Typography> :<div>
        <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mb: 0, pb: 0, lineHeight: '1', color: 'burlywood' }} textAlign="center" >{params.row.timeUpdate}</Typography>
        <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} textAlign="center" >{params.row.dataUpdate}</Typography>
        </div>
      }
    },
    {
      field: 'dataClose',
      headerName: 'Выполнено',
      width: 130,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <Chip 
        icon={params.row.state === 2 ? <CheckCircleRoundedIcon /> : <ErrorRoundedIcon /> } 
        label={params.row.state !== 2 ? 'Нет' : <div>
          <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mb: 0, pb: 0, lineHeight: '1' }} textAlign="center" >{params.row.timeClose}</Typography>
          <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} textAlign="center" >{params.row.dataClose}</Typography>
          </div>} 
        color={params.row.state === 2 ? 'success' : 'error'} 
        variant="outlined" />       
    },    
    {
      field: 'actions',
      type: 'actions',
      headerName: '...',
      width: 80,
      cellClassName: 'actions',
      disableColumnMenu: true,
      hideSortIcons: true,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            // <GridActionsCellItem
            //   icon={<SaveIcon />}
            //   label="Save"
            //   sx={{
            //     color: 'primary.main',
            //   }}
            //   onClick={handleSaveClick(id)}
            // />,
            // <GridActionsCellItem
            //   icon={<CancelIcon />}
            //   label="Cancel"
            //   className="textPrimary"
            //   onClick={handleCancelClick(id)}
            //   color="inherit"
            // />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<FindInPageOutlinedIcon />}
            label="Просмотреть"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteForeverOutlinedIcon />}
            label="Просмотреть"
            onClick={deletTask(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: '89vh',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
        flex: 1
      }}
    >
      <Box sx={{ height: '100% !important', width: "100% !important" }}>
        <DataGrid
          autoPageSize

          sx={{ fontSize: "14px",  width: "inherit" }}
          rows={rows}
          columns={columns}
          onProcessRowUpdateError={(error) => console.log(error)}
          columnVisibilityModel={{
            idDelivAddr: false,
            idMyCompany: false,
          }}          
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          density="compact"
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}

        />
      </Box>
    </Box>
  );
}