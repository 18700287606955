import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_FNO910 = 'FNO910/SET_WAGES';
const TOGGLE_IS_FETCHING = 'FNO910/TOGGLE_IS_FETCHING';

//Started props
let initialState = {
    fno910: [],
    isFetching: false,
};

//Reducers functions
const fno910Reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FNO910:
            return {
                ...state,
                fno910: action.data
            }
        case TOGGLE_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching
            }
    
        default:
            return state;
    }
}

//Action Creators functions
const setAllFNO910 = (data) => {
    return { type: SET_FNO910, data }
}

const toggleIsFetching = (isFetching) => {
    return { type: TOGGLE_IS_FETCHING, isFetching }
}

//Thunk functions

export const getAllFNO910Requst = (obj) => {
    return async (dispatch) => {
            dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.getAllFNO910(obj)
        if (data.success === 1) {
            dispatch(setAllFNO910(data.allFno910))
            dispatch(toggleIsFetching(false))
        } else {
            dispatch(toggleIsFetching(false))
            dispatch(setAllFNO910([]))
        }
    }
}

export const updateFNO910 = (obj, idCompony) => {
    return async (dispatch) => {
        const data = await ConnectToServer.putFNO910(obj)
        if (data.success === 1) {
            dispatch(getAllFNO910Requst({ mycompany: [idCompony] }))
            //dispatch(toggleBeForm(false))
        } else {
            console.log(data);
        }
    }
}

export const addNewFNO910 = (obj, idCompony) => {    
    return async (dispatch) => {
        const data = await ConnectToServer.addFNO910(obj)
        if (data.success === 1) {
            dispatch(getAllFNO910Requst({ mycompany: [idCompony] }))
            //dispatch(toggleBeForm(false))
        } else {
            console.log(data);
        }
    }
}

export default fno910Reducer;