import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import * as React from 'react';
import AVRTableContent from './AVRTableContent';
import SignatureTableAVR from './SignatureTableAVR';



const AVRPDF = ({ application, compony, myCompony, addressDelivery, avr }) => {
    const contentAmount = avr.map((con) => con.amount)
    const contentSumm = avr.map((con) => con.total)
    const sumWithContentAmount = contentAmount.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    const sumWithContentSumm = contentSumm.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    const styles = StyleSheet.create({
        page: {
            padding: '15px',
            fontFamily: "Calibri",
            paddingLeft: '20px',
            paddingRight: '20px'
        },
        table: {
            display: "table",
            width: "auto",
        },
        tableRow: {
            flexDirection: "row"
        },
        tableRowHeader: {
            flexDirection: "row",
            marginBottom: '8px'
        },
        tableCol75: {
            width: "75%",
        },
        tableCol25: {
            width: "25%",
        },
        tableColNumDoc: {
            borderWidth: 1,
            borderBottomWidth: 0,
            borderRightWidth: 0,
        },
        tableColNumDocRight: {
            borderWidth: 1,
            borderBottomWidth: 0,
        },
        tableColNumDocBotton: {
            borderBottom: 1
        },
        tableCell: {
            width: '100%',
            textAlign: 'center',
            fontSize: 10,
        },
        tableCell10: {
            width: '100%',
            textAlign: 'left',
            fontSize: 10,
            verticalAlign: 'sub',
            paddingBottom: '2px'
        },
        tableCell11BlackCenter: {
            width: '100%',
            textAlign: 'center',
            fontSize: 11,
            fontWeight: 'bold',
            border: 1,
            verticalAlign: 'sub',
            paddingBottom: '2px'
        },
        tableCellName: {
            width: '100%',
            textAlign: 'center',
            fontSize: 11,
            fontWeight: 'bold',
            margin: 'auto',
            borderBottom: 1,
            verticalAlign: 'sub',
            paddingBottom: '2px'
        },
        tableCellContract: {
            width: '100%',
            textAlign: 'left',
            fontSize: 10,
            margin: 'auto',
            borderBottom: 1,
            verticalAlign: 'sub',
            paddingBottom: '2px'
        },
        tableText9Italy: {
            fontSize: 9,
            margin: 'auto',
            fontStyle: 'Italy'
        },
        tableText9ItalyRight: {
            fontSize: 9,
            width: '100%',
            textAlign: 'right',
            marginTop: '10px'
        },
        tableText11: {
            fontSize: 11,
            width: '100%',
            textAlign: 'center',
            verticalAlign: 'sub',
            paddingBottom: '1px'
        },
        tableCellDocName:{
            marginTop: '6px',
            marginBottom: '10px',
            fontSize: 13,
            width: '100%',
            textAlign: 'center',
            fontWeight: 'bold',
            verticalAlign: 'sub',
            paddingBottom: '1px'
        },
        tableColNo67: {
            width: "67%",
            margin: 0
        },
        tableColNo15: {
            width: "15%",
            margin: 0
        },
        tableColNo8: {
            width: "8%",
            margin: 0
        },
        tableColNo10: {
            width: "10%",
            margin: 0
        },
        tableColNo12: {
            width: "12%",
            margin: 0
        },
        tableColNo19: {
            width: "19%",
            margin: 0
        },
        tableColNo48: {
            width: "48%",
            margin: 0
        },
        tableColNo40: {
            width: "40%",
            margin: 0
        },
        tableColNo60: {
            width: "60%",
            margin: 0
        },
        tableColNo20: {
            width: "20%",
            margin: 0
        },
        tableColNo21: {
            width: "21%",
            margin: 0
        },
        tableColNo80: {
            width: "80%",
            margin: 0
        }

    })

    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page} >
                <View style={styles.table}>
                    <View style={styles.tableRowHeader}>
                        <View style={styles.tableCol75}>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableText9Italy}>Приложение 50</Text>
                            <Text style={styles.tableText9Italy}>к приказу Министра финансов</Text>
                            <Text style={styles.tableText9Italy}>Республики Казахстан</Text>
                            <Text style={styles.tableText9Italy}>от 20 декабря 2012 года №562</Text>
                            <Text style={styles.tableText9ItalyRight}>Форма P-1</Text>
                        </View>
                    </View>
                    {/* Заказчик */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColNo8}>
                        </View>
                        <View style={styles.tableColNo67}>
                        </View>
                        <View style={styles.tableColNo10}>
                        </View>
                        <View style={styles.tableColNo15}>
                            <Text style={styles.tableText11}>ИИН/БИН</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColNo8}>
                            <Text style={styles.tableCell10}>Заказчик</Text>
                        </View>
                        <View style={styles.tableColNo67}>
                            <Text style={styles.tableCellName}>{compony.name + ', ' + compony.addressUyr}</Text>
                        </View>
                        <View style={styles.tableColNo10}>
                        </View>
                        <View style={styles.tableColNo15}>
                            <Text style={styles.tableCell11BlackCenter}>{compony.bin}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColNo8}>
                        </View>
                        <View style={styles.tableColNo67}>
                            <Text style={styles.tableText9Italy}>полное наименование, адрес, данные о средствах связи</Text>
                        </View>
                        <View style={styles.tableColNo10}>
                        </View>
                        <View style={styles.tableColNo15}>
                        </View>
                    </View>

                    {/* Исполнитель */}

                    <View style={styles.tableRow}>
                        <View style={styles.tableColNo8}>
                            <Text style={styles.tableCell10}>Исполнитель</Text>
                        </View>
                        <View style={styles.tableColNo67}>
                            <Text style={styles.tableCellName}>{myCompony.name + ', ' + myCompony.addressUyr}</Text>
                        </View>
                        <View style={styles.tableColNo10}>
                        </View>
                        <View style={styles.tableColNo15}>
                            <Text style={styles.tableCell11BlackCenter}>{myCompony.bin}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColNo8}>
                        </View>
                        <View style={styles.tableColNo67}>
                            <Text style={styles.tableText9Italy}>полное наименование, адрес, данные о средствах связи</Text>
                        </View>
                        <View style={styles.tableColNo10}>
                        </View>
                        <View style={styles.tableColNo15}>
                        </View>
                    </View>
                    {/* Договор */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColNo60}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColNo20}>
                                    <Text style={styles.tableCell10}>Договор (контракт)</Text>
                                </View>
                                <View style={styles.tableColNo80}>
                                    <Text style={styles.tableCellContract}>{application.contract}</Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColNo20}>
                                </View>
                                <View style={styles.tableColNo80}>
                                    <Text style={styles.tableCellDocName}>АКТ ВЫПОЛНЕННЫХ РАБОТ (ОКАЗАННЫХ УСЛУГ)</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.tableColNo40}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColNo20}>

                                </View>
                                <View style={styles.tableColNo21}>
                                    <View style={styles.tableColNumDoc}>
                                        <Text style={styles.tableCell}>
                                            Номер документа
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.tableColNo21}>
                                    <View style={styles.tableColNumDocRight}>
                                        <Text style={styles.tableCell}>
                                            Дата составления
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.tableColNo19}>

                                </View>
                                <View style={styles.tableColNo19}>

                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColNo20}>

                                </View>
                                <View style={styles.tableColNo21}>
                                    <View style={styles.tableColNumDoc}>
                                        <View style={styles.tableColNumDocBotton}>
                                            <Text style={styles.tableCell}>
                                                {application.number}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableColNo21}>
                                    <View style={styles.tableColNumDocRight}>
                                        <View style={styles.tableColNumDocBotton}>
                                            <Text style={styles.tableCell}>
                                                {application.date}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.tableColNo19}>

                                </View>
                                <View style={styles.tableColNo19}>

                                </View>
                            </View>
                        </View>
                    </View>

                    <AVRTableContent avr={avr} sumWithContentAmount={sumWithContentAmount} sumWithContentSumm={sumWithContentSumm} />
                    <SignatureTableAVR myCompony={myCompony}  />
                </View>
            </Page>
        </Document>
    );
}

export default AVRPDF;