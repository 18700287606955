import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
//import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
//import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  useGridApiContext,
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';


function CustomFooter(props) {
  return (
    <TableContainer sx={{ minHeight: props.myCompony.nds ? 65 : 30 }} component={Paper}>
      <Table size="small" aria-label="spanning table">
        <TableBody>
          <TableRow>
            <TableCell rowSpan={3} />
            <TableCell align="right" colSpan={2}>Итого</TableCell>
            <TableCell align="left">{props.allTotal}</TableCell>
          </TableRow>
          {props.myCompony.nds ?
            <TableRow>
              <TableCell rowSpan={3} />
              <TableCell align="right">{"НДС " + props.myCompony.ndsAmount + "%"}</TableCell>
              <TableCell align="left">{props.allTotal}</TableCell>
            </TableRow>
            : <></>}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const CustomNoRowsOverlay = () => {
  return (
    <Typography variant="hoverline" gutterBottom display="block" color="#000000" sx={{ pt: "15px", textAlign: "center" }}>
      Нет записи
    </Typography>
  )
}

function EditToolbar(props) {
  //const { setRows, setRowModesModel } = props;
  const allContents = useSelector(state => state.сontents.allContents)

  const dispatch = useDispatch()

  const setOpenFormLists = (data) => {
    dispatch({ type: 'AUTH/SET_OPEN_FORM_CATALOG', data })
  }

  const { setRows } = props;
  const apiRef = useGridApiContext();
  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, name: '', total: 0, price: 0, amount: 0, isNew: true }]);

    const handleValueChange = async () => {
      await apiRef.current.startCellEditMode({ id, field: 'name' });
    }
    setTimeout(handleValueChange, 500)
    // setRowModesModel((oldModel) => ({
    //   ...oldModel,
    //   [id]: { mode: startCellEditMode({ id, field: 'name' }), fieldToFocus: 'name' },
    // }));
  };

  return (
    <GridToolbarContainer>
      {allContents.length !== 0 ?
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} >
          Добавить запись
        </Button> :
        <Button color="primary" startIcon={<AddIcon />} onClick={() => setOpenFormLists(true)} >
          Добавьте Товары\Услуги в Справочнике
        </Button>
      }
    </GridToolbarContainer>
  );
}

export default function TableUnits({ myCompony, allContents, initialContent, setStateTable }) {
  const [rows, setRows] = React.useState(initialContent);
  let allTotal = 0
  if (rows.length > 0) {
    allTotal = rows.reduce((a, b) => a + b.total, 0)
  }

  React.useEffect(() => {
    setStateTable(rows)
  }, [rows, setStateTable])

  const [rowModesModel, setRowModesModel] = React.useState({});
  const content = allContents.map(cont => cont.name)

  function setAllData(params) {
    let newParams = { ...params.row }
    if (params.value) {
      const contentNew = allContents.filter(cont => cont.name === params.value)[0]
      const idCont = contentNew.id
      const plu = contentNew.plu
      const price = contentNew.price
      const unit = contentNew.unit
      const name = contentNew.name
      const total = 0
      newParams = { ...params.row, idCont, plu, price, unit, name, total }
    }
    return newParams;

  }

  function setTotalData(params) {
    const amount = params.value
    let total = 0
    if (params.row.price) {
      total = params.row.price * amount
    }

    return { ...params.row, amount, total: +total.toFixed(2) };
  }

  function setTotalDataPrice(params) {
    const price = params.value
    let total = 0
    if (params.row.amount) {
      total = params.row.amount * price
    }
    return { ...params.row, price, total: +total.toFixed(2) };
  }

  function getTotalSumm(params) {
    //newTotal = (params.row.amount || 0) * (params.row.price || 0)
    return +((params.row.amount || 0) * (params.row.price || 0)).toFixed(2);
  }




  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  // const handleEditClick = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  // };

  // const handleSaveClick = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  // };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    //setAllTotal(AllTotal + newTotal)    
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'idCont',
      headerName: 'id Записи',
      width: 50,
      disableColumnMenu: true,
      hideSortIcons: true,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Наименование',
      width: 130,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      type: 'singleSelect',
      valueOptions: content,
      headerAlign: 'center',
      valueSetter: setAllData,
    },
    {
      field: 'plu',
      headerName: 'Номенкл. номер',
      width: 80,
      editable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      hideSortIcons: true,
    },
    {
      field: 'amount',
      headerName: 'Кол-во',
      type: 'number',
      width: 100,
      align: 'right',
      headerAlign: 'center',
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      valueSetter: setTotalData,
    },
    {
      field: 'price',
      headerName: 'Цена',
      type: 'number',
      width: 120,
      align: 'right',
      headerAlign: 'center',
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      valueSetter: setTotalDataPrice,
    },
    {
      field: 'total',
      headerName: 'Сумма',
      type: 'number',
      width: 120,
      align: 'right',
      headerAlign: 'center',
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      valueGetter: getTotalSumm,
      //valueSetter: setTotalSumm,
      //valueFormatter: ({ value }) => setAllTotal(value),
    },
    {
      field: 'unit',
      headerName: 'Ед. Измерения',
      headerAlign: 'center',
      width: 50,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '...',
      width: 40,
      disableColumnMenu: true,
      headerAlign: 'center',
      hideSortIcons: true,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            // <GridActionsCellItem
            //   icon={<SaveIcon />}
            //   label="Save"
            //   sx={{
            //     color: 'primary.main',
            //   }}
            //   onClick={handleSaveClick(id)}
            // />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          // <GridActionsCellItem
          //   icon={<EditIcon />}
          //   label="Edit"
          //   className="textPrimary"
          //   onClick={handleEditClick(id)}
          //   color="inherit"
          // />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 300,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        sx={{ fontSize: "11px" }}
        rows={rows}
        columns={columns}
        onProcessRowUpdateError={(error) => console.log(error)}
        columnVisibilityModel={{
          idCont: false,
          plu: false,
          unit: false,
        }}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        density="compact"
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}

        slots={{
          toolbar: EditToolbar,
          footer: CustomFooter,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
          footer: { myCompony, allTotal },
        }}
      />

    </Box>
  );
}