import * as React from 'react';
import TableAllOrders from '../components/TableAllOrders';
import DeleteOrders from '../components/DeleteOrders';
import EditOrders from '../components/EditOrders';

const Orders = ({  
    myCompony, 
    authUser, 
    deleteApplicationRequest, 
    allApplications,
    allContents, 
    allCompanies, 
    setApplicationRequest, 
    updateApplicationRequest,
    selectCompany
 }) => {

    const [openFormApplic, setOpenFormApplic] = React.useState(false);
    const [openFormDelete, setOpenFormDelete] = React.useState(false);
    const [openFormApplicId, setOpenFormApplicId] = React.useState({ id: 0, update: 0 });
    let dateDoc = "0"
    let nuberDoc = "0"

    const handleDeleteClick = (id) => {
        deleteApplicationRequest({ id, companyId: selectCompany.id, idUser: authUser.id, userKey: authUser.userKey })
        //console.log({id, mycompany: authUser.mycompany[0], idUser: authUser.id, userKey: authUser.userKey});
        setOpenFormApplicId({ id: 0, update: 0 })
        setOpenFormDelete(false)
        setOpenFormApplic(false)
    }

    if (openFormApplicId.id > 0) {
        const application = allApplications.filter((app) => app.id === openFormApplicId.id)[0]
        dateDoc = application.date
        nuberDoc = application.number
    }

    const handleCloseFormProfile = () => {
        setOpenFormApplic(false)
    }

    const handleOpenFormProfile = (id, update) => {
        setOpenFormApplicId({ id, update })
        setOpenFormApplic(true)
    }

    return (<div style={{marginTop: '15px'}}>       
        <TableAllOrders myCompony={selectCompany} handleOpenFormProfile={handleOpenFormProfile} setOpenFormApplicId={setOpenFormApplicId} setOpenFormDelete={setOpenFormDelete} />

        <DeleteOrders allApplications={allApplications} openFormDelete={openFormDelete} setOpenFormDelete={setOpenFormDelete} handleDeleteClick={handleDeleteClick} openFormApplicId={openFormApplicId} nuberDoc={nuberDoc} />

        <EditOrders 
        openFormApplic={openFormApplic}
        allContents={allContents}
        allCompanies={allCompanies}
        openFormApplicId={openFormApplicId}
        allApplications={allApplications}
        authUser={authUser}
        myCompony={selectCompany}
        setApplicationRequest={setApplicationRequest}
        handleCloseFormProfile={handleCloseFormProfile}
        updateApplicationRequest={updateApplicationRequest}
        nuberDoc={nuberDoc}
        dateDoc={dateDoc}
        selectCompany={selectCompany}
        />
       
    </div>)
}

export default Orders