import * as React from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { Avatar, ListItem, ListItemAvatar } from '@mui/material';

export default function ProductListComponent({ products, addProduct, fragment, action }) {
    return (
        <List>
            {products.map((prod) => <div key={prod.id} >
                <ListItem sx={{ p: 0 }}
                    secondaryAction={
                        action(prod.id)
                    }
                >
                    <ListItemButton onClick={() => addProduct(prod)} >
                        <ListItemAvatar >
                            <Avatar alt={prod.name} src={prod.dir + prod.photo} sx={{ width: 56, height: 56 }} variant="rounded" />
                        </ListItemAvatar>
                        <ListItemText primary={prod.name} secondary={
                            fragment(prod)
                        } sx={{ pl: 2 }} />
                    </ListItemButton>
                </ListItem>
                <Divider sx={{ borderColor: '#fcbb42' }} />
            </div>
            )}
        </List>
    );
}
