//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Workers from './Workers';
import { updatePayConfirm, updateWages, addNewWages } from '../Redux/wagesReducer'

class WorkersContainer extends React.PureComponent {

    render() {
        return (<>
            <div style={{maxWidth: "412px", margin: "0 auto", display: 'contents'}} >
                <Workers                    
                    updatePayConfirm={this.props.updatePayConfirm}
                    updateWages={this.props.updateWages}
                    addNewWages={this.props.addNewWages}
                />
            </div>
        </>)
    }
}

const mapStateToProps = (state) => {
    return {    
        
    }
};

export default connect(mapStateToProps, {updatePayConfirm, updateWages, addNewWages})(WorkersContainer)
