import { Alert, Box, Button, CircularProgress, Divider, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import React, { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import InvocePDF from '../../Components/PDF/Invoce/InvocePDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';



const NoPay = () => {
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const applicationState = useSelector(state => state.invoce.application)
    const componyState = useSelector(state => state.myCompany)
    const myCompony = useSelector(state => state.invoce.myCompony)
    const today = new Date()
   
    const application =  applicationState.content.map((appl) => ({...applicationState, content: [appl], allTotal: appl.total, number: today.getHours().toString() + today.getMinutes().toString(), date: today.getDate().toString() + '.' + (today.getMonth() + 1).toString() + '.' + today.getFullYear().toString(),}))
    const compony = {
        name: componyState.name,
        addressUyr: componyState.addressUyr,
    }
    
    const open = Boolean(anchorElUser);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }

    const handleCloseExitMenu = () => {
        setAnchorElUser(null);
    }

    setTimeout(function () {
        navigate("/")
    }, 120 * 1000);

    const exitUser = () => {
        localStorage.clear("finupkzuser")
        navigate("/")
        window.location.reload()
    }

    return <>
        <Box sx={{ width: '100vw', height: '100vh', backgroundColor: '#fff', textAlign: 'center' }} >
            <div style={{ position: 'absolute', top: '20%', left: '25%', marginTop: '50px', marginLeft: '-105px' }}>
                <Alert severity="error">Уважаемый пользователь, истек срок подписки! <br />
                    Для продления подписки необходимо произвести оплату. <br /> <br /> Спасибо, что Вы с нами!
                </Alert>
                <Button onClick={() => navigate("/")} variant="outlined" sx={{ width: '290px', marginTop: '16px' }}>
                    Нажмите если вы уже оплатили или подождите 2 минуты...
                </Button>
                <br />
                <Button onClick={handleOpenUserMenu} color='error' variant="outlined" sx={{ width: '200px', marginTop: '16px' }}>
                    Скачать счет
                </Button>

                <Menu
                    sx={{ mt: '45px', pt: '0', pb: '0' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleCloseExitMenu}
                >
                    <MenuList sx={{ textDecoration: 'none', pt: '0', pb: '0' }} dense>
                        <Suspense fallback={<div>Loading...</div>}>
                            {open ?
                                <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<InvocePDF application={application[0]} compony={compony} myCompony={myCompony} />} fileName={'Счет №' + application[0].number + '.pdf'}>
                                    {({ loading }) =>
                                        loading ? (<CircularProgress color="primary" />
                                        ) : (
                                            <MenuItem sx={{ textDecoration: 'none' }} >
                                                <Typography sx={{ textDecoration: 'none' }} textAlign="center" >Скачать Счет на 1 мес. - 5000тг.</Typography>
                                            </MenuItem>
                                        )
                                    }
                                </PDFDownloadLink>
                                : <></>}
                        </Suspense>

                        <Divider sx={{ borderColor: '#fcbb42' }} variant="middle" component="li" />

                        <Suspense fallback={<div>Loading...</div>}>
                            {open ?
                                <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<InvocePDF application={application[1]} compony={compony} myCompony={myCompony} />} fileName={'Счет №' + application[0].number + '.pdf'}>
                                    {({ loading }) =>
                                        loading ? (<CircularProgress color="primary" />
                                        ) : (
                                            <MenuItem sx={{ textDecoration: 'none' }} >
                                                <Typography sx={{ textDecoration: 'none' }} textAlign="center" >Скачать Счет на 7 мес. - 30000тг.</Typography>
                                            </MenuItem>
                                        )
                                    }
                                </PDFDownloadLink>
                                : <></>}
                        </Suspense>

                        <Divider sx={{ borderColor: '#fcbb42', mt: '0', mb: '0' }} variant="middle" />

                        <Suspense fallback={<div>Loading...</div>}>
                            {open ?
                                <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<InvocePDF application={application[2]} compony={compony} myCompony={myCompony} />} fileName={'Счет №' + application[0].number + '.pdf'}>
                                    {({ loading }) =>
                                        loading ? (<CircularProgress color="primary" />
                                        ) : (
                                            <MenuItem sx={{ textDecoration: 'none' }} >
                                                <Typography sx={{ textDecoration: 'none' }} textAlign="center" >Скачать Счет на 14 мес. - 60000тг.</Typography>
                                            </MenuItem>
                                        )
                                    }
                                </PDFDownloadLink>
                                : <></>
                            }
                        </Suspense>
                    </MenuList>
                </Menu>

                <br />
                <Button onClick={exitUser} color='secondary' variant="outlined" sx={{ width: '200px', marginTop: '16px' }}>
                    выход
                </Button>
            </div>
        </Box>
    </>
}

export default NoPay;
