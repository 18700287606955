import React from 'react';
import {
  Grid,
  TextField,
} from "@mui/material";

const myHelper = {
  text: {
    required: "Заполните поле"
  }
};

const NewAddressDelivery = ({ control, Controller }) => {

  return (
        <Grid sx={{mt: 0}} container spacing={2}>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="firstName"
              //defaultValue={authUser.firstName}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  size='small'
                  label="Имя"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="lastName"
              //defaultValue={authUser.lastName}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Фамилия"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="city"
              //defaultValue={authUser.city}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Город"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="address"
             // defaultValue={authUser.address}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Адрес"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid> 

          <Grid item xs={12}>
            <Controller
              control={control}

              name="email"
              //defaultValue={authUser.email}
              rules={{
                required: false
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="email"
                  size='small'
                  fullWidth
                  label="Email"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="phone"
              //defaultValue={authUser.phone}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Телефон"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <Controller
              control={control}
              name="iin"
              defaultValue={authUser.iin}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  label="ИИН"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid> */}

          <Grid item xs={12}>
            <Controller
              control={control}
              name="defaultKNP"
             // defaultValue={authUser.defaultKNP}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  size='small'
                  fullWidth
                  label="КНП(по умолчанию выбирается в счете)"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>          
        </Grid>
  );
}

export default NewAddressDelivery