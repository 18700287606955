import { Button, Card, CardHeader, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import * as React from 'react';
import logo from '../../../../../Img/logo_clipbord.png'
import { useState } from 'react';

const BriefInfoCard = ({ words, total, cardColor, cardText, cash, cetFilter }) => {
    const [info, setInfo] = useState(words.variableDataBriefInfo[2])
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const open = Boolean(anchorElUser);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }

    const handleCloseExitMenu = () => {
        setAnchorElUser(null);
    }

    const setCaption = (number) => {
        setInfo(words.variableDataBriefInfo[number])
        cetFilter(number)
        setAnchorElUser(null);
    }

    return (
        <Card sx={{ backgroundColor: cardColor, p: 2, borderRadius: 3, position: 'relative' }}>
            <CardHeader
                sx={{ pt: 0, pb: 0 }}

                action={<>
                    <Button onClick={handleOpenUserMenu} sx={{ borderRadius: '8px', fontSize: '10px', backgroundColor: 'gray', color: '#fff' }} variant="contained" size="small" > {info.text} </Button>
                    <Menu                        
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleCloseExitMenu}
                    >
                        <MenuList sx={{ textDecoration: 'none', pt: '0', pb: '0' }} dense>
                            {words.variableDataBriefInfo.map((item, index) =>
                            <MenuItem key={index} onClick={() => setCaption(index)} sx={{ textDecoration: 'none' }} >
                                <Typography sx={{ textDecoration: 'none' }} fontSize={12} textAlign="center" >{item.text}</Typography>
                            </MenuItem>
                            )}
                            {/* <MenuItem sx={{ textDecoration: 'none' }} >
                                <Typography sx={{ textDecoration: 'none' }} textAlign="center" >{words.variableDataBriefInfo[0]}</Typography>
                            </MenuItem>

                            <Divider sx={{ borderColor: '#fcbb42' }} variant="middle" component="li" />

                            <MenuItem sx={{ textDecoration: 'none' }} >
                                <Typography sx={{ textDecoration: 'none' }} textAlign="center" >{words.variableDataBriefInfo[1]}</Typography>
                            </MenuItem>

                            <Divider sx={{ borderColor: '#fcbb42', mt: '0', mb: '0' }} variant="middle" />

                            <MenuItem sx={{ textDecoration: 'none' }} >
                                <Typography sx={{ textDecoration: 'none' }} textAlign="center" >{words.variableDataBriefInfo[2]}</Typography>
                            </MenuItem> */}
                        </MenuList>
                    </Menu>
                </>
                }
                title={<>
                    <Typography align='left' variant='h6' component="h6" sx={{ mb: '14px', fontSize: '1.2rem' }} > {cardText} </Typography>
                    <Typography align='left' variant='h3' component="h3" sx={{ mb: '12px', fontSize: '1.7rem', fontWeight: '600' }} > {total.toLocaleString("ru-RU") + (cash ? ' тг.' : '')}<span><img src={logo} alt="logo" style={{ position: 'absolute', width: '200px', opacity: '0.2', right: '15px', top: '12px' }} />  </span> </Typography>

                </>}
                // subheader={<>
                //     <Typography variant="caption" align='left' display="block" gutterBottom> <span style={{ fontWeight: '600', color: 'green', fontSize: '14px' }} >+20%</span> С прошлого месяца </Typography>
                // </>}

            />
        </Card>
    )
}

export default BriefInfoCard