//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import BuhChat from './BuhChat';
import { newMessage, setStatusMessageRequest, uploadFileRequest } from '../../../Redux/chatReducer';

class BuhChatContainer extends React.PureComponent {

    render() {
        return (<BuhChat  
                    newMessage={this.props.newMessage}   
                    setStatusMessageRequest={this.props.setStatusMessageRequest}     
                    uploadFileRequest={this.props.uploadFileRequest}                 
                />)
    }
}

const mapStateToProps = (state) => {
    return {        
    }
};

export default connect(mapStateToProps, { 
    newMessage,
    setStatusMessageRequest,
    uploadFileRequest
})(BuhChatContainer)
