import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useState } from 'react';
import { DialogContent, DialogTitle, Stack } from '@mui/material';
import CountNumber from './Forms/CountNumber';
import ProductListComponent from './ProductListComponent';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductList({ products, openList, setOpenList, setMasterBasket, masterBasket }) {

    const [formOpenNumber, setOpenFormNumber] = useState(false)
    const [selectProduct, setSelectProduct] = useState({})

    const handleClose = () => {
        setOpenList(false);
    };

    const addProduct = (item) => {
        setOpenFormNumber(true)
        setSelectProduct(item)
    }

    const addInBasket = (item) => {
        const idProdBasket = masterBasket.filter((pr) => pr.id === selectProduct.id)
        if (idProdBasket.length > 0) {
            alert('Товар уже добавлен в Корзину!!!')
        } else {
            setMasterBasket([{ ...selectProduct, amount: parseInt(item.amount) }])
            setOpenFormNumber(false)
        }
    }

    const fragment = (prod) => {
        return <React.Fragment>
            <Typography variant="caption" gutterBottom sx={{ display: 'block' }} >
                {prod.captions}
            </Typography>
            <Typography
                variant="body2"
                sx={{ color: 'text.primary', display: 'inline' }}
            >
                ЦЕНА: {prod.price} тг.
            </Typography>
        </React.Fragment>
    }

    const action = () => {
        return <></>
    }

    return (<>
        <Dialog
            fullScreen
            open={openList}
            onClose={handleClose}
            TransitionComponent={Transition}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" sx={{p:0}}>
                <AppBar sx={{ position: 'static' }} >
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Stack >
                            <Typography sx={{ ml: 1, flex: 1, mb: 0 }} variant="h6" component="div">
                                {products.name}
                            </Typography>
                            {/* <Typography sx={{ ml: 1, flex: 1, mt: 0 }} variant="caption" component="div">
                            Количество: {total}
                        </Typography> */}
                        </Stack>
                        {/* <Button autoFocus variant="outlined" color="inherit" size="small" disabled={off} onClick={addProducts} sx={{ ml: 2 }} >
                        Добавить в заявку
                    </Button> */}
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent dividers={true}>

                <ProductListComponent products={products.products.map((prod) => ({ ...prod, dir: products.directory, groupeName: products.name }))} addProduct={addProduct} fragment={fragment} action={action} />
            </DialogContent>
        </Dialog>

        <Dialog
            open={formOpenNumber}
            onClose={() => setOpenFormNumber(false)}
            scroll='body'
        >
            <DialogContent>
                <CountNumber addInBasket={addInBasket} startNumber={1} caption={'В Корзину'} />
            </DialogContent>
        </Dialog>
    </>
    );
}
