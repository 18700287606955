import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from 'react-redux';
import { useState } from 'react';

const myHelper = {
  email: {
    required: "Email is Required",
    pattern: "Invalid Email Address"
  },
  text: {
    required: "Заполните поле"
  }
};

const UserProfile = ({ authUser, handleCloseForm, handleOpenFormPass, updateAuthUserRequest }) => {

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  })
  const pointsCompany = useSelector(state => state.pointsCompany.allPoints)
  const authUserTop = useSelector(state => state.auth.authUser)

  const backdrop = useSelector(state => state.auth.backdrop)
  const [selectPoint, setSelectPoint] = useState(authUser.idPoint === 0 ? undefined : pointsCompany.filter((p) => p.idPoint === authUser.idPoint)[0]);
  const [idPointSelect, setIdPointSelect] = useState(authUser.idPointSelect)

  const handleChange = (event) => {
    setIdPointSelect(event.target.checked)
  };

  const handleOnSubmit = (evt) => {
    console.log(evt);
    const updateUserData = { id: authUser.id, userKey: authUser.userKey, ...evt, idPointSelect, idPoint: idPointSelect ? evt.idPoint.idPoint : 0 }
    updateAuthUserRequest(updateUserData)

  };


  return (
    <div className="App">
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="firstName"
              defaultValue={authUser.firstName}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  size='small'
                  label="Имя"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="lastName"
              defaultValue={authUser.lastName}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Фамилия"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="city"
              defaultValue={authUser.city}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Город"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="address"
              defaultValue={authUser.address}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Адрес"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid> 

          <Grid item xs={12}>
            <Controller
              control={control}

              name="email"
              defaultValue={authUser.email}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="email"
                  size='small'
                  fullWidth
                  label="Email"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="phone"
              defaultValue={authUser.phone}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Телефон"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <Controller
              control={control}
              name="iin"
              defaultValue={authUser.iin}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  label="ИИН"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid> */}

          <Grid item xs={12}>
            <Controller
              control={control}
              name="defaultKNP"
              defaultValue={authUser.defaultKNP}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="number"
                  size='small'
                  fullWidth
                  label="КНП(по умолчанию выбирается в счете)"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'left' }}>
            <FormControlLabel control={
              <Checkbox size="small" checked={idPointSelect} onChange={handleChange} name='idPointSelect' />
            }
              label="Установить точку продаж в Заявке (обязательно для режима Розничный налог)"
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="idPoint"
              defaultValue={selectPoint}
              rules={{
                required: idPointSelect
              }}
              render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  disablePortal
                  value={selectPoint}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      onChange(newValue)
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setSelectPoint(newValue.inputValue)
                      onChange(newValue.inputValue)
                    } else {
                      setSelectPoint(newValue)
                      onChange(newValue)
                    }
                  }}
                  noOptionsText="Необходимо добавить Точку продаж в справочнике"
                  options={pointsCompany}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {

                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.city + " --- " + option.street + ', ' + option.numberHouse;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.city + " --- " + option.street + ', ' + option.numberHouse}</li>}
                  renderInput={(params) =>
                    <TextField
                      fullWidth
                      {...params}
                      size="small"
                      label="Точка продажи"
                      error={error !== undefined}
                      helperText={error ? myHelper.text[error.type] : ""}
                    />}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'left' }}>

            <Typography variant="hoverline" gutterBottom display="block" color="#07139e" sx={{ textAlign: 'left' }}>
              Разрешить/Запретить формирование:
            </Typography>

            <Controller
              control={control}
              name="invoce"
              defaultValue={authUser.invoce}
              render={({ field }) => (
                <FormControlLabel control={<Checkbox size="small" checked={field.value} />} label="Счет на оплату" {...field} />
              )}
            />
            <br />


            <Controller
              control={control}
              name="nakladnayAVR"
              defaultValue={authUser.nakladnayAVR}
              render={({ field }) => (
                <FormControlLabel control={<Checkbox size="small" checked={field.value} />} label="Накладная/АВР" {...field} />
              )}
            />
            <br />


            <Controller
              control={control}
              name="esf"
              defaultValue={authUser.esf}
              render={({ field }) => (
                <FormControlLabel control={<Checkbox size="small" checked={field.value} />} label="ЭСФ" {...field} />
              )}
            />

          </Grid>


          <Grid sx={{ textAlign: "left" }} item xs={12}>
            <Button color="error" onClick={handleOpenFormPass} type="button" variant="text">Изменить пароль</Button>
          </Grid>

          <Grid item xs={authUserTop.account !== 2 ? 6 : 12}>
            <Button type="submit" variant="outlined">Сохранить</Button>
          </Grid>
          <Grid item xs={6} display={authUserTop.account !== 2 ? 'block' : 'none'}>
            {authUserTop.account !== 2 ? <Button onClick={handleCloseForm} type="button" variant="outlined">Отмена</Button> : null}
          </Grid>

        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}

export default UserProfile