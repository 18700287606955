import React from 'react';
import BriefInfoExpenses from './Components/BriefInfoExpenses';
import FloatingActionButtons from './Components/FloatingActionButtons';
import { Dialog, DialogContent, DialogTitle, Divider, FormControl, List, NativeSelect } from '@mui/material';
import ExpensesMin from '../Components/ExpensesMin';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import NewExpenses from './Components/NewExpenses';
import UpdateWageForm from '../Forms/UpdateWageForm';

function sortData(selectedMonth, selectedYear, data) {
  const filteredData = data.filter(item => {
    const dateParts = item.date.split('.');
    const itemMonth = parseInt(dateParts[1], 10);
    const itemYear = parseInt(dateParts[2], 10);

    return itemMonth === selectedMonth && itemYear === selectedYear;
  });

  // Сортировка данных по дате (если требуется)
  filteredData.sort((a, b) => new Date(a.date) - new Date(b.date));

  return filteredData;
}


const Expenses = ({ newExpenses, addNewWages, deleteExpenses, updateExpenses, delWages, updateWages, uploadImgRequest, myCompany }) => {

  const expensGroups = useSelector(state => state.expensGroups.groups)
  const expensColors = useSelector(state => state.expensGroups.colors)
  const expenses = useSelector(state => state.expenses.expenses)

  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [sortedData, setSortData] = useState([])
  const [expMin, setExpMin] = useState(<></>)
  const [opt, setOptions] = React.useState(0);
  const [selectMonth, setSelectMonth] = useState([])
  const [selectOptions, setSelectOptions] = useState(<></>)
  const [openFormNewexpenses, setOpenFormNewexpenses] = useState(false)
  const [openFormWage, setOpenFormWage] = useState(false)
  const [updWages, setUpdWages] = useState({})

  const submitCloseForm = () => {
    dispatch({ type: 'EXPENSES/UPLOAD_IMG', img: '' })
    setOpenFormNewexpenses(false)
  }

  const handleCloseFormWage = () => {
    setOpenFormWage(false)
  }

  useEffect(() => {
    if (expenses.length > 0) {
      const idExpensGroups = expensGroups.map((g) => g.id)
      const nameExpensGroups = expensGroups.map((g) => g.name)
      setData(expenses.map((exp) => (
        {
          id: exp.id,
          date: exp.date,
          value: exp.total,
          label: idExpensGroups.includes(exp.groupeId) ? nameExpensGroups[idExpensGroups.indexOf(exp.groupeId)] : "Прочее",
          color: idExpensGroups.includes(exp.groupeId) ? expensColors[idExpensGroups.indexOf(exp.groupeId)] : '#bdbdbd',
          title: exp.expensesTitel,
          month: exp.month,
          year: exp.year,
          photo: exp.photo,
        }
      )))
    } else {
      setData([])
    }
  }, [expenses, expensColors, expensGroups])

  // useEffect(() => {
  //   if (sortedData.length > 0) {
  //     setExpMin(sortedData.map((exp) => <ExpensesMin key={exp.id} exp={exp} />))
  //   }
  // }, [sortedData])

  // Функция для формирования массива из доступных месяцев в данных
  function getAvailableMonths(data) {
    const monthsMap = new Map();

    data.forEach(item => {
      const dateParts = item.date.split('.');
      const month = parseInt(dateParts[1], 10);
      const year = parseInt(dateParts[2], 10);

      const monthString = `${year}-${month.toString().padStart(2, '0')}`;
      monthsMap.set(monthString, true);
    });

    // Получение всех уникальных месяцев с учетом годов
    const allMonths = Array.from(monthsMap.keys());

    // Сортировка месяцев по годам и месяцам
    const sortedMonths = allMonths.sort((a, b) => {
      const [yearA, monthA] = a.split('-');
      const [yearB, monthB] = b.split('-');

      if (yearA !== yearB) {
        return parseInt(yearA, 10) - parseInt(yearB, 10);
      } else {
        return parseInt(monthA, 10) - parseInt(monthB, 10);
      }
    });

    // Преобразуем формат месяца
    const formattedMonths = sortedMonths.map(month => {
      const dateParts = month.split('-');
      const year = dateParts[0];
      const monthNumber = parseInt(dateParts[1], 10);
      dateParts.push(new Date(year, monthNumber - 1, 1).toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' }))
      return dateParts
    });

    return formattedMonths.reverse();
  }

  // Пример использования

  useEffect(() => {
    const availableMonths = getAvailableMonths(data);
    setSelectOptions(availableMonths.map((mon, ind) => <option key={ind} value={parseInt(ind)}>{mon[2]}</option>))
    setSelectMonth(availableMonths);
  }, [data])

  useEffect(() => {
    if (selectMonth[opt]) {
      const x = sortData(parseInt(selectMonth[opt][1]), parseInt(selectMonth[opt][0]), data)
      const expensesmin = x.map((exp) => 
        <ExpensesMin 
          key={exp.id} 
          exp={exp} 
          deleteExpenses={deleteExpenses} 
          updateExpenses={updateExpenses} 
          delWages={delWages} 
          updateWages={updateWages} 
          setOpenFormWage={setOpenFormWage}
          setUpdWages={setUpdWages}
          myCompany={myCompany}
          />)
      setExpMin(expensesmin)
      setSortData(x)
    }
  }, [selectMonth, opt, data])

  const select = (evt) => {
    setOptions(evt.target.value);
  }

  return <>
    <FormControl>
      <NativeSelect
        value={opt}
        inputProps={{
          name: 'month',
          id: 'uncontrolled-native',
        }}
        onChange={select}
      >
        {selectOptions}
      </NativeSelect>
    </FormControl>

    <BriefInfoExpenses dataExpenses={sortedData} />

    <Divider sx={{ borderColor: '#fcbb42', mt: 1 }} />

    <div>
      <List dense={true} sx={{ p: 0 }} >
        {expMin}
      </List>
    </div>

    <FloatingActionButtons setOpenFormNewexpenses={setOpenFormNewexpenses} />

    <Dialog
      open={openFormNewexpenses}
      onClose={submitCloseForm}
      scroll='body'
      aria-labelledby="customized-dialog-title"
      maxWidth='true'
    >
      <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #fcbb42' }} color="#07139e" id="customized-dialog-title">
        Новая запись
      </DialogTitle>
      <DialogContent >
        <NewExpenses addNewWages={addNewWages} newExpenses={newExpenses} submitCloseForm={submitCloseForm} uploadImgRequest={uploadImgRequest} myCompany={myCompany} />
      </DialogContent>
    </Dialog>

    <Dialog
      open={openFormWage}
      onClose={handleCloseFormWage}
      scroll='body'
    >
      <DialogContent>
        <UpdateWageForm
          handleCloseFormWage={handleCloseFormWage}
          updateExpenses={updateExpenses}
          updateWages={updateWages}
          updWages={updWages}
        />
      </DialogContent>
    </Dialog>
  </>
}

export default Expenses;
