import React from 'react';
import { Avatar, Card, CardHeader, Typography} from '@mui/material';

const CardTOO = ({myCompany}) => {

    const getRejim = () => {
        if (myCompany.rejym === 0) {
          return "Упрощённый"
        } else 
        if (myCompany.rejym === 1) {
          return "Общеустановленный"
        } else 
        if (myCompany.rejym === 2) {
          return "Розничный налог"
        } else {
          return "нет"
        }
    }

  return (<Card >
    <CardHeader
        avatar={
            <Avatar aria-label="recipe" />
        }

        title="Товариществом с ограниченной ответственностью"
        subheader={
            <>
                <Typography variant="body2" color="text.secondary">
                    {myCompany.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    ИИН/БИН: {myCompany.bin}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Режим: {getRejim()}
                </Typography>
            </>
        }
    />                
</Card>)}

export default CardTOO;