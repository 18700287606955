import React from 'react';
import { Box, Dialog, DialogContent, Fab, Grid } from '@mui/material';
import AddchartIcon from '@mui/icons-material/Addchart';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Swiper from './Components/Swiper';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import TableAdressCompany from '../../Components/TableAdressCompany';
import NewFNO913Form from '../../Forms/NewFNO913Form';
import Fno913min from './FormsFNO/Fno913min';
import { useEffect } from 'react';

const season = ['1 квартал', '2 квартал', '3 квартал', '4 квартал']
const seasonM = [['01', '02', '03'], ['04', '05', '06'], ['07', '08', '09'], ['10', '11', '12']]
let kvartal = 5

const nowDate = dayjs(new Date())

const Fno913 = ({ pointsUpdate, addNewWages, newExpenses, updateFNO913, addNewFNO913, myCompany }) => {

  const kgdCode = useSelector(state => state.globalSattings.kgdCode)
  const allApplications = useSelector(state => state.applicaions.allApplications)
  const pointsCompany = useSelector(state => state.pointsCompany.allPoints)
  const wages = useSelector(state => state.wages.wages)
  const allFno913 = useSelector(state => state.fno913.fno913)
  const authUser = useSelector(state => state.auth.authUser)
  
  const totalSeasonsT = [...new Set(allApplications.map((app) => app.date.split('.').splice(1, 2)).map((nm) => seasonM.map((s, index) => s.includes(nm[0]) ? index : false).filter((s) => s === 0 || s)[0].toString() + nm[1]))].map((s) => [parseInt(s.slice(0, 1)), s.slice(1, 5)])

  if ([0, 1, 2].includes(nowDate.$M)) { kvartal = 0 }
  if ([3, 4, 5].includes(nowDate.$M)) { kvartal = 1 }
  if ([6, 7, 8].includes(nowDate.$M)) { kvartal = 2 }
  if ([9, 10, 11].includes(nowDate.$M)) { kvartal = 3 }
  
  const totalSeasons = totalSeasonsT[0][0] === kvartal ? [...totalSeasonsT] : [[kvartal, nowDate.$y.toString()], ...totalSeasonsT]
 
  const [openForm, setOpenForm] = useState(false)
  const [openFormAddPoints, setOpenFormAddPoints] = useState(false)
  const [fno913, setFno913Form] = useState(<></>)

  useEffect(() => {    

    const getFno913 = allFno913.map((fno) =>
      <Fno913min key={fno.id} fno={fno} kgdCode={kgdCode} updateFNO913={updateFNO913} />
    )
    setFno913Form(getFno913.reverse())
  }, [allFno913, kgdCode, updateFNO913])

  const openPoints = () => {
    setOpenFormAddPoints(true)
  }

  const handleCloseFormAddPoints = () => {
    setOpenFormAddPoints(false)
  }

  if (pointsCompany.length === 0) {
    return <>
      <Box sx={{ textAlign: "center" }}>
        <Fab onClick={openPoints} variant="extended" size="medium" aria-label="add" sx={{ color: "#07139e", bgcolor: "#b39ddb", fontWeight: "600", marginTop: "10px" }}>
          <AddBusinessRoundedIcon color='secondary' sx={{ mr: 1 }} />
          Необходимо добавить Точку продаж!!!
        </Fab>
      </Box>
      <Dialog
        open={openFormAddPoints}
        onClose={handleCloseFormAddPoints}
        scroll='body'
      >
        <DialogContent>
          <TableAdressCompany pointsUpdate={pointsUpdate} idMyCompany={myCompany.id} />
        </DialogContent>
      </Dialog>
    </>
  }

  const getWagesWithSeason = (s, step) => {
    const m = [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10, 11, 12]]
    const wagesWithSeason =  wages.filter((w)=>s[1]===w.year.toString()).filter((w)=>m[s[0]].includes(w.month))
    const summWagesWithSeason = wagesWithSeason.reduce((sum, value) => sum + value.wage, 0)
    if (step === 0) {
      return summWagesWithSeason
    } else {
      return wagesWithSeason
    }
  }

const defoultPoints = authUser.idPoint === 0? pointsCompany[0]:authUser.idPoint
//const wagesWithSeason = totalSeasons.map((s)=>getWagesWithSeason(s, 1))

  const getTotalSeason = (s) => {
   
    const totalSum = allApplications.filter((app) => seasonM[s[0]].includes(app.date.split('.')[1]) && s[1] === app.date.split('.')[2]).map((app)=>({...app, idPoint: app.idPoint === 0? defoultPoints:app.idPoint}))
    
    const total = totalSum.reduce((sum, value) => sum + value.allTotal, 0)
    const tax = pointsCompany.map((p)=>p.kgd).map((p)=>kgdCode.filter((tax)=>tax.code === p)[0])
    const wage = getWagesWithSeason(s, 0)
    
    let total1 = pointsCompany.map((p)=>totalSum.filter((app)=> app.idPoint === p.idPoint).reduce((sum, value) => value.esf ? sum : sum + value.allTotal, 0))
    const ratio1 = total1.map((t)=>((t/total)*100).toFixed(2))  
    const wagesRetio1 = ratio1.map((t)=>(Math.round((parseFloat(t)/100)*wage))) 
  
    const personalTotal = [...total1]
    total1 = total1.map((t, index)=>t-wagesRetio1[index])
    const tax1 = total1.map((t,index)=> Math.round(t*(tax[index].personalTax/100)))
    let total2 = pointsCompany.map((p)=>totalSum.filter((app)=> app.idPoint === p.idPoint).reduce((sum, value) => value.esf ? sum + value.allTotal : sum, 0))
    const ratio2 = total2.map((t)=>((t/total)*100).toFixed(2))  
    const wagesRetio2 = ratio2.map((t)=>Math.round((parseFloat(t)/100)*wage)) 
    const companyTotal = [...total2]

    total2 = total2.map((t, index)=>t-wagesRetio2[index])

    const tax2 = total2.map((t,index)=> Math.round(t*(tax[index].companyTax/100)))
    const wagesSeason = getWagesWithSeason(s, 1)
        
    return { total, total1, total2, y: s[1], season: s[0], wages: wage, tax1, tax2, wageClolor: wage === 0? 'error':'default', pointsCompany, ratio1, ratio2, personalTotal, companyTotal, wagesSeason}
  }

  const summaryFNO913 = totalSeasons.map((s) => getTotalSeason(s))

  const maxSteps = summaryFNO913.length;

  const getFilterFNO913 = (season) => {
    let filtr = true
    allFno913.map((f)=>{
      if (f.season === season.season && f.year === season.y && f.status !== 2) {
        filtr = false
      } 
      return f
    })
    return filtr
  }

  const summaryFNO913OnlyNew = summaryFNO913.filter((f)=>getFilterFNO913(f))
  const totalSeasonsOnlyNew = summaryFNO913OnlyNew.map((s)=>[s.season, s.y])
  const wagesWithSeasonOnlyNew = totalSeasonsOnlyNew.map((s)=>getWagesWithSeason(s, 1))

  const handleCloseForm = () => {
    setOpenForm(false)
  }

  const handleClickNewFNO913 = () => {
    setOpenForm(true)
  }

  return (<>
    <Swiper maxSteps={maxSteps} summaryFNO913={summaryFNO913} season={season} />

    <Box sx={{ textAlign: "center" }}>

      <Fab onClick={handleClickNewFNO913} variant="extended" size="medium" aria-label="add" sx={{ color: "#07139e", bgcolor: "#b39ddb", fontWeight: "600", marginTop: "10px" }}>
        <AddchartIcon color='secondary' sx={{ mr: 1 }} />
        Создать новую ФНО 913.00
      </Fab>
      <Grid container>
        {fno913}
      </Grid>
      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        scroll='body'
      >
        <DialogContent>
        <NewFNO913Form
            handleCloseForm={handleCloseForm}
            totalSeasons={totalSeasonsOnlyNew}
            summaryFNO913={summaryFNO913OnlyNew}
            addNewWages={addNewWages}
            newExpenses={newExpenses}
            wagesWithSeason={wagesWithSeasonOnlyNew}
            addNewFNO913={addNewFNO913}
            myCompany={myCompany}
          />
        </DialogContent>
      </Dialog>
    </Box>
  </>
  )
}

export default Fno913;