import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography
} from "@mui/material";
import { useSelector } from 'react-redux';
import DocumentOffert from '../Docs/DocumentOffert';
import CloseIcon from '@mui/icons-material/Close';

const myHelper = {
  email: {
    required: "Заполните поле",
    pattern: "Не корректный формат e-mail"
  },
  text: {
    required: "Заполните поле"
  },
  password: {
    required: "Заполните поле",
    minLength: "Пароль должен быть не менее 3 символов"
  }
};

const RegistrationForm = ({ setOpenFormReg, addUserPwdRequest }) => {
  const [openForm, setOpenForm] = React.useState(false);
  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });

  const handleOpenForm = () => {
    setOpenForm(true)
  }
  const handleCloseForm = () => {
    setOpenForm(false)
  }
  const openCollapse = useSelector(state => state.auth.openCollapseRegError)
  const errorAuth = useSelector(state => state.auth.errorAuth)

  const handleOnClose = () => {
    setOpenFormReg(false)
  };

  const handleOnSubmit = (evt) => {
    addUserPwdRequest(evt)
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Typography variant="h5" gutterBottom component="div" sx={{ color: '#07139e', textAlign: 'center', mb: 3 }}>
          Регистрация
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="firstname"
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  size="small"
                  label="Имя"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="lastname"
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  size="small"
                  label="Фамилия"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} >
            <Collapse in={openCollapse}>
              <Alert severity="error">{errorAuth}</Alert>
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="login"
              defaultValue=""
              rules={{
                required: true,
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="email"
                  fullWidth
                  size="small"
                  label="E-mail"
                  error={error !== undefined}
                  helperText={error ? myHelper.email[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="password"
              defaultValue=""
              rules={{
                required: true,
                minLength: 3
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="password"
                  fullWidth
                  size="small"
                  label="Пароль"
                  error={error !== undefined}
                  helperText={error ? myHelper.password[error.type] : ""}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="ofertaDocs"
              defaultValue={false}              
              render={({ field }) => (
                <FormControlLabel  control={<Checkbox size="small" required checked={field.value} />} label={<>
                <Typography variant="caption" display="block" gutterBottom>
                Я ознакомился и согласен с условиями:
                </Typography>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleOpenForm}
                    type="button"
                  >
                    Договор оферта, Политика конфиденциальности и Пользовательское соглашение.
                  </Link>
                  </>
                } {...field} />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Button type="submit" size="large" color="secondary" variant="outlined" fullWidth>Регистрация</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleOnClose} size="large" color="secondary" variant="contained" type="button" fullWidth>Отмена</Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        scroll='body'
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #fcbb42' }} color="#07139e" id="customized-dialog-title">
          Публичная оферта (предложение) оказания бухгалтерских услуг от ТОО «Finup.kz»
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseForm}
          color='error'
          sx={{
            position: 'absolute',
            right: 1,
            top: 1,
            p: '5px'
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContentText >
          <DocumentOffert />
        </DialogContentText>
      </Dialog>
    </>
  );
}

export default RegistrationForm