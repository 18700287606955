//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import { contentsRequest } from '../Redux/contentReducer'
import { getApplicationRequest } from '../Redux/applicationsReducer'
import { getOldApplicationRequest } from '../Redux/oldApplicationsReducer'
import { getAddrDeliveryRequest } from '../Redux/addrDeliveryReducer'
import { getAllWagesRequst } from '../Redux/wagesReducer'
import { getAllExpensGroupsRequst } from '../Redux/expensGroupsReducer'
import { getAllExpensesRequest } from '../Redux/expensesReducer'
import { getAllFNO910Requst } from '../Redux/fno910Reducer'
import { getAllFNO913Requst } from '../Redux/fno913Reducer'
import { pointsRequest, pointsUpdate } from '../Redux/pointsReducer'
import { chatsRequest } from '../Redux/chatReducer';
import Dashboard from './Dashboard';
import { getCompaniesRequest } from '../Redux/companiesReducer';
import { getUsersRequst } from '../Redux/usersReducer';
import { getALLTaskRequest } from '../Redux/taskReducer';

class DashboardContainer extends React.PureComponent {

    render() {
        return (<Dashboard                     
                    getCompaniesRequest={this.props.getCompaniesRequest}
                    contentsRequest = {this.props.contentsRequest}
                    getApplicationRequest = {this.props.getApplicationRequest}
                    getAddrDeliveryRequest={this.props.getAddrDeliveryRequest}
                    getOldApplicationRequest={this.props.getOldApplicationRequest}      
                    getAllWagesRequst={this.props.getAllWagesRequst}
                    getUsersRequst={this.props.getUsersRequst}
                    getAllExpensGroupsRequst={this.props.getAllExpensGroupsRequst}
                    getAllExpensesRequest={this.props.getAllExpensesRequest}
                    getAllFNO910Requst={this.props.getAllFNO910Requst}
                    pointsRequest={this.props.pointsRequest}
                    getAllFNO913Requst={this.props.getAllFNO913Requst}
                    chatsRequest={this.props.chatsRequest}
                    pointsUpdate={this.props.pointsUpdate}
                    getALLTaskRequest={this.props.getALLTaskRequest}
                />)
    }
}

const mapStateToProps = (state) => {
    return {   
    }
};

export default connect(mapStateToProps, {     
    getCompaniesRequest, 
    contentsRequest, 
    getApplicationRequest, 
    getAddrDeliveryRequest,
    getOldApplicationRequest,      
    getAllWagesRequst,
    getAllExpensGroupsRequst,
    getAllExpensesRequest,
    getAllFNO910Requst,
    pointsRequest,
    getAllFNO913Requst,
    chatsRequest,
    getUsersRequst,
    pointsUpdate,
    getALLTaskRequest
})(DashboardContainer)
