import { StyleSheet, Text, View } from '@react-pdf/renderer';
import * as React from 'react';



const NakladnayaTableContent = ({ nakladnaya, sumWithContentAmount, sumWithContentSumm }) => {

    const styles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableSum: {
            display: "table",
            width: "auto",
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row",
        },
        tableRowSmall: {
            flexDirection: "row",
            height: '25px'
        },
        tableCol5: {
            width: "5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol45: {
            width: "45%",
        },
        tableCol24: {
            width: "24%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableCol12: {
            width: "12%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol9: {
            width: "9%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol7: {
            width: "7%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol114: {
            width: "11.4%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol76: {
            width: "7.6%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol19: {
            width: "19%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol14: {
            width: "14%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol40Left: {
            width: "40%",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderTopWidth: 0,
            borderBottomWidth: 0,
            padding: '0'
        },
        tableCol60: {
            width: "60%",
        },
        tableCell: {
            width: '100%',
            textAlign: 'center',
            fontSize: 10,
            verticalAlign: 'sub',
            marginTop: 'auto',
            marginBottom: '2px'
        },
        tableCellTop: {
            width: '100%',
            textAlign: 'center',
            fontSize: 10,
            verticalAlign: 'sub',
            paddingBottom: '2px',
            margin: 'auto',
        },
        tableCellSumm: {
            textAlign: 'right',
            fontSize: 10,
            marginTop: 'auto',
            marginBottom: '2px',
            width: '100%',
            paddingRight: 3,
        },
        tableCellSummRight: {
            textAlign: 'right',
            fontSize: 10,
            marginTop: 'auto',
            marginBottom: '2px',
            width: '100%',
            paddingTop: 3,
            paddingRight: 3,
            verticalAlign: 'sub'

        },
        tableCellName: {
            width: '100%',
            textAlign: 'left',
            fontSize: 10,
            marginTop: 'auto',
            marginBottom: '2px',
            verticalAlign: 'sub'
        },
        tableCol100: {
            borderWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderTopWidth: 0
        },
        tableCellSummBorderLeft: {
            borderLeftWidth: 1
        }
    })

    return (
        <View>
            <View style={styles.table}>
                {/* шапка таблицы */}
                <View style={styles.tableRow}>
                    <View style={styles.tableCol5}>
                        <Text style={styles.tableCellTop}>Номер по порядку</Text>
                    </View>
                    <View style={styles.tableCol24}>
                        <Text style={styles.tableCellTop}>Наименование, характеристика</Text>
                    </View>
                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCellTop}>Номенкла- турный номер</Text>
                    </View>
                    <View style={styles.tableCol7}>
                        <Text style={styles.tableCellTop}>Единица измерения</Text>
                    </View>
                    <View style={styles.tableCol19}>
                        <View style={styles.tableCol100} >
                            <Text style={styles.tableCellTop}>Количество</Text>
                        </View>
                        <View style={styles.tableRowSmall}>
                            <View style={styles.tableCol60}>
                                <Text style={styles.tableCellTop}>подлежит отпуску</Text>
                            </View>
                            <View style={styles.tableCol40Left}>
                                <Text style={styles.tableCellTop}>отпущено</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.tableCol12}>
                        <Text style={styles.tableCellTop}>Цена за единицу, в KZT</Text>
                    </View>
                    <View style={styles.tableCol12}>
                        <Text style={styles.tableCellTop}>Сумма с НДС, в KZT</Text>
                    </View>
                    <View style={styles.tableCol12}>
                        <Text style={styles.tableCellTop}>Сумма НДС, в KZT</Text>
                    </View>
                </View>

                <View style={styles.tableRow}>
                    <View style={styles.tableCol5}>
                        <Text style={styles.tableCell}>1</Text>
                    </View>
                    <View style={styles.tableCol24}>
                        <Text style={styles.tableCell}>2</Text>
                    </View>
                    <View style={styles.tableCol9}>
                        <Text style={styles.tableCell}>3</Text>
                    </View>
                    <View style={styles.tableCol7}>
                        <Text style={styles.tableCell}>4</Text>
                    </View>
                    <View style={styles.tableCol114}>
                        <Text style={styles.tableCell}>5</Text>
                    </View>
                    <View style={styles.tableCol76}>
                        <Text style={styles.tableCell}>6</Text>
                    </View>
                    <View style={styles.tableCol12}>
                        <Text style={styles.tableCell}>7</Text>
                    </View>
                    <View style={styles.tableCol12}>
                        <Text style={styles.tableCell}>8</Text>
                    </View>
                    <View style={styles.tableCol12}>
                        <Text style={styles.tableCell}>9</Text>
                    </View>
                </View>
                {/* Данные таблицы */}
                {nakladnaya.map((cont, index) =>
                    <View key={cont.id} style={styles.tableRow}>
                        <View style={styles.tableCol5}>
                            <Text style={styles.tableCell}>{index + 1}</Text>
                        </View>
                        <View style={styles.tableCol24}>
                            <Text style={styles.tableCellName}>{cont.name}</Text>
                        </View>
                        <View style={styles.tableCol9}>
                            <Text style={styles.tableCell}>{cont.plu}</Text>
                        </View>
                        <View style={styles.tableCol7}>
                            <Text style={styles.tableCell}>{cont.unit}</Text>
                        </View>
                        <View style={styles.tableCol114}>
                            <Text style={styles.tableCellSummRight}>{cont.amount}</Text>
                        </View>
                        <View style={styles.tableCol76}>
                            <Text style={styles.tableCellSummRight}>{cont.amount}</Text>
                        </View>
                        <View style={styles.tableCol12}>
                            <Text style={styles.tableCellSummRight}>{cont.price},00</Text>
                        </View>
                        <View style={styles.tableCol12}>
                            <Text style={styles.tableCellSummRight}>{cont.total},00</Text>
                        </View>
                        <View style={styles.tableCol12}>
                            <Text style={styles.tableCellSummRight}>0,00</Text>
                        </View>
                    </View>
                )}
            </View>
            {/* итого */}
            <View style={styles.tableSum}>
                <View style={styles.tableRow}>
                    <View style={styles.tableCol45}>
                        <Text style={styles.tableCellSumm}>Итого</Text>
                    </View>
                    <View style={styles.tableCol114}>
                        <View style={styles.tableCellSummBorderLeft}>
                            <Text style={styles.tableCellSummRight}>{sumWithContentAmount}</Text>
                        </View>
                    </View>
                    <View style={styles.tableCol76}>
                        <Text style={styles.tableCellSummRight}>{sumWithContentAmount}</Text>
                    </View>
                    <View style={styles.tableCol12}>
                        <Text style={styles.tableCell}>х</Text>
                    </View>
                    <View style={styles.tableCol12}>
                        <Text style={styles.tableCellSummRight}>{sumWithContentSumm},00</Text>
                    </View>
                    <View style={styles.tableCol12}>
                        <Text style={styles.tableCellSummRight}>0,00</Text>
                    </View>
                </View>
            </View>
        </View>
    );
}

export default NakladnayaTableContent;