import * as React from 'react';
import BriefInfoCard from './BriefInfoCard';
import { useState } from 'react';
import { useEffect } from 'react';
import { Grid } from '@mui/material';

const FunctionCard = ({ allApplications, words }) => {
    const [total, setTotal] = useState(0)
    const [totalOrders, setTotalOrders] = useState(0)
    const [totalProduct, setTotalProduct] = useState(0)
    const [totalCash, setTotalCash] = useState(0)

    const [data, setData] = useState([]);

    // Предположим, что данные приходят в виде JSON и содержат поле 'date'
    

    useEffect(() => {
        const jsonData = [...allApplications];

        // Функция для преобразования строки даты в объект Date
        const parseDate = dateString => {
            const [day, month, year] = dateString.split('.').map(Number);
            return new Date(year, month - 1, day); // Месяцы в JavaScript начинаются с 0
        };

        // Функция для сортировки данных по дате
        const sortByDate = (a, b) => {
            const dateA = parseDate(a.date);
            const dateB = parseDate(b.date);
            return dateA - dateB;
        };

        // Сортировка данных по дате
        const sortedData = jsonData.sort(sortByDate);

        // Фильтрация данных для текущего дня
        const currentDate = new Date();
        const currentDayData = sortedData.filter(item => {
            const itemDate = parseDate(item.date);
            return itemDate.getDate() === currentDate.getDate() &&
                itemDate.getMonth() === currentDate.getMonth() &&
                itemDate.getFullYear() === currentDate.getFullYear();
        });

        // Фильтрация данных для текущей недели
        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
        const endOfWeek = new Date(currentDate);
        endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay()));
        const currentWeekData = sortedData.filter(item => {
            const itemDate = parseDate(item.date);
            return itemDate >= startOfWeek && itemDate <= endOfWeek;
        });

        // Фильтрация данных для текущего месяца
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const currentMonthData = sortedData.filter(item => {
            const itemDate = parseDate(item.date);
            return itemDate >= startOfMonth && itemDate <= endOfMonth;
        });

        // Фильтрация данных для текущего года
        const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
        const endOfYear = new Date(currentDate.getFullYear(), 11, 31);
        const currentYearData = sortedData.filter(item => {
            const itemDate = parseDate(item.date);
            return itemDate >= startOfYear && itemDate <= endOfYear;
        });

        // Установка отсортированных и отфильтрованных данных в состояние
        setData({
            allData: sortedData,
            currentDayData,
            currentWeekData,
            currentMonthData,
            currentYearData,
        });
        
    }, [allApplications]); // Зависимость пуста, чтобы useEffect выполнился только один раз

    useEffect(() =>{
        if (data.currentDayData) {        
        cetFilterProduct()
        cetFilterCash()
        cetFilterOrders()
        cetFilterTotal()
        }
    }, [data])


    const cardColor = ['rgb(144, 202, 249)', 'rgb(179, 157, 219)', 'rgb(255, 229, 127)', 'rgb(46 125 50 / 57%)']
    const cardText = ['Продано товаров и услуг', 'Поступило средств', 'Всего заявок', 'Сумма реализации']

    const cetFilterProduct = (date) => {
        let allProd = 0
        switch (date) {
            case 0:
                allProd = data.currentDayData.map((app) => app.content).map((ap) => ap.reduce((sum, value) => sum + value.amount, 0)).reduce((sum, value) => sum + value, 0)
                setTotalProduct(allProd)
                break
            case 1:
                allProd = data.currentWeekData.map((app) => app.content).map((ap) => ap.reduce((sum, value) => sum + value.amount, 0)).reduce((sum, value) => sum + value, 0)
                setTotalProduct(allProd)
                break
            case 2:
                allProd = data.currentMonthData.map((app) => app.content).map((ap) => ap.reduce((sum, value) => sum + value.amount, 0)).reduce((sum, value) => sum + value, 0)
                setTotalProduct(allProd)
                break
            case 3:
                allProd = data.currentYearData.map((app) => app.content).map((ap) => ap.reduce((sum, value) => sum + value.amount, 0)).reduce((sum, value) => sum + value, 0)
                setTotalProduct(allProd)
                break
            default:
                allProd = data.currentMonthData.map((app) => app.content).map((ap) => ap.reduce((sum, value) => sum + value.amount, 0)).reduce((sum, value) => sum + value, 0)
                setTotalProduct(allProd)
                break
        }
    }

    const cetFilterCash = (date) => {
        let allCash = 0
        switch (date) {
            case 0:
                allCash = data.currentDayData.filter((app) => app.payment).reduce((sum, value) => sum + value.allTotal, 0)
                setTotalCash(allCash)
                break
            case 1:
                allCash = data.currentWeekData.filter((app) => app.payment).reduce((sum, value) => sum + value.allTotal, 0)
                setTotalCash(allCash)
                break
            case 2:
                allCash = data.currentMonthData.filter((app) => app.payment).reduce((sum, value) => sum + value.allTotal, 0)
                setTotalCash(allCash)
                break
            case 3:
                allCash = data.currentYearData.filter((app) => app.payment).reduce((sum, value) => sum + value.allTotal, 0)
                setTotalCash(allCash)
                break
            default:
                allCash = data.currentMonthData.filter((app) => app.payment).reduce((sum, value) => sum + value.allTotal, 0)
                setTotalCash(allCash)
                break
        }
    }

    const cetFilterOrders = (date) => {
        let allOrders = 0
        switch (date) {
            case 0:
                allOrders = data.currentDayData.length
                setTotalOrders(allOrders)
                break
            case 1:
                allOrders = data.currentWeekData.length
                setTotalOrders(allOrders)
                break
            case 2:
                allOrders = data.currentMonthData.length
                setTotalOrders(allOrders)
                break
            case 3:
                allOrders = data.currentYearData.length
                setTotalOrders(allOrders)
                break
            default:
                allOrders = data.currentMonthData.length
                setTotalOrders(allOrders)
                break
        }
    }

    const cetFilterTotal = (date) => {
        let allTotal = 0
        switch (date) {
            case 0:
                allTotal = data.currentDayData.reduce((sum, value) => sum + value.allTotal, 0)
                setTotal(allTotal)
                break
            case 1:
                allTotal = data.currentWeekData.reduce((sum, value) => sum + value.allTotal, 0)
                setTotal(allTotal)
                break
            case 2:
                allTotal = data.currentMonthData.reduce((sum, value) => sum + value.allTotal, 0)
                setTotal(allTotal)
                break
            case 3:
                allTotal = data.currentYearData.reduce((sum, value) => sum + value.allTotal, 0)
                setTotal(allTotal)
                break
            default:
                allTotal = data.currentMonthData.reduce((sum, value) => sum + value.allTotal, 0)
                setTotal(allTotal)
                break
        }
    }

    return (<>

        <Grid item xs={12} sm={12} md={6} lg={3}>
            <BriefInfoCard
                total={totalProduct}
                words={words}
                cardColor={cardColor[0]}
                cardText={cardText[0]}
                cash={false}
                cetFilter={cetFilterProduct}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
            <BriefInfoCard
                total={totalCash}
                words={words}
                cardColor={cardColor[1]}
                cardText={cardText[1]}
                cash={true}
                cetFilter={cetFilterCash}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
            <BriefInfoCard
                total={totalOrders}
                words={words}
                cardColor={cardColor[2]}
                cardText={cardText[2]}
                cash={false}
                cetFilter={cetFilterOrders}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
            <BriefInfoCard
                total={total}
                words={words}
                cardColor={cardColor[3]}
                cardText={cardText[3]}
                cash={true}
                cetFilter={cetFilterTotal}
            />
        </Grid>
    </>)
}

export default FunctionCard