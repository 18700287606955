//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import { getAuthUser } from '../Redux/authSelectors';
import { getMyCompony } from '../Redux/mycompanySelectors';
import { updateAuthUserRequest, updateUserPwdRequest } from '../Redux/authReducer'
import { updateMyCompanyRequest, uploadSignatureImgRequest, uploadSealImgRequest } from '../Redux/mycompanyReducer'
import { clientsDelete, clientsUpdate } from '../Redux/companiesReducer'
import { contentsUpdate, contentsDelete } from '../Redux/contentReducer'
import { delivAddressUpdate, delivAddressDelete } from '../Redux/addrDeliveryReducer'
import Client from './Client';
import { chatsRequest } from '../Redux/chatReducer';

class ClientContainer extends React.PureComponent {

    render() {
        return (<>
            <div style={{maxWidth: "412px", margin: "0 auto", display: 'contents'}} >
                <Client                    
                    authUser={this.props.authUser}
                    myCompony={this.props.myCompony}
                    updateAuthUserRequest = {this.props.updateAuthUserRequest}
                    updateUserPwdRequest={this.props.updateUserPwdRequest}
                    chatsRequest={this.props.chatsRequest}
                    contentsUpdate={this.props.contentsUpdate}
                    contentsDelete={this.props.contentsDelete}
                    clientsUpdate={this.props.clientsUpdate}
                    clientsDelete={this.props.clientsDelete}                    
                    delivAddressUpdate={this.props.delivAddressUpdate}
                    delivAddressDelete={this.props.delivAddressDelete}    
                    updateMyCompanyRequest={this.props.updateMyCompanyRequest}                     
                    uploadSealImgRequest={this.props.uploadSealImgRequest}
                    uploadSignatureImgRequest={this.props.uploadSignatureImgRequest}           
                />
            </div>
        </>)
    }
}

const mapStateToProps = (state) => {
    return {    
        authUser: getAuthUser(state),
        myCompony: getMyCompony(state),
    }
};

export default connect(mapStateToProps, {        
    chatsRequest,
    updateAuthUserRequest,
    updateUserPwdRequest,
    updateMyCompanyRequest,
    contentsUpdate,
    contentsDelete,
    clientsUpdate,
    clientsDelete,    
    delivAddressUpdate,
    delivAddressDelete,
    uploadSealImgRequest,  
    uploadSignatureImgRequest
})(ClientContainer)
