import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import NewApplication from '../../Forms/NewApplication';

const EditOrders = ({ selectCompany, openFormApplic, handleCloseFormProfile, nuberDoc, dateDoc, allContents, allCompanies, openFormApplicId, allApplications, authUser, myCompony, setApplicationRequest, updateApplicationRequest }) => {

    return (
        <Dialog
            open={openFormApplic}
            onClose={handleCloseFormProfile}
            scroll='body'
            aria-labelledby="customized-dialog-title"
        >
            <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #07139e', fontSize: '18px' }} color="#07139e" id="customized-dialog-title">
                {(openFormApplicId.id < 1) ? "Новая заявка" : "Заявка № " + nuberDoc + " от " + dateDoc + " г."}
            </DialogTitle>



            <DialogContent >
                <div style={{ marginTop: '10px' }}>
                    <NewApplication
                        selectCompany={selectCompany}
                        allContents={allContents}
                        allCompanies={allCompanies}
                        applicationId={openFormApplicId}
                        allApplications={allApplications}
                        authUser={authUser}
                        myCompony={myCompony}
                        setApplicationRequest={setApplicationRequest}
                        handleCloseFormProfile={handleCloseFormProfile}
                        updateApplicationRequest={updateApplicationRequest}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default EditOrders