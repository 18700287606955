import React from 'react';
import { Box, Divider, Grid, MobileStepper, Paper, Stack, Typography, styled, useTheme } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';

const PaperItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  maxWidth: 400,
}));

const Swiper = ({ maxSteps, summaryFNO913, season }) => {

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {summaryFNO913.map((step, index) => (
          <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 2 }} key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <PaperItem
                elevation={3}
                sx={{
                  my: 1,
                  mx: 'auto',
                  p: 2,
                }}>
                <Typography>{season[step.season] + " " + step.y + "г."}</Typography>
                <Divider />

                <Stack spacing={1} direction="row" alignItems="center">
                  <Stack>
                    <AccountBalanceRoundedIcon color='primary' />
                  </Stack>
                  <Stack sx={{ minWidth: 0 }}>
                    <Typography noWrap>Доход: {step.total.toLocaleString("ru-RU")} тг.</Typography>
                  </Stack>
                </Stack>
                <Divider />

                <Stack spacing={1} direction="row" alignItems="center">
                  <Stack>
                    <AccountBalanceWalletRoundedIcon color='primary' />
                  </Stack>
                  <Stack sx={{ minWidth: 0 }}>
                    <Typography color={step.wageClolor} noWrap>Выплачено ЗП: {step.wages.toLocaleString("ru-RU")} тг.</Typography>
                  </Stack>
                </Stack>
                <Divider />
                {step.total1.map((t, index) => (<div key={index}>
                  <Typography fontSize={"12px"} fontWeight={"600"} noWrap>Точка продаж #{index}</Typography>
                  <Divider />
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Stack>
                      <AccountCircleRoundedIcon color='primary' />
                    </Stack>

                    <Stack direction="row" spacing={0.8} sx={{ minWidth: 0 }}>
                      <Typography fontSize={"12px"} fontWeight={"600"} noWrap>Физ.лиц: {t.toLocaleString("ru-RU")} тг.</Typography>
                      <Divider orientation="vertical" variant="middle" flexItem />
                      <Typography fontSize={"12px"} fontWeight={"600"} noWrap>Налог: {step.tax1[index].toLocaleString("ru-RU")} тг.</Typography>
                    </Stack>

                  </Stack>
                  <Divider />
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Stack>
                      <CorporateFareRoundedIcon color='primary' />
                    </Stack>

                    <Stack direction="row" spacing={0.8} sx={{ minWidth: 0 }}>
                      <Typography fontSize={"12px"} fontWeight={"600"} noWrap>Юр.лиц: {step.total2[index].toLocaleString("ru-RU")} тг.</Typography>
                      <Divider orientation="vertical" variant="middle" flexItem />
                      <Typography fontSize={"12px"} fontWeight={"600"} noWrap>Налог: {step.tax2[index].toLocaleString("ru-RU")} тг.</Typography>
                    </Stack>

                  </Stack>
                  <Divider />
                </div>))}

              </PaperItem>
            ) : null}
          </Box>
        ))}
      </SwipeableViews>
      <Grid container >

        <Grid item xs={4} sm={4} md={4} >

        </Grid>
        <Grid item xs={4} sm={4} md={4}  >
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{ py: 0, mb: 1, ml: maxSteps > 4 ? 10 - maxSteps : 8 - maxSteps }}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4} >

        </Grid>
      </Grid>
    </Box>
  )
}

export default Swiper;