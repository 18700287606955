import * as React from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { Backdrop, Badge, Button, CircularProgress, styled, Tooltip } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const UploadButtonTask = ({ uploadFileTaskRequest, task, giveTimeDate, caption }) => {

  const backdrop = useSelector(state => state.task.backdrop)
  const file = useSelector(state => state.task.file)
  const [image, setImage] = useState(null);
  const [type, setType] = useState(99);
  const filesClosedTask = useSelector(state => state.task.filesClosedTask)

  useEffect(() => {
    if (image) {
      uploadFileTaskRequest(image, task.id, { idTask: task.id, idUser: giveTimeDate().user, url: file, date: giveTimeDate().date, time: giveTimeDate().time, name: image.name, type })
      setImage(null)
    }
  }, [image])

  const uploadImg = async (e) => {

    const file = e.target.files[0];
    const typeIMG = file.type.slice(0, 5)

    if (file && typeIMG === "image") {
      const dimensions = await getImageDimensions(file);

      const maxSizeInMB = 1;

      if (file.size / (1024 * 1024) > maxSizeInMB) {
        // Урезаем изображение
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const maxWidth = 800; // Желаемая ширина изображения

        const scale = maxWidth / dimensions.width;
        canvas.width = maxWidth;
        canvas.height = dimensions.height * scale;

        ctx.drawImage(dimensions.img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          const resizedFile = new File([blob], file.name, {
            type: 'image/jpeg', // Меняйте тип в соответствии с вашими требованиями
          });
          setType(1)
          setImage(resizedFile)
        }, 'image/jpeg', 0.7) // 0.7 - качество изображения, можно изменять
      } else {
        // Изображение не требует урезки
        setType(1)
        setImage(file)
      }
    } else {
      switch (file.type) {
        case "application/pdf":
          setType(2);
          break;

        default:
          setType(3);
          break;
      }
      setImage(file)
    }
  };

  const getImageDimensions = (file) => {

    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          resolve({ img, width: img.width, height: img.height });
        };
      };

      reader.readAsDataURL(file);
    });
  }

  return (<>
    <Tooltip title="Загрузить документы">    
      <Button component="label" size='small' variant="outlined" startIcon={
        <Badge badgeContent={task.uploadFile ? filesClosedTask.filter((item) => item.idTask === task.id).length : 0} color="primary">
          <UploadFileIcon color='info' sx={{ m: 0, width: '30px', height: '30px' }}  />
          </Badge>
      } sx={{ border: 0, p: 0, minWidth: '45px', pl: 1 }} >
        <VisuallyHiddenInput onChange={uploadImg} type="file" accept='*' />
        {caption}
      </Button>  
              </Tooltip>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={backdrop}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  </>
  )
}

export default UploadButtonTask;
