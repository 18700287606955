//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Expenses from './Expenses';
import { newExpenses, deleteExpenses, updateExpenses, uploadImgRequest } from '../Redux/expensesReducer'
import { addNewWages, delWages, updateWages } from '../Redux/wagesReducer'

class ExpensesContainer extends React.PureComponent {

    render() {
        return (<>
            <div >
                <Expenses                   
                    newExpenses={this.props.newExpenses}
                    addNewWages={this.props.addNewWages}
                    deleteExpenses={this.props.deleteExpenses}
                    updateExpenses={this.props.updateExpenses}
                    delWages={this.props.delWages}
                    updateWages={this.props.updateWages}
                    uploadImgRequest={this.props.uploadImgRequest}
                    myCompany={this.props.myCompany}
                />
            </div>
        </>)
    }
}

const mapStateToProps = (state) => {
    return {    
    }
};

export default connect(mapStateToProps, { newExpenses, addNewWages, deleteExpenses, updateExpenses, delWages, updateWages, uploadImgRequest })(ExpensesContainer)
