//import s from './MainPanelAdministration.module.css';
import React from 'react';
import UserProfilePanel from './UserProfilePanel';
import { connect } from 'react-redux';
import { getAuthUser } from '../../../Redux/authSelectors';
import { updateAuthUserRequest, updateUserPwdRequest } from '../../../Redux/authReducer'



class UserProfilePanelContainer extends React.PureComponent {

    render() {
        return (<>         
        <div style={{maxWidth: "412px", }} >   
                <UserProfilePanel                   
                    authUser={this.props.authUser}   
                    updateAuthUserRequest={this.props.updateAuthUserRequest}                
                    updateUserPwdRequest={this.props.updateUserPwdRequest}                
                />         
                </div>     
        </>)
    }
}

const mapStateToProps = (state) => {
    return {        
        authUser: getAuthUser(state),
    }
};

export default connect(mapStateToProps, {   
    updateAuthUserRequest, 
    updateUserPwdRequest
})(UserProfilePanelContainer)
