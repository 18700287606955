import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from 'react-redux';
import { useState } from 'react';
import dayjs from 'dayjs';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useEffect } from 'react';
import DialogPayTaxConfirmation from './DialogPayTaxConfirmation';
import NewWageForm from '../Fno/Fno/Components/NewWageForm';

const myHelper = {
  text: {
    required: "Заполните поле"
  }
};

const tableRimCount = ["Ⅰ", "Ⅱ", "Ⅲ", "Ⅳ", "Ⅴ", "Ⅵ"]
const season1 = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь"]
const season2 = ["Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]

const NewFNO910Form = ({ handleCloseForm, addNewFNO910, addNewWages, newExpenses, myCompany }) => {

  const [open, setOpen] = useState(false);
  const [openFormNewWage, setOpenFormNewWage] = useState(false);

  const wages = useSelector(state => state.wages.wages)
  const backdrop = useSelector(state => state.fno910.isFetching)
  const fno910 = useSelector(state => state.fno910.fno910)
  const authUser = useSelector(state => state.auth.authUser)
  const allApplications = useSelector(state => state.applicaions.allApplications)
  
  const handleClose = () => {
    setOpen(false);
    setOpenFormNewWage(false);
  };

  const nowDate = dayjs(new Date())

  let years = []

  for (let i = 0; i < 4; i++) {
    years.push(nowDate.$y - i)
  }

  const [season, setSeason] = useState(0);
  const [seasonMonth, setSeasonMonth] = useState(season1);
  const [seasonWages, setSeasonWages] = useState([]);
  const [seasonIndex, setSeasonIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const [year, setYear] = useState(nowDate.$y);
  const [sentFNO, setSentFNO] = useState(false);
  const [btnSent, setBtnSent] = useState(true);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [summApps, setSummApps] = useState(0)

  useEffect(() => {
    const month = season===0?[1,2,3,4,5,6]:[7,8,9,10,11,12]
    const apps = allApplications.map((a)=>({...a, m: parseInt(a.date.slice(3, 5)), y: parseInt(a.date.slice(6, 10))})).filter((a)=>a.y===year&&month.includes(a.m)) //Applications with selected season
    const summ = apps.reduce((a, b) => a + b.allTotal, 0)
    setSummApps(summ);
  }, [allApplications, season, year])

  useEffect(() => {
    if (sentFNO) {

      const socMath = (wage, mzp) => {
        const sumSoc = wage * 0.9
        let socTotal = mzp * 0.035
        if (sumSoc > mzp) {
          if (sumSoc > mzp * 7) {
            socTotal = (mzp * 7) * 0.035
          } else {
            socTotal = sumSoc * 0.035
          }
        }
        return Math.round(socTotal)
      }

      const monthWages = tableRimCount.map((li, index) => seasonWages.filter((w) => w.month === (index + 1) + seasonIndex))
      const allSoc = seasonWages.map((w) => socMath(w.wage, w.mzp))
      const idMyCompany = myCompany.id
      const idUser = authUser.id
      const m1 = monthWages[0].map((w) => ({ ...w, opw013: w.wage * 0.1, co011: socMath(w.wage, w.mzp), opwr014: w.wage * 0.015, wosms015: (w.mzp * 1.4) * 0.05 }))
      const m2 = monthWages[1].map((w) => ({ ...w, opw013: w.wage * 0.1, co011: socMath(w.wage, w.mzp), opwr014: w.wage * 0.015, wosms015: (w.mzp * 1.4) * 0.05 }))
      const m3 = monthWages[2].map((w) => ({ ...w, opw013: w.wage * 0.1, co011: socMath(w.wage, w.mzp), opwr014: w.wage * 0.015, wosms015: (w.mzp * 1.4) * 0.05 }))
      const m4 = monthWages[3].map((w) => ({ ...w, opw013: w.wage * 0.1, co011: socMath(w.wage, w.mzp), opwr014: w.wage * 0.015, wosms015: (w.mzp * 1.4) * 0.05 }))
      const m5 = monthWages[4].map((w) => ({ ...w, opw013: w.wage * 0.1, co011: socMath(w.wage, w.mzp), opwr014: w.wage * 0.015, wosms015: (w.mzp * 1.4) * 0.05 }))
      const m6 = monthWages[5].map((w) => ({ ...w, opw013: w.wage * 0.1, co011: socMath(w.wage, w.mzp), opwr014: w.wage * 0.015, wosms015: (w.mzp * 1.4) * 0.05 }))
      const ipn101202 = total * 0.015
      const cn103101 = ipn101202 > allSoc.reduce((sum, value) => sum + value, 0) ? ipn101202 - allSoc.reduce((sum, value) => sum + value, 0) : 0
      addNewFNO910({ idMyCompany, idUser, season, year, total, m1, m2, m3, m4, m5, m6, ipn101202, cn103101, allSoc: allSoc.reduce((sum, value) => sum + value, 0) }, idMyCompany)
      handleCloseForm()
      setSentFNO(false)
    }

  }, [sentFNO, authUser.id, handleCloseForm, myCompany.id, seasonIndex, seasonWages, total, addNewFNO910, season, year])


  useEffect(() => {
    const yearWages = wages.filter((w) => w.year === year)
    if (season === 0) {
      setSeasonMonth(season1)
      const sw = yearWages.filter((w) => [1, 2, 3, 4, 5, 6].includes(w.month))
      setSeasonIndex(0)
      setSeasonWages(sw)
    } else {
      setSeasonMonth(season2)
      const sw = yearWages.filter((w) => [7, 8, 9, 10, 11, 12].includes(w.month))
      setSeasonIndex(6)
      setSeasonWages(sw)
    }

  }, [season, wages, year])

  const handleChange = (event) => {
    setSeason(event.target.value);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  })

  const dialogOpen = (id) => {
    btnSent ? setSentFNO(true) : setOpen(false)
  }

  const handleOnSubmit = (evt) => {
    setTotal(Math.round(parseInt(evt.total)))

    const checkFNO = fno910.filter((fno) => fno.season === season && fno.year === year && fno.status !== 2)

    if (checkFNO.length !== 0) {
      setText("Вы пытаетесь отправить ФНО, которая уже существует. Для повторной отправки ФНО, необходимо сначала отменить существующую!")
      setTitle("ВНИМАНИЕ!!!")
      setOpen(true)
      setBtnSent(false)
    } else {
      setBtnSent(true)
      seasonWages.length < 5 ?
        setText("Вы проверили все данные? Отсутствуют данные по налогам Заработной платы, одного или нескольких месяцев!!!") :
        setText("Вы проверили все данные?")
      setTitle("Отправить ФНО 910.00?")
      setOpen(true)
    }
  };

  const addWage = (m, y) => {
    setOpenFormNewWage(true)
  }

  return (
    <div className="App">
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2}>

          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Период</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={season}
                label="Период"
                onChange={handleChange}
              >
                <MenuItem value={0}>1 полугодие</MenuItem>
                <MenuItem value={1}>2 полугодие</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Год</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={year}
                label="Год"
                onChange={handleChangeYear}
              >
                {years.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" textAlign="left" gutterBottom>
              Сумма выписанных и оплаченных заявок: <span style={{fontWeight: 'bold'}}>{summApps}тг. </span>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="total"
              //defaultValue={authUser.firstName}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="nuber"
                  fullWidth
                  size='small'
                  label="Доход за период"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <List
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader sx={{ lineHeight: '22px', p: 0, color: 'rgb(2, 136, 209)' }} component="div" id="nested-list-subheader">
                  Проверьте установленную по месяцам заработную плату Индивидуального предпринимателя, проверьте все платежи по налогам. Заработная плата должна быть установлена за каждый месяц.
                </ListSubheader>
              }
              dense={true}>
              {tableRimCount.map((li, index) =>
                <ListItem
                  key={index}
                  sx={{ border: 2, mt: 0.5, borderRadius: 2, borderColor: seasonWages.filter((w) => w.month === (index + 1) + seasonIndex).length > 0 ? "#2e7d3291" : "red" }}
                  secondaryAction={
                    seasonWages.filter((w) => w.month === (index + 1) + seasonIndex).length > 0 ? <></> :
                      <IconButton onClick={() => addWage(index + seasonIndex + 1, year)} edge="end" aria-label="delete">
                        <AddBoxOutlinedIcon color='success' sx={{ width: 44, height: 44 }} />
                      </IconButton>

                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: '#6d6d6d' }} variant="rounded">
                      {li}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={seasonMonth[index]}
                    secondary={seasonWages.filter((w) => w.month === (index + 1) + seasonIndex).length > 0 ? seasonWages.filter((w) => w.month === (index + 1) + seasonIndex)[0].wage + "тг." : "Заработная плата отсутствует"}
                  />
                </ListItem>
              )}
            </List>
          </Grid>


          <Grid item xs={6}>
            <Button type="submit" variant="outlined">Сохранить</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleCloseForm} type="button" variant="outlined">Отмена</Button>
          </Grid>

        </Grid>
      </Box>
      <DialogPayTaxConfirmation open={open} handleClose={handleClose} titleText={title} text={text} idPay={1} sentPayConfirmation={dialogOpen} />
      <Dialog
        open={openFormNewWage}
        onClose={handleClose}
        scroll='body'
        aria-labelledby="customized-dialog-title"
        maxWidth='true'
      >
        <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #fcbb42' }} color="#07139e" id="customized-dialog-title">
          Новая запись
        </DialogTitle>
        <DialogContent >
          <NewWageForm addNewWages={addNewWages} newExpenses={newExpenses} handleClose={handleClose} myCompany={myCompany} />
        </DialogContent>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}

export default NewFNO910Form