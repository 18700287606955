import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_ALL_CONTENTS = 'CONTENTS/SET_ALL_CONTENTS';

//Started props
let initialState = {
    isFetching: false,
    initialValuesForm: {},    
    activeForm: false,        
    allContents: []
};

//Reducers functions
const contentReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ALL_CONTENTS:
            return {
                ...state,
                allContents: action.cont}            
        
        default:
            return state;
    }
}

//Action Creators functions
const setContents = (cont) => {
    return { type: SET_ALL_CONTENTS, cont }
}

//Thunk functions
export const contentsRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getALLContents(obj)
        if (data.success === 1) {
            dispatch(setContents(data.contents))
        } else {
            console.log(data.message)
            dispatch(setContents([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const contentsUpdate = (obj, mycomp) => {
    return async (dispatch) => {
        const data = await ConnectToServer.setContents(obj)
        if (data.success === 1) {
            //console.log(data);
           dispatch(contentsRequest({ mycompany: [mycomp] }))
        } else {
            console.log(data.message)
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const contentsDelete = (obj, mycomp) => {
    return async (dispatch) => {
        const data = await ConnectToServer.delContents(obj)
        if (data.success === 1) {
            //console.log(data);
           dispatch(contentsRequest({ mycompany: [mycomp] }))
        } else {
            console.log(data.message)
            //dispatch(toggleIsFetching(false))
        }
    }
}

export default contentReducer;