import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export default function TableDashboardMobile({row, allApplications, allCompanies, name}) {

  const [rows, setRows] = React.useState(row);
  const words = useSelector(state => state.language.words)
  
  useEffect(()=>{
    setRows(row)
  }, [row, allApplications, allCompanies])
  
  const [rowModesModel, setRowModesModel] = React.useState({});
 

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    //setAllTotal(AllTotal + newTotal)    
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'name',
      headerName: name,
      width: 170,
      disableColumnMenu: true,
      hideSortIcons: true,
      editable: false,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'value',
      headerName: '',
      type: 'number',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'right',
      hideSortIcons: true,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        let colorName = '#000'

        if( params.row.name === words.notPaid && params.value > 0 ) { colorName = 'red' }
        if( params.row.name === words.notESF && params.value > 0 ) { colorName = 'blue' }
        if( params.row.name === words.paidDone && params.value > 0 ) { colorName = 'green' }
        
        return <>
          <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mt: 0, mb: 0, pt: 0, pb: 0, color: colorName }} textAlign="center" >{params.value}</Typography>
        </>
      }
    },    
  ];

  return (
    <Box
      sx={{
        
        width: '100%',
        mt: 5,        
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'primary',
        },
        '& .super-app-theme--header': {
          borderBottom: 2,
          borderRadius: 0
        },
      }}
    >
      <DataGrid        
        sx={{border: 0}}
        rows={rows}
        columns={columns}
        onProcessRowUpdateError={(error) => console.log(error)}
        columnVisibilityModel={{
          // idCont: false,
          // plu: false,
          // unit: false,
        }}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        hideFooter={true}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        density="compact"
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}

      />

    </Box>
  );
}