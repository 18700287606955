import React from 'react';
import { Controller } from "react-hook-form";
import {
  Divider,
  Grid, TextField, Typography,
} from "@mui/material";
import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ruRU } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/ru';
import { useState } from 'react';
import dayjs from 'dayjs';


const Wages = ({ control, myHelper, setData }) => {
  const mzpSattings = useSelector(state => state.globalSattings.mzp)
  const [month, setMonth] = useState('')
  const wages = useSelector(state => state.wages.wages)
  const m = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

  // Находим максимальный год и месяц в массиве
  const maxYearMonth = wages.reduce((max, item) => {
    const currentYearMonth = `${item.year}-${item.month.toString().padStart(2, '0')}`;
    return currentYearMonth > max ? currentYearMonth : max;
  }, '0');

  // // Сортируем массив по найденному году и месяцу
  // const sortedData = wages.sort((a, b) => {
  //   const aYearMonth = `${a.year}-${a.month.toString().padStart(2, '0')}`;
  //   const bYearMonth = `${b.year}-${b.month.toString().padStart(2, '0')}`;
  //   return bYearMonth.localeCompare(aYearMonth);
  // });

  const getMonthWithMinDate = () => {
    const now = dayjs(new Date())
    const yan = dayjs(new Date(maxYearMonth))
    let yer = 0

    if (now.$y > yan.$y) {
      yer = (now.$y - yan.$y) * 12
    }
    const minMonth = yan.$M - yer + 1
    return minMonth
  }

  const getMZP = (y) => {
    if (y === 2023) {
      return 70000
    } else {
      return mzpSattings
    }
  }

  const datePic = (evt) => {
    if (evt) {
      setData({
        month: evt.$M + 1,
        year: evt.$y,
        mzp: getMZP(evt.$y),
        label: m[evt.$M] + " " + evt.$y
      })
      setMonth(m[evt.$M] + " " + evt.$y)
    }
  }




  return (<>

    <Grid item xs={12}>
      <LocalizationProvider adapterLocale='ru' dateAdapter={AdapterDayjs} localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
        <DatePicker
          label='Период'
          openTo="month"
          views={['year', 'month']}
          onChange={(evt) => datePic(evt)}
          minDate={dayjs(new Date().setMonth(getMonthWithMinDate()))}
        />
      </LocalizationProvider>
    </Grid>

    <Grid item xs={12}>
      <Typography variant="hoverline" gutterBottom display="block" color="#07139e" sx={{ textAlign: 'center', fontWeight: "600", fontSize: "18px" }}>
        Установите Заработную плату на период {month}, с нее будут подсчитаны платежи по налогам. Минимальная заработная плата в Казахстане {mzpSattings}тг.
      </Typography>
      <Divider sx={{ borderColor: '#07139e' }} />
    </Grid>

    <Grid item xs={12}>
      <Controller
        control={control}
        name="wage"
        defaultValue={mzpSattings}
        rules={{
          required: true
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            type="number"
            label="Заработная плата"
            error={error !== undefined}
            helperText={error ? myHelper.text[error.type] : ""}
          />
        )}
      />
    </Grid>
  </>
  )
}

export default Wages;