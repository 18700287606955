import React from 'react';
import { Avatar, Backdrop, Box, Card, CardActions, CardContent, CardHeader, Checkbox, CircularProgress, Dialog, DialogContent, FormControlLabel, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb';
import AppBarContainer from '../Components/AppBar/AppBarContainer';
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';
import { useSelector } from 'react-redux';
import DialogPayTaxConfirmation from '../Forms/DialogPayTaxConfirmation';

const Workers = ({updatePayConfirm}) => {
    const [open, setOpen] = React.useState(false);        
    
    const [titleText, setTitleText] = React.useState("");
    const [text, setText] = React.useState("");
    const [idPay, setIdPay] = React.useState(0);
    const mzpSattings = useSelector(state => state.globalSattings.mzp)
    const myCompany = useSelector(state => state.myCompany)
    const wages = useSelector(state => state.wages.wages)

    const tTextConfirm = "Оплатили все налоги по заработной плате?"
    const textConfirm = "Платежи по налогам по заработной плате за текущий месяц, оплачиваются до 25 числа следующего месяца."
    const tTextNotConfirm = "Подтвердить отмену платежей?"

  const handleClickOpen = (tt, t, id) => {
    setIdPay(id)
    setTitleText(tt)
    setText(t)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sentPayConfirmation = (id) => {
    updatePayConfirm({id: id.id, pay: !id.pay}, myCompany.id)
    setOpen(false)
  }  
  
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Месяцы в JavaScript начинаются с 0
    const currentHalfYear = currentMonth <= 6 ? 1 : 2;

    const monthNames = [
        "Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
        "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
    ];

    const monthsInHalfYear = currentHalfYear === 1
        ? monthNames.slice(0, 6)
        : monthNames.slice(6, 12);

    const resultArray = {
        year: currentYear,
        halfYear: currentHalfYear,
        monthNumber: monthsInHalfYear.map((month, index) => (
            currentHalfYear === 1 ? index + 1 : index + 7
            )),
        monthName: monthsInHalfYear.map((month, index) => (
            month
        )),
    };

    const tableSortYear = wages.filter((w) => resultArray.year === w.year) //сортируем по году
    const tableSortHalfYear = tableSortYear.filter((w) => resultArray.monthNumber.includes(w.month)) //сортируем по текущему полугодию

    //Обработка нажатия на кнопку с галочкой
    const clickPay = (obj) => {
         if (obj.payment.length > 0) {
            obj.pay? handleClickOpen(tTextNotConfirm, textConfirm, obj):handleClickOpen(tTextConfirm, textConfirm, obj)
         }        
    }
    
    let tableMonth = resultArray.monthNumber.map((month, index) => {
        const x = tableSortHalfYear.filter((sort)=>sort.month===month)[0]; 

        const socMath = () => {
            const sumSoc = x.wage * 0.9
            let socTotal = 2975
            if (sumSoc > mzpSattings) {
                if (sumSoc > mzpSattings*7) {
                    socTotal = (mzpSattings*7)*0.035
                } else {
                    socTotal = sumSoc*0.035
                }
            }
            return socTotal
        }
       
        return {
        oklad: x? x.wage.toFixed(2):"Нет данных",
        opv: x? (x.wage/10).toFixed(2):"...",
        opvr: x? (x.wage*0.015).toFixed(2):"...",        
        soc: x?socMath().toFixed(2):"...", //вычислить
        insurance: x? ((mzpSattings*1.4)*0.05).toFixed(2):"...",
        month:  resultArray.monthName[index] + " " + resultArray.year + "г.",
        payment: x? x.payment? "#2e7d3291": "rgb(211, 47, 47)" :"",
        pay: x? x.payment: false,
        id: x? x.id: 0,
        m: month,
        y: resultArray.year,
    }})

    return <>
        <Box >
            <AppBarContainer />
            <Breadcrumb name={"Платежи ИП"} />
            <Card >
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" />
                    }

                    title="Индивидуальный предприниматель"
                    subheader={
                        <>
                            <Typography variant="body2" color="text.secondary">
                                {myCompany.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                ИИН: {myCompany.bin}
                            </Typography>
                        </>
                    }
                />
                <CardContent sx={{ pt: 0, pb: 0 }}>
                    <>
                        <Typography variant="h6" gutterBottom color="text.secondary">
                            Платежи ИП по Заработной плате
                        </Typography>
                        <FormControlLabel control={<Checkbox checked={myCompany.mzp} />} label="Минимальная ставка" />
                    </>
                </CardContent>
                <CardActions disableSpacing>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container>
                            {tableMonth.map((item, index) =>
                                <Grid key={index} item xs={6}>
                                    <List dense={true} sx={{ p: 0.3 }}>
                                        <ListItem
                                            sx={{ pl: 0, pr: 0, border: 2, borderColor: '#fcbb42', borderRadius: 3 }}
                                        >
                                            <ListItemButton onClick={()=>clickPay(item)} sx={{ maxWidth: '48px', pl: '4px', pr: '4px' }}>
                                                <ListItemAvatar sx={{ minWidth: '30px' }} >
                                                    <Avatar sx={{ bgcolor: item.payment, width: '32px', height: '32px' }} >
                                                        <DoneOutlineRoundedIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                            </ListItemButton>
                                             <ListItemButton sx={{ pl: '4px', pr: '4px' }}> {/* onClick={()=>!item.pay? sentUpdateWages(item):null} */}
                                                <ListItemText
                                                    primaryTypographyProps={{ fontSize: '1rem' }}
                                                    primary={item.month}
                                                    secondary={<>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Оклад: {item.oklad}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            ОПВ: {item.opv}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            ОПВР: {item.opvr}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            СО: {item.soc}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            ВОСМС: {item.insurance}
                                                        </Typography>
                                                        <Typography variant="button" color="text.secondary">
                                                            Итого: {item.opv !== "..."? (parseInt(item.opv)+parseInt(item.opvr)+parseInt(item.soc)+parseInt(item.insurance)).toFixed(2) : "..."}
                                                        </Typography>
                                                    </>}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </CardActions>
            </Card>
        </Box>
        <DialogPayTaxConfirmation open={open} handleClose={handleClose} titleText={titleText} text={text} idPay={idPay} sentPayConfirmation={sentPayConfirmation} /> 
        
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={false}
        >
            <CircularProgress color="primary" />
        </Backdrop>
    </>
}


export default Workers;
