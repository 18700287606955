import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_WAGES = 'WAGES/SET_WAGES';

//Started props
let initialState = {
    wages: []
};

//Reducers functions
const wagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WAGES:
            return {
                ...state,
                wages: action.data
            }
    //     case TOGGLE_IS_FETCHING:
    //         return {
    //             ...state,
    //             isFetching: action.isFetching
    //         }
    //     case TOGGLE_BE_FORM:
    //         return {
    //             ...state,
    //             activeForm: action.activeForm
    //         }
    //     case SET_USER_PROFILE_INFO:
    //         return {
    //             ...state,
    //             userProfileInfo: action.userProfileInfo
    //         }
        default:
            return state;
    }
}

//Action Creators functions
const setAllWages = (data) => {
    return { type: SET_WAGES, data }
}

// const setUserProfileInfo = (userProfileInfo) => {
//     return { type: SET_USER_PROFILE_INFO, userProfileInfo }
// }

// const toggleIsFetching = (isFetching) => {
//     return { type: TOGGLE_IS_FETCHING, isFetching }
// }

// export const toggleBeForm = (activeForm) => {
//     return { type: TOGGLE_BE_FORM, activeForm }
// }

//Thunk functions

export const getAllWagesRequst = (obj) => {
    return async (dispatch) => {
            //dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.getAllWages(obj)
        if (data.success === 1) {
            dispatch(setAllWages(data.wages))
            //dispatch(toggleIsFetching(false))
        } else {
            console.log(data);
            dispatch(setAllWages([]))
        }
    }
}

export const updatePayConfirm = (obj, idCompony) => {
    return async (dispatch) => {
        const data = await ConnectToServer.putPayConfirm(obj)
        if (data.success === 1) {
            dispatch(getAllWagesRequst({ mycompany: [idCompony] }))
            //dispatch(toggleBeForm(false))
        } else {
            console.log(data);
        }
    }
}

export const updateWages = (obj, idCompony) => {
    return async (dispatch) => {
        const data = await ConnectToServer.putWages(obj)
        if (data.success === 1) {
            dispatch(getAllWagesRequst({ mycompany: [idCompony] }))
            //dispatch(toggleBeForm(false))
        } else {
            console.log(data);
        }
    }
}

export const addNewWages = (obj, idCompony) => {    
    return async (dispatch) => {
        const data = await ConnectToServer.addWages(obj)
        if (data.success === 1) {
            dispatch(getAllWagesRequst({ mycompany: [idCompony] }))
            //dispatch(toggleBeForm(false))
        } else {
            console.log(data);
        }
    }
}

export const delWages = (obj, idCompony) => {    
    return async (dispatch) => {
        const data = await ConnectToServer.deleteWages(obj)
        if (data.success === 1) {
            dispatch(getAllWagesRequst({ mycompany: [idCompony] }))
            //dispatch(toggleBeForm(false))
        } else {
            console.log(data);
        }
    }
}

export default wagesReducer;