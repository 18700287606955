//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Login from './Login';
import {authUserCheckRequest, addUserPwdRequest} from '../Redux/authReducer'

class LoginContainer extends React.PureComponent {

    render() {
        return (<>            
                <Login
                    authUserCheckRequest={this.props.authUserCheckRequest}
                    addUserPwdRequest={this.props.addUserPwdRequest}
                />           
        </>)
    }
}

const mapStateToProps = (state) => {
    return {}
};

export default connect(mapStateToProps, {authUserCheckRequest, addUserPwdRequest})(LoginContainer)
