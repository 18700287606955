import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import * as React from 'react';

const DeleteOrders = ({ openFormDelete, setOpenFormDelete, handleDeleteClick, openFormApplicId, nuberDoc, allApplications }) => {
    let application = {esfDone: false}
    if (openFormApplicId.id !== 0){
        application = allApplications.filter((app) => app.id === openFormApplicId.id)[0]
    }
   

    return (
        <Dialog
            open={openFormDelete}
            onClose={() => setOpenFormDelete(false)}
            scroll='body'
        >
            <DialogContent>
                <Grid container spacing={1} sx={{ textAlign: 'center' }}>
                    <Grid item xs={12}>
                        {application.esfDone?<Typography color="error" sx={{ m: 'auto' }} >
                            Внимание: По данной заявке была отправлена ЭСФ!!!
                        </Typography> : null}
                        <Typography color="#07139e" sx={{ m: 'auto' }} >
                            УДАЛИТЬ ЗАЯВКУ № {nuberDoc}?
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => handleDeleteClick(openFormApplicId.id)} type="button" color='error' variant="outlined">Удалить</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => setOpenFormDelete(false)} color='error' type="button" variant="outlined">Отмена</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>

    )
}

export default DeleteOrders