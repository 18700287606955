import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import TableUnits from '../Components/TableUnits';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import { useState } from 'react';
import ReadMoreRoundedIcon from '@mui/icons-material/ReadMoreRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FormInfoCompany from './FormInfoCompany';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DialogNewDataContainer from '../Components/DialogNewDataContainer';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';

const myHelper = {
  email: {
    required: "Email is Required",
    pattern: "Invalid Email Address"
  },
  text: {
    required: "Заполните поле"
  }
};



const NewApplication = ({ applicationId, selectCompany, authUser, myCompony, allContents, allCompanies, allApplications, setApplicationRequest, handleCloseFormProfile, updateApplicationRequest }) => {

  const pointsCompany = useSelector(state => state.pointsCompany.allPoints)
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openFormInfo, setOpenFormInfo] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [idComponent, setIdComponent] = useState(0)
  const [textTitle, setTextTitle] = useState('')

  const dispatch = useDispatch()

  const setOpenFormLists = (data) => {
    dispatch({ type: 'AUTH/SET_OPEN_FORM_CATALOG', data })
}

  const [info, setInfo] = useState({})

  const [stateTable, setStateTable] = useState([]);
  const allAddressDelivery = useSelector(state => state.addressDelivery.addrDelivery)
  const [deliveryAddressesState, setdeliveryAddresses] = useState(applicationId.id === 0 ? [] : allAddressDelivery.filter((addr) => addr.idCompany === allApplications.filter((app) => app.id === applicationId.update)[0].clientId))
  const [selectDeliveryAddress, setSelectDeliveryAddress] = useState(applicationId.id === 0 ? null : allAddressDelivery.filter((addr) => addr.id === allApplications.filter((app) => app.id === applicationId.update)[0].deliveryAddressId)[0])
  //const [dateNewExpenses, setDdateNewExpenses] = useState(applicationId.id === 0 ? dayjs(new Date()):dayjs(new Date(allApplications.filter((app) => app.id === applicationId.id)[0].date)))
  let checkedESFDone = false;
  let checkedIvoce = false;
  let checkedAVR = false;
  let checkedESF = false;

  const handleClose = () => {
    setOpenDialog(false)
  } 

  const handleOpenNewAddrDevDialog = () => {
    setTextTitle('Добавить новый адрес доставки для клиента.')
    setIdComponent(2)
    setOpenDialog(true)
  } 

  const handleOpenNewCompany = () => {
    setTextTitle('Добавить нового контрагента.')
    setIdComponent(1)
    setOpenDialog(true)
  } 

  const getDate = () => {
    const dateApplication = allApplications.filter((app) => app.id === (applicationId.id < 0 ? applicationId.update : applicationId.id))[0].date
    const dateApplicationConvert = dayjs(new Date(parseInt(dateApplication.slice(6, 10)), parseInt(dateApplication.slice(3, 5)) - 1, parseInt(dateApplication.slice(0, 2))))
    const application = allApplications.filter((app) => app.id === (applicationId.id < 0 ? applicationId.update : applicationId.id))[0]
    ///setSelectPoint(application.idPoint === 0 ? {city:"", street:"", numberHouse:""} :pointsCompany.filter((p)=>p.idPoint === application.idPoint)[0])

    checkedESFDone = application ? application.esfDone : false
    return dateApplicationConvert
  }

  const getPoint = () => {
    const application = allApplications.filter((app) => app.id === (applicationId.id < 0 ? applicationId.update : applicationId.id))[0]
    let newP = undefined

    if (pointsCompany.length > 0) {

      if (application.idPoint !== 0) {
        newP = pointsCompany.filter((p) => p.idPoint === application.idPoint)[0]
      } else {
        newP = pointsCompany[0]
      }
    }
    return newP
  }

  const [dateNewExpenses, setDdateNewExpenses] = useState(applicationId.id <= 0 ? dayjs(new Date()) : getDate())
  const [selectPoint, setSelectPoint] = useState(applicationId.id === 0 ? pointsCompany.length === 0 ? undefined : pointsCompany.filter((p) => p.idPoint === authUser.idPoint)[0] : getPoint());

  let valueKNP = authUser.defaultKNP

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  })

  let componyName
  let contract
  let initialContent = []

  const getInfo = (evt) => {
    setOpenFormInfo(true)
    setInfo(evt.customer)
  }

  const handleOnSubmit = (evt) => {
    // evt = { id: user.id, ...evt, img: user.img }
    // updateAuthUserRequest(evt) // send request to server

    let allTotal = 0
    const payment = false
    const clientId = evt.customer.id
    const ownerId = authUser.id
    const companyId = selectCompany.id
    const content = stateTable.map((cont) => ({ ...cont, amount: cont.amount.toString(), price: cont.price.toString(), total: cont.total.toString() }))
    const idGroup = authUser.idGroup[0]
    const onDeletion = 0
    let deliv = 0
    let esfD = false

    if (evt.deliveryAddress !== null && evt.deliveryAddress !== 0) {
      deliv = evt.deliveryAddress.id
    }

    if (applicationId.id <= 0) {
      if (stateTable.length > 0) {
        allTotal = stateTable.reduce((a, b) => a + b.total, 0)
        setApplicationRequest({ ...evt, idPoint: evt.pointCompany ? evt.pointCompany.idPoint : 0, esf: evt.nakladnayAVR, clientId, ownerId, companyId, content, allTotal, payment, idGroup, onDeletion, deliveryAddressId: deliv, date: (dateNewExpenses.$D < 10 ? "0" + (dateNewExpenses.$D) : dateNewExpenses.$D) + '.' + (dateNewExpenses.$M + 1 < 10 ? "0" + (dateNewExpenses.$M + 1) : dateNewExpenses.$M + 1) + '.' + dateNewExpenses.$y })
        handleCloseFormProfile()
      } else {
        setOpenCollapse(true)
        setTimeout(() => setOpenCollapse(false), 4000)
      }
    } else {
      const application = allApplications.filter((app) => app.id === applicationId.id)[0]
      //создать процесс обновления заявки

      if (evt.esfDone) {
        if (evt.esfDone !== application.esfDone) {
          esfD = true
        }
      }
      let idP = 0
      if (evt.pointCompany) {
        idP = evt.pointCompany.idPoint
      }

      const checkedDate = (dateNewExpenses.$D < 10 ? "0" + (dateNewExpenses.$D) : dateNewExpenses.$D) + '.' + (dateNewExpenses.$M + 1 < 10 ? "0" + (dateNewExpenses.$M + 1) : dateNewExpenses.$M + 1) + '.' + dateNewExpenses.$y
      const newContent = content.map((c) => ({ ...c, total: parseInt(c.total), price: parseInt(c.price), amount: parseInt(c.amount) }))

      if (JSON.stringify(newContent) !== JSON.stringify(application.content) || application.idPoint !== idP || clientId !== application.clientId || deliv !== application.deliveryAddressId || evt.esf !== application.esf || evt.invoice !== application.invoice
        || evt.nakladnayAVR !== application.nakladnayAVR || evt.knp !== application.knp || esfD || evt.contract !== application.contract || checkedDate !== application.date) {

        if (stateTable.length > 0) {
          allTotal = stateTable.reduce((a, b) => a + b.total, 0)

          updateApplicationRequest({ ...evt, esfDone: esfD ? evt.esfDone : application.esfDone, idPoint: evt.pointCompany ? evt.pointCompany.idPoint : 0, clientId, ownerId, companyId, content, allTotal, payment, idGroup, onDeletion, deliveryAddressId: deliv, id: applicationId.id, idUser: authUser.id, userKey: authUser.userKey, date: checkedDate })
          handleCloseFormProfile()
          // console.log({ ...evt, esfDone: !evt.esfDone || evt.esfDone === application.esfDone? application.esfDone:evt.esfDone, clientId, ownerId, companyId, content, allTotal, payment, idGroup, onDeletion, deliveryAddressId: deliv, id: applicationId.id, idUser: authUser.id, userKey: authUser.userKey, date: checkedDate});
        } else {
          setOpenCollapse(true)
          setTimeout(() => setOpenCollapse(false), 4000)
        }

      } else {
        handleCloseFormProfile()
      }
    }

    //console.log({ ...evt, id, number, date, clientId, ownerId, companyId, content, allTotal, payment, idGroup, onDeletion, edit, deliveryAddressId: evt.deliveryAddress.id });

  };

  if (applicationId.id > 0) {
    const application = allApplications.filter((app) => app.id === applicationId.id)[0]
    const clientName = allCompanies.filter((app) => app.id === application.clientId)[0]
    //const content = stateTable.length === 0?application.content:stateTable
    checkedIvoce = application.invoice
    checkedAVR = application.nakladnayAVR
    checkedESF = application.esf
    checkedESFDone = application.esfDone
    initialContent = application.content
    valueKNP = application.knp
    componyName = clientName
    contract = application.contract
  }

  if (applicationId.id < 0) {
    const application = allApplications.filter((app) => app.id === applicationId.update)[0]
    const clientName = allCompanies.filter((app) => app.id === application.clientId)[0]
    checkedIvoce = application.invoice
    checkedAVR = application.nakladnayAVR
    checkedESF = application.esf
    checkedESFDone = application.esfDone
    initialContent = application.content
    valueKNP = application.knp
    componyName = clientName
    contract = application.contract
  }

  const [openKNP, setOpenKNP] = React.useState(checkedIvoce);
  const handleChange = (event) => {
    setOpenKNP(event.target.checked);
  };

  return (
    <div className="App">
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <LocalizationProvider adapterLocale='ru' dateAdapter={AdapterDayjs} localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
              <DatePicker
                label='Дата'
                openTo="day"
                defaultValue={dateNewExpenses}
                onChange={(evt) => setDdateNewExpenses(evt)}
                rules={{
                  required: true
                }}
                slotProps={{ textField: { size: 'small', fullWidth: true } }}

              //minDate={dayjs(new Date().setMonth(getMonthWithMinDate()))}
              />
            </LocalizationProvider>
          </Grid>

          {myCompony.rejym === 2 && pointsCompany.length > 0 && authUser.idPointSelect ?
            <Grid item xs={12}>
              <Controller
                control={control}
                name="pointCompany"
                defaultValue={selectPoint}
                render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                  <Autocomplete
                    disablePortal
                    value={selectPoint}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        onChange(newValue)
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setSelectPoint(newValue.inputValue)
                        onChange(newValue.inputValue)
                      } else {
                        setSelectPoint(newValue)
                        onChange(newValue)
                      }
                    }}
                    options={pointsCompany}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {

                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.city + " --- " + option.street + ', ' + option.numberHouse;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.city + " --- " + option.street + ', ' + option.numberHouse}</li>}
                    renderInput={(params) =>
                      <TextField
                        fullWidth
                        {...params}
                        size="small"
                        label="Точка продажи"
                        error={error !== undefined}
                        helperText={error ? myHelper.text[error.type] : ""}
                      />}
                  />
                )}
              />
            </Grid> : <></>}

          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Controller
                control={control}
                name="customer"
                defaultValue={componyName || null}
                rules={{
                  required: true
                }}
                fullWidth
                render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                  <Autocomplete
                    disablePortal
                    fullWidth
                    value={field.value}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {

                        onChange(newValue)
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setdeliveryAddresses(allAddressDelivery.filter((addr) => addr.idCompany === newValue.inputValue.id))

                        onChange(newValue.inputValue)
                      } else {
                        setSelectDeliveryAddress('')
                        if (newValue !== null) {
                          setdeliveryAddresses(allAddressDelivery.filter((addr) => addr.idCompany === newValue.id))
                        }   else {
                          setdeliveryAddresses([])
                        }                     
                        onChange(newValue)
                      }
                    }}
                    id="customersId"
                    options={allCompanies}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {

                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    renderInput={(params) =>
                      <TextField
                        fullWidth
                        {...params}

                        size="small"
                        label="Выберите клиента"
                        error={error !== undefined}
                        helperText={error ? myHelper.text[error.type] : ""}
                      />}
                  />
                )}
              />
              <IconButton onClick={handleSubmit(getInfo)} color='primary' sx={{ border: 1, borderRadius: 2, width: '36px', height: '36px' }} aria-label="delete">
                <ReadMoreRoundedIcon color='secondary' fontSize="inherit" />
              </IconButton>
              <IconButton onClick={handleOpenNewCompany} color='primary' sx={{ border: 1, borderRadius: 2, width: '36px', height: '36px' }} aria-label="delete">
                <AddCircleOutlineRoundedIcon color='secondary' fontSize="inherit" />
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Controller
              control={control}
              name="deliveryAddress"
              defaultValue={selectDeliveryAddress || ""}
              fullWidth
              render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                <Autocomplete
                  disablePortal
                  fullWidth
                  noOptionsText={
                    <Stack>
                    <Alert severity="warning" >Добавьте адрес доставки в Справочник...</Alert>
                                <Button onClick={()=>setOpenFormLists(true)} color='secondary' variant="outlined" startIcon={<WidgetsRoundedIcon />}>
                                    Открыть Справочники...
                                </Button>
                                </Stack>
                  }
                  value={selectDeliveryAddress}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {

                      onChange(newValue)
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setSelectDeliveryAddress(newValue.inputValue)
                      onChange(newValue.inputValue)
                    } else {
                      setSelectDeliveryAddress(newValue)
                      onChange(newValue)
                    }
                  }}

                  options={deliveryAddressesState}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {

                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.address;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.address}</li>}
                  renderInput={(params) =>
                    <TextField
                      fullWidth
                      {...params}
                      size="small"
                      label="Адрес доставки"
                      error={error !== undefined}
                      helperText={error ? myHelper.text[error.type] : ""}
                    />}
                />
              )}
            />
            {/* <IconButton onClick={handleOpenNewAddrDevDialog} color='primary' sx={{ border: 1, borderRadius: 2, width: '36px', height: '36px' }} aria-label="delete">
                <AddCircleOutlineRoundedIcon color='secondary' fontSize="inherit" />
              </IconButton> */}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="contract"
              defaultValue={contract}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  type="text"
                  fullWidth
                  label="Договор(если есть)"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} >
            <Collapse in={openCollapse}>
              <Alert severity="error">Таблица пустая! Заполните таблицу.</Alert>
            </Collapse>
          </Grid>

          <Grid item xs={12}>
            <TableUnits
              myCompony={myCompony}
              allContents={allContents}
              initialContent={initialContent}
              setStateTable={setStateTable}
              setOpenFormLists={setOpenFormLists}
            />
          </Grid>

          {authUser.esf || authUser.invoce || authUser.nakladnayAVR ?
            <Grid item xs={12} sx={{ textAlign: 'left' }}>

              <Typography variant="hoverline" gutterBottom display="block" color="#07139e" sx={{ textAlign: 'left' }}>
                Сформировать
              </Typography>
              {authUser.invoce ?
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Controller
                      control={control}
                      name="invoice"
                      defaultValue={checkedIvoce}
                      render={({ field: { onChange, ...field } }) => (
                        <FormControlLabel
                          control={<Checkbox size="small" checked={field.value} />}
                          label="Счет на оплату"
                          {...field}
                          onChange={(event, newValue) => {
                            handleChange(event)
                            onChange(event)
                          }}

                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      control={control}
                      name="knp"
                      defaultValue={valueKNP}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          size="small"
                          type="text"
                          disabled={!openKNP}
                          fullWidth
                          label="КНП"
                          error={error !== undefined}
                          helperText={error ? myHelper.text[error.type] : ""}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                : <></>}
              {authUser.nakladnayAVR ? <>
                <Controller
                  control={control}
                  name="nakladnayAVR"
                  defaultValue={checkedAVR}
                  render={({ field }) => (
                    <FormControlLabel control={<Checkbox size="small" checked={field.value} />} label="Накладная/АВР" {...field} />
                  )}
                />
                <br />
              </>
                : <></>}
              {authUser.esf ?
                <Controller
                  control={control}
                  name="esf"
                  defaultValue={checkedESF}
                  render={({ field }) => (
                    <FormControlLabel control={<Checkbox size="small" checked={field.value} />} label="ЭСФ" {...field} />
                  )}
                />
                : <></>}
            </Grid>
            : <></>}

          <Grid item xs={12} sx={{ textAlign: 'left' }} >
            {authUser.account === 2 ?
              <Controller
                control={control}
                name="esfDone"
                defaultValue={checkedESFDone}
                render={({ field }) => (
                  <FormControlLabel control={<Checkbox size="small" checked={field.value} />} label="ЭСФ Отправлено..." {...field} />
                )}
              />
              : <></>}
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="outlined">Сохранить</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleCloseFormProfile} type="button" variant="outlined">Отмена</Button>
          </Grid>

        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <Dialog
        open={openFormInfo}
        onClose={() => setOpenFormInfo(false)}
        scroll='body'
      >
        <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #07139e', fontSize: '18px', minWidth: '300px' }} color="#07139e" id="customized-dialog-title">
          Информация о компании
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => setOpenFormInfo(false)}
          color='error'
          sx={{
            position: 'absolute',
            right: 1,
            top: 1,
            p: '5px'
          }}
        >
          <CloseRoundedIcon />
        </IconButton>

        <DialogContent>
          {Object.keys(info).length !== 0?
          <FormInfoCompany info={info} />:
          <Grid container spacing={1} sx={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <Typography color="#07139e" sx={{ m: 'auto' }} >
              Компания не выбрана
            </Typography>
            </Grid>
          </Grid>
}
        </DialogContent>
      </Dialog>
      <DialogNewDataContainer open={openDialog} handleClose={handleClose} idComponent={idComponent} textTitle={textTitle}  />
    </div>
  );
}

export default NewApplication