import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,

} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { Alert, Collapse, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const CustomNoRowsOverlay = () => {
  return (
    <Typography variant="hoverline" gutterBottom display="block" color="#000000" sx={{ pt: "15px", textAlign: "center" }}>
      Нет записи
    </Typography>
  )
}

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;
  const idMyCompany = useSelector(state => state.myCompany.id)
  const idUserOwner = useSelector(state => state.auth.authUser.id)
  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [{ id, idClient: 0, name: '', isNew: true, idMyCompany, idUserOwner, update: true }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));

  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} >
        Добавить запись
      </Button>
    </GridToolbarContainer>
  );
}

export default function TableUnitsClients({ setDataClientsForm, setDelClients, reloadTable }) {
  const [openError, setopenError] = React.useState(false)
  const allCompanies = useSelector(state => state.companies.companies)
  const initialState = allCompanies.map((com) => ({ ...com, idClient: com.id, update: false }))
  const [rows, setRows] = React.useState(initialState);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const kbe = [17, 19]

  React.useEffect(()=>{setRows(allCompanies.map((com) => ({ ...com, idClient: com.id, update: false })))},[allCompanies,setRows, reloadTable])

  React.useEffect(() => {
    setDataClientsForm(rows)
  }, [rows, setDataClientsForm])

  const handleRowEditStop = (params, event) => {
    
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    if (!openError) {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }
  };

  const handleDeleteClick = (id) => () => {
    setopenError(false)
    setDelClients(rows.filter((row) => row.id === id)[0])
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {       
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });       

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
    setTimeout(() => setopenError(false), 1000)    
  };

  const processRowUpdate = (newRow) => {
    //setAllTotal(AllTotal + newTotal)    
    const updatedRow = { ...newRow, isNew: false, update: true };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const valueSetBin = (params) => {
    const allBin = rows.filter((row) => row.id !== params.row.id).map((row) => row.bin).filter((row) => row !== undefined)
    let bin = params.value
    if (allBin.includes(params.value)) {
      setopenError(true)
      bin = bin.slice(0, -1)
    } else {
      setopenError(false)
    }    
    return { ...params.row, bin }
  }

  const columns = [
    {
      field: 'idClient',
      headerName: '№',
      width: 10,
      type: 'number',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'idUserOwner',
      headerName: 'idUserOwner',
      width: 10,
      type: 'number',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'idMyCompany',
      headerName: 'idMyCompany',
      width: 10,
      type: 'number',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'name',
      headerName: 'Наименование',
      width: 130,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'bin',
      headerName: 'БИН',
      width: 90,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
      valueSetter: valueSetBin,
    },
    {
      field: 'addressUyr',
      headerName: 'Адрес',
      width: 100,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'phone',
      headerName: 'Телефон',
      width: 100,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'email',
      headerName: 'Почта',
      width: 100,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'bik',
      headerName: 'БИК Банка',
      width: 100,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'nameBank',
      headerName: 'Название Банка',
      width: 100,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'iban',
      headerName: 'Номер счета',
      width: 100,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'kbe',
      headerName: 'КБе',
      width: 50,
      type: 'singleSelect',
      valueOptions: kbe,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '...',
      width: 70,
      cellClassName: 'actions',
      disableColumnMenu: true,
      hideSortIcons: true,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >

      <Collapse in={openError} sx={{ position: "absolute", zIndex: "100" }}>
        <Alert severity='error'>Компания с таким БИН уже существует!!!</Alert>
      </Collapse>

      <DataGrid
        sx={{ fontSize: "11px" }}
        rows={rows}
        autoPageSize
        columns={columns}
        onProcessRowUpdateError={(error) => console.log(error)}
        columnVisibilityModel={{
          idClient: false,
          idMyCompany: false,
          idUserOwner: false,
        }}
        editMode='row'
        hideFooterPagination={false}
        hideFooterSelectedRowCount={true}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        density="compact"
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}

        slots={{
          toolbar: EditToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />

    </Box>
  );
}