import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TableWorkwrsV from './TableWorkwrsV';
import { Divider, Stack } from '@mui/material';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';



export default function StepperFNO913({ setBtnAction, workers, summaryFNO913, wagesWithSeason, seasonMonth, addWage }) {
    const [activeStep, setActiveStep] = React.useState(0);
    
    const steps = [
        {
            label: 'Суммы доходов и расходов для исчисления налогов.',
            description: <Stack>
                <Typography variant="caption">Сумма облагаемого дохода: {summaryFNO913.total.toLocaleString("ru-RU")} тг. </Typography>
                <Typography variant="caption">Сумма расходов: {summaryFNO913.wages.toLocaleString("ru-RU")} тг.</Typography>
                <Typography variant="caption">Численность работников: {workers.length.toLocaleString("ru-RU")}</Typography>
            </Stack>,
            component: workers.map((w) => <TableWorkwrsV key={w.id} worker={w} wagesWithSeason={wagesWithSeason} seasonMonth={seasonMonth} addWage={addWage} />)
        },
        {
            label: 'Информация о месте осуществления деятельности.',
            description: <Stack>
                <Typography variant="caption"> Точки осуществелия деятельности:</Typography>
                {summaryFNO913.pointsCompany.map((p, index) => <Typography key={index} variant="caption"> {'#' + (index + 1) + ': ' + p.city + ', ' + p.street + ', ' + p.numberHouse + ', ' + p.numberRoom}</Typography>)}
            </Stack>,
            component: <> </>

        },
        {
            label: 'Исчисленные налоги',
            description: <Typography variant="caption"> Сумма исчисленных налогов (КПН / ИПН): {(summaryFNO913.tax1.reduce((sum, value) => sum + value, 0) + summaryFNO913.tax2.reduce((sum, value) => sum + value, 0)).toLocaleString("ru-RU")} тг.</Typography>,
            component: summaryFNO913.total1.map((t, index) => (<div key={index}>
                <Typography fontSize={"12px"} fontWeight={"600"} noWrap>Точка продаж #{index}</Typography>
                <Divider />
                <Stack spacing={1} direction="row" alignItems="center">
                    <Stack>
                        <AccountCircleRoundedIcon color='primary' />
                    </Stack>

                    <Stack direction="row" spacing={0.8} sx={{ minWidth: 0 }}>
                        <Typography fontSize={"12px"} fontWeight={"600"} noWrap>Физ.лиц: {t.toLocaleString("ru-RU")} тг.</Typography>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Typography fontSize={"12px"} fontWeight={"600"} noWrap>Налог: {summaryFNO913.tax1[index].toLocaleString("ru-RU")} тг.</Typography>
                    </Stack>

                </Stack>
                <Divider />
                <Stack spacing={1} direction="row" alignItems="center">
                    <Stack>
                        <CorporateFareRoundedIcon color='primary' />
                    </Stack>

                    <Stack direction="row" spacing={0.8} sx={{ minWidth: 0 }}>
                        <Typography fontSize={"12px"} fontWeight={"600"} noWrap>Юр.лиц: {summaryFNO913.total2[index].toLocaleString("ru-RU")} тг.</Typography>
                        <Divider orientation="vertical" variant="middle" flexItem />
                        <Typography fontSize={"12px"} fontWeight={"600"} noWrap>Налог: {summaryFNO913.tax2[index].toLocaleString("ru-RU")} тг.</Typography>
                    </Stack>

                </Stack>
                <Divider />
            </div>))

        },
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        activeStep === 2 ? setBtnAction(false) : setBtnAction(true)
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    //   const handleReset = () => {
    //     setActiveStep(0);
    //   };

    return (
        <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={step.description}
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent sx={{pl: activeStep === 2 ? 0 : 1, ml: activeStep === 2 ? 0 : 1.5}}>

                            {/* <Typography>{step.description}</Typography> */}
                            {step.component}
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        size='small'
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Далее
                                    </Button>
                                    <Button
                                        size='small'
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Назад
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {/* {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )} */}
        </Box>
    );
}