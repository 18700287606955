import * as React from 'react';
import { Avatar, Button, CircularProgress, Dialog, DialogContent, Divider, Grid, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvocePDF from './PDF/Invoce/InvocePDF';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import NakladnayaPDF from './PDF/Nakladnaya/NakladnayaPDF';
import AVRPDF from './PDF/AVR/AVRPDF';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { useState } from 'react';
import { useEffect } from 'react';

export default function ApplicationMin({ application, allCompanies, openForm, old, updatePaidRequest, updateAVRRequest, updateEsfRequest }) {

    const summ = application.content.reduce((sum, cont) => sum + cont.total, 0)
    const compony = allCompanies.filter(comp => comp.id === application.clientId)[0]
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [docName, setDacName] = useState("");
    const [colorDoc, setColorDoc] = useState("#90caf9");
    const myCompony = useSelector(state => state.myCompany)
    const allAddressDelivery = useSelector(state => state.addressDelivery.addrDelivery)
    const nakladnaya = application.content.filter((appl) => appl.unit !== "усл.")
    const avr = application.content.filter((appl) => appl.unit === "усл.")
    let addressDelivery = allAddressDelivery.filter((addr) => addr.id === application.deliveryAddressId)[0]
    const [openFormSentEsf, setOpenFormSentEsf] = useState(false);
    const authUser = useSelector(state => state.auth.authUser)

    const updateABR = () => {
        const obj = {
            nakladnayAVR: true,
            id: application.id,
            userKey: authUser.userKey,
            idUser: authUser.id,
            myCompony: myCompony.id
        }
        updateAVRRequest(obj)
    }

    const updateEsf = () => {
        const obj = {
            esfDone: myCompony.customerType > 0 ? false : true,
            esf: true,
            id: application.id,
            userKey: authUser.userKey,
            idUser: authUser.id,
            myCompony: myCompony.id
        }
        updateEsfRequest(obj)
    }

    useEffect(() => {

        if (application.esfDone) {
            setDacName("Выписан - ЭСФ")
            setColorDoc('#2e7d3291')
        } else
            if (application.esf) {
                setDacName("Ожидается отправка ЭСФ")
                setColorDoc('#07139e')
            } else
                if (application.nakladnayAVR) {
                    setDacName("Выписан - АВР/Накладная")
                    setColorDoc('rgb(211, 47, 47)')
                } else
                    if (application.invoice) {
                        setDacName("Выписан - Счет на оплату")
                        setColorDoc('#fcbb42')
                    }

    }, [application.esfDone, application.esf, application.invoice, application.nakladnayAVR])

    const open = Boolean(anchorElUser);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }

    const handleCloseExitMenu = () => {
        setAnchorElUser(null);
    }

    let addr = " "

    if (addressDelivery === undefined) {
        addr = " "
        addressDelivery =
        {
            address: " ",
            date: "",
            id: 0,
            idCompany: 0,
            idMyCompany: 0,
            phone: " ",
            workerName: " "
        }

    } else {
        addr = addressDelivery.address
    }

    const onSubmintPay = () => {
        const obj = {
            payment: application.payment ? !application.payment : !application.payment,
            idCompany: application.clientId,
            id: application.id,
            total: application.allTotal,
            userKey: authUser.userKey,
            idUser: authUser.id,
            myCompony: myCompony.id
        }
        if (!old) {

            updatePaidRequest(obj)
        }
    }

    return (<>
        <ListItem
            sx={{ pl: 0, pr: 0 }}
            secondaryAction={<>
                {application.invoice || application.nakladnayAVR ?
                    <IconButton edge="end" onClick={handleOpenUserMenu} aria-label="delete">
                        <ShareIcon fontSize="large" />
                    </IconButton> : null}
                <Menu
                    sx={{ mt: '45px', pt: '0', pb: '0' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleCloseExitMenu}
                >
                    <MenuList sx={{ textDecoration: 'none', pt: '0', pb: '0' }} dense>
                        <Suspense fallback={<div>Loading...</div>}>
                            {open ?
                                (application.invoice ?
                                    <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<InvocePDF application={application} compony={compony} myCompony={myCompony} />} fileName={'Счет №' + application.invoceNumber + ' "' + compony.name + '" ' + addr + '.pdf'}>
                                        {({ loading }) =>
                                            loading ? (<CircularProgress color="primary" />
                                            ) : (
                                                <MenuItem sx={{ textDecoration: 'none' }} >
                                                    <Typography sx={{ textDecoration: 'none' }} textAlign="center" >Счет</Typography>
                                                </MenuItem>
                                            )
                                        }
                                    </PDFDownloadLink> : <></>)
                                : <></>}
                        </Suspense>

                        {application.invoice && application.nakladnayAVR ? <Divider sx={{ borderColor: '#fcbb42' }} variant="middle" component="li" /> : null}

                        <Suspense fallback={<div>Loading...</div>}>
                            {open ?
                                (application.nakladnayAVR && nakladnaya.length > 0 ?
                                    <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<NakladnayaPDF nakladnaya={nakladnaya} addressDelivery={addressDelivery} application={application} compony={compony} myCompony={myCompony} />} fileName={'Накладная №' + application.number + ' "' + compony.name + '" ' + addr + '.pdf'}>
                                        {({ loading }) =>
                                            loading ? (<CircularProgress color="primary" />
                                            ) : (
                                                <MenuItem sx={{ textDecoration: 'none' }} >
                                                    <Typography sx={{ textDecoration: 'none' }} textAlign="center" >Накладная</Typography>
                                                </MenuItem>
                                            )
                                        }
                                    </PDFDownloadLink> : <></>)
                                : <></>}
                        </Suspense>

                        {nakladnaya.length > 0 && avr.length > 0 ? <Divider sx={{ borderColor: '#fcbb42', mt: '0', mb: '0' }} variant="middle" /> : null}

                        <Suspense fallback={<div>Loading...</div>}>
                            {open ?
                                (application.nakladnayAVR && avr.length > 0 ?
                                    <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<AVRPDF avr={avr} addressDelivery={addressDelivery} application={application} compony={compony} myCompony={myCompony} />} fileName={'АВР №' + application.number + ' "' + compony.name + '" ' + addr + '.pdf'}>
                                        {({ loading }) =>
                                            loading ? (<CircularProgress color="primary" />
                                            ) : (
                                                <MenuItem sx={{ textDecoration: 'none' }} >
                                                    <Typography sx={{ textDecoration: 'none' }} textAlign="center" >АВР</Typography>
                                                </MenuItem>
                                            )
                                        }
                                    </PDFDownloadLink> : <></>)
                                : <></>
                            }
                        </Suspense>
                    </MenuList>
                </Menu>
                <IconButton onClick={() => onSubmintPay()} edge="end" aria-label="delete" sx={{ ml: 1 }}>
                    <CreditScoreIcon color={application.payment ? 'success' : 'default'} fontSize="large" />
                </IconButton>
                <IconButton onClick={() => openForm(-1, application.id)} edge="end" aria-label="delete" sx={{ ml: 1 }}>
                    <ContentCopyIcon fontSize="large" />
                </IconButton>
            </>
            }
        >
            <ListItemButton sx={{ pl: 1 }} onClick={() => openForm(application.id, application.id)}>
                <ListItemAvatar >
                    <Avatar sx={{ bgcolor: colorDoc }} >
                        {application.number}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={'Запись ' + compony.name}
                    secondary={<>
                        {docName.length > 0 ? <> <Typography component="span" sx={{ fontSize: '9px', fontWeight: '700' }} textAlign="left" >{docName}</Typography> <br /></> : <> </>}

                        {application.date + " - " + summ + " тг."}
                    </>}
                />
            </ListItemButton>
        </ListItem>

        {application.invoice ?
            !application.nakladnayAVR ?
                <Button
                    component="label"
                    size='small'
                    variant="outlined"
                    tabIndex={-1}
                    color='secondary'
                    startIcon={<PostAddRoundedIcon />}
                    sx={{ padding: '0px 4px', marginBottom: 0.5 }}
                    onClick={updateABR}
                >
                    АВР/Накладную
                </Button> : <></> : <></>}
        {application.nakladnayAVR ?
            !application.esf ?
                <Button
                    component="label"
                    size='small'
                    variant="outlined"
                    tabIndex={-1}
                    color='secondary'
                    startIcon={<ErrorOutlineRoundedIcon />}
                    sx={{ padding: '0px 4px', marginBottom: 0.5 }}
                    onClick={() => setOpenFormSentEsf(true)}
                >
                    ЭСФ
                </Button> :
                <></> : <></>}
        <Divider sx={{ borderColor: '#fcbb42' }} />

        <Dialog
            open={openFormSentEsf}
            onClose={() => setOpenFormSentEsf(false)}
            scroll='body'
        >
            <DialogContent>
                <Grid container spacing={1} sx={{ textAlign: 'center' }}>
                    <Grid item xs={12}>
                        <Typography color="#07139e" sx={{ m: 'auto' }} >
                            {myCompony.customerType > 0 ? "Выписать ЭСФ?" : "Подтвердите отправку ЭСФ!!!"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={updateEsf} type="button" color='error' variant="outlined">{myCompony.customerType > 0 ? "Выписать" : "Отправлено"}</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => setOpenFormSentEsf(false)} color='error' type="button" variant="outlined">Отмена</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>

    </>
    );
}