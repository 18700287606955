import { Alert, Box, Button } from '@mui/material';
import React from 'react';
import WifiOffIcon from '@mui/icons-material/WifiOff';



const NoConnection = () => {

    setTimeout(function(){
        window.location.href = '/'
      }, 120 * 1000);

    return <>
        <Box sx={{width: '100vw', height: '100vh', backgroundColor: '#07139e'}} >
            <Alert sx={{ position: 'absolute', top: '20%', left: '50%', marginTop: '50px', marginLeft: '-105px' }} severity="warning">Отсутствует ИНТЕРНЕТ!!!</Alert>
            <Button onClick={() => window.location.href = '/'} variant="outlined" sx={{ width: '290px', position: 'absolute', top: '25%', left: '50%', marginTop: '70px', marginLeft: '-136px' }} startIcon={<WifiOffIcon />}>
                Нажмите для перезапуска приложения или подождите 2 минуты...
            </Button>
        </Box>
    </>
}

export default NoConnection;
