import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function DocumentOffert() {
    return (
        <Box sx={{ width: '100%', maxWidth: 500 }}>

            <Typography variant="h5" gutterBottom>
                Публичная оферта (предложение) оказания бухгалтерских услуг от ТОО «FINUP.KZ»
            </Typography>

            <Typography variant="caption" display="block" gutterBottom>
                Настоящее предложение ТОО «FINUP.KZ» (далее – Исполнитель) в лице директора, Урмановой Индиры Бектемировны, действующего на основании Устава, БИН: 231240002687, адрес: РК, г.Астана, ул. Родниковая д.1/1, БЦ «Абат», офис 402, ИИК: KZ71722S000032312465, БИК: CASPKZKA, АО "Kaspi Bank", веб-сайт:https://finup.kz/, адрес электронной почты:buh@finup.kz, является Публичной офертой, по которой Исполнитель делает предложение заключить Договор оказания бухгалтерских услуг, с применением информационных технологий, на указанных в предложении условиях, с любым лицом, кто отзовётся. Данное предложение содержит все существенные условия, т.е. условия, которые признаны существенными действующим законодательством или необходимы для оказания бухгалтерских услуг онлайн, посредством мобильного приложения либо на веб-сайте, а также те условия, относительно которых по заявлению Исполнителя должно быть достигнуто соглашение.
            </Typography>

            <Typography variant="subtitle2" gutterBottom>
                1.	Понятия и термины, используемые в настоящей публичной оферте
            </Typography>

            <Typography variant="caption" display="block" gutterBottom>
                1.1	Услуги – перечень бухгалтерских услуг Исполнителя с различными Тарифами, размещенных в мобильном приложении и на веб-сайте, предоставляемых на территории Республики Казахстан совершеннолетним дееспособным физическим и юридическим лицам, с применением информационных технологий;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.2	Договор - Оферта – настоящее предложение, которое делает Исполнитель любому, кто отзовётся, и которое содержит все существенные условия. Данное предложение содержит все условия, которые признаны существенными законодательством или необходимы для оказания Услуг, а также те условия, относительно которых по заявлению Исполнителя должно быть достигнуто соглашение (Гражданский кодекс РК, статья: 395);
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.3	Акцепт – ответ Пользователя или совершение Пользователем действий, определенных настоящим Договором - Офертой, свидетельствующих о полном и безоговорочном принятии всех его условий, влекущее за собой исполнение обязательств и условий предусмотренных Договором – Офертой (Гражданский кодекс РК, статья: 396);
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.4	Информационная технология – совокупность методов, производственных процессов и программно-технических средств, объединенных в технологический комплекс, обеспечивающий сбор, создание, хранение, накопление, обработку, поиск, вывод,
                копирование, передачу и распространение информации;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.5	Сайт – интернет – ресурс FinUP.kz, т.е. информация, размещенная на аппаратно – программном комплексе, имеющем уникальный сетевой адрес и функционирующем в Интернете (Закон РК от 24.11.2015 г. №418-V ЗРК, статья: 1, п.п.46)
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.6	Мобильное приложение – программное обеспечение, разработанное для мобильных операционных систем iOS и Android, предоставляющее собой доступ Пользователям к сервисам удаленного обслуживания, предназначенное для получения бухгалтерских услуг посредством мобильного телефона или иного устройства, поддерживающего Мобильное приложение и доступ в интернет;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.7	Посетитель – лицо посетившее сайт, без цели регистрации на нем или размещения заказа;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.8	Пользователь – лицо осуществившее Акцепт Договора – Оферты Исполнителя;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.9	Заказ – задание Пользователя на оказание Услуг, оформленное в соответствии с условиями Договора – Оферты;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.10	 Стороны – сторонами Договора – Оферты являются совместно Исполнитель и Пользователь;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.11	Персональные данные – сведения, регламентированные Договором - Офертой, относящиеся к определенному физическому либо юридическому лицу, зафиксированные на электронном, бумажном и (или) ином материальном носителе (Закон РК от 21.05.2013 г. №94-V, п.2, статья: 1);
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.12	 Сбор Персональных данных – действия, направленные на получение Персональных данных;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.13	 Обработка Персональных данных – действия, направленные на накопление, хранение, изменение, дополнение, использование, распространение, обезличивание, блокирование и уничтожение Персональных данных (Закон РК от 21.05.2013 г. №94-V, статья: 1, п.п.12);
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.14	 Третьи лица – лица связанные, как в настоящее время, так и в будущем, с Исполнителем обстоятельствами или правоотношениями по Сбору, Обработке и защите Персональных данных;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.15	 Тариф – ставка или система ставок за различные услуги, предоставляемые в рамках Договора – Оферты;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.16	 ЭЦП – электронная цифровая подпись;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.17	 Лимит – представляет собой количественное ограничение, накладываемое на определённые характеристики операций;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.18	 Пароль – условное слово или произвольный набор знаков, состоящий из букв, цифр и других символов, предназначенный в целях защиты и используемый для управления входом в систему по учетным записям Пользователя.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                2.	Предмет Договора – Оферты
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.1. Предметом настоящего Договора - Оферта является предоставление бухгалтерских услуг Пользователю на платной основе силами Исполнителя, согласно выбранного Тарифа Пользователем. Совершая покупку Тарифа, Пользователь подтверждает принятие всех его условий по настоящему Договору.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.2. Настоящий Договор - Оферта считается заключенным и приравнивается к заключению Договора в письменной форме с момента совершения нижеперечисленных действий:
                <br />
                -  регистрация Пользователя в «Личном кабинете» в приложении «online Бухгалтер» (далее – Приложение).
                <br />
                -  Акцепт (принятие) всех условий настоящего Договора, а именно – проставление галочки в соответствующем окне в Приложении;
                <br />
                -  покупка выбранного Пользователем Тарифа, в порядке и на условиях, предусмотренных настоящим Договором - Офертой.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.3. Исполнитель принимает все разумные меры к тому, чтобы Услуги оказывались бесперебойно и не содержали неточностей и ошибок, при этом Исполнитель не может гарантировать этого в связи с техническими издержками либо отсутствием сети Интернет.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.4. В обязанности Исполнителя, по поддержке Программного обеспечения и базы данных в сети Интернет, не входит предоставление Исполнителем доступа к сети Интернет. Доступ к сети Интернет Пользователь обеспечивает самостоятельно.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.5. Сайт и Приложение могут быть временно недоступны, частично или полностью, и соответственно, оказание услуг может быть временно прекращено, частично или полностью по причине проведения профилактических или иных работ, а также по любым другим причинам технического характера.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.6. Исполнитель имеет право периодически проводить необходимые профилактические или иные работы с предварительным уведомлением Пользователей или без такового.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.7. При пользовании Приложением, Пользователю запрещаются любые действия, направленные на получение несанкционированного доступа к ресурсам (Сайт, Приложение и иные технические средства) и конфиденциальной информации Исполнителя, Персональным учетным и иным данным других Пользователей, а также любым другим данным, доступным через сеть Интернет и сервер Исполнителя.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                3.	Условия оказания услуг
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.1. Условия оказания Услуг и обязательства, принятые Пользователем по Договору - Оферте, в том числе правила пользования Приложением должны быть выполнены Пользователем.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.2. Правила пользования Мобильным приложением:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.2.1. Данные вносимые Пользователем при регистрации, а также при пользовании Приложением, должны быть достоверными, полными и точными. Пользователь несет полную ответственность за достоверность таких данных;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.2.2. Ответственность за сохранность и конфиденциальность логина и пароля при регистрации несет Пользователь;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.2.3. Пользование Приложением и (или) использование Услуг должно осуществляться Пользователем только в соответствии с требованиями законодательства Республики Казахстан;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.2.4. Пользователь после Акцептования Договора - Оферта добровольно прикрепляет ЭЦП компании (ИП или ТОО) в разделе «Профиль компании» Приложения:
                <br />
                - AUTH_RSA - для идентификации в государственных порталах (https://cabinet.salyk.kz/, https://elicense.kz/, https://www.enbek.kz/,  https: //stat.gov.kz и прочие);
                <br />
                - RSA/GOST - для подписи по отправке налоговых декларации, заявок, ЭСФ, ЭАВР и прочие.
                <br />
                - ЭЦП (электронные файлы AUTH_RSA и RSA/GOST) компании хранятся в памяти Приложения.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.2.5. При истечении срока действия ЭЦП компании (ИП или ТОО) Пользователь прикрепляет актуальную ЭЦП компании (ИП или ТОО) в течение 3 (трех) рабочих дней, в противном случае, предоставление Услуг отодвигается на период предоставления актуального ЭЦП компании (ИП или ТОО).
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.3. Исполнитель оказывает Услуги по выбранному Тарифу Пользователя в том виде, в котором она доступна на момент ее оказания, без прямых или косвенных гарантий. Виды, особенности, основные свойства, объем, количество, срок оказания и иные условия оказания Услуг, а также формат их представления определяются Исполнителем. При использовании Приложения, Исполнитель не несет ответственность за несоответствие представляемой Услуги ожиданиям Пользователя.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.4. Условия оказания Услуг:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.4.1. Пользователь выбирает Тариф в Приложении.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.4.2. Пользователь производит оплату (покупку) за Тариф.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.4.3. Услуги либо доступ к их получению предоставляются только при условии оплаты (покупки) Пользователя.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.5. В рамках исполнения Договора - Оферты, Пользователем может быть выбрано несколько Тарифов.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.6. Платежи и (или) переводы денег, осуществляемые юридическими лицами (ТОО), филиалами или представительствами юридического лица через поставщиков платежных услуг, осуществляются только с использованием банковских счетов и (или) электронных кошельков согласно п. 8 статьи 25 Закона РК «О платежах и платежных системах» от 26 июля 2016 года.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                4.	Срок, цена и порядок оплаты услуг
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.1. Услуги Исполнителем предоставляются Пользователю на определенные периоды времени, а также в пределах Лимита установленные в рамках Тарифа.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.2. Оплата (покупка) за Тариф по данному Договору - Оферте осуществляется Пользователем на условиях 100 % (стопроцентной) предоплаты в виде безналичного расчета. Стоимость не включает в себя налог на добавленную стоимость.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.3. Исполнитель приостановит оказание Услуг (заблокирует доступ) при истечении срока действия Тарифа.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.4. Пользователь покупает дополнительный Тариф для получения дополнительных Услуг, которые не выходят в действующий Тариф.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.5. Стоимость Услуг устанавливается в тенге – национальной валюте Республики Казахстан. Все платежи за Услуги по Договору – Оферте, также осуществляются в тенге.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.6. Дата оплаты (покупка) за Тариф, зачисления денежных средств, считается дата поступления денежных средств на расчетный счет Исполнителя.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                5.	Прием-передача оказанных услуг
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.1. Прием-передача Услуг совершается не позднее последнего дня срока действия Тарифа путем направления соответствующего уведомления в Приложении.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                6.	Согласие на сбор и обработку персональных данных
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                6.1. В соответствии с законодательством Республики Казахстан, регламентирующим  отношения, связанные со сбором и обработкой Персональных данных при совершении  Пользователем Акцепта, в порядке определенном Договором, Пользователь дает  безусловное согласие Исполнителю и третьим лицам1 на сбор, обработку, трансграничную  передачу (при необходимости) Персональных данных Пользователя, не противоречащими  законодательству Республики Казахстан способами, в целях, регламентированных  Договором, и в источниках, в том числе общедоступных, по усмотрению Исполнителя, в связи с уже существующими и возникающими с Исполнителем или возникающих в будущем любых гражданско-правовых и (или) иных правоотношений, в дальнейшем – Персональные данные Пользователя.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                6.2. Под Персональными данными Пользователя, Стороны определили ниже следующие сведения, относящиеся к Пользователю, а также происходящие в них в будущем изменения и (или) дополнения, зафиксированные на электронном, бумажном и (или) ином материальном носителе, включая, но, не ограничиваясь:
                <br />
                Для юридических лиц:
                <br />
                - наименование компании, БИН, банковские реквизиты, юридический адрес, рабочие и мобильные номера телефонов, адрес(а) электронной почты.
                <br />
                Для индивидуальных предпринимателей и физических лиц:
                <br />
                - фамилия, имя, отчество, ИИН, дата рождения, место работы и должность, почтовый адрес (адрес места жительства, адрес регистрации (прописки)), домашний, рабочий, мобильный номер телефонов, адрес(а) электронной почты.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                6.3. Сбор, Обработка и трансграничная передача (при необходимости) Персональных данных Пользователя осуществляется Исполнителем для следующих целей, включая, но, не ограничиваясь:
                <br />
                - для надлежащего исполнения условий Договора, а также прав и обязанностей, возложенных на Исполнителя законодательством Республики Казахстан;
                <br />
                - для судебной и внесудебной защиты прав Исполнителя: в случае нарушения обязательств по настоящему Договору; в случае возникновения спорных ситуаций, включая спорные ситуаций с третьими лицами;
                <br />
                - для проведения диагностики, направленных на улучшение качества Услуг;
                <br />
                - для проведения маркетинговых программ, статистических исследований, а также для продвижения Услуг путем осуществления прямых контактов с Пользователем с помощью различных средств связи, включая, но, не ограничиваясь: почтовая рассылка, электронная почта, телефонная связь, сеть Интернет и т.п.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                6.4. Пользователь, передавая Исполнителю Персональные данные по открытым каналам связи, осознает и принимает на себя риск несанкционированного получения их иными лицами и связанные с этим последствия. Исполнитель не несет ответственности при несанкционированном получении Персональных данных иными лицами их передачи с открытыми каналами связи.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                6.5. Согласие Пользователя на сбор, обработку и трансграничную передачу (при необходимости) Персональных данных, регламентированное настоящим разделом может быть отозвано в случаях, регламентированных законодательством Республики Казахстан, на основании соответствующего заявления, оформленного в письменной форме, которое вручается Исполнителю в срок не менее 30 (тридцати) календарных дней до даты отзыва настоящего Согласия. При этом, Пользователю известно, что настоящее Согласие не может быть отозвано в случаях, если этот отзыв будет противоречить законодательству Республики Казахстан, либо при наличии не исполненных Пользователем обязательств перед Исполнителем.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                7.	Права, обязанности и ответственность сторон
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                7.1. Исполнитель вправе:
                <br />
                - в любой момент приостановить оказание Услуг, в случаях ненадлежащего и (или)
                несвоевременного исполнения обязательств Пользователем, проведения профилактических или иных работ, а также по любым другим причинам технического характера;
                <br />
                - ограничить доступ к ресурсам (Приложение и Сайт), в случаях обнаружения и (или) попытках несанкционированного доступа к указанным ресурсам, в том числе, попытки несанкционированного получения конфиденциальной информации;
                <br />
                - запрашивать у Пользователя информацию, документы и (или) ЭЦП, необходимые для надлежащего оказания Услуг;
                <br />
                - вносить изменения и (или) дополнения в настоящий Договор, в порядке и на условиях, предусмотренных Договором;
                <br />
                - в одностороннем порядке расторгнуть настоящий Договор, направив Пользователю соответствующее уведомление в Приложении;
                <br />
                - раскрыть информацию (полученную Сторонами в ходе оказания Услуг) государственным органам Республики Казахстан, на основании соответствующего запроса.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                7.2. Исполнитель обязан:
                <br />
                - надлежащим образом оказывать Услуги;
                <br />
                - осуществлять меры по защите Приложения и Сайта;
                <br />
                - предоставить доступ к Приложению в порядке и на условиях настоящего Договора;
                <br />
                - нести иные обязанности в соответствии с Законами Республики Казахстан.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                7.3. Пользователь вправе:
                <br />
                - пользоваться Приложением;
                <br />
                - ознакомиться со своими Персональными данными, содержащимися в Приложении;
                <br />
                - требовать от Исполнителя оказания Услуг надлежащего качества.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                7.4. Пользователь обязан:
                <br />
                - соблюдать права и законные интересы Исполнителя:
                <br />
                - по запросу Исполнителя предоставить информацию, документы и (или) ЭЦП, необходимые для надлежащего оказания Услуг;
                <br />
                - соблюдать высокий уровень защиты личных данных (логин и пароль от «Личного кабинета»).
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                7.5. За неисполнение и (или) ненадлежащее исполнение обязательств по Договору Стороны несут ответственность в соответствии с настоящим Договором и законодательством Республики Казахстан.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                7.6. Пользователь обязуется в письменном или электронном виде извещать Исполнителя о любых изменениях и (или) дополнениях исходных данных/информации,  ЭЦП, переданных Пользователем Исполнителю для целей оказания Услуг по Договору - Оферте, за  исключением информации и данных, к которым Исполнитель будет иметь доступ в период  действия Договора - Оферты. Срок извещения о возникших изменениях составляет не более 5 (пяти) рабочих дней со дня возникновения таких изменений.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                7.7. При несанкционированном использовании логина и (или) пароля Пользователя, последний обязуется незамедлительно уведомить об этом Исполнителя. Исполнитель не несет ответственности за любые убытки, причиненные Пользователю в связи с несанкционированным использованием его логина и (или) пароля.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                7.8. Исполнитель ни при каких обстоятельствах не несет ответственности перед Пользователем за косвенные убытки. Под косвенными убытками подразумеваются (включая, но не ограничиваясь) - потеря дохода, прибыли, ожидаемой экономии, деловой активности или репутации.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                8.	Форс-мажор
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                8.1. Стороны освобождается от ответственности за частичное или полное неисполнение, или ненадлежащее исполнение обязательств, по настоящему Договору - Оферте, если такое неисполнение явилось следствием обстоятельств непреодолимой силы (форс-мажора), а именно: природные и стихийные явления, военные действия, блокады, террористические действия, действия органов государственной власти и управления, если эти обстоятельства  непосредственно повлияли на исполнение настоящего Договора - Оферты. При этом, срок исполнения обязательств откладывается на весь период действия обстоятельств непреодолимой силы.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                8.2. Сторона, для которой создалась невозможность исполнения обязательств по настоящему Договору - Оферте вследствие обстоятельств непреодолимой силы, должна без промедления известить другую Сторону о наступлении и характере этих обстоятельств, но не позднее 1 (одного) месяца с момента их наступления посредством Приложения, электронной почты и/или мессенджеров WhatsApp или Telegram.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                8.3. В извещении должны содержаться данные о наступлении и характере обстоятельств и о возможных их последствиях, по возможности, оценка их влияния на исполнение Сторонами своих обязательств по настоящему Договору - Оферте и на срок исполнения обязательств. Также необходимо указать срок, в который предполагается исполнить обязательства по настоящему Договору - Оферте. Причиненные убытки, вызванные не направлением или несвоевременным направлением извещения, обязательны к возмещению Стороной, их вызвавшей.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                8.4. Сторона должна без промедления, но не позднее 1 (одного) месяца, известить другую Сторону посредством Приложения, электронной почты и/или мессенджеров WhatsApp или Telegram о прекращении этих обстоятельств.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                8.5. Если Сторона, подвергшаяся воздействию обстоятельств непреодолимой силы, не направит регламентированные настоящим Договором - Офертой документы, удостоверяющие наличие этих обстоятельств, то такая Сторона лишается права ссылаться на такие обстоятельства как на основание, освобождающее ее от ответственности за неисполнение  или ненадлежащее исполнение обязательств по настоящему Договору - Оферте.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                8.6. Срок исполнения обязательств Стороной по настоящему Договору - Оферте отодвигается соразмерно времени, в течение которого действовали обстоятельства непреодолимой силы, а также их последствия, вызванные этими обстоятельствами. В случае продления обстоятельств непреодолимой силы и их последствий, которые продолжают действовать более 2 (двух) месяцев, Стороны вправе расторгнуть настоящий Договор - Оферту без обращения в  органы правосудия, при этом обязательства прекращаются в связи с невозможностью их  исполнения (за исключением обязательств по оплате) уведомив об этом письменно в  соответствии с условиями настоящего Договора либо проводят дополнительные  переговоры для определения способов исполнения настоящего Договора.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                9.	Порядок разрешения споров
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                9.1. Разрешение споров между Сторонами осуществляется путем переговоров.
            </Typography>
            <Typography variant="caption" gutterBottom>
                9.2. В случае, если споры и разногласия не будут урегулированы путем переговоров, то тогда разрешение споров и разногласий будет решаться в судебном порядке в соответствии с законодательством Республики Казахстан.
            </Typography>
            <Typography variant="subtitle2" display="block" gutterBottom>
                10.	Порядок изменения и расторжения договора
            </Typography>
            <Typography variant="caption" gutterBottom>
                10.1. Стороны пришли к соглашению, что уведомлениями и (или) запросами Стороны по Договору - Оферте могут обмениваться без применения бумажных носителей, посредством Мобильного приложения, мессенджеров WhatsApp и/или Telegram, SMS сообщений и Push уведомлений в Мобильном приложении.
            </Typography>
            <Typography variant="caption" gutterBottom>
                10.2. Стороны пришли к соглашению, что Исполнитель вправе по своему усмотрению изменить и (или) дополнить Договор - Оферту, в том числе его условия и (или) порядок оказания Услуг, количество и (или) комплектность, а также стоимость оказываемых Услуг.
            </Typography>
            <Typography variant="caption" gutterBottom>
                10.2.1. При этом, Пользователь обязуется самостоятельно знакомиться с такими изменениями и (или) дополнениях на Сайте и в Мобильном приложении.
            </Typography>
            <Typography variant="caption" gutterBottom>
                10.2.2. Стороны пришли к соглашению, что использование Приложения Пользователем, после внесения в него соответствующих изменений и (или) дополнений, рассматривается как безоговорочное согласие с вносимыми изменениями и (или) дополнениями в Договор - Оферту.
                В целях обеспечения возможности хранения и воспроизведения условий Договора - Оферты, Исполнитель предоставляет Пользователю возможность осуществить запись текста Договора - Оферты на момент совершения Акцепта на программно-техническое средство Пользователя, нажав на ссылку «Публичная оферта» на Сайте.
            </Typography>
            <Typography variant="caption" gutterBottom>
                10.3. Действующая редакция Договора размещается на Сайте Исполнителя в разделе «Публичная оферта» и Пользователь принимает на себя обязательство регулярно (не реже 2 (двух) раз в месяц) знакомиться с его условиями на предмет его изменения и  (или) дополнения.  Пользователь не вправе, без согласия Исполнителя, передавать права и обязанности по Договору - Оферте третьим лицам для исполнения обязательств по Договору - Оферте. Исполнитель вправе передать права и обязанности по Договору – Оферте другому лицу, с направлением соответствующего уведомления Пользователю.
            </Typography>
            <Typography variant="subtitle2" display="block" gutterBottom>
                11.	Заключительные положения
            </Typography>
            <Typography variant="caption" gutterBottom>
                11.1 Во всем остальном, что не предусмотрено Договором - Офертой, Стороны будут руководствоваться действующим законодательством РК.
            </Typography>
            <Typography variant="caption" gutterBottom>
                11.2. Принимая условия данного Договора - Оферты, в связи с тем, что для некоторых документов необходима индивидуальная разработка, Пользователь соглашается, с тем, что образцы и шаблоны документов, в полной мере не всегда могут соответствовать интересам Пользователя.
            </Typography>
            <Typography variant="caption" gutterBottom>
                11.3. Договор - Оферта вступает в силу с момента его Акцептования, как это отражено в пункте 2.2. настоящего Договора - Оферта и действует до момента полного исполнения Сторонами принятых на себя обязательств.
            </Typography>
            <Typography variant="caption" gutterBottom>
                11.4.  Срок действия Договора – Оферта продлевается на следующий календарный год, если ни одна из Сторон не заявила о его прекращении не менее, чем за 30 (тридцать) календарных дней до окончания календарного года, продление срока действия Договора устанавливается бессрочно.
            </Typography>

            <Typography variant="h5" gutterBottom>
                Политика конфиденциальности и обработки персональных данных
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                Настоящая политика конфиденциальности и обработки персональных данных регулирует порядок обработки и использования персональных и иных данных сайта ТОО «FINUP.KZ» (далее — Оператор). Действующая редакция настоящей Политики конфиденциальности, постоянно доступна для ознакомления, и размещена в сети Интернет по адресу: finup.kz
                Передавая Оператору персональные и иные данные посредством «Личного кабинета» на сайте в Приложении «online Бухгалтер», Пользователь подтверждает свое согласие на использование указанных данных на условиях, изложенных в настоящей Политике конфиденциальности.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                Если Пользователь не согласен с условиями настоящей Политики конфиденциальности, он обязан прекратить использование Сайта.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                Безусловным акцептом настоящей Политики конфиденциальности является начало регистрации Пользователем на сайте в «Личном кабинете» Приложения.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                1.ТЕРМИНЫ
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.1. Сайт - сайт, расположенный в сети Интернет по адресу finup.kz.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                Все исключительные права на Сайт и его отдельные элементы (включая программное обеспечение, дизайн) принадлежат Оператору в полном объеме. Передача исключительных прав Пользователю не является предметом настоящей Политики конфиденциальности.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.2. Пользователь — лицо использующее Сайт.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.3. Законодательство — действующее законодательство Республики Казахстан.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.4. Персональные данные — персональные данные Пользователя, которые Пользователь предоставляет самостоятельно при регистрации или в процессе использования функционала Сайта.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.5. Данные — иные данные о Пользователе (не входящие в понятие Персональных данных).
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.6. Регистрация — заполнение Пользователем Регистрационной формы, расположенной на Сайте в «Личном кабинете», путем указания необходимых сведений и отправки сканированных документов.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.7. Регистрационная форма — форма, расположенная на Сайте, которую Пользователь должен заполнить для возможности использования сайта в полном объеме.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.8. Услуга(и) — услуги, предоставляемые Оператором на основании соглашения.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                2. СБОР И ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.1. Оператор собирает и хранит только те Персональные данные, которые необходимы для оказания Услуг Оператором и взаимодействия с Пользователем.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.2. Персональные данные могут использоваться в следующих целях:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.2.1 оказание Услуг Пользователю;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.2.2 идентификация Пользователя;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.2.3 взаимодействие с Пользователем;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.2.4 направление Пользователю рекламных материалов, информации и запросов;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.2.5 проведение статистических и иных исследований;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.3. Оператор в том числе обрабатывает следующие данные:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.3.1 фамилия, имя и отчество;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.3.2 адрес электронной почты;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.3.3 номер телефона (в т.ч. мобильного).
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.4. Пользователю запрещается указывать на Сайте персональные данные третьих лиц (за исключением условия представления интересов этих лиц, имея документальное подтверждение третьих лиц на осуществление таких действий).
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                3. ПОРЯДОК ОБРАБОТКИ ПЕРСОНАЛЬНЫХ И ИНЫХ ДАННЫХ
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.1. Оператор обязуется использовать Персональные данные в соответствии с Законом «О персональных данных» Республики Казахстан и внутренними документами Оператора.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.2. В отношении Персональных данных и иных Данных Пользователя сохраняется их конфиденциальность, кроме случаев, когда указанные данные являются общедоступными.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.3. Оператор имеет право сохранять архивную копию Персональных данных.
                Оператор имеет право хранить Персональные данные и Данные на серверах вне территории Республики Казахстан.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.4. Оператор имеет право передавать Персональные данные и Данные Пользователя без согласия Пользователя следующим лицам:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.4.1 государственным органам, в том числе органам дознания и следствия, и органам местного самоуправления по их мотивированному запросу;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.4.2 в иных случаях, прямо предусмотренных действующим законодательством Республики Казахстан.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.5. Оператор имеет право передавать Персональные данные и Данные третьим лицам, не указанным в п.3.4. настоящей Политики конфиденциальности, в следующих случаях:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.5.1 Пользователь выразил свое согласие на такие действия;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.5.2 передача необходима в рамках использования Пользователем Сайта или оказания Услуг Пользователю;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.6. Оператор осуществляет автоматизированную обработку Персональных данных и Данных.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                4. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.1. Оператор осуществляет надлежащую защиту Персональных и иных данных в соответствии с Законодательством и принимает необходимые и достаточные организационные и технические меры для защиты Персональных данных.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.2. Применяемые меры защиты в том числе позволяют защитить Персональные данные от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ними третьих лиц.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                5. ИНЫЕ ПОЛОЖЕНИЯ
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.1. К настоящей Политике конфиденциальности и отношениям между Пользователем и Оператором, возникающим в связи с применением Политики конфиденциальности, подлежит применению право Республики Казахстан.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.2. Все возможные споры, вытекающие из настоящего Соглашения, подлежат разрешению в соответствии с действующим законодательством по месту регистрации Оператора.
                Перед обращением в суд Пользователь должен соблюсти обязательный досудебный порядок и направить Оператору соответствующую претензию в письменном виде. Срок ответа на претензию составляет 30 (тридцать) рабочих дней.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.3. Если по тем или иным причинам одно или несколько положений Политики конфиденциальности будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Политики конфиденциальности.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.4. Оператор имеет право в любой момент изменять Политику конфиденциальности (полностью или в части) в одностороннем порядке без предварительного согласования с Пользователем. Все изменения вступают в силу с момента ее размещения на Сайте.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.5. Пользователь обязуется самостоятельно следить за изменениями Политики конфиденциальности путем ознакомления с актуальной редакцией.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.6. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по электронной почте buh@finup.kz или по телефонам: +7(708)4252550, +7(7172)252550.
            </Typography>

            <Typography variant="h5" gutterBottom>
                ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                1. Основные понятия
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.1. Приложение «Online Бухгалтер» (далее – Приложение) – программное обеспечение, специально разработанное под конкретную мобильную платформу (iOS, Android), предоставляющее доступ Пользователям к сервисам удаленного обслуживания, предназначенное для получения электронных бухгалтерских услуг посредством мобильного телефона или иного устройства, поддерживающего Приложение и доступ в интернет.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.2. Разработчик – ТОО «FINUP.KZ», БИН:231240002687, адрес: РК, г.Астана, БЦ «Абат», ул. Родниковая д.1/1, офис 402.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                1.3. Пользователь – любое физическое/юридическое лицо (в лице уполномоченного представителя), использующее Приложение.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                2. Предмет Соглашения
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.1. Настоящие Соглашение регламентирует отношения между Разработчиком и Пользователем (далее вместе именуемые «Стороны») и регулируют права и обязанности Разработчика и Пользователя в связи с использованием Мобильного приложения.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.2. Установка Приложения в мобильное устройство Пользователя является акцептом настоящего Соглашения и подтверждением согласия Пользователя с его условиями. Доступ к Приложению, использование Приложения и/или совершение любых иных действий в Приложении Пользователем означает, что Пользователь принимает и обязуется соблюдать все условия настоящего Соглашения.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.3. Положения настоящего Соглашения рассматриваются как публичная оферта в соответствии со ст. 395 Гражданского кодекса Республики Казахстан. Пользователь обязан полностью ознакомиться с настоящим Соглашением до первого запуска Приложения. Запуск Приложения Пользователем означает полное и безоговорочное принятие Пользователем настоящего Соглашения в соответствии со ст. 396 Гражданского кодекса Республики Казахстан. Положения настоящего Соглашения могут быть приняты только в целом без каких-либо изъятий.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                2.4. Разработчик вправе вносить изменения в настоящее Соглашение. При внесении изменений в Соглашение Разработчик размещает новую редакцию Соглашения на Сайте, не позднее, чем за [10] дней до вступления в силу соответствующих изменений. Предыдущие редакции Соглашения хранятся в архиве документации Разработчика.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                3. Права и обязанности Сторон
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.1. Пользователь вправе получить доступ к использованию Приложения после соблюдения требований об условиях использования Мобильного приложения и пользоваться всеми доступными для Пользователя услугами;
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.2. Пользователь вправе пользоваться Приложением исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных законодательством Республики Казахстан.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.3. Пользователь обязан самостоятельно обеспечивать неразглашение (тайну) данных, хранящихся в учетной записи, в том числе пароля, и несет ответственность за их разглашение любым способом, в том числе за все риски и убытки, возникшие, в связи с этим. Разглашение данных учетной записи является существенным нарушением настоящего Соглашения. Пользователь несет ответственность за все действия, совершенные под его учетной записью, и с использованием его логина и пароля, а также за все действия, совершенные с его Мобильного или иного устройства, на котором используется Приложение. Пользователь обязуется незамедлительно уведомлять Разработчика обо всех случаях нарушения безопасности и несанкционированного доступа к учетной записи. При невыполнении Пользователем данной обязанности Пользователь самостоятельно и в полном объеме несет ответственность за действия, совершенные под его учетной записью. Для проведения проверки учетная запись Пользователя может быть заблокирована Разработчиком.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.3. Не вводить в заблуждение других Пользователей и третьих лиц.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.4. Пользователь обязан ознакомиться с информацией о Разработчике, политикой конфиденциальности Разработчика и настоящими Соглашением до первого запуска Приложения. При несогласии с положениями указанных документов Пользователь обязан воздержаться от запуска и использования Приложения.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.5. Пользователю запрещено использовать Приложение любым способом, нарушающим законодательные и/или договорные требования.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.6. Пользователю запрещено использовать Приложение любым способом, который может отключить, перегружать, повреждать, наносить ущерб, или помешать использованию Приложения любыми другими лицами, включая их способность получать Услуги в режиме реального времени через Приложение.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.7. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием Приложения, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства Республики Казахстан при использовании Приложения.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.8. Разработчик оставляет за собой право устанавливать ограничения в использовании Приложения для всех пользователей либо для отдельной категории пользователей.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.9. При нарушении Пользователем условий настоящего Соглашения и/или его неотъемлемых частей, Разработчик имеет право отказать Пользователю в использовании Приложения до устранения причин, послуживших основанием для отказа в предоставлении доступа к Приложению.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.10. Разработчик вправе в любое время вносить изменения в функционал Приложения, интерфейс и/или содержание Приложения с уведомлением Пользователей или без такового.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.11. Разработчик вправе запросить и использовать информацию о Пользователе исключительно в целях предоставления услуг по использованию Приложения. Использование информации о Пользователе регулируется политикой конфиденциальности Разработчика.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                3.12. Разработчик обязан обеспечить техническую поддержку Мобильного приложения и предоставить простой способ связи на Сайте для обращений Пользователей по всем возникающим в процессе использования Приложения.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                4. Интеллектуальная собственность
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.1. Пользователь признает, что Приложение, его интерфейс и содержание (включая, но не ограничиваясь, элементы дизайна, текст, графические изображения, иллюстрации, видео, скрипты, программы, музыка, звуки и другие объекты и их подборки, связанные с Приложением) защищены авторским правом, товарными знаками, патентами и иными правами, которые принадлежат Разработчику или иным законным правообладателям.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.2. Разработчик предоставляет Пользователю право на использование Приложения, а именно на запуск и дальнейшую эксплуатацию Приложения исключительно в целях удовлетворения личных нужд, без права передачи данного права третьим лицам.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.3. Пользователь не вправе воспроизводить, копировать, изменять, уничтожать, перерабатывать (включая выполнение любого перевода или локализации), продавать, сдавать в прокат, опубликовывать, скачивать, иным образом распространять Приложение либо его компоненты, декомпилировать или иным образом пытаться извлечь исходный код компонентов Приложения, являющихся программным обеспечением, а также изменять функционал Приложения
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.4. Пользователь не вправе удалять и/или изменять какую-либо информацию, размещенную Разработчиком в рамках Приложения, в том числе знаки охраны авторского права и средств индивидуализации.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.5. Права, указанные в пункте 4.2 настоящего Соглашения, предоставляется на весь срок использования Приложением Пользователем. Право на использование распространяется также на все обновления и/или дополнительные компоненты Приложения, которые могут быть созданы и предоставлены Разработчиком в будущем.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                4.6.  Ничто в настоящем Соглашении не может быть рассмотрено как передача исключительных прав на Приложение и/или его компоненты Пользователю.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                5. Гарантии и Ответственность
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.1. Пользователь признает и соглашается с тем, что Приложение предоставляется на условиях «как есть». Разработчик не предоставляет гарантий в отношении последствий использования Приложения, взаимодействия Приложения с другим программным обеспечением.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.2. Разработчик не предоставляет гарантий того, что Приложение может подходить для конкретных целей использования. Пользователь признает и соглашается с тем, что результат использования Приложения может не соответствовать ожиданиям Пользователя.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.3. Разработчик ни при каких обстоятельствах не несет ответственность за любой косвенный, случайный, неумышленный ущерб (включая упущенную выгоду, ущерб, причиненный утратой данных), вызванный в связи с использованием Приложения или невозможностью его использования, в том числе в случае отказа работы Приложения или иного перерыва в использовании Приложения.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.4. Пользователь самостоятельно несет ответственность за свои действия по использованию Приложения, в том числе за действия по размещению и передаче информации, комментариев, изображений и иных материалов другим Пользователям с помощью Приложения. Пользователь самостоятельно несет ответственность за соблюдение прав третьих лиц, применимого законодательства, настоящего Соглашения, каких-либо правил и/или обязательных инструкций Разработчика, размещенных в разделе «Помощь» Приложения, при использовании Приложения.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                5.5. За нарушения, допущенные Пользователем, Разработчик вправе отказать Пользователю в дальнейшем предоставлении услуг или ограничить такое представление полностью или частично с уведомлением Пользователя или без такового.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
                6. Заключительные положения
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                6.2. Разработчик вправе в любой момент приостанавливать или прекращать функционирование Приложения с уведомлением Пользователей или без такового.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                6.3. Настоящее Соглашение регулируются и толкуются в соответствии с законодательством Республики Казахстан. Вопросы, не урегулированные Соглашением, подлежат разрешению в соответствии с законодательством Республики Казахстан.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                6.4. В случае возникновения любых споров или разногласий, связанных с исполнением настоящего Соглашения, Разработчик и Пользователь приложат все усилия для их разрешения путем проведения переговоров между ними. если споры и разногласия не будут урегулированы путем переговоров между сторонами, они подлежат разрешению в судебном порядке, согласно законодательства Республики Казахстан.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                6.5. Настоящее Соглашение составлено на русском языке.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                6.6. Настоящее Соглашение может быть изменено и/или дополнено Разработчиком в любое время в течение срока действия Соглашения по своему усмотрению без необходимости получения на то согласия Пользователя. Все изменения и/или дополнения размещаются Разработчиком в соответствующем разделе Приложения и вступают в силу в день такого размещения. Пользователь обязуется своевременно и самостоятельно знакомиться со всеми изменениями и/или дополнениями. При наличии возражений Пользователь вправе направить извещение в течение 3 календарных дней с момента размещения изменений и/или дополнений к Соглашению. В таком случае Соглашение считается расторгнутым Сторонами с даты получения Компанией такого извещения от Пользователя.
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
                6.7. Если какое-либо из положений настоящего Соглашения будет признано недействительным, это не оказывает влияния на действительность или применимость остальных положений настоящего Соглашения.
            </Typography>
        </Box>
    );
}