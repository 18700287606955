import * as React from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material';
import { useDrawingArea } from '@mui/x-charts';

const size = {
  
  height: 320,

};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={(left + width / 2)+22} y={(top + height / 2)-6} sx={{ fontSize: '20px', fontWeight: '600' }} >
      {children}
    </StyledText>
  );
}

export default function ChartsPia({data}) {
  const allTotal = data.reduce((sum, value) => sum + value.value, 0)
  return (
    <PieChart      
      series={[
        {
          arcLabel: (item) => `${item.label}`,
          arcLabelMinAngle: 10,
          data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          innerRadius: 60,
          outerRadius: 150,
          paddingAngle: 5,
          cornerRadius: 5,
          cx: 150,
          cy: 150,
        },

      ]}
      slotProps={{ legend: { hidden: true } }}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: '#07139e',
          fontWeight: 'bold',
        },

      }}

      {...size}

    >
      <PieCenterLabel>
        {allTotal + 'тг.'}
      </PieCenterLabel>
    </PieChart>
  );
}