import * as React from 'react';
import { useSelector } from 'react-redux';
import FunctionCard from './Card/FunctionCard';
import { Grid } from '@mui/material';

const PanelBriefInfo = () => {
    const words = useSelector(state => state.language.words)
    const allCompanies = useSelector(state => state.companies.companies)
    const allApplications = useSelector(state => state.applicaions.allApplications)



    return (
        <Grid container spacing={3} sx={{mt: '0'}}>
            <FunctionCard
                allCompanies={allCompanies}
                allApplications={allApplications}
                words={words}
            />
        </Grid>)
}

export default PanelBriefInfo