import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  
} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const CustomNoRowsOverlay = () => {
  return (
    <Typography variant="hoverline" gutterBottom display="block" color="#000000" sx={{ pt: "15px", textAlign: "center" }}>
      Нет записи
    </Typography>
  )
}

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;
  const idMyCompany = useSelector(state => state.myCompany.id)  
  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [{ id, idDelivAddr: 0, isNew: true, idMyCompany, update: true }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'plu' },
    }));
    
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} >
        Добавить запись
      </Button>
    </GridToolbarContainer>
  );
}

export default function TableUnitsAdressDelivery({ setDataDelivAddrForm, setDelDelivAddr, reloadTable }) {
  const allAddressDelivery = useSelector(state => state.addressDelivery.addrDelivery)
  const allCompanies = useSelector(state => state.companies.companies)
  const initialState = allAddressDelivery.map((cont) => ({...cont, idDelivAddr: cont.id, update: false}))//?????
  const [rows, setRows] = React.useState(initialState);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const companiName = allCompanies.map((com) => ({name: com.name, id: com.id}))

  React.useEffect(()=>{setRows(allAddressDelivery.map((cont) => ({...cont, idDelivAddr: cont.id, update: false})))},[allAddressDelivery,setRows, reloadTable])

  React.useEffect(() => {
    setDataDelivAddrForm(rows)
  }, [rows, setDataDelivAddrForm])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setDelDelivAddr(rows.filter((row) => row.id === id)[0])
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    //setAllTotal(AllTotal + newTotal)    
    const updatedRow = { ...newRow, isNew: false, update: true };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'idDelivAddr',
      headerName: '№',
      width: 10,
      type: 'number',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'idCompany',
      headerName: 'Компания',
      width: 120,
      type: 'singleSelect',
      getOptionValue: (value) => value.id,
      getOptionLabel: (value) => value.name,
      valueOptions: companiName,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    }, 
    {
      field: 'address',
      headerName: 'Адрес',
      width: 140,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },       
    {
      field: 'workerName',
      headerName: 'ФИО сотрудника',
      width: 100,
      align: 'left',
      headerAlign: 'center',
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'phone',
      headerName: 'Телефон',
      width: 100,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'idMyCompany',
      headerName: 'id компании',
      width: 80,
      type: 'number',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
    }, 
    {
      field: 'actions',
      type: 'actions',
      headerName: '...',
      width: 70,
      cellClassName: 'actions',
      disableColumnMenu: true,
      hideSortIcons: true,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        sx={{ fontSize: "11px" }}
        rows={rows}
        columns={columns}
        autoPageSize
        onProcessRowUpdateError={(error) => console.log(error)}
        columnVisibilityModel={{
          idDelivAddr: false,
          idMyCompany: false,
        }}
        editMode='row'
        hideFooterPagination={false}
        hideFooterSelectedRowCount={true}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        density="compact"
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}

        slots={{
          toolbar: EditToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />

    </Box>
  );
}