//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import { clientsDelete, clientsUpdate } from '../../../Redux/companiesReducer'

import ClientsPanel from './ClientsPanel';




class ClientsPanelContainer extends React.PureComponent {

    render() {
        return (<>            
                <ClientsPanel
                    clientsUpdate={this.props.clientsUpdate}
                    clientsDelete={this.props.clientsDelete} 
                    myCompany={this.props.myCompany}    
                />           
        </>)
    }
}

const mapStateToProps = (state) => {
    return {        
    }
};

export default connect(mapStateToProps, { 
    clientsUpdate,
    clientsDelete,  
})(ClientsPanelContainer)
