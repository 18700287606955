import * as React from 'react';
import { useSelector } from 'react-redux';
import UserCard from '../../../components/UserCard';
import { Grid } from '@mui/material';

const UsersPanel = ({ updateAuthUserRequest, updateUserPwdRequest }) => {
    //const myCompany = useSelector(state => state.myCompany)
    const users = useSelector(state => state.users.users)
    const usersCard = users.map((u) => <UserCard key={u.id} user={u} updateAuthUserRequest={updateAuthUserRequest} updateUserPwdRequest={updateUserPwdRequest} />)

    return (<div style={{ marginTop: '15px' }}>
        <Grid container spacing={2} >
            {usersCard}
        </Grid>
    </div>)
}

export default UsersPanel