import React, { useEffect } from 'react';
import { Alert, Box, Button, Collapse, Dialog, DialogContent, DialogTitle, Fab, Grid, IconButton, List, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ApplicationMin from '../Components/ApplicationMin';
import NewApplication from '../Forms/NewApplication';
import { styled } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded';
import { useDispatch } from 'react-redux';
import WidgetsRoundedIcon from '@mui/icons-material/WidgetsRounded';

const Home = ({
    allApplications,
    allContents,
    allCompanies,
    authUser,
    myCompony,
    setApplicationRequest,
    updateApplicationRequest,
    deleteApplicationRequest,
    updatePaidRequest,
    updateAVRRequest,
    updateEsfRequest,
    updateEsfDoneRequest
}) => {

    const [openFormApplic, setOpenFormApplic] = React.useState(false);
    const [openFormDelete, setOpenFormDelete] = React.useState(false);
    const [openFormApplicId, setOpenFormApplicId] = React.useState({ id: 0, update: 0 });
    //const allAddressDelivery = useSelector(state => state.addressDelivery.addrDelivery)
    const dispatch = useDispatch()

    const setOpenFormCompany = (data) => {
        dispatch({ type: 'MYCOMPONY/SET_OPEN_FORM', data })
    }

    const setOpenFormLists = (data) => {
        dispatch({ type: 'AUTH/SET_OPEN_FORM_CATALOG', data })
    }
    

    let AllApplications = <></>

    const handleCloseFormProfile = () => {
        setOpenFormApplic(false)
    }

    const handleOpenFormProfile = (id, update) => {
        setOpenFormApplicId({ id, update })
        setOpenFormApplic(true)
    }
    const Demo = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
    }));

    if (allApplications.length !== 0 && allCompanies.length !== 0) {
        AllApplications = allApplications.map((appl) => <ApplicationMin key={appl.id} updateEsfDoneRequest={updateEsfDoneRequest} updateEsfRequest={updateEsfRequest} updateAVRRequest={updateAVRRequest} updatePaidRequest={updatePaidRequest} old={false} application={appl} allContents={allContents} allCompanies={allCompanies} openForm={handleOpenFormProfile} />)
    }
    let dateDoc = "0"
    let nuberDoc = "0"

    if (openFormApplicId.id > 0) {
        const application = allApplications.filter((app) => app.id === openFormApplicId.id)[0]
        dateDoc = application.date
        nuberDoc = application.number
    }

    const handleDeleteClick = (id) => {
        const application = allApplications.filter((app) => app.id === id)[0]
        if (application.esfDone) {
            alert("Нельзя удалить!!! Была отправлена ЭСФ");
        } else {
            deleteApplicationRequest({ id, companyId: authUser.mycompany[0], idUser: authUser.id, userKey: authUser.userKey })
            //console.log({id, mycompany: authUser.mycompany[0], idUser: authUser.id, userKey: authUser.userKey});
            setOpenFormApplicId({ id: 0, update: 0 })
            setOpenFormDelete(false)
            setOpenFormApplic(false)
        }
    }

    useEffect(() => window.scrollTo(0, 0), []);

    const allMess = [
        {
            value: myCompony.name.length === 0 ||
                myCompony.bin.length === 0, mess: "Заполните данные о своей компании!!!", col: "warning"
        },
        {
            value: allCompanies.length === 0 ||
                allContents.length === 0, mess: "Заполните справочники Товары/Контрагенты!!!", col: "error"
        },
    ]

    return <>
        <Box sx={{ textAlign: "center" }}>

            {
                (myCompony.name.length === 0 ||
                    myCompony.bin.length === 0) ?
                    allMess.map((am) =>
                        <Grid key={am.col} item xs={12} sx={{ pt: '20px' }}>
                            <Collapse in={am.value}>
                                <Alert severity={am.col}>{am.mess}</Alert>
                                {am.col === "warning" ? 
                                <Button onClick={() => setOpenFormCompany(true)} color='secondary' variant="outlined" startIcon={<BusinessCenterRoundedIcon />}>
                                    Редактировать данные компании
                                </Button>:
                                <Button onClick={() => setOpenFormLists(true)} color='secondary' variant="outlined" startIcon={<WidgetsRoundedIcon />}>
                                    Добавить Товары\Услуги в Справочнике
                                </Button>}
                            </Collapse>
                        </Grid>
                    ) : <Fab onClick={() => handleOpenFormProfile(0, 0)} variant="extended" size="medium" color="primary" aria-label="add" sx={{ color: "#07139e", fontWeight: "600", marginTop: "10px" }}>
                    <AddIcon color='secondary' sx={{ mr: 1 }} />
                    Новая запись
                </Fab>
            }
            



            <Demo>
                <List dense={true}>
                    {AllApplications}
                </List>
            </Demo>

            <Dialog
                open={openFormApplic}
                onClose={handleCloseFormProfile}
                scroll='body'
                aria-labelledby="customized-dialog-title"
            >
                <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #07139e', fontSize: '18px' }} color="#07139e" id="customized-dialog-title">
                    {(openFormApplicId.id < 1) ? "Новая запись" : "Запись № " + nuberDoc + " от " + dateDoc + " г."}
                </DialogTitle>

                <IconButton
                    aria-label="close"
                    onClick={() => setOpenFormDelete(true)}
                    color='error'
                    sx={{
                        position: 'absolute',
                        right: 1,
                        top: 1,
                        p: '5px'
                    }}
                >
                    <DeleteOutlineIcon />
                </IconButton>

                <DialogContent>
                    <NewApplication
                        selectCompany={myCompony}
                        allContents={allContents}
                        allCompanies={allCompanies}
                        applicationId={openFormApplicId}
                        allApplications={allApplications}
                        authUser={authUser}
                        myCompony={myCompony}
                        setApplicationRequest={setApplicationRequest}
                        handleCloseFormProfile={handleCloseFormProfile}
                        updateApplicationRequest={updateApplicationRequest}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openFormDelete}
                onClose={() => setOpenFormDelete(false)}
                scroll='body'
            >
                <DialogContent>
                    <Grid container spacing={1} sx={{ textAlign: 'center' }}>
                        <Grid item xs={12}>
                            <Typography color="#07139e" sx={{ m: 'auto' }} >
                                УДАЛИТЬ ЗАПИСЬ № {nuberDoc}?
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={() => handleDeleteClick(openFormApplicId.id)} type="button" color='error' variant="outlined">Удалить</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={() => setOpenFormDelete(false)} color='error' type="button" variant="outlined">Отмена</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </Box >
    </>
}

export default Home;
