import React from 'react';
import Fno910min from './FormsFNO/Fno910min';
import { Box, Dialog, DialogContent, Fab, Grid } from '@mui/material';
import AddchartIcon from '@mui/icons-material/Addchart';
import NewFNO910Form from '../../Forms/NewFNO910Form';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useCallback } from 'react';

const Fno910 = ({ addNewFNO910, updateFNO910, addNewWages, newExpenses, myCompany }) => {

  const allFno910 = useSelector(state => state.fno910.fno910)
  const [openForm, setOpenForm] = useState(false)
  const [fno910, setFno910Form] = useState(<></>)

  const updatefno = useCallback((id) => {
    updateFNO910({id, status: 2}, myCompany.id)
  }, [myCompany.id, updateFNO910])

  useEffect(() => {    

    const getFno910 = allFno910.map((fno) =>
      <Fno910min key={fno.id} fno={fno} updatefno={updatefno} />
    )
    setFno910Form(getFno910)
  }, [allFno910, updatefno])

  const handleCloseForm = () => {
    setOpenForm(false)
  }

  const handleClickNewFNO910 = () => {
    setOpenForm(true)
  }

  return (<>    
    <Box sx={{ textAlign: "center" }}>

      <Fab onClick={handleClickNewFNO910} variant="extended" size="medium" aria-label="add" sx={{ color: "#07139e", bgcolor: "#b39ddb", fontWeight: "600", marginTop: "10px" }}>
        <AddchartIcon color='secondary' sx={{ mr: 1 }} />
        Создать новую ФНО 910.00
      </Fab>
      <Grid container>
        {fno910}
      </Grid>
      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        scroll='body'
      >
        <DialogContent>
          <NewFNO910Form
            handleCloseForm={handleCloseForm}
            addNewFNO910={addNewFNO910}
            addNewWages={addNewWages}
            newExpenses={newExpenses}
            myCompany={myCompany}
          />
        </DialogContent>
      </Dialog>
    </Box>
  </>
  )
}

export default Fno910;