import * as React from 'react';
import PanelBriefInfoContainer from './Dashboard/Panel1/PanelBriefInfoContainer';
import PanelTabale from './Dashboard/Panel2/PanelTabale';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import WorkPlans from './Dashboard/Panel3/WorkPlans';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const DashboardPanel = ({
    updateTaskRequest,
    updateTaskCloseRequest,
    updateTaskReopenRequest,
    uploadFileTaskRequest,
    setUploadCloseFileTaskRequest
}) => {

    window.scroll(0, 0);

    const giveTimeDate = () => {
        //now DataTime
        const nowDate = dayjs(new Date())
        const time = (nowDate.$H > 9 ? nowDate.$H : '0' + nowDate.$H) + ':' + (nowDate.$m > 9 ? nowDate.$m : '0' + nowDate.$m)
        const date = nowDate.$D + '.' + (nowDate.$M + 1 > 9 ? nowDate.$M + 1 : '0' + (nowDate.$M + 1)) + '.' + nowDate.$y
        return { time, date, user: authUser.id}
    }

    const newTask = useSelector(state => state.task.allTask).filter((item)=>item.state===0||item.state===1)
    const closeTask = useSelector(state => state.task.allTask).filter((item)=>item.state===2)
    const filesClosedTask = useSelector(state => state.task.filesClosedTask)
    const allCompany = useSelector(state => state.myCompany.allCompany)
    const authUser = useSelector(state => state.auth.authUser)
    
    const updateTaskState = (obj) => {
        updateTaskRequest({ ...obj, dataStart: giveTimeDate().date, timeStart: giveTimeDate().time }, { idUser: authUser.id })
    }

    const updateTaskStateClose = (obj) => {
        updateTaskCloseRequest({ ...obj, dataClose: giveTimeDate().date, timeClose: giveTimeDate().time }, { idUser: authUser.id })
    }

    const updateTaskStateReopen = (obj) => {
        updateTaskReopenRequest({ ...obj, dataUpdate: giveTimeDate().date, timeUpdate: giveTimeDate().time }, { idUser: authUser.id })
    }

    return (<>
        <PanelBriefInfoContainer />
        <Grid container spacing={1}>
            <Grid item md={7}>
                <PanelTabale />
            </Grid>
            <Grid item md={5}>
                <Typography variant='h3' fontSize={18} fontWeight={600} sx={{ lineHeight: '1.33', letterSpacing: '-0.025em', mt: '40px' }}>
                    План - график задач
                </Typography>
                <Divider sx={{ borderColor: '#fcbb42' }} />
                <Paper variant="outlined" square={false} sx={{ backgroundColor: '#ff050587', mt: 1 }} >
                    <Typography variant="overline" display="block" gutterBottom fontSize={14} sx={{ color: '#fff', ml: 1 }} >
                        Текущие задачи
                    </Typography>
                    <WorkPlans
                        task={newTask}
                        allCompany={allCompany}
                        updateTaskState={updateTaskState}
                        updateTaskStateClose={updateTaskStateClose}
                        updateTaskStateReopen={updateTaskStateReopen}
                        uploadFileTaskRequest={uploadFileTaskRequest}
                        setUploadCloseFileTaskRequest={setUploadCloseFileTaskRequest}
                        giveTimeDate={giveTimeDate}
                    />
                </Paper>
                <Paper variant="outlined" square={false} sx={{ backgroundColor: '#05a5059e', mt: 1 }} >
                    <Typography variant="overline" display="block" gutterBottom fontSize={14} sx={{ color: '#fff', ml: 1 }} >
                        Выполненные задачи
                    </Typography>
                    <WorkPlans
                        task={closeTask}
                        allCompany={allCompany}
                        updateTaskState={updateTaskState}
                        updateTaskStateClose={updateTaskStateClose}
                        updateTaskStateReopen={updateTaskStateReopen}
                        uploadFileTaskRequest={uploadFileTaskRequest}
                        setUploadCloseFileTaskRequest={setUploadCloseFileTaskRequest}
                        giveTimeDate={giveTimeDate}
                    />
                </Paper>
            </Grid>
        </Grid>
    </>)

}

export default DashboardPanel