import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListPlans from './ListPlans';
import { useState } from 'react';
import CloseListPlans from './CloseListPlans';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const WorkPlans = ({
  task,
  allCompany,
  updateTaskState,
  updateTaskStateClose,
  updateTaskStateReopen,
  uploadFileTaskRequest,
  setUploadCloseFileTaskRequest,
  giveTimeDate
}) => {
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [idTask, setIdTask] = useState(0)
  const [stateTask, setStateTask] = useState(0)

  const handleClickOpen = (id, state) => {
    setIdTask(id)
    setStateTask(state)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateStateTask = () => {
    if (stateTask === 2) {
      updateTaskStateClose({ id: [idTask], state: stateTask })
    } else {
      if (stateTask === 0) {
        updateTaskStateReopen({ id: [idTask], state: stateTask })
      }
    }
    setOpen(false);
  };

  const handleChange = (panel, tasks) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    
    const updateTaskOpen = tasks.filter((item) => item.state === 0).map((item) => item.id)
   
    if (updateTaskOpen.length > 0) {
      updateTaskState({ id: updateTaskOpen, state: 1 })
    }
  };

  const taskComp = [...new Set(task.map((item) => item.idCompany))].map((item) => ({
    idCompany: item,
    name: allCompany.filter((comp) => comp.id === item)[0].name,
    task: task.filter((t) => t.idCompany === item)
  }))

  return (
    <div>
      {taskComp.length > 0 ?
        taskComp.map((item) => 
          <Accordion key={item.id} expanded={expanded === item.idCompany} onChange={handleChange(item.idCompany, item.task)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>
                {item.name}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}> {item.task.filter((item) => item.state === 0).length > 0 ? "Есть новая Задача! /" : ""} Задачи: {item.task.length}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item.task[0].state === 2 ? <CloseListPlans tasks={item.task} handleClickOpen={handleClickOpen} giveTimeDate={giveTimeDate} /> : <ListPlans tasks={item.task} handleClickOpen={handleClickOpen} uploadFileTaskRequest={uploadFileTaskRequest} setUploadCloseFileTaskRequest={setUploadCloseFileTaskRequest} giveTimeDate={giveTimeDate} />}
            </AccordionDetails>
          </Accordion>
        )

        : <Typography sx={{ width: '33%', flexShrink: 0 }}>
          ОТСУТСТВУЮТ
        </Typography>}

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle style={{ cursor: 'move' }} >
          {stateTask === 2 ? "Заявка выполнена?" : "Вернуть заявку на доработку?"}
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Нет
          </Button>
          <Button onClick={handleUpdateStateTask}>Да</Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default WorkPlans