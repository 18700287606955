//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Fno913 from './Fno913';
import { pointsUpdate } from '../../Redux/pointsReducer'
import { newExpenses } from '../../Redux/expensesReducer'
import { addNewWages } from '../../Redux/wagesReducer'
import { updateFNO913, addNewFNO913 } from '../../Redux/fno913Reducer'

class Fno913Container extends React.PureComponent {

    render() {
        return (<>            
                <Fno913    
                    pointsUpdate={this.props.pointsUpdate}   
                    newExpenses={this.props.newExpenses}
                    addNewWages={this.props.addNewWages}  
                    updateFNO913={this.props.updateFNO913}
                    addNewFNO913={this.props.addNewFNO913}
                    myCompany={this.props.myCompany}
                />            
        </>)
    }
}

const mapStateToProps = (state) => {
    return {    
        
    }
};

export default connect(mapStateToProps, {pointsUpdate, addNewWages, newExpenses, updateFNO913, addNewFNO913})(Fno913Container)
