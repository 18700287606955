import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Avatar, Badge, Chip, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState } from 'react';

export default function CloseListPlans({ tasks, handleClickOpen }) {
  const filesClosedTask = useSelector(state => state.task.filesClosedTask)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {tasks.map((value) => {
        const labelId = `checkbox-list-secondary-label-${value.id}`;
        return (
          <ListItem
            key={value.id}
            secondaryAction={<>
              <Chip label={value.timeFinish} variant="outlined" size="small" color='secondary' sx={{ border: 0 }} icon={<AccessTimeIcon />} />
              <Chip label={value.dataFinish} size="small" sx={{ ml: 0.5, border: 0 }} variant="outlined" color='secondary' icon={<CalendarMonthIcon />} />

              <Tooltip title="Скачать документы">
                <IconButton
                  variant="outlined"
                  edge="end"
                  color='info'
                  size="large"
                  onClick={handleClick}
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Badge badgeContent={value.uploadFile ? filesClosedTask.filter((item) => item.idTask === value.id).length : 0} color="primary">
                    <SimCardDownloadOutlinedIcon color='info' sx={{ fontSize: '1.9rem' }} />
                  </Badge>
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 0,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {filesClosedTask.filter((item) => item.idTask === value.id).map((file) =>
                  <a href={file.url} target="_blank" download>
                    <MenuItem >
                      <Avatar> {file.type} </Avatar> {file.name}
                    </MenuItem>
                  </a>
                )}
              </Menu>

              <IconButton onClick={() => handleClickOpen(value.id, 0)} variant="outlined" edge="end" color='error' size="large" >
                <ReplyOutlinedIcon color='error' sx={{ fontSize: '1.9rem' }} />
              </IconButton>
            </>
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemAvatar>
                #{value.id}

              </ListItemAvatar>
              <ListItemText sx={{ pr: 26 }} id={labelId} primary={value.title} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}