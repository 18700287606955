import * as React from 'react';
import { Avatar, Divider, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import { useSelector } from 'react-redux';

export default function ExpensesMin({ exp, deleteExpenses, delWages, setOpenFormWage, setUpdWages, myCompany }) {
    const wages = useSelector(state => state.wages.wages)

    const handleClickDelete = () => {
        if (exp.label === "Зарплата") {
            const deleleteWages = wages.filter((w) => w.year === exp.year && w.month === exp.month)[0]
            delWages(deleleteWages, myCompany.id)
        }
        deleteExpenses(exp, myCompany.id)
    }

    const handleClickEdit = () => {
        const updWages = wages.filter((w) => w.year === exp.year && w.month === exp.month)[0]
        setUpdWages({ total: exp.value, wagesId: updWages, expensesId: exp.id })
        setOpenFormWage(true)
    }

    return (<>
        <ListItem
            sx={{ pl: 0, pr: 0 }}

            secondaryAction={<>
                {exp.label === "Зарплата" ?
                    <IconButton onClick={() => handleClickEdit()} edge="end" aria-label="delete" sx={{ ml: 1 }}>
                        <ModeEditOutlineOutlinedIcon fontSize="large" />
                    </IconButton> : <></>}
                {exp.photo.length > 0 ?
                    <a href={exp.photo} target="_blank" download>
                        <IconButton edge="end" aria-label="delete" sx={{ ml: 1 }}>

                            <SaveAltRoundedIcon fontSize="large" />
                        </IconButton>
                    </a> : <></>}
                <IconButton onClick={() => handleClickDelete()} edge="end" aria-label="delete" sx={{ ml: 1 }}>
                    <DeleteOutlineRoundedIcon fontSize="large" />
                </IconButton>

            </>
            }
        >
            <ListItemButton sx={{ pl: 1 }} >
                <ListItemAvatar sx={{ minWidth: '50px' }}>
                    <Avatar sx={{ bgcolor: exp.color }} >
                        {exp.id}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={exp.label + ' - ' + exp.value + 'тг.'}
                    secondary={exp.title + " - " + exp.date + "г."}
                />
            </ListItemButton>
        </ListItem>
        <Divider sx={{ borderColor: '#fcbb42' }} />
    </>
    );
}