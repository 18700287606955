import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { IconButton } from '@mui/material';

export default function TableWorkwrsV({ worker, wagesWithSeason, seasonMonth, addWage }) {
  
  return (
    <TableContainer sx={{ mt: 0.5 }} component={Paper}>
      <Table sx={{ minWidth: 260 }} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ maxWidth: 20 }} ></TableCell>
            <TableCell style={{ maxWidth: 50 }} align="right">{worker.firstName + ' ' + worker.lastName}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {seasonMonth.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell style={{ maxWidth: 20 }} component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell style={{ maxWidth: 50 }} align="right">{wagesWithSeason.filter((w) => w.month === row.id).length > 0 ?
                wagesWithSeason.filter((w) => w.month === row.id)[0].wage :
                <IconButton size='small' sx={{ p: 0 }} onClick={addWage} edge="end" aria-label="delete">
                  <AddBoxOutlinedIcon color='success'  />
                </IconButton>}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}