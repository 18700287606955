import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import logo from '../Img/finup_logo_.png';
// import logo1 from '../Img/logo.png';
import logo2 from '../../Img/logo512.png';
import { Dialog, DialogContent, DialogTitle, Divider, MenuList, Stack } from '@mui/material';
import UserProfile from '../../Forms/UserProfile';
import UserProfilePass from '../../Forms/UserProfilePass';
import CompanyProfile from '../../Forms/CompanyProfile';
import { useDispatch, useSelector } from 'react-redux';
import TabLists from '../../Forms/TabLists';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import PaymentsBord from '../../Forms/PaymentsBord';
import { useState } from 'react';
import { useEffect } from 'react';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

function ComponentAppBar({ 
    authUser, 
    myCompony, 
    updateAuthUserRequest, 
    updateUserPwdRequest, 
    updateMyCompanyRequest, 
    contentsUpdate, 
    contentsDelete, 
    clientsUpdate,
    clientsDelete,
    delivAddressUpdate,
    delivAddressDelete,
    uploadSealImgRequest, 
    uploadSignatureImgRequest,
    newExpensGroups, 
    updateExpensGroups,
    pointsUpdate,
    pointsDelete
}) {

    //const [openFormLists, setOpenFormLists] = React.useState(false);
    const [openFormPayments, setOpenFormPayments] = React.useState(false);
    const navigate = useNavigate();
    const [dataPST, setDataProductServiceForm] = React.useState([]);
    const [delContents, setDelContent] = React.useState([]);
    const [dataClients, setDataClientsForm] = React.useState([]);
    const [delClients, setDelClient] = React.useState([]);
    const [dataDelivAddr, setDataDelivAddrForm] = React.useState([]);
    const [delDelivAddr, setDelAddrSeliv] = React.useState([]);

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const openFormCompany = useSelector(state => state.myCompany.formOpen)
    const openForm = useSelector(state => state.auth.formOpen)
    const openFormPass = useSelector(state => state.auth.formOpenPass)
    const openFormLists = useSelector(state => state.auth.formOpenСatalog)

    const setDelContents = (cont) => {
        delContents.push(cont.id)
        setDelContent(delContents)
    }

    const setDelClients = (client) => {
        delClients.push(client.id)
        setDelClient(delClients)
    }

    const setDelDelivAddr = (addrDev) => {
        delDelivAddr.push(addrDev.id)
        setDelAddrSeliv(delDelivAddr)
    }


    const submitProductServiceForm = () => {
        const newContents = dataPST.filter((cont) => cont.idContent === 0 && cont.update)
        const updateContents = dataPST.filter((cont) => cont.idContent !== 0 && cont.update)
        const newClients = dataClients.filter((cont) => cont.idClient === 0 && cont.update)
        const updateClients = dataClients.filter((cont) => cont.idClient !== 0 && cont.update)
        const newDelivAddr = dataDelivAddr.filter((cont) => cont.idDelivAddr === 0 && cont.update)
        const updateDelivAddr = dataDelivAddr.filter((cont) => cont.idDelivAddr !== 0 && cont.update)

        if (updateContents.length !== 0 || newContents.length !== 0) {
            contentsUpdate({ update: updateContents, new: newContents }, myCompony.id)
        }
        if (delContents.length !== 0) {
            contentsDelete({ delContents: delContents }, myCompony.id);
        }

        if (updateClients.length !== 0 || newClients.length !== 0) {
            clientsUpdate({ update: updateClients, new: newClients }, myCompony.id)
        }
        if (delClients.length !== 0) {
            clientsDelete({ delClients: delClients }, myCompony.id);
        }

        if (updateDelivAddr.length !== 0 || newDelivAddr.length !== 0) {
            delivAddressUpdate({ update: updateDelivAddr, new: newDelivAddr }, myCompony.id)
        }
        if (delDelivAddr.length !== 0) {
            delivAddressDelete({ delDeliveryAddress: delDelivAddr }, myCompony.id);
        }

        setOpenFormLists(false)
    }

    const dispatch = useDispatch()

    const setOpenFormCompany = (data) => {
        dispatch({ type: 'MYCOMPONY/SET_OPEN_FORM', data })
    }

    const setOpenForm = (data) => {
        dispatch({ type: 'AUTH/SET_OPEN_FORM', data })
    }

    const setOpenFormPass = (data) => {
        dispatch({ type: 'AUTH/SET_OPEN_FORM_PASS', data })
    }

    const setOpenFormLists = (data) => {
        dispatch({ type: 'AUTH/SET_OPEN_FORM_CATALOG', data })
    }


    const open = Boolean(anchorElUser);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseFormPass = () => {
        setOpenFormPass(false)
    }

    const handleCloseFormPayments = () => {
        setOpenFormPayments(false)
    }

    const handleOpenFormPass = () => {
        setOpenFormPass(true)
    }

    const handleCloseForm = () => {
        setOpenForm(false)
        setOpenFormCompany(false)
    }

    const handleOpenFormAuth = () => {
        setOpenForm(true)
        setAnchorElUser(null);
    };

    const handleOpenFormLists = () => {
        setOpenFormLists(true)
        setAnchorElUser(null);
    };

    const handleOpenUsersPage = () => {
        navigate("/workers")        
        setAnchorElUser(null)
    };

    const handleOpenFnoPage = () => {
        navigate("/fno")        
        setAnchorElUser(null)
    };

    const handleOpenFormPayments = () => {
        setOpenFormPayments(true)
        setAnchorElUser(null);
    };

    const handleOpenFormCompany = () => {
        setOpenFormCompany(true)
        setAnchorElUser(null);
    };

    const handleCloseExitMenu = () => {
        setAnchorElUser(null);
    };
    
    const handleExitUser = () => {        
        localStorage.clear("finupkzuser")
        setAnchorElUser(null)
        navigate("/")
        window.location.reload()
    };

    const [settings, setSettings] = useState([
        { title: 'Пользователь', onClick: handleOpenFormAuth }, 
        { title: 'Компания', onClick: handleOpenFormCompany }, 
        { title: 'Справочники', onClick: handleOpenFormLists }, 
        { title: 'Налоги-зарплата ИП', onClick: handleOpenUsersPage }, 
        { title: 'Налоговые формы', onClick: handleOpenFnoPage }, 
        // { title: 'Платежи', onClick: handleOpenFormPayments }, 
        { title: 'Выход', onClick: handleExitUser }
    ])

    useEffect(()=>{
        let newArray = [
            { title: 'Пользователь', onClick: handleOpenFormAuth, index: 0 }, 
            { title: 'Компания', onClick: handleOpenFormCompany, index: 1 }, 
            { title: 'Справочники', onClick: handleOpenFormLists, index: 2 }, 
            { title: 'Налоги-зарплата ИП', onClick: handleOpenUsersPage, index: 3 }, 
            { title: 'Налоговые формы', onClick: handleOpenFnoPage, index: 4 }, 
            // { title: 'Платежи', onClick: handleOpenFormPayments, index: 5 }, 
            { title: 'Выход', onClick: handleExitUser, index: 6 }
        ]
        if (myCompony.type===1) {
            newArray = [...newArray.filter((s) => s.index!==3)]
        }
        if (myCompony.rejym===1) {
            newArray = [...newArray.filter((s) => s.index!==4)]
        }
        setSettings(newArray)
    }, [myCompony.type, myCompony.rejym])


    return (
        <AppBar position="fixed" color='secondary' top="0">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* <img style={{ height: "45px" }} alt="complex" src={logo} /> */}
                    {/* <Avatar src={logo1} sx={{ width: 56, height: 56 }} variant="rounded"/> */}
                    <Avatar src={logo2} sx={{ width: 56, height: 56 }} variant="rounded" />
                    <Stack spacing={1} >
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            color='primary'
                            sx={{
                                ml: 1,
                                display: { md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.1rem',
                                textDecoration: 'none',
                                lineHeight: '1.3',
                                fontSize: '0.8rem',
                            }}
                        >
                            Online
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            color='primary'
                            href="/"
                            sx={{
                                ml: 1,
                                display: { md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.1rem',
                                textDecoration: 'none',
                                lineHeight: '1.3',
                                fontSize: '0.8rem',
                            }}
                        >
                            Бухгалтер
                        </Typography>
                    </Stack>
                    <Box sx={{ flexGrow: 1, textAlign: "right" }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <MenuRoundedIcon fontSize="large" color='primary'/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleCloseExitMenu}
                        >
                            <MenuList dense>
                                {settings.map((setting, index) => (                               
                                <div key={setting.title}>
                                    <MenuItem key={setting.title} onClick={setting.onClick}>
                                        <Typography textAlign="center" >{setting.title}</Typography>
                                    </MenuItem>
                                    {(setting === "Выход") ? "" : <Divider sx={{ borderColor: '#fcbb42' }} variant="middle" component="li" />}
                                </div>
                                ))}
                            </MenuList>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>

            <Dialog
                open={openForm}
                onClose={handleCloseForm}
                scroll='body'
            >
                <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #fcbb42' }} color="#07139e" id="customized-dialog-title">
                    Настройка профиля пользователя
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    
                    color='error'
                    sx={{
                        position: 'absolute',
                        right: 1,
                        top: 1,
                        p: '5px'
                    }}
                >
                  
                </IconButton>
                <DialogContent>
                    <UserProfile
                        authUser={authUser}
                        handleCloseForm={handleCloseForm}
                        handleOpenFormPass={handleOpenFormPass}
                        updateAuthUserRequest={updateAuthUserRequest}

                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openFormLists}
                onClose={submitProductServiceForm}
                scroll='body'
                aria-labelledby="customized-dialog-title"
                maxWidth={false}
            >
                <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #fcbb42' }} color="#07139e" id="customized-dialog-title">
                    Справочники
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={submitProductServiceForm}
                    color='error'
                    sx={{
                        position: 'absolute',
                        right: 1,
                        top: 1,
                        p: '5px'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ padding: '0' }} >
                    <TabLists
                        setDataProductServiceForm={setDataProductServiceForm}
                        setDelContents={setDelContents}
                        setDataClientsForm={setDataClientsForm}
                        setDelClients={setDelClients}
                        setDataDelivAddrForm={setDataDelivAddrForm}
                        setDelDelivAddr={setDelDelivAddr}
                        newExpensGroups={newExpensGroups}
                        updateExpensGroups={updateExpensGroups}
                        pointsUpdate={pointsUpdate}
                        pointsDelete={pointsDelete}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openFormPayments}
                onClose={handleCloseFormPayments}
                scroll='body'
                aria-labelledby="customized-dialog-title"
            >
                <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #fcbb42' }} color="#07139e" id="customized-dialog-title">
                    Платежи
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseFormPayments}
                    color='error'
                    sx={{
                        position: 'absolute',
                        right: 1,
                        top: 1,
                        p: '5px'
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ padding: '0', maxWidth: '90%' }} >
                    <PaymentsBord/>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openFormCompany}
                onClose={handleCloseForm}
                scroll='body'
            >
                <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #fcbb42' }} color="#07139e" id="customized-dialog-title">
                    Настройка профиля компании
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    
                    color='error'
                    sx={{
                        position: 'absolute',
                        right: 1,
                        top: 1,
                        p: '5px'
                    }}
                >
                  
                </IconButton>
                <DialogContent>
                    <CompanyProfile uploadSealImgRequest={uploadSealImgRequest} uploadSignatureImgRequest={uploadSignatureImgRequest} authUser={authUser} myCompony={myCompony} handleCloseForm={handleCloseForm} updateMyCompanyRequest={updateMyCompanyRequest} />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openFormPass}
                onClose={handleCloseFormPass}
                scroll='body'
            >
                <DialogContent>
                    <UserProfilePass authUser={authUser} handleCloseFormPass={handleCloseFormPass} updateUserPwdRequest={updateUserPwdRequest} />
                </DialogContent>
            </Dialog>
        </AppBar>
    );
}
export default ComponentAppBar;