import ConnectToServer from "../API/ConnectToServer";


//Action type
const SET_ADDRESS_DELIVERY = 'ADDRDELIVERY/SET_ADDRESS_DELIVERY'

//Started props
let initialState = {
    addrDelivery: [],
    isFetching: false,
    activeForm: false
};

//Reducers functions
const addrDeliveryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADDRESS_DELIVERY:
            return {
                ...state,
                addrDelivery: action.addrDelivery
            }
    
        default:
            return state;
    }
}

//Action Creators functions
const setAddrDelivery = (addrDelivery) => {
    return { type: SET_ADDRESS_DELIVERY, addrDelivery }
}

//Thunk functions
export const getAddrDeliveryRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getALLAddrDelivery(obj)
        if (data.success === 1) {
            dispatch(setAddrDelivery(data.deliveryaddress))            
        } else {
            dispatch(setAddrDelivery([])) 
        }
    }
}

export const delivAddressUpdate = (obj, mycomp) => {
    return async (dispatch) => {
        const data = await ConnectToServer.setDelivAddress(obj)
        if (data.success === 1) {
            //console.log(data);
           dispatch(getAddrDeliveryRequest({ mycompany: [mycomp] }))
        } else {
            console.log(data.message)
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const delivAddressDelete = (obj, mycomp) => {
    return async (dispatch) => {
        const data = await ConnectToServer.delDelivAddress(obj)
        if (data.success === 1) {
            //console.log(data);
           dispatch(getAddrDeliveryRequest({ mycompany: [mycomp] }))
        } else {
            console.log(data.message)
            //dispatch(toggleIsFetching(false))
        }
    }
}

export default addrDeliveryReducer;