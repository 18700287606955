import React from 'react';
import { Box } from '@mui/material';
import SpeedDialBTN from './Components/SpeedDialBTN';
import { useDispatch, useSelector } from 'react-redux';
import BasketShop from './Components/BasketShop';
import { useCallback } from 'react';
import ProductOrderClient from './Components/ProductOrderClient';

const ShopOrders = ({ addNewOrdersShopRequest, getAllOrdersClientsRequest }) => {

    const myCompany = useSelector(state => state.myCompany)
    const authUser = useSelector(state => state.auth.authUser)
    const allProducts = useSelector(state => state.shop.allProducts)
    const groupsProduct = useSelector(state => state.shop.groupsProduct)
    const masterBasket = useSelector(state => state.shop.masterBasket)
    const ordersClients = useSelector(state => state.shop.ordersClients)
    const productOrdersClients = useSelector(state => state.shop.productOrdersClients)

    const productsOrder = productOrdersClients.map((po)=>({...po, ...allProducts.filter((pr)=>pr.id===po.idProduct)[0]})).map((op)=>({...op, dir: groupsProduct.filter((gr)=>gr.id===op.idGroup)[0].directory}))
    
    const viewDrdersClients = ordersClients.map((ord)=>({...ord, products: productsOrder.filter((prod)=>prod.idOrders===ord.id)}))

    //getAllOrdersClientsRequest({idUser: authUser.id})
    
    const dispatch = useDispatch()

    const setMasterBasket = useCallback((products) => {
        dispatch({ type: 'SHOP/SET_IN_BASKET', products })
    }, [dispatch])

    const productsList = groupsProduct.map((gr) => ({ ...gr, products: allProducts.filter((pr) => pr.idGroup === gr.id) }))

    const sendOrder = () => {
        //отправка Заказа
        console.log(masterBasket);
        if (masterBasket.length === 0) {
            alert('Корзина пуста!!!')
        } else {
            const idShop = myCompany.idShops.filter((id) => id === masterBasket[0].idShop)[0]

            const newOrder = {
                orders: masterBasket,
                idUser: authUser.id,
                idCompany: myCompany.id,
                idShop: idShop,
            }
            addNewOrdersShopRequest(newOrder)
            setMasterBasket([])
        }
    }

    const addMasterBasket = (item) => {
        setMasterBasket([...masterBasket, ...item])
    }

    const delProductInBasket = (id) => {
        const newRoductArray = masterBasket.filter((pr) => pr.id !== id)
        setMasterBasket(newRoductArray)
    }

    return <>
        <Box sx={{ textAlign: "center" }}>
            <BasketShop masterBasket={masterBasket} sendOrder={sendOrder} delProductInBasket={delProductInBasket} setMasterBasket={setMasterBasket} />
            <ProductOrderClient viewDrdersClients={viewDrdersClients} />
            <SpeedDialBTN productsList={productsList} setMasterBasket={addMasterBasket} masterBasket={masterBasket} />
        </Box >
    </>
}

export default ShopOrders;
