import * as React from 'react';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import { Avatar, Typography } from '@mui/material';

const PDFMessage = ({ message }) => {
    return (
        <a href={message.src} target="_blank" rel="noreferrer" download>
            <Avatar sx={{ bgcolor: '#dc3545', height: 64, width: 64, borderRadius: 2 }} variant="rounded">
                <PictureAsPdfRoundedIcon sx={{ height: 45, width: 45 }} />
            </Avatar>
            <Typography color="text.secondary" variant="body2" >
                {message.name}
            </Typography>
        </a>
    );
}

export default PDFMessage;