import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import * as React from 'react';



const TableContent = ({ application, myCompony }) => {
    
    const styles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        seal: {
            position: 'relative',
            top: '-60px',
            left: '5px',
            width: '128px',
        },
        signature: {
            position: 'relative',
            top: '-155px',
            left: '100px',
            width: '100px',
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableCol5: {
            width: "5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol10: {
            width: "10%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol15: {
            width: "15%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol30: {
            width: "30%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol25: {
            width: "25%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableColNo25: {
            width: "25%",
        },
        tableColLine: {
            width: "100%",
            borderBottom: 2,
            marginTop: 10,
            marginBottom: 5,
        },
        tableCol100: {
            width: "100%",
        },
        tableCol50: {
            width: "50%",
        },
        tableCellTest11Bold: {
            margin: "auto",
            marginTop: 5,
            fontSize: 11,
            fontWeight: 'bold'
        },
        tableCellTest11: {
            marginTop: 'auto',
            marginBottom: '2px',
            fontSize: 11,
            marginLeft: 2
        },
        tableCellNuber11: {
            marginTop: 'auto',
            marginBottom: '2px',
            fontSize: 11,
            marginRight: 2,
            alignItems: 'flex-end',
            textAlign: 'right'
        },
        tableCellSum1: {
            fontSize: 10,
            marginLeft: 2,
            borderBottom: 1,
            marginTop: 5,

        },
        tableCellSum2: {
            fontSize: 9,
            marginLeft: 2,
            fontWeight: 'bold',
            fontStyle: 'Italy'
        },
        tableCellScince: {
            fontSize: 11,
            marginTop: 30,
            marginLeft: 30,
        }
    })

    function numberToString(_number, toUpper) {
        var toUpper = toUpper || false;
        var _arr_numbers = new Array();
        _arr_numbers[1] = new Array('', 'один', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять', 'десять', 'одиннадцать', 'двенадцать', 'тринадцать', 'четырнадцать', 'пятнадцать', 'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать');
        _arr_numbers[2] = new Array('', '', 'двадцать', 'тридцать', 'сорок', 'пятьдесят', 'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто');
        _arr_numbers[3] = new Array('', 'сто', 'двести', 'триста', 'четыреста', 'пятьсот', 'шестьсот', 'семьсот', 'восемьсот', 'девятьсот');

        function number_parser(_num, _desc) {
            var _string = '';
            var _num_hundred = '';
            if (_num.length === 3) {
                _num_hundred = _num.substr(0, 1);
                _num = _num.substr(1, 3);
                _string = _arr_numbers[3][_num_hundred] + ' ';
            }
            if (_num < 20) _string += _arr_numbers[1][parseFloat(_num)] + ' ';
            else {
                var _first_num = _num.substr(0, 1);
                var _second_num = _num.substr(1, 2);
                _string += _arr_numbers[2][_first_num] + ' ' + _arr_numbers[1][_second_num] + ' ';
            }

            switch (_desc) {
                case 0:
                    if (_num.length === 2 && parseFloat(_num.substr(0, 1)) === 1) {
                        _string += 'тенге';
                        break;
                    }
                    var _last_num = parseFloat(_num.substr(-1));
                    if (_last_num === 1) _string += 'тенге';
                    else if (_last_num > 1 && _last_num < 5) _string += 'тенге';
                    else _string += 'тенге';
                    break;

                case 1:
                    _num = _num.replace(/^[0]{1,}$/g, '0');
                    if (_num.length === 2 && parseFloat(_num.substr(0, 1)) === 1) {
                        _string += 'тысяч ';
                        break;
                    }

                    var _last_num = parseFloat(_num.substr(-1));
                    if (_last_num === 1) _string += 'тысяча ';
                    else if (_last_num > 1 && _last_num < 5) _string += 'тысячи ';
                    else if (parseFloat(_num) > 0) _string += 'тысяч ';
                    _string = _string.replace('один ', 'одна ');
                    _string = _string.replace('два ', 'две ');
                    break;

                case 2:
                    _num = _num.replace(/^[0]{1,}$/g, '0');
                    if (_num.length === 2 && parseFloat(_num.substr(0, 1)) === 1) {
                        _string += 'миллионов ';
                        break;
                    }

                    var _last_num = parseFloat(_num.substr(-1));
                    if (_last_num === 1) _string += 'миллион ';
                    else if (_last_num > 1 && _last_num < 5) _string += 'миллиона ';
                    else if (parseFloat(_num) > 0) _string += 'миллионов ';
                    break;

                case 3:
                    _num = _num.replace(/^[0]{1,}$/g, '0');
                    if (_num.length === 2 && parseFloat(_num.substr(0, 1)) === 1) {
                        _string += 'миллиардов ';
                        break;
                    }

                    var _last_num = parseFloat(_num.substr(-1));
                    if (_last_num === 1) _string += 'миллиард ';
                    else if (_last_num > 1 && _last_num < 5) _string += 'миллиарда ';
                    else if (parseFloat(_num) > 0) _string += 'миллиардов ';
                    break;

            }

            return _string;

        }

        function decimals_parser(_num) {
            var _first_num = _num.substr(0, 1);
            var _second_num = parseFloat(_num.substr(1, 2));
            var _string = ' ' + _first_num + _second_num;
            if (_second_num === 1) _string += ' тиын';
            else if (_second_num > 1 && _second_num < 5) _string += ' тиын';
            else _string += ' тиын';
            return _string;

        }

        if (!_number || _number === 0) return false;
        if (typeof _number !== 'number') {
            _number = _number + '';
            _number = _number.replace(',', '.');
            _number = parseFloat(_number);
            if (isNaN(_number)) return false;
        }

        _number = _number.toFixed(2);
        if (_number.indexOf('.') !== -1) {
            var _number_arr = _number.split('.');
            var _number = _number_arr[0];
            var _number_decimals = _number_arr[1];
        }

        var _number_length = _number.length;
        var _string = '';
        var _num_parser = '';
        var _count = 0;

        for (var _p = (_number_length - 1); _p >= 0; _p--) {
            var _num_digit = _number.substr(_p, 1);
            _num_parser = _num_digit + _num_parser;
            if ((_num_parser.length === 3 || _p === 0) && !isNaN(parseFloat(_num_parser))) {
                _string = number_parser(_num_parser, _count) + _string;
                _num_parser = '';
                _count++;
            }
        }

        if (_number_decimals) _string += decimals_parser(_number_decimals);
        if (toUpper === true || toUpper === 'upper') {
            _string = _string.substr(0, 1).toUpperCase() + _string.substr(1);
        }
        return _string.replace(/[\s]{1,}/g, ' ');
    };

    Number.prototype.numberToString = function (toUpper) {
        return numberToString(this, toUpper);
    };

    String.prototype.numberToString = function (toUpper) {
        return numberToString(this, toUpper);
    };

    return (
        <View>
            <View style={styles.table}>
                {/* шапка таблицы */}
                <View style={styles.tableRow}>
                    <View style={styles.tableCol5}>
                        <Text style={styles.tableCellTest11Bold}>№</Text>
                    </View>
                    <View style={styles.tableCol30}>
                        <Text style={styles.tableCellTest11Bold}>Наименование</Text>
                    </View>
                    <View style={styles.tableCol15}>
                        <Text style={styles.tableCellTest11Bold}>Кол-во</Text>
                    </View>
                    <View style={styles.tableCol10}>
                        <Text style={styles.tableCellTest11Bold}>Ед.</Text>
                    </View>
                    <View style={styles.tableCol15}>
                        <Text style={styles.tableCellTest11Bold}>Цена</Text>
                    </View>
                    <View style={styles.tableCol25}>
                        <Text style={styles.tableCellTest11Bold}>Сумма</Text>
                    </View>
                </View>
                {/* Данные таблицы */}
                {application.content.map((cont, index) =>
                    <View key={cont.id} style={styles.tableRow}>
                        <View style={styles.tableCol5}>
                            <Text style={styles.tableCellTest11}>{index + 1}</Text>
                        </View>
                        <View style={styles.tableCol30}>
                            <Text style={styles.tableCellTest11}> {cont.name} </Text>
                        </View>
                        <View style={styles.tableCol15}>
                            <Text style={styles.tableCellNuber11}>{cont.amount}</Text>
                        </View>
                        <View style={styles.tableCol10}>
                            <Text style={styles.tableCellTest11}>{cont.unit}</Text>
                        </View>
                        <View style={styles.tableCol15}>
                            <Text style={styles.tableCellNuber11}>{cont.price},00</Text>
                        </View>
                        <View style={styles.tableCol25}>
                            <Text style={styles.tableCellNuber11}>{cont.total},00</Text>
                        </View>
                    </View>
                )}
            </View>

            {/* итого */}
            <View style={styles.tableRow}>
                <View style={styles.tableColNo25}>
                    <Text style={styles.tableCellTest11}></Text>
                </View>
                <View style={styles.tableColNo25}>
                    <Text style={styles.tableCellTest11}></Text>
                </View>
                <View style={styles.tableColNo25}>
                    <Text style={styles.tableCellNuber11}>Итого:</Text>
                </View>
                <View style={styles.tableColNo25}>
                    <Text style={styles.tableCellNuber11}>{application.allTotal},00</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableColNo25}>
                    <Text style={styles.tableCellTest11}></Text>
                </View>
                <View style={styles.tableColNo25}>
                    <Text style={styles.tableCellTest11}></Text>
                </View>
                <View style={styles.tableColNo25}>
                    <Text style={styles.tableCellNuber11}>В том числе НДС:</Text>
                </View>
                <View style={styles.tableColNo25}>
                    <Text style={styles.tableCellNuber11}>без НДС</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableColLine}>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol50}>
                    <Text style={styles.tableCellSum1}>Всего наименований {application.content.length}, на сумму: {application.allTotal},00 </Text>
                </View>
                <View style={styles.tableColNo25}>
                    <Text style={styles.tableCellTest11}></Text>
                </View>
                <View style={styles.tableColNo25}>
                    <Text style={styles.tableCellTest11}></Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol100}>
                    <Text style={styles.tableCellSum2}>Сумма прописью: {numberToString(application.allTotal, true)}</Text>
                </View>
            </View>
            <Text style={styles.tableCellScince}>Исполнитель_____________________________{myCompony.director}</Text>
            {myCompony.seal.length ===0? <View></View> : <Image style={styles.seal} src={myCompony.seal} />} 
            {myCompony.signature.length ===0? <View></View>: <Image style={styles.signature} src={myCompony.signature} />} 
        </View>
    );
}

export default TableContent;