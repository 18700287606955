import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  //Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import RegistrationForm from './RegistrationForm';
import { useDispatch, useSelector } from 'react-redux';
//import { ContentCutOutlined } from '@mui/icons-material';

const myHelper = {
  email: {
    required: "Заполните поле",
    pattern: "Не корректный формат e-mail"
  },
  password: {
    required: "Заполните поле"
  }
};

const LoginForm = ({ authUserCheckRequest, addUserPwdRequest }) => {
  const formOpenReg = useSelector(state => state.auth.formOpenRegistration)
  const openCollapse = useSelector(state => state.auth.openCollapseError)
  const errorAuth = useSelector(state => state.auth.errorAuth)
  const dispatch = useDispatch()

  const setOpenFormReg = (data) => {
    dispatch({ type: 'AUTH/SET_OPEN_FORM_REGISTRATION', data })
  }

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });


  const handleOnSubmit = (evt) => {
    authUserCheckRequest(evt)
  };

  return (
    <div >
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Typography variant="h5" gutterBottom component="div" sx={{ color: '#07139e', textAlign: 'center', mb: 3 }}>
          Вход в личный кабинет
        </Typography>
        <Grid item xs={12} >
          <Collapse in={openCollapse}>
            <Alert severity="error">{errorAuth}</Alert>
          </Collapse>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="login"
              defaultValue=""
              rules={{
                required: true,
                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="email"
                  fullWidth
                  size="small"
                  label="Логин: e-mail"
                  error={error !== undefined}
                  helperText={error ? myHelper.email[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="password"
              defaultValue=""
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="password"
                  fullWidth
                  size="small"
                  label="Пароль"
                  error={error !== undefined}
                  helperText={error ? myHelper.password[error.type] : ""}
                />
              )}
            />
          </Grid>


          <Grid item xs={12}>
            <Button size="large" color="secondary" variant="outlined" type="submit" fullWidth>Вход</Button>
          </Grid>
          {/* <Grid item xs={12}>
            <Button size="small" type="button">Забыли пароль?</Button>
            <Divider color='#07139e' flexItem />
          </Grid> */}

          <Grid item xs={12}>

            <Button onClick={() => setOpenFormReg(true)} size="large" color="secondary" variant="contained" type="button" fullWidth>Регистрация</Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={formOpenReg}
        onClose={() => setOpenFormReg(false)}
        scroll='body'
      >
        <DialogContent>
          <RegistrationForm setOpenFormReg={setOpenFormReg} addUserPwdRequest={addUserPwdRequest} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LoginForm