import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
  TextField,
} from "@mui/material";

const CountNumber = ({addInBasket, startNumber, caption}) => {

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });


  const handleOnSubmit = (evt) => {
    addInBasket(evt);
  };

  return (
    <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
      <Grid container spacing={2} sx={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="amount"
            defaultValue={startNumber}

            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Количество"
                type="number"
                size="small"
                sx={{
                  width: '100px'
                }}
                InputProps={{
                  inputProps: {
                    max: 100, min: 1
                  }
                }}
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  },
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Button size="large" color="secondary" variant="contained" type="submit" >{caption}</Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CountNumber