import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useSelector } from 'react-redux';


const UserProfilePass = ({ authUser, handleCloseFormPass, updateUserPwdRequest }) => {
  const [openError, setOpenError] = React.useState(false);
  const backdrop = useSelector(state=>state.auth.backdrop)
  const authUserTop = useSelector(state => state.auth.authUser)

  const [state] = React.useState({
    vertical: 'center',
    horizontal: 'top',
  });

  const { vertical, horizontal } = state;

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError(false);
  };


  const formSchema = Yup.object().shape({
    newPass: Yup.string()
      .required('Заполните поле')
      .min(3, 'Пароль должен быть более 3 символов'),
    
    newPass2: Yup.string()
      .required('Заполните поле')
      .oneOf([Yup.ref('newPass'), null], 'Пароли не совпадают!'),
  })
  const formOptions = { resolver: yupResolver(formSchema) }

  const { control, handleSubmit, formState } = useForm({ ...formOptions, reValidateMode: "onBlur" });
  const { errors } = formState

  const handleOnSubmit = (evt) => {
    const updateUserPass = { id: authUser.id, idOwner: authUserTop.id, userKey: authUserTop.userKey, ...evt }
    if(authUserTop.account === 2) {
      if (evt.newPass === evt.newPass2) {
        updateUserPwdRequest(updateUserPass);      
      }
    } else
    if (evt.oldPass === authUser.password && evt.newPass === evt.newPass2) {
      updateUserPwdRequest(updateUserPass);          
    } else { setOpenError(true) }
  };

  return (
    <div className="App">
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="hoverline" gutterBottom display="block" color="#07139e" sx={{ textAlign: 'center', fontWeight: "600", fontSize: "18px" }}>
              Смена пароля пользователя
            </Typography>
            <Divider sx={{ borderColor: '#07139e' }} />
          </Grid>

          {authUserTop.account !== 2?
          <Grid item xs={12}>
            <Controller
              control={control}
              name="oldPass"
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="password"
                  fullWidth
                  size='small'
                  label="Текущий пароль"
                  error={errors.oldPass !== undefined}
                  helperText={errors.oldPass?.message}
                />
              )}
            />
          </Grid>: <></>}

          <Grid item xs={12}>
            <Controller
              control={control}
              name="newPass"
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="password"
                  size='small'
                  fullWidth
                  label="Новый пароль"
                  error={errors.newPass !== undefined}
                  helperText={errors.newPass?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="newPass2"
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="password"
                  size='small'
                  fullWidth
                  label="Подтвердите новый пароль"
                  error={errors.newPass2 !== undefined}
                  helperText={errors.newPass2?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Button type="submit" variant="outlined">Сохранить</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleCloseFormPass} type="button" variant="outlined">Отмена</Button>
          </Grid>

        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openError}
        autoHideDuration={2000}
        onClose={handleCloseError}
        sx={{ top: { xs: 90, sm: 0 } }}
      >
        <Alert onClose={handleCloseError} severity="error">
          <AlertTitle>Не правильно указали текущий пароль!</AlertTitle>
        </Alert>
      </Snackbar>

    </div>
  );
}

export default UserProfilePass