//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import ShopOrders from './ShopOrders';
import { addNewOrdersShopRequest, getAllOrdersClientsRequest } from '../Redux/shopReducer';
class ShopOrdersContainer extends React.PureComponent {

    render() {
        return (<>
            <div style={{maxWidth: "412px", margin: "0 auto"}} >
                <ShopOrders
                    addNewOrdersShopRequest={this.props.addNewOrdersShopRequest}
                    getAllOrdersClientsRequest={this.props.getAllOrdersClientsRequest}
                />
            </div>
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        
    }
};

export default connect(mapStateToProps, { 
    addNewOrdersShopRequest,
    getAllOrdersClientsRequest
})(ShopOrdersContainer)
