import * as React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

export default function FloatingActionButtons({setOpenFormNewexpenses}) {

  return (    
      <Fab onClick={()=>setOpenFormNewexpenses(true)} color="primary" aria-label="add" sx={{position: 'fixed', right: '12px', bottom: '72px'}} >
        <AddIcon color='secondary'/>
      </Fab>
  );
}