import React from 'react';
import {
  Grid,
  Stack,
  Typography,
} from "@mui/material";


const FormInfoCompany = ({ info }) => {

  return (
    <Grid container spacing={1} sx={{ textAlign: 'center' }}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Typography textAlign='left' color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
            Компания:
          </Typography>
          <Typography textAlign='left' sx={{ m: 'auto' }} >
            {info.name}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
        <Typography textAlign='left' color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
          Телефон: 
        </Typography>
          <Typography textAlign='left' sx={{ m: 'auto' }} >
          {info.phone}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
        <Typography textAlign='left' color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
          e-mail: 
        </Typography>
          <Typography textAlign='left' sx={{ m: 'auto' }} >
          {info.email}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
        <Typography textAlign='left' color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
          ИИН/БИН: 
        </Typography>
          <Typography textAlign='left' sx={{ m: 'auto' }} >
          {info.bin}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
        <Typography textAlign='left' color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
          Адрес: 
        </Typography>
          <Typography textAlign='left' sx={{ m: 'auto' }} >
          {info.addressUyr}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
        <Typography textAlign='left' color="#07139e" sx={{ m: 'auto', minWidth: '82px' }} >
          БИК: 
        </Typography>
          <Typography textAlign='left' sx={{ m: 'auto' }} >
          {info.bik}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
        <Typography textAlign='left' color="#07139e" sx={{ minWidth: '82px' }} >
          Счет: 
        </Typography>
          <Typography textAlign='left' sx={{ m: 'auto' }} >
          {info.iban}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
        <Typography textAlign='left' color="#07139e" sx={{ minWidth: '82px' }} >
          В банке: 
        </Typography>
          <Typography textAlign='left' sx={{ m: 'auto' }} >
          {info.nameBank}
          </Typography>
        </Stack>  
      </Grid>
    </Grid>
  );
}

export default FormInfoCompany