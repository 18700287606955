import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,

} from '@mui/x-data-grid';
import {
  randomId,
} from '@mui/x-data-grid-generator';
import { Alert, Collapse, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const CustomNoRowsOverlay = () => {
  return (
    <Typography variant="hoverline" gutterBottom display="block" color="#000000" sx={{ pt: "15px", textAlign: "center" }}>
      Нет записи
    </Typography>
  )
}

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;
  const idMyCompany = useSelector(state => state.myCompany.id)
  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [{ id, idContent: 0, name: '', price: 0, isNew: true, idMyCompany, update: true }, ...oldRows]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'tovarUsluga' },
    }));

  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick} >
        Добавить запись
      </Button>
    </GridToolbarContainer>
  );
}

let units = ["шт.", "усл.", "кг.", "л.", "компл.", "т.", "м.", "м2.", "пог. м.", "упак."]

export default function TableUnitsTovarUsluga({ setDataProductServiceForm, setDelContents, reloadTable }) {
  const [openError, setopenError] = React.useState(false)
  const allContents = useSelector(state => state.сontents.allContents) 
  const initialState = allContents.map((cont) => ({ ...cont, idContent: cont.id, update: false }))
  const [rows, setRows] = React.useState(initialState);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const productService = ["Товар", "Услуга"]

  useEffect(()=>{setRows(allContents.map((cont) => ({ ...cont, idContent: cont.id, update: false })))},[allContents,setRows, reloadTable])

  React.useEffect(() => {
    setDataProductServiceForm(rows)
  }, [rows, setDataProductServiceForm])

  const handleRowEditStop = (params, event) => {    
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (acti) => () => {
    if (!openError) {
      setRowModesModel({ ...rowModesModel, [acti.id]: { mode: GridRowModes.View } });
    }
  };

  const handleDeleteClick = (id) => () => {
    setopenError(false)
    setDelContents(rows.filter((row) => row.id === id)[0])
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
    setTimeout(() => setopenError(false), 1000)   
  };

  const processRowUpdate = (newRow) => {
    //setAllTotal(AllTotal + newTotal)    
    const updatedRow = { ...newRow, isNew: false, update: true };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  function valueGetUnit(params) {
    return params.row.tovarUsluga === "Услуга" ? "усл." : params.value
  }
 
  const valueSetPlu = (params) => {
    const allPlu = rows.filter((row) => row.id !== params.row.id).map((row) => row.plu).filter((row) => row !== undefined)
    if (allPlu.includes(params.value)) {
      setopenError(true)
    } else {
      setopenError(false)
    }
    return { ...params.row, plu: params.value }
  }

  const columns = [
    {
      field: 'tovarUsluga',
      headerName: 'Услуга или товар',
      width: 130,
      type: 'singleSelect',
      valueOptions: productService,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'idContent',
      headerName: '№',
      width: 10,
      type: 'number',
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'plu',
      headerName: 'Номенкл. номер',
      width: 105,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
      valueSetter: valueSetPlu,
    },
    {
      field: 'name',
      headerName: 'Наименование',
      width: 130,
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'price',
      headerName: 'Цена',
      type: 'number',
      width: 120,
      align: 'right',
      headerAlign: 'center',
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'unit',
      headerName: 'Ед. Измерения',
      width: 80,
      editable: true,
      type: 'singleSelect',
      valueOptions: units,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
      valueGetter: valueGetUnit,
    },
    {
      field: 'idMyCompany',
      headerName: 'id компании',
      width: 80,
      type: 'number',
      editable: true,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '...',
      width: 70,
      cellClassName: 'actions',
      disableColumnMenu: true,
      hideSortIcons: true,
      getActions: (acti) => {
        const isInEditMode = rowModesModel[acti.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(acti)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(acti.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(acti.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(acti.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >

      <Collapse in={openError} sx={{ position: "absolute", zIndex: "100" }}>
        <Alert severity='error'>Номенклатура товара/услуги не уникальная!!!</Alert>
      </Collapse>

      <DataGrid
        sx={{ fontSize: "11px" }}
        autoPageSize
        rows={rows}
        columns={columns}
        onProcessRowUpdateError={(error) => console.log(error)}
        columnVisibilityModel={{
          idContent: false,
          idMyCompany: false,
        }}
        editMode='row'
        hideFooterPagination={false}
        hideFooterSelectedRowCount={true}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        density="compact"
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}

        slots={{
          toolbar: EditToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />

    </Box>
  );
}