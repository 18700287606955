import { Box } from '@mui/material';
import React from 'react';
import LoginForm from '../Forms/LoginForm';
import logo from '../Img/finup_logo_app.png'


const Login = ({authUserCheckRequest, addUserPwdRequest}) => {

    return (<div style={{backgroundColor: '#07139e', textAlign: 'center'}}>
        <img style={{ width: '100%', maxWidth: '333px' }} alt="finup.kz Бухгалтерское обслуживание" src={logo} />
        <Box sx={{ position: 'absolute', mt: 2, left: '50%', transform: 'translate(-50%, -0%)', width: 300, bgcolor: '#fff', p: 4 }}>
            <LoginForm
            authUserCheckRequest={authUserCheckRequest}
            addUserPwdRequest={addUserPwdRequest}
            />
        </Box>
    </div>
    );
}

export default Login;
