//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import Fno910 from './Fno910';
import { addNewFNO910, updateFNO910 } from '../../Redux/fno910Reducer';
import { newExpenses } from '../../Redux/expensesReducer'
import { addNewWages } from '../../Redux/wagesReducer'

class Fno910Container extends React.PureComponent {

    render() {
        return (<>            
                <Fno910                    
                    addNewFNO910={this.props.addNewFNO910}
                    updateFNO910={this.props.updateFNO910}
                    newExpenses={this.props.newExpenses}
                    addNewWages={this.props.addNewWages}
                    myCompany={this.props.myCompany}
                />            
        </>)
    }
}

const mapStateToProps = (state) => {
    return {    
        
    }
};

export default connect(mapStateToProps, {addNewFNO910, updateFNO910, addNewWages, newExpenses})(Fno910Container)
