import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TableUnitsTovarUsluga from '../Components/TableUnitsTovarUsluga';
import TableUnitsClients from '../Components/TableUnitsClients';
import TableUnitsAdressDelivery from '../Components/TableUnitsAdressDelivery';
import TableUnitsExpensGroups from '../Components/TableUnitsExpensGroups';
import TableAdressCompany from '../Components/TableAdressCompany';
import { useSelector } from 'react-redux';

export default function TabLists({ 
  setDataProductServiceForm, 
  setDelContents, 
  setDataClientsForm, 
  setDelClients, 
  setDataDelivAddrForm, 
  setDelDelivAddr, 
  newExpensGroups, 
  updateExpensGroups,
  pointsUpdate
 }) {
  const [value, setValue] = React.useState('1');
  const idMyCompany = useSelector(state => state.myCompany.id)
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }; 

  return (
    <Box sx={{ width: '100%', typography: 'body1' }} >      
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList 
            onChange={handleChange} textColor='secondary' 
            aria-label="lab API tabs example"
            variant="scrollable"
            scrollButtons={false}
            >
            <Tab label="Товары / Услуги" value="1" />
            <Tab label="Контрагенты" value="2" />
            <Tab label="Адреса доставки" value="3" />
            <Tab label="Группы расходов" value="4" />
            <Tab label="Точки продаж" value="5" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 0 }}>
          <TableUnitsTovarUsluga setDataProductServiceForm={setDataProductServiceForm} setDelContents={setDelContents} />
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0 }}>
          <TableUnitsClients setDataClientsForm={setDataClientsForm} setDelClients={setDelClients} />
        </TabPanel>
        <TabPanel value="3" sx={{ p: 0 }}>
          <TableUnitsAdressDelivery setDataDelivAddrForm={setDataDelivAddrForm} setDelDelivAddr={setDelDelivAddr} />
        </TabPanel>
        <TabPanel value="4" sx={{ p: 0 }}>
          <TableUnitsExpensGroups newExpensGroups={newExpensGroups} updateExpensGroups={updateExpensGroups}/>
        </TabPanel>
        <TabPanel value="5" sx={{ p: 0 }}>
          <TableAdressCompany pointsUpdate={pointsUpdate} idMyCompany={idMyCompany} />
        </TabPanel>
      </TabContext>      
    </Box>
  );
}