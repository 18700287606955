//import s from './MainPanelAdministration.module.css';
import React from 'react';
import Orders from './Orders';
import { connect } from 'react-redux';
import { getAllApplications } from '../../Redux/applicationsSelectors';
import { getAllContents } from '../../Redux/contentSelectors';
import { getAllCompanies } from '../../Redux/companiesSelectors';
import { getAuthUser } from '../../Redux/authSelectors';
import { setApplicationRequest } from '../../Redux/applicationsReducer';
import { getMyCompony } from '../../Redux/mycompanySelectors';
import { updateApplicationRequest, deleteApplicationRequest } from '../../Redux/applicationsReducer'


class OrdersContainer extends React.PureComponent {

    render() {
        return (<>            
                <Orders
                    allApplications={this.props.allApplications}
                    allContents={this.props.allContents}
                    allCompanies={this.props.allCompanies}
                    authUser={this.props.authUser}
                    setApplicationRequest={this.props.setApplicationRequest}
                    myCompony={this.props.myCompony}                    
                    updateApplicationRequest={this.props.updateApplicationRequest}
                    deleteApplicationRequest={this.props.deleteApplicationRequest}
                    selectCompany={this.props.selectCompany}
                />           
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        allApplications: getAllApplications(state),
        allContents: getAllContents(state),
        allCompanies: getAllCompanies(state),
        authUser: getAuthUser(state),
        myCompony: getMyCompony(state),
    }
};

export default connect(mapStateToProps, { 
    setApplicationRequest,          
    updateApplicationRequest,
    deleteApplicationRequest
})(OrdersContainer)
