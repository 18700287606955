import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Badge, Chip, IconButton, Tooltip } from '@mui/material';
import UploadButtonTask from '../../../components/UploadButtonTask';
import { useSelector } from 'react-redux';

export default function ListPlans({
  tasks,
  handleClickOpen,
  uploadFileTaskRequest,
  giveTimeDate
}) {
  
  return (
    <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {tasks.map((value) => {
        const labelId = `checkbox-list-secondary-label-${value.id}`;
        return (
          <ListItem
            key={value.id}
            secondaryAction={<>
              <Chip label={value.timeFinish} variant="outlined" size="small" color='secondary' sx={{ border: 0 }} icon={<AccessTimeIcon />} />
              <Chip label={value.dataFinish} size="small" sx={{ ml: 0.5, border: 0 }} variant="outlined" color='secondary' icon={<CalendarMonthIcon />} />
              
                  <UploadButtonTask task={value} uploadFileTaskRequest={uploadFileTaskRequest} giveTimeDate={giveTimeDate} sizeBtn={'1.9rem'} />
                
              <IconButton onClick={() => handleClickOpen(value.id, 2)} variant="outlined" edge="end" color='error' size="large" >
                <CloseIcon color='error' sx={{ fontSize: '1.9rem' }} />
              </IconButton>
            </>
            }
            disablePadding
          >
            <ListItemButton>
              <ListItemAvatar>
                #{value.id}

              </ListItemAvatar>
              <ListItemText sx={{ pr: 26 }} id={labelId} primary={value.title} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}