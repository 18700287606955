import React from 'react';
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Grid,
} from "@mui/material";
import { useState } from 'react';
import Wages from '../../../Expenses/Components/Wages';
import dayjs from 'dayjs';

const myHelper = {
  text: {
    required: "Заполните поле"
  }
};

const NewWageForm = ({ handleClose, addNewWages, newExpenses, myCompany }) => {

  const [data, setData] = useState({})
  const nowDate = dayjs(new Date())

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  })

  const handleOnSubmit = (evt) => {
    if (Object.keys(data).length !== 0) {
      addNewWages({...evt, ...data, idIP : myCompany.id}, myCompany.id)
      newExpenses(
        {
        ...data, 
        groupeId: 1, 
        total: evt.wage, 
        myCompanyId : myCompany.id, 
        expensesTitel: 'Зарплата за ' + data.label, 
        providerId: 0, 
        photo: '', 
        date: nowDate.$D+'.'+(data.month<10?'0'+data.month:data.month)+'.'+data.year
      }, myCompany.id)
      handleClose()
    } else {
      //сообщение что поле пустое
    }


  };

  return (
    <div className="App">
      <Box sx={{ mt: '8px' }} component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2}>
          <Wages control={control} myHelper={myHelper} setData={setData} />
          <Grid item xs={6}>
            <Button type="submit" variant="outlined">Сохранить</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={() => handleClose()} type="button" variant="outlined">Отмена</Button>
          </Grid>

        </Grid>
      </Box>
    </div>
  );
}

export default NewWageForm