import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import CookieRoundedIcon from '@mui/icons-material/CookieRounded';
import LunchDiningRoundedIcon from '@mui/icons-material/LunchDiningRounded';
import { useState } from 'react';
import ProductList from './ProductList';

const actions = [
  { icon: <LunchDiningRoundedIcon sx={{color: '#07139e'}} />, name: 'Горячие блюда' },
  { icon: <CookieRoundedIcon sx={{color: '#07139e'}}  />, name: 'Десерты' },
  { icon: <BakeryDiningIcon sx={{color: '#07139e'}}  fontSize='large' />, name: 'Выпечка' },
  
];

export default function SpeedDialBTN({productsList, setMasterBasket, masterBasket}) {
  const [open, setOpen] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [products, setProducts] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openGroupeOrder = (evt) => {       
    setProducts(evt);
    setOpenList(true)
    handleClose()
  }

  return (
    <Box >      
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={{ position: 'absolute', bottom: 70, right: 16 }}
        icon={<SpeedDialIcon sx={{color: '#fff'}} />}
        onClose={handleClose}        
        onOpen={handleOpen}
        open={open}
      >
        {productsList.map((action, index) => (
          <SpeedDialAction
            key={action.id}
            icon={actions[index].icon}
            tooltipTitle={action.name}
            sx={{
              '& .MuiSpeedDialAction-staticTooltipLabel': { color: '#fff', backgroundColor: '#07139e' },
              '& .MuiSpeedDialAction-fabClosed': { color: '#fff', backgroundColor: '#07139e' },
          }}
            tooltipOpen
            onClick={()=>openGroupeOrder(action)}
          />
        ))}
      </SpeedDial>
      {openList?<ProductList openList={openList} products={products} setOpenList={setOpenList} setMasterBasket={setMasterBasket} masterBasket={masterBasket} /> : <></>}
    </Box>
  );
}
