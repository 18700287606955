import * as React from 'react';
import { useSelector } from 'react-redux';
import TableDashboard from './TableDashboard';
import { Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import TableDashboardMobile from './TableDashboardMobile';

const PanelTabale = () => {
    const words = useSelector(state => state.language.words)
    const allCompanies = useSelector(state => state.companies.companies)
    const allApplications = useSelector(state => state.applicaions.allApplications)
    let componentTable = []

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    // const row = {
    //     name: '',
    //     orders: '',
    //     sumOrders: '',
    //     notPaid: '',
    //     notESF: '',
    //     total: ''
    // }

    const row = allCompanies.map((comp) => ({
        id: comp.id,
        name: comp.name,
        orders: allApplications.filter((app) => app.clientId === comp.id).length,
        sumOrders: allApplications.filter((app) => app.clientId === comp.id).reduce((sum, value) => sum + value.allTotal, 0),
        notPaid: allApplications.filter((app) => app.clientId === comp.id).filter((app) => !app.payment).length,
        notESF: allApplications.filter((app) => app.clientId === comp.id).filter((app) => !app.esfDone && app.esf).length,
        total: allApplications.filter((app) => app.clientId === comp.id).filter((app) => app.payment).reduce((sum, value) => sum + value.allTotal, 0),
    }))

    const mobileRow = row.map((table) => ({
        name: table.name,
        id: table.id,
        table: [
            { id: 1, name: words.summOrders, value: table.orders },
            { id: 2, name: words.revenueOrders, value: table.sumOrders },
            { id: 3, name: words.notPaid, value: table.notPaid },
            { id: 4, name: words.notESF, value: table.notESF },
            { id: 5, name: words.paidDone, value: table.total },
        ]
    }))

    if (matches) {
        componentTable = [<TableDashboard key={1} words={words} row={row} allApplications={allApplications} allCompanies={allCompanies} />]
    } else {
        componentTable = mobileRow.map((mRow) =>
            <TableDashboardMobile key={mRow.id} name={mRow.name} words={words} row={mRow.table} allApplications={allApplications} allCompanies={allCompanies} />
        )

    }

    return (<>
        <Typography variant='h3' fontSize={18} fontWeight={600} sx={{ lineHeight: '1.33', letterSpacing: '-0.025em', mt: '40px' }}>
            Сводная информация по клиентам
        </Typography>
        <Divider sx={{ borderColor: '#fcbb42' }} />
        {componentTable}
    </>
    )
}

export default PanelTabale