import * as React from 'react';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import { useEffect } from 'react';
import TableUnitsAdressDelivery from '../../../Components/TableUnitsAdressDelivery';

const ClientsAdressPanel = ({ delivAddressUpdate, delivAddressDelete, myCompany }) => {
    const [dataDelivAddr, setDataDelivAddrForm] = React.useState([]);
    const [delDelivAddr, setDelAddrSeliv] = React.useState([]);
    const [buttonHiden, setButtonHiden] = useState(false)
    const newDelivAddr = dataDelivAddr.filter((cont) => cont.idDelivAddr === 0 && cont.update).map((c)=>({...c, idMyCompany: myCompany.id}))
    const updateDelivAddr = dataDelivAddr.filter((cont) => cont.idDelivAddr !== 0 && cont.update).map((c)=>({...c, idMyCompany: myCompany.id}))
    const [reloadTable, setreloadTable] = React.useState(false)
    
    useEffect(()=>{
        if (updateDelivAddr.length !== 0 || newDelivAddr.length !== 0) {
            setButtonHiden(true)
        }
        if (delDelivAddr.length !== 0) {
            setButtonHiden(true)
        }
        
    }, [setButtonHiden, dataDelivAddr, delDelivAddr, updateDelivAddr.length, newDelivAddr.length, delDelivAddr.length])

    const setDelDelivAddr = (addrDev) => {
        delDelivAddr.push(addrDev.id)
        setDelAddrSeliv(delDelivAddr)
    }

    const submitProductServiceForm = () => {        

        if (updateDelivAddr.length !== 0 || newDelivAddr.length !== 0) {
            delivAddressUpdate({ update: updateDelivAddr, new: newDelivAddr }, myCompany.id)
        }
        if (delDelivAddr.length !== 0) {
            delivAddressDelete({ delDeliveryAddress: delDelivAddr }, myCompany.id);
        }
        setButtonHiden(false)
        setDataDelivAddrForm([])
        setDelAddrSeliv([])
    }

    const cancelСhanges = () =>{
        setButtonHiden(false)
        setDataDelivAddrForm([])
        setDelAddrSeliv([])
        reloadTable?setreloadTable(!reloadTable):setreloadTable(!reloadTable)
    }

    return (
        <Box
            sx={{
                height: '85vh',
                width: '100%',
                mt: '5px',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                flex: 1
            }}
        >
            <Button onClick={submitProductServiceForm} sx={{ml: 1}} variant="outlined" disabled={!buttonHiden}  startIcon={<SaveAsOutlinedIcon />} >Сохранить</Button>
            <Button onClick={cancelСhanges} sx={{ml: 1}} variant="outlined" disabled={!buttonHiden}  startIcon={<SaveAsOutlinedIcon />} >Отменить изменения</Button>
            <Box sx={{ height: '100% !important', width: "100% !important", mt: '5px' }}>
             <TableUnitsAdressDelivery setDataDelivAddrForm={setDataDelivAddrForm} setDelDelivAddr={setDelDelivAddr} reloadTable={reloadTable} />
            </Box>
        </Box>
    )
}

export default ClientsAdressPanel