import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_ALL_POINTS = 'CONTENTS/SET_ALL_POINTS';

//Started props
let initialState = {
    isFetching: false,       
    allPoints: []
};

//Reducers functions
const pointsReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ALL_POINTS:
            return {
                ...state,
                allPoints: action.points}            
        
        default:
            return state;
    }
}

//Action Creators functions
const setPoints = (points) => {
    return { type: SET_ALL_POINTS, points }
}

//Thunk functions
export const pointsRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getALLPoints(obj)
        if (data.success === 1) {
            dispatch(setPoints(data.points))
        } else {
            console.log(data.message)
            dispatch(setPoints([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const pointsUpdate = (obj, mycomp) => {
    console.log(obj);
    console.log(mycomp);
    return async (dispatch) => {
        const data = await ConnectToServer.setPoints(obj)
        if (data.success === 1) {
           dispatch(pointsRequest({ mycompany: [mycomp] }))
        } else {
            console.log(data.message)
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const pointsDelete = (obj, mycomp) => {
    return async (dispatch) => {
        const data = await ConnectToServer.delPoints(obj)
        if (data.success === 1) {
            //console.log(data);
           dispatch(pointsRequest({ mycompany: [mycomp] }))
        } else {
            console.log(data.message)
            //dispatch(toggleIsFetching(false))
        }
    }
}

export default pointsReducer
;