import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_ALL_OLD_APPLICATIONS = 'OLDAPPLICATION/SET_ALL_OLD_APPLICATIONS';
const SET_BACKDROP = 'OLDAPPLICATION/SET_BACKDROP';

//Started props
let initialState = {
    isFetching: false,
    allOldApplications: []
};

//Reducers functions
const oldApplicationsReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_ALL_OLD_APPLICATIONS:
            return {
                ...state,
                allOldApplications: action.applications
            }

        case SET_BACKDROP:
            return {
                ...state,
                isFetching: action.data
            }

        default:
            return state;
    }
}

//Action Creators functions
// const setNewApplication = (newApplication) => {
//     console.log(newApplication);
//     return { type: ADD_APPLICATION, newApplication }
// }

const setAllOldApplication = (applications) => {
    return { type: SET_ALL_OLD_APPLICATIONS, applications }
}

const setBackdoorOldApplication = (data) => {
    return { type: 'OLDAPPLICATION/SET_BACKDROP', data }
}

//Thunk functions
export const getOldApplicationRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.getALLOldApplication(obj)
        if (data.success === 1) {
            const allAppl = data.allApplications.map((appl)=> ({...appl, content: appl.content.map((cont)=>({...cont, amount: Number(cont.amount), price: Number(cont.price), total: Number(cont.total) }))}))
           
            dispatch(setAllOldApplication(allAppl))
            dispatch(setBackdoorOldApplication(false))
        } else {
            console.log(data.message)
            //dispatch(toggleIsFetching(false))
            dispatch(setAllOldApplication([]))
            dispatch(setBackdoorOldApplication(false))
        }
    }
}

export default oldApplicationsReducer;