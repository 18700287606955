//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import { delivAddressUpdate, delivAddressDelete } from '../../../Redux/addrDeliveryReducer'
import ClientsAdressPanel from './ClientsAdressPanel';




class ClientsAdressPanelContainer extends React.PureComponent {

    render() {
        return (<>            
                <ClientsAdressPanel
                    delivAddressUpdate={this.props.delivAddressUpdate}
                    delivAddressDelete={this.props.delivAddressDelete} 
                    myCompany={this.props.myCompany}   
                />           
        </>)
    }
}

const mapStateToProps = (state) => {
    return {        
    }
};

export default connect(mapStateToProps, { 
    delivAddressUpdate,
    delivAddressDelete  
})(ClientsAdressPanelContainer)
