import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import * as React from 'react';
//import seal from '../../../Img/seal.png'
//import signature from '../../../Img/signature.png'



const SignatureTableNakladnaya = ({ addressDelivery, sumWithContentAmount, sumWithContentSumm, myCompony }) => {

    const styles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            marginTop: '5px'
        },
        tableRow: {
            flexDirection: "row"
        },
        seal: {
            position: 'absolute',
            top: '-80px',
            left: '-110px',
            width: '128px',
        },
        signature: {
            position: 'absolute',
            top: '-10px',
            left: '0px',
            width: '100px',
        },
        tableRowTop: {
            flexDirection: "row",
            marginTop: '15px'
        },
        tableRowTop30: {
            flexDirection: "row",
            marginTop: '30px'
        },
        tableRowTop7: {
            flexDirection: "row",
            marginTop: '7px'
        },
        tableCol27: {
            width: "27%",
        },
        tableCol21: {
            width: "21%",
        },
        tableCol82: {
            width: "82%",
        },

        tableCol30: {
            width: "30%",
        },
        tableCol24: {
            width: "24%",
        },
        tableCol23: {
            width: "23%",
        },
        tableCol18: {
            width: "18%",
        },
        tableCol15: {
            width: "15%",
        },
        tableCol8: {
            width: "8%",
        },
        tableCol2: {
            width: "2%",
        },
        tableCol37: {
            width: "37%",
        },
        tableCol45: {
            width: "45%",
        },
        tableCol50Line: {
            width: "50%",
            borderRight: 1,
            paddingRight: '15px',
            marginTop: '10px'

        },
        tableCol50: {
            width: "50%",
            marginTop: '10px',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        tableCell: {
            width: '100%',
            textAlign: 'left',
            fontSize: 10,
        },
        tableCellDelivery: {
            width: '100%',
            textAlign: 'left',
            fontSize: 10,
            fontWeight: 'bold'
        },
        tableCellBlack: {
            width: '100%',
            paddingTop: '5px',
            textAlign: 'left',
            fontSize: 10,
            margin: 'auto',
            fontWeight: 'bold',
            verticalAlign: 'super'
        },
        tableCell7: {
            width: '100%',
            textAlign: 'left',
            fontSize: 7,
            margin: 'auto',

            paddingLeft: '2px'
        },
        tableCellItaly: {
            width: '100%',
            textAlign: 'center',
            fontSize: 10,
            margin: 'auto',
            fontStyle: 'Italy',
        },
        tableCellCenter: {
            width: '100%',
            textAlign: 'center',
            fontSize: 10,
            margin: 'auto',
        },
        tableCellLine: {
            width: '100%',
            textAlign: 'left',
            fontSize: 10,
            margin: 'auto',
            borderBottom: 1
        },
    })

    function numberToString(_number, toUpper1) {
        var toUpper = toUpper1 || false;
        var _arr_numbers = [];
        _arr_numbers[1] = ['', 'один', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять', 'десять', 'одиннадцать', 'двенадцать', 'тринадцать', 'четырнадцать', 'пятнадцать', 'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать']
        _arr_numbers[2] = ['', '', 'двадцать', 'тридцать', 'сорок', 'пятьдесят', 'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто']
        _arr_numbers[3] = ['', 'сто', 'двести', 'триста', 'четыреста', 'пятьсот', 'шестьсот', 'семьсот', 'восемьсот', 'девятьсот']
        let _last_num

        function number_parser(_num, _desc) {
            var _string = '';
            var _num_hundred = '';
            if (_num.length === 3) {
                _num_hundred = _num.substr(0, 1);
                _num = _num.substr(1, 3);
                _string = _arr_numbers[3][_num_hundred] + ' ';
            }
            if (_num < 20) _string += _arr_numbers[1][parseFloat(_num)] + ' ';
            else {
                var _first_num = _num.substr(0, 1);
                var _second_num = _num.substr(1, 2);
                _string += _arr_numbers[2][_first_num] + ' ' + _arr_numbers[1][_second_num] + ' ';
            }

            switch (_desc) {
                case 0:
                    if (_num.length === 2 && parseFloat(_num.substr(0, 1)) === 1) {
                        _string += 'тенге';
                        break;
                    }
                    _last_num = parseFloat(_num.substr(-1));
                    if (_last_num === 1) _string += 'тенге';
                    else if (_last_num > 1 && _last_num < 5) _string += 'тенге';
                    else _string += 'тенге';
                    break;

                case 1:
                    _num = _num.replace(/^[0]{1,}$/g, '0');
                    if (_num.length === 2 && parseFloat(_num.substr(0, 1)) === 1) {
                        _string += 'тысяч ';
                        break;
                    }

                    _last_num = parseFloat(_num.substr(-1));
                    if (_last_num === 1) _string += 'тысяча ';
                    else if (_last_num > 1 && _last_num < 5) _string += 'тысячи ';
                    else if (parseFloat(_num) > 0) _string += 'тысяч ';
                    _string = _string.replace('один ', 'одна ');
                    _string = _string.replace('два ', 'две ');
                    break;

                case 2:
                    _num = _num.replace(/^[0]{1,}$/g, '0');
                    if (_num.length === 2 && parseFloat(_num.substr(0, 1)) === 1) {
                        _string += 'миллионов ';
                        break;
                    }

                    _last_num = parseFloat(_num.substr(-1));
                    if (_last_num === 1) _string += 'миллион ';
                    else if (_last_num > 1 && _last_num < 5) _string += 'миллиона ';
                    else if (parseFloat(_num) > 0) _string += 'миллионов ';
                    break;

                case 3:
                    _num = _num.replace(/^[0]{1,}$/g, '0');
                    if (_num.length === 2 && parseFloat(_num.substr(0, 1)) === 1) {
                        _string += 'миллиардов ';
                        break;
                    }

                   _last_num = parseFloat(_num.substr(-1));
                    if (_last_num === 1) _string += 'миллиард ';
                    else if (_last_num > 1 && _last_num < 5) _string += 'миллиарда ';
                    else if (parseFloat(_num) > 0) _string += 'миллиардов ';
                    break;
                
                default:
                    break

            }

            return _string;

        }

        function decimals_parser(_num) {
            var _first_num = _num.substr(0, 1);
            var _second_num = parseFloat(_num.substr(1, 2));
            var _string = ' ' + _first_num + _second_num;
            if (_second_num === 1) _string += ' тиын';
            else if (_second_num > 1 && _second_num < 5) _string += ' тиын';
            else _string += ' тиын';
            return _string;

        }

        if (!_number || _number === 0) return false;
        if (typeof _number !== 'number') {
            _number = _number + '';
            _number = _number.replace(',', '.');
            _number = parseFloat(_number);
            if (isNaN(_number)) return false;
        }

        _number = _number.toFixed(2);
        if (_number.indexOf('.') !== -1) {
            var _number_arr = _number.split('.');
            _number = _number_arr[0];
            var _number_decimals = _number_arr[1];
        }

        var _number_length = _number.length;
        var _string = '';
        var _num_parser = '';
        var _count = 0;

        for (var _p = (_number_length - 1); _p >= 0; _p--) {
            var _num_digit = _number.substr(_p, 1);
            _num_parser = _num_digit + _num_parser;
            if ((_num_parser.length === 3 || _p === 0) && !isNaN(parseFloat(_num_parser))) {
                _string = number_parser(_num_parser, _count) + _string;
                _num_parser = '';
                _count++;
            }
        }

        if (_number_decimals) _string += decimals_parser(_number_decimals);
        if (toUpper === true || toUpper === 'upper') {
            _string = _string.substr(0, 1).toUpperCase() + _string.substr(1);
        }
        return _string.replace(/[\s]{1,}/g, ' ');
    };

    Number.numberToString = function (toUpper) {
        return numberToString(this, toUpper);
    };

    String.numberToString = function (toUpper) {
        return numberToString(this, toUpper);
    };

    const sumAmount = numberToString(sumWithContentAmount, true).slice(0, -13)

    return (
        <View style={styles.table}>
            {/* итого */}
            <View style={styles.tableRowTop7}>
                <View style={styles.tableCol27}>
                    <Text style={styles.tableCell}>Всего отпущено количество запасов (прописью)</Text>
                </View>
                <View style={styles.tableCol18}>
                    <Text style={styles.tableCellLine}>{sumAmount}</Text>
                </View>
                <View style={styles.tableCol18}>
                    <Text style={styles.tableCellCenter}>на сумму (прописью), в KZT</Text>
                </View>
                <View style={styles.tableCol37}>
                    <Text style={styles.tableCellLine}>{numberToString(sumWithContentSumm, true)}</Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableCol50Line}>
                    {/* менеджер */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol24}>
                            <Text style={styles.tableCell}>Отпуск разрешил</Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellLine}> </Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}>/</Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellLine}> </Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}>/</Text>
                        </View>
                        <View style={styles.tableCol30}>
                            <Text style={styles.tableCellLine}> </Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol24}>

                        </View>
                        <View style={styles.tableCol23}>
                            <Text style={styles.tableCellItaly}>должность</Text>
                        </View>
                        <View style={styles.tableCol23}>
                            <Text style={styles.tableCellItaly}>подпись</Text>
                        </View>
                        <View style={styles.tableCol30}>
                            <Text style={styles.tableCellItaly}>расшифровка подписи</Text>
                        </View>
                    </View>
                    {/* Бухгалтер */}
                    <View style={styles.tableRowTop}>
                        <View style={styles.tableCol24}>
                            <Text style={styles.tableCell}>Главный бухгалтер</Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellLine}> </Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}>/</Text>
                        </View>
                        <View style={styles.tableCol45}>
                            <Text style={styles.tableCellLine}>{myCompony.buh.length === 0? "Не предусмотрен":myCompony.buh}</Text>
                        </View>
                        <View style={styles.tableCol8}>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol24}>
                            <Text style={styles.tableCell}> </Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellItaly}>подпись</Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}></Text>
                        </View>
                        <View style={styles.tableCol45}>
                            <Text style={styles.tableCellItaly}>расшифровка подписи</Text>
                        </View>
                        <View style={styles.tableCol8}>
                        </View>
                    </View>
                    <Text style={styles.tableCellBlack}>М.П.</Text>
                    {/* Отпустил */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol24}>
                            <Text style={styles.tableCell}>Отпустил</Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellLine}> </Text>
                            {myCompony.seal.length ===0? <View></View>: <Image style={styles.seal} src={myCompony.seal} />} 
                            {myCompony.signature.length ===0? <View></View>: <Image style={styles.signature} src={myCompony.signature} />} 
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}>/</Text>
                        </View>
                        <View style={styles.tableCol45}>
                            <Text style={styles.tableCellLine}>{myCompony.signatory.length === 0? myCompony.director:myCompony.signatory}</Text>
                        </View>
                        <View style={styles.tableCol8}>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol24}>
                            <Text style={styles.tableCell}> </Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellItaly}>подпись</Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}></Text>
                        </View>
                        <View style={styles.tableCol45}>
                            <Text style={styles.tableCellItaly}>расшифровка подписи</Text>
                        </View>
                        <View style={styles.tableCol8}>
                        </View>
                    </View>

                </View>
                <View style={styles.tableCol50}>
                    <Text style={styles.tableCell}>По доверенности         №____________от"____"___________________20___года</Text>
                    <View style={styles.tableRowTop7}>
                        <View style={styles.tableCol18}>
                            <Text style={styles.tableCell}>выданной</Text>
                        </View>
                        <View style={styles.tableCol82}>
                            <Text style={styles.tableCellLine}> </Text>
                        </View>
                    </View>
                    <View style={styles.tableRowTop7}>
                        <Text style={styles.tableCellLine}> </Text>
                    </View>
                    {/* Получил */}
                    <View style={styles.tableRowTop30}>
                        <View style={styles.tableCol24}>
                            <Text style={styles.tableCell}>Запасы получил</Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellLine}> </Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}>/</Text>
                        </View>
                        <View style={styles.tableCol45}>
                            <Text style={styles.tableCellLine}> </Text>
                        </View>
                        <View style={styles.tableCol8}>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol24}>
                            <Text style={styles.tableCell}> </Text>
                        </View>
                        <View style={styles.tableCol21}>
                            <Text style={styles.tableCellItaly}>подпись</Text>
                        </View>
                        <View style={styles.tableCol2}>
                            <Text style={styles.tableCell7}></Text>
                        </View>
                        <View style={styles.tableCol45}>
                            <Text style={styles.tableCellItaly}>расшифровка подписи</Text>
                        </View>
                        <View style={styles.tableCol8}>
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.tableRowTop7}>
                <View style={styles.tableCol15}>
                    <Text style={styles.tableCell}> Контакт </Text>
                </View>
                <View style={styles.tableCol82}>
                    <Text style={styles.tableCellDelivery}>{addressDelivery.phone}</Text>
                </View>
            </View>
            <View style={styles.tableRowTop7}>
                <View style={styles.tableCol15}>
                    <Text style={styles.tableCell}> ФИО сотрудника </Text>
                </View>
                <View style={styles.tableCol82}>
                    <Text style={styles.tableCellDelivery}>{addressDelivery.workerName}</Text>
                </View>
            </View>
            <View style={styles.tableRowTop7}>
                <View style={styles.tableCol15}>
                    <Text style={styles.tableCell}> Адрес доставки </Text>
                </View>
                <View style={styles.tableCol82}>
                    <Text style={styles.tableCellDelivery}>{addressDelivery.address}</Text>
                </View>
            </View>
        </View>
    );
}

export default SignatureTableNakladnaya;