import React from 'react';
import { useForm } from "react-hook-form";
import {  
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,  
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import NewWageForm from '../Fno/Fno/Components/NewWageForm';
import StepperFNO913 from '../Fno/Fno/Components/StepperFNO913';

const allMonth = [
  [{ id: 1, name: "Январь" }, { id: 2, name: "Февраль" }, { id: 3, name: "Март" }],
  [{ id: 4, name: "Апрель" }, { id: 5, name: "Май" }, { id: 6, name: "Июнь" }],
  [{ id: 7, name: "Июль" }, { id: 8, name: "Август" }, { id: 9, name: "Сентябрь" }],
  [{ id: 10, name: "Октябрь" }, { id: 11, name: "Ноябрь" }, { id: 12, name: "Декабрь" }]
]

const NewFNO913Form = ({ handleCloseForm, addNewWages, newExpenses, summaryFNO913, totalSeasons, wagesWithSeason, addNewFNO913, myCompany }) => {

  const [openFormNewWage, setOpenFormNewWage] = useState(false);
  const backdrop = useSelector(state => state.fno913.isFetching)
  const authUser = useSelector(state => state.auth.authUser)
  const workers = [{ ...authUser }]

  const handleClose = () => {
    setOpenFormNewWage(false);
  };

  let years = [...new Set(totalSeasons.map((y) => y[1]))]

  const [season, setSeason] = useState(totalSeasons[0][0] === 0 ? 3 : totalSeasons[0][0] - 1);//нужно учесть если есть предыдущий!!!
  const [year, setYear] = useState(totalSeasons[0][0] === 0 ? totalSeasons[1][1] : totalSeasons[0][1]); //исправить!!!!
  const [selecteFNO913, setSelectedFNO913] = useState(summaryFNO913.length > 1 ? summaryFNO913[1] : summaryFNO913[0]);
  const [selecteWagesWithSeason, setSelectedWagesWithSeason] = useState(wagesWithSeason.length > 1 ? wagesWithSeason[1] : wagesWithSeason[0]);
  const [btnAction, setBtnAction] = useState(true);

  useEffect(() => {
    totalSeasons.map((s, index) => {
      if (s[0] === season && s[1] === year.toString()) {
        setSelectedFNO913(summaryFNO913[index])
        setSelectedWagesWithSeason(wagesWithSeason[index])
      }
      return s
    })   
  }, [totalSeasons, season, year, summaryFNO913, wagesWithSeason])

  const handleChange = (event) => {
    const checkSeason = totalSeasons.filter((s) => s[0] === event.target.value)
    if (checkSeason.length === 0) {
      alert("Данные по выбранному Кварталу/Году, отсутствуют!")
    } else {
      if (checkSeason[0][1] === year) {
        setSeason(event.target.value)
      } else {
        setSeason(event.target.value)
        setYear(parseInt(checkSeason[0][1]))
      }
    }
  };

  const handleChangeYear = (event) => {
    const checkSeason = totalSeasons.filter((s) => s[1] === event.target.value)
    if (checkSeason[0][0] === season) {
      setYear(event.target.value)
    } else {
      setYear(event.target.value)
      setSeason(checkSeason[0][0])
    }

  };

  const { handleSubmit } = useForm({
    reValidateMode: "onBlur"
  })

  const handleOnSubmit = (evt) => {
    const dataWithFormFNO312 = {
      season,
      year,
      myCompany: myCompany.id,
      userCreator: authUser.id,
      gross: selecteFNO913.total,
      totalWages: selecteFNO913.wages,
      totalWorkers: workers.length,
      totalTax: selecteFNO913.tax1.reduce((sum, value) => sum + value, 0) + selecteFNO913.tax2.reduce((sum, value) => sum + value, 0),
      pointsCompany: selecteFNO913.pointsCompany,
      personalTotal: selecteFNO913.personalTotal,
      personalTax: selecteFNO913.tax1,
      personalRatio: selecteFNO913.ratio1,
      personalTotalReduction: selecteFNO913.total1,
      companyTotal: selecteFNO913.companyTotal,
      companyTax: selecteFNO913.tax2,
      companyTotalReduction: selecteFNO913.total2,
      componyRatio: selecteFNO913.ratio2,
      wages: selecteFNO913.wagesSeason,
    }
    addNewFNO913(dataWithFormFNO312, myCompany.id);
    handleCloseForm()
  };

  const addWage = () => {
    setOpenFormNewWage(true)
  }

  return (
    <div className="App">
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" fontSize={16} textAlign="center" gutterBottom>
              Расчет налоговой формы 913.00
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Период</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={season}
                label="Период"
                onChange={handleChange}
              >
                <MenuItem value={0}>1 квартал</MenuItem>
                <MenuItem value={1}>2 квартал</MenuItem>
                <MenuItem value={2}>3 квартал</MenuItem>
                <MenuItem value={3}>4 квартал</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small-label">Год</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={year}
                label="Год"
                onChange={handleChangeYear}
              >
                {years.map((y) => <MenuItem key={y} value={y}>{y}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>

          <StepperFNO913
            setBtnAction={setBtnAction}
            workers={workers}
            summaryFNO913={selecteFNO913}
            wagesWithSeason={selecteWagesWithSeason}
            seasonMonth={allMonth[season]}
            addWage={addWage}
          />

          <Grid item xs={6}>
            <Button disabled={btnAction} type="submit" variant="contained">Сохранить</Button>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={handleCloseForm} type="button" variant="outlined">Отмена</Button>
          </Grid>

        </Grid>
      </Box>
      
      <Dialog
        open={openFormNewWage}
        onClose={handleClose}
        scroll='body'
        aria-labelledby="customized-dialog-title"
        maxWidth='true'
      >
        <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #fcbb42' }} color="#07139e" id="customized-dialog-title">
          Новая запись
        </DialogTitle>
        <DialogContent >
          <NewWageForm addNewWages={addNewWages} newExpenses={newExpenses} handleClose={handleClose} myCompany={myCompany} />
        </DialogContent>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}

export default NewFNO913Form