
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import MainAppContainer from './MainApp/MainAppContainer';
import LoginContainer from './Login/LoginContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { Alert, AlertTitle, Backdrop, CircularProgress, Slide, Snackbar } from '@mui/material';
import NoConnection from './NoConnection/NoConnection';
import ClientContainer from './Client/ClientContainer';
import NoPay from './Errors/NoPay/NoPay';
import WorkersContainer from './Workers/WorkersContainer';
import Fno from './Fno/Fno';
import DashboardContainer from './Dashboard/DashboardContainer';

function App() {
  const [ComponentContainer, setComponentContainer] = useState(<></>)
  const backdrop = useSelector(state => state.auth.backdrop)
  const notPay = useSelector(state => state.auth.notPay)
  const userID = useSelector(state => state.auth.userId)
  const dayPay = useSelector(state => state.auth.dayPay)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const wordDay = ['', 'день', 'дня','дня','дня','дней']

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (dayPay < 6 && dayPay > 0) {
      setOpen(true)
    }
  }, [dayPay, setOpen])

  const setUserKey = useCallback((data) => {
    dispatch({ type: 'AUTH/SET_USER_KEY', data })
  }, [dispatch])

  useEffect(() => {
    if (!notPay) {
      navigate("/nopay")
    }
  }, [notPay, navigate])

  useEffect(() => {
    const getStorageUser = () => {
      const user_ = localStorage.getItem("finupkzuser")
      if (user_) {
        setUserKey(JSON.parse(user_))
      } else {
        setComponentContainer(<LoginContainer />)
      }
    }

    if (userID === 0) {
      getStorageUser()
    } else {
      setComponentContainer(<MainAppContainer />)
    }
  }, [userID, setComponentContainer, setUserKey])

  return (<>

    <main>
      <Routes>        
        <Route path="/" element={ComponentContainer} />
        <Route path="/error" element={<NoConnection />} />
        <Route path="/client" element={<ClientContainer />} />
        <Route path="/cabinet" element={<DashboardContainer />} />
        <Route path="/nopay" element={<NoPay />} />
        <Route path="/workers" element={<WorkersContainer />} />        
        <Route path="/fno" element={<Fno />} />        
      </Routes>
    </main>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={backdrop}
    >
      <CircularProgress color="primary" />
    </Backdrop>

    <Snackbar
      open={open}
      sx={{ display: open ? 'flex' : 'none' }}
      autoHideDuration={10000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={(props) => <Slide {...props} direction="up" />}
    >
      <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
        <AlertTitle>Внимание!</AlertTitle>
        Уважаемый пользователь, срок подписки заканчивается через — <strong>{dayPay} {wordDay[dayPay]}! </strong>
        <br />
        Для продления подписки необходимо произвести оплату. 
        <br /> <br />
        Спасибо, что Вы с нами!
      </Alert>
    </Snackbar>
  </>
  );
}

export default App;
