import React from 'react';
import { Controller } from "react-hook-form";
import {
  Avatar,
  Backdrop,
  Button,
  CardHeader,
  CircularProgress,
  Grid,
  TextField,
  styled,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import { ruRU } from '@mui/x-date-pickers/locales';
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})


const NewExpensesForm = ({ control, myHelper, setDdateNewExpenses, uploadImgRequest }) => {

  const backdrop = useSelector(state => state.expenses.backdrop)
  const img = useSelector(state => state.expenses.img)
  const [image, setImage] = useState(null);
  const [icon, setIcon] = useState(<ImageNotSupportedIcon sx={{width:60, height: 60}} />);

  useEffect(()=>{
    if (image) { 
      uploadImgRequest(image)
      setIcon(image.type==="application/pdf"? <PictureAsPdfOutlinedIcon sx={{width:100, height: 100}}/> :<ImageNotSupportedIcon sx={{width:60, height: 60}} />)
     }
  }, [image])  

  const uploadImg = async (e) => {
    
    const file = e.target.files[0];

    if (file&&file.type!=="application/pdf") {
      const dimensions = await getImageDimensions(file);

      const maxSizeInMB = 1;

      if (file.size / (1024 * 1024) > maxSizeInMB) {
        // Урезаем изображение
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const maxWidth = 800; // Желаемая ширина изображения

        const scale = maxWidth / dimensions.width;
        canvas.width = maxWidth;
        canvas.height = dimensions.height * scale;

        ctx.drawImage(dimensions.img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((blob) => {
          const resizedFile = new File([blob], file.name, {
            type: 'image/jpeg', // Меняйте тип в соответствии с вашими требованиями
          });
          setImage(resizedFile)
        }, 'image/jpeg', 0.7) // 0.7 - качество изображения, можно изменять
      } else {
        // Изображение не требует урезки
        setImage(file)       
      }
    } else {
      setImage(file)
    }   
  };

  

  const getImageDimensions = (file) => {

    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          resolve({ img, width: img.width, height: img.height });
        };
      };

      reader.readAsDataURL(file);
    });
  }

  return (<>

    <Grid item xs={12}>
      <LocalizationProvider adapterLocale='ru' dateAdapter={AdapterDayjs} localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
        <DatePicker
          label='Дата'
          openTo="day"
          defaultValue={dayjs(new Date())}
          slotProps={{ textField: { size: 'small', fullWidth: true } }}
          onChange={(evt) => setDdateNewExpenses(evt)}
          rules={{
            required: true
          }}
        //minDate={dayjs(new Date().setMonth(getMonthWithMinDate()))}
        />
      </LocalizationProvider>
    </Grid>

    <Grid item xs={12}>
      <Controller
        control={control}
        name="total"
        rules={{
          required: true
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            type="number"
            fullWidth
            size='small'
            label="Сумма"
            error={error !== undefined}
            helperText={error ? myHelper.text[error.type] : ""}
          />
        )}
      />
    </Grid>
    <Grid item xs={12}>
      <Controller
        control={control}
        name="expensesTitel"
        rules={{
          required: true
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            type="text"
            fullWidth
            size='small'
            label="Описание"
            error={error !== undefined}
            helperText={error ? myHelper.text[error.type] : ""}
          />
        )}
      />
    </Grid>

    <Grid item xs={12}>
      <CardHeader
        sx={{ p: "3px", textAlign: 'left' }}
        avatar={<Avatar src={img.length === 0 ? null : img} aria-label="recipe" variant="rounded" sx={{ width: '150px', height: '150px' }} > {icon} </Avatar>}
        title={
          <Button component="label" variant="contained" size="small" startIcon={<CloudUploadIcon />}>
            Загрузить фото документа
            <VisuallyHiddenInput onChange={uploadImg} type="file" accept='image/*,.png,.jpg,.jpeg,.gif,.pdf' />
          </Button>
        }
      />
    </Grid>

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={backdrop}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  </>
  );
}

export default NewExpensesForm