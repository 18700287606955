export const getAuthUser = state => state.auth.authUser


// export const getAuthUserId = state => state.auth.userId

// export const openRegistrationForm = state => state.auth.registrationForm

// export const openUpdateAuthUserForm = state => state.auth.updateAuthUserForm

// export const openUpdateLoginAuthUserForm = state => state.auth.updateLoginAuthUserForm

// export const getBackdropUpdateForm = state => state.auth.backdropFormUpdate

// export const getEmailUnRegistered = state => state.auth.emailUnRegistered

// export const getErrorAuth = state => state.auth.errorAuth

// export const getAuthUserKey = state => state.auth.key

// export const getAuthUser = state => state.auth.user

// export const getLogout = state => state.auth.logout

// export const getLogin = state => state.auth.login

// export const getAuthUserWorkoutList = state => state.auth.user.workouts