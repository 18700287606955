import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Divider, Grid, Stack } from '@mui/material';
import UserProfilePanel from '../Panels/Users/UserProfilePanel';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const UserCard = ({ user, updateAuthUserRequest, updateUserPwdRequest }) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const getAccountColor = (account) => {
        switch (account) {
            case 0:
                return '#90caf9';

            case 2:
                return '#ffe57f';

            default:
                return '#90caf9';
        }
    }

    return (
        <Grid item xs={12} md={6} sm={6} lg={4} xl={3} >
            <Card sx={{ maxWidth: 345, boxShadow: 'gray 0px 8px 10px -3px;', border: 4, borderColor: getAccountColor(user.account), borderRadius: 3 }}>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: '#07139e' }} aria-label="recipe">
                            {user.firstName[0]}
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings">
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={user.firstName + ' ' + user.lastName}
                    subheader={user.email}
                />
                <CardContent>
                    <Stack spacing={0.5}>
                        <Typography variant="body2" color="text.secondary" textAlign={'left'} >
                            Город: {user.city}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" textAlign={'left'} >
                            Телефон: {user.phone}
                        </Typography>
                    </Stack>
                </CardContent>
                <Divider />
                <CardActions sx={{py: 0}} >
                <Typography variant="button" color='secondary' display="block" gutterBottom >Настройки профиля</Typography>
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon color='secondary' />
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <UserProfilePanel authUser={user} updateAuthUserRequest={updateAuthUserRequest} updateUserPwdRequest={updateUserPwdRequest} />
                        
                    </CardContent>
                </Collapse>
            </Card>
        </Grid>
    );
}

export default UserCard;