import * as React from 'react';
import UserProfile from '../../../Forms/UserProfile';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent } from '@mui/material';
import UserProfilePass from '../../../Forms/UserProfilePass';

const UserProfilePanel = ({ authUser, updateAuthUserRequest, updateUserPwdRequest }) => {
    const openFormPass = useSelector(state => state.auth.formOpenPass)

    const dispatch = useDispatch()

    const setOpenFormPass = (data) => {
        dispatch({ type: 'AUTH/SET_OPEN_FORM_PASS', data })
    }

    const handleCloseForm = () => {

    }

    const handleOpenFormPass = () => {
        setOpenFormPass(true)
    }

    const handleCloseFormPass = () => {
        setOpenFormPass(false)
    }

    return (<div style={{marginTop: '15px'}}> 
        <UserProfile authUser={authUser} handleCloseForm={handleCloseForm} handleOpenFormPass={handleOpenFormPass} updateAuthUserRequest={updateAuthUserRequest} />
        <Dialog
            open={openFormPass}
            onClose={handleCloseFormPass}
            scroll='body'
        >
            <DialogContent>
                <UserProfilePass authUser={authUser} handleCloseFormPass={handleCloseFormPass} updateUserPwdRequest={updateUserPwdRequest} />
            </DialogContent>
        </Dialog>
    </div>)
}

export default UserProfilePanel