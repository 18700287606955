import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,

} from '@mui/x-data-grid';

import { Chip, CircularProgress, Divider, Menu, MenuItem, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import { Suspense } from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvocePDF from '../../Components/PDF/Invoce/InvocePDF';
import NakladnayaPDF from '../../Components/PDF/Nakladnaya/NakladnayaPDF';
import AVRPDF from '../../Components/PDF/AVR/AVRPDF';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect } from 'react';

const CustomNoRowsOverlay = () => {
  return (
    <Typography variant="hoverline" gutterBottom display="block" color="#000000" sx={{ pt: "15px", textAlign: "center" }}>
      Нет записи
    </Typography>
  )
}




export default function TableAllOrders({ setOpenFormApplicId, setOpenFormDelete, handleOpenFormProfile, myCompony }) {

  function EditToolbar(props) {
    // const { setRows, setRowModesModel } = props;
    // const idMyCompany = useSelector(state => state.myCompany.id)
    // const handleClick = () => {
    //   const id = randomId();
    //   setRows((oldRows) => [{ id, idDelivAddr: 0, isNew: true, idMyCompany, update: true }, ...oldRows]);
    //   setRowModesModel((oldModel) => ({
    //     ...oldModel,
    //     [id]: { mode: GridRowModes.Edit, fieldToFocus: 'plu' },
    //   }));

    // };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={() => handleOpenFormProfile(0, 0)} >
          Добавить заявку
        </Button>
      </GridToolbarContainer>
    );
  }

  const allAddressDelivery = useSelector(state => state.addressDelivery.addrDelivery)
  const allCompanies = useSelector(state => state.companies.companies)
  const allApplications = useSelector(state => state.applicaions.allApplications)
  const [rows, setRows] = React.useState(allApplications);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const allUsers = useSelector(state => state.users.users)
  useEffect(()=>{setRows(allApplications)},[allApplications,setRows])

  function DocsMenu(docs) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const nakladnaya = docs.content.filter((appl) => appl.unit !== "усл.")
    const avr = docs.content.filter((appl) => appl.unit === "усл.")
    const compony = allCompanies.filter(comp => comp.id === docs.clientId)[0]
    let addressDelivery = allAddressDelivery.filter((addr) => addr.id === docs.deliveryAddressId)[0]

    if (!docs.invoice&&!docs.nakladnayAVR){
      return <></>
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    let addr = " "
    
    if (addressDelivery === undefined) {
      addr = " "
      addressDelivery =
      {address: " ",
      date:"",
      id:0,
      idCompany:0,
      idMyCompany: 0,
      phone: " ",
      workerName: " "}
      
    } else {
      addr = addressDelivery.address
    }

    return (
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          startIcon={<SimCardDownloadIcon />}
          onClick={handleClick}
          size='small'
        >
          Скачать
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >         

          <Suspense fallback={<div>Loading...</div>}>
            {open ?
              (docs.invoice ?
                <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<InvocePDF application={docs} compony={compony} myCompony={myCompony} />} fileName={'Счет №' + docs.number + ' "' + compony.name + '" ' + addr + '.pdf'}>
                  {({ loading }) =>
                    loading ? (<CircularProgress color="primary" />
                    ) : (
                      <MenuItem sx={{ textDecoration: 'none' }} >
                        <PictureAsPdfRoundedIcon color='error' fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mt: 0, mb: 0, pt: 0, pb: 0 }} textAlign="center" >
                          Счет
                        </Typography>
                      </MenuItem>
                    )
                  }
                </PDFDownloadLink> : <></>)
              : <></>}
          </Suspense>

          {docs.invoice && docs.nakladnayAVR ? <Divider sx={{ borderColor: '#fcbb42' }} variant="middle" component="li" /> : null}

          <Suspense fallback={<div>Loading...</div>}>
            {open ?
              (docs.nakladnayAVR && nakladnaya.length > 0 ?
                <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<NakladnayaPDF nakladnaya={nakladnaya} addressDelivery={addressDelivery} application={docs} compony={compony} myCompony={myCompony} />} fileName={'Накладная №' + docs.number + ' "' + compony.name + '" ' + addr + '.pdf'}>
                  {({ loading }) =>
                    loading ? (<CircularProgress color="primary" />
                    ) : (
                      <MenuItem sx={{ textDecoration: 'none' }} >
                        <PictureAsPdfRoundedIcon color='error' fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mt: 0, mb: 0, pt: 0, pb: 0 }} textAlign="center" >Накладная</Typography>
                      </MenuItem>
                    )
                  }
                </PDFDownloadLink> : <></>)
              : <></>}
          </Suspense>

          {nakladnaya.length > 0 && avr.length > 0 ? <Divider sx={{ borderColor: '#fcbb42', mt: '0', mb: '0' }} variant="middle" /> : null}

          <Suspense fallback={<div>Loading...</div>}>
            {open ?
              (docs.nakladnayAVR && avr.length > 0 ?
                <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<AVRPDF avr={avr} addressDelivery={addressDelivery} application={docs} compony={compony} myCompony={myCompony} />} fileName={'АВР №' + docs.number + ' "' + compony.name + '" ' + addr + '.pdf'}>
                  {({ loading }) =>
                    loading ? (<CircularProgress color="primary" />
                    ) : (
                      <MenuItem sx={{ textDecoration: 'none' }} >
                        <PictureAsPdfRoundedIcon color='error' fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mt: 0, mb: 0, pt: 0, pb: 0 }} textAlign="center" >АВР</Typography>
                      </MenuItem>
                    )
                  }
                </PDFDownloadLink> : <></>)
              : <></>
            }
          </Suspense>

        </Menu>
      </div>
    );
  }


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    handleOpenFormProfile(id, id)
    // console.log(id);
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id) => () => {
    setOpenFormApplicId({ id: id, update: 0 })
    setOpenFormDelete(true)
    // setRows(rows.filter((row) => row.id !== id));
  };

  const handleCopyClick = (id) => () => {
    handleOpenFormProfile(-1, id)
    // setRows(rows.filter((row) => row.id !== id));
  };

  const onRowClick = React.useCallback(
    (params) => {
      handleOpenFormProfile(params.id, params.id)
    },
    [handleOpenFormProfile],
  );

  // const handleSaveClick = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  // };



  // const handleCancelClick = (id) => () => {
  //   setRowModesModel({
  //     ...rowModesModel,
  //     [id]: { mode: GridRowModes.View, ignoreModifications: true },
  //   });

  //   const editedRow = rows.find((row) => row.id === id);
  //   if (editedRow.isNew) {
  //     setRows(rows.filter((row) => row.id !== id));
  //   }
  // };

  const processRowUpdate = (newRow) => {
    //setAllTotal(AllTotal + newTotal)    
    const updatedRow = { ...newRow, isNew: false, update: true };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'number',
      headerName: '№',
      width: 10,
      type: 'number',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
    },
    // {
    //   field: 'idCompany',
    //   headerName: 'Компания',
    //   width: 120,
    //   type: 'singleSelect',
    //   getOptionValue: (value) => value.id,
    //   getOptionLabel: (value) => value.name,
    //   valueOptions: companiName,
    //   editable: true,
    //   disableColumnMenu: true,
    //   hideSortIcons: true,
    //   align: 'left',
    //   headerAlign: 'center',
    // },
    {
      field: 'date',
      headerName: 'Дата',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'clientId',
      headerName: 'Клиент',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        const compony = allCompanies.filter(comp => comp.id === params.value)
        return compony.lenght === 0? <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }}  textAlign="left" >Загрузка...</Typography>:
          <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mt: 0, mb: 0, pt: 0, pb: 0 }} textAlign="center" >{compony[0].name}</Typography>
        
      }
    },
    {
      field: 'ownerId',
      headerName: 'Создал',
      width: 180,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params) => {
        const user = allUsers.filter(usr => usr.id === params.value)
        return user.lenght === 0? <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }}  textAlign="left" >Не редактировали</Typography>:
        <div>
          <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mb: 0, pb: 0, lineHeight: '1' }} color='secondary' textAlign="left" >{user[0].lastName + ' ' + user[0].firstName}</Typography>
          <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} textAlign="left" >{user[0].login}</Typography>
        </div>
        
      }
    },
    {
      field: 'edit',
      headerName: 'Редактировал',
      width: 180,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params) => {
        const user = allUsers.filter(comp => comp.id === params.value)
        return params.value === 0||user.length === 0? <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }}  textAlign="left" >Не редактировали</Typography>:
        <div>
            <Typography sx={{ textDecoration: 'none', fontWeight: '600', fontSize: '0.82rem', mb: 0, pb: 0, lineHeight: '1' }} color='secondary' textAlign="left" >{user[0].lastName + ' ' + user[0].firstName}</Typography>
            <Typography sx={{ textDecoration: 'none', fontSize: '0.82rem', mt: 0, pt: 0, lineHeight: '1' }} textAlign="left" >{user[0].login}</Typography>
          </div>        
      }
    },
    {
      field: 'allTotal',
      headerName: 'Сумма',
      width: 150,
      type: 'number',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      headerAlign: 'center',
    },
    {
      field: 'payment',
      headerName: 'Оплата',
      width: 80,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'center',
      headerAlign: 'center',
      type: 'boolean',
      renderCell: (params) => params.value ? <CheckCircleRoundedIcon color='success' /> : <ErrorRoundedIcon color='error' />,

    },
    {
      field: 'esfDone',
      headerName: 'ЭСФ',
      width: 215,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      color: 'red',
      align: 'left',
      headerAlign: 'center',
      type: 'boolean',
      renderCell: (params) => <Chip icon={params.row.esf ? params.value ? <CheckCircleRoundedIcon /> : <ErrorRoundedIcon /> : <CancelRoundedIcon />} label={params.row.esf ? params.value ? 'Отправлено' : 'Необходимо отправить' : 'Не требуется'} color={params.row.esf ? params.value ? 'success' : 'error' : 'info'} variant="outlined" />,
    },
    {
      field: 'nakladnayAVR',
      headerName: 'Документы',
      width: 120,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'center',
      headerAlign: 'center',
      type: 'actions',
      renderCell: (params) => DocsMenu(params.row),

    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '...',
      width: 120,
      cellClassName: 'actions',
      disableColumnMenu: true,
      hideSortIcons: true,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            // <GridActionsCellItem
            //   icon={<SaveIcon />}
            //   label="Save"
            //   sx={{
            //     color: 'primary.main',
            //   }}
            //   onClick={handleSaveClick(id)}
            // />,
            // <GridActionsCellItem
            //   icon={<CancelIcon />}
            //   label="Cancel"
            //   className="textPrimary"
            //   onClick={handleCancelClick(id)}
            //   color="inherit"
            // />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label="Редактировать"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<ContentCopyRoundedIcon />}
            label="Копировать"
            onClick={handleCopyClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Удалить"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: '89vh',
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
        flex: 1
      }}
    >
      <Box sx={{ height: '100% !important', width: "100% !important" }}>
        <DataGrid
          autoPageSize

          sx={{ fontSize: "14px",  width: "inherit" }}
          rows={rows}
          columns={columns}
          onProcessRowUpdateError={(error) => console.log(error)}
          columnVisibilityModel={{
            idDelivAddr: false,
            idMyCompany: false,
          }}
          onRowClick={onRowClick}

          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          density="compact"
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}

        />
      </Box>
    </Box>
  );
}