import * as React from 'react';
import Box from '@mui/material/Box';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import { Avatar, Button, Dialog, DialogContent, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import ProductListComponent from './ProductListComponent';
import SendIcon from '@mui/icons-material/Send';
import { useState } from 'react';
import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CountNumber from './Forms/CountNumber';

export default function BasketShop({ masterBasket, sendOrder, delProductInBasket, setMasterBasket }) {
  const [total, setTotal] = useState(0)
  const [formOpenNumber, setOpenFormNumber] = useState(false)
  const [selectProduct, setSelectProduct] = useState({})

  useEffect(() => {
    if (masterBasket.length > 0) {
      const tt = masterBasket.map((bs) => bs.amount * bs.price).reduce(function (a, b) { return a + b }, 0);
      setTotal(tt)
    }
  }, [masterBasket])

  const fragment = (prod) => {
    return <React.Fragment>
      <Typography variant="caption" gutterBottom sx={{ display: 'block' }} >
        Количество: {prod.amount} шт.
      </Typography>
      <Typography variant="caption" gutterBottom sx={{ display: 'block' }} >
        Цена: {prod.price} тг.
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: 'text.primary', display: 'inline' }}
      >
        СУММА: {prod.price * prod.amount} тг.
      </Typography>
    </React.Fragment>
  }

  const action = (id) => {
    return <IconButton onClick={() => delProductInBasket(id)} edge="end" aria-label="delete">
      <DeleteIcon fontSize='large' color='error' />
    </IconButton>
  }

  const addProduct = (item) => {
    setOpenFormNumber(true)
    setSelectProduct(item)
  }

  const addInBasket = (item) => {
    const newBasket = masterBasket.map((pr) => pr.id === selectProduct.id ? { ...pr, amount: item.amount } : { ...pr })
    setMasterBasket(newBasket)
    console.log(item);
    console.log(selectProduct);
    setOpenFormNumber(false)
  }

  return (
    <Box >
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <ListItem
          secondaryAction={
            <Button size='small' onClick={sendOrder} endIcon={<SendIcon />}>
              Заказать
            </Button>
          }
        >
          <ListItemAvatar >
            <Avatar sx={{ backgroundColor: masterBasket.length > 0 ? 'green' : '#bdbdbd' }} >
              <LocalGroceryStoreOutlinedIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Корзина" secondary={masterBasket.length > 0 ?
            <Stack >
              <Typography variant="caption" gutterBottom sx={{ display: 'block', mb: 0 }} >
                {"Добавили: " + masterBasket.length + " позиции"}
              </Typography>
              <Typography variant="caption" gutterBottom sx={{ display: 'block', mb: 0 }} >
                {"Сумма: " + total + " тг."}
              </Typography>
            </Stack> : "Пусто"} />
        </ListItem>
        {masterBasket.length > 0 ? <Divider sx={{ borderColor: '#fcbb42' }} /> : <></>}

        {masterBasket.length > 0 ?
          <ProductListComponent products={masterBasket} addProduct={addProduct} fragment={fragment} action={action} />
          : <></>}
      </List>
      <Divider sx={{ borderColor: '#07139e' }} />
      <Dialog
        open={formOpenNumber}
        onClose={() => setOpenFormNumber(false)}
        scroll='body'
      >
        <DialogContent>
          <CountNumber addInBasket={addInBasket} startNumber={selectProduct.amount} caption={'ОК'} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
