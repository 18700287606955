import * as React from 'react';
import dayjs from 'dayjs';
import TableAllTask from '../../components/TableAllTask';
import FormViewTask from '../../../Forms/FormViewTask';
import { useSelector } from 'react-redux';
import FormNewTask from '../../../Forms/FormNewTask';

const TaskManager = ({ uploadFileTaskRequest, uploadFileNewTaskRequest, addNewTaskRequest, deleteTaskRequest, updateTaskCloseRequest }) => {
    
    const [openFormViewTask, setOpenFormViewTask] = React.useState(false);
    const [openFormNewTask, setOpenFormNewTask] = React.useState(false);
    const [openFIdTask, setIdTask] = React.useState(0);
    const authUser = useSelector(state => state.auth.authUser)

    const handleCloseFormViewTask = () => {
        setOpenFormViewTask(false)
    }

    const handleCloseFormNewTask = () => {
        setOpenFormNewTask(false)
    }

    const handleOpenFormViewTask = (id) => {
        setIdTask(id)
        setOpenFormViewTask(true)
    }

    const handleOpenFormNewTask = () => {
        setOpenFormNewTask(true)
    }

    const giveTimeDate = () => {
        //now DataTime
        const nowDate = dayjs(new Date())
        const time = (nowDate.$H > 9 ? nowDate.$H : '0' + nowDate.$H) + ':' + (nowDate.$m > 9 ? nowDate.$m : '0' + nowDate.$m)
        const date = nowDate.$D + '.' + (nowDate.$M + 1 > 9 ? nowDate.$M + 1 : '0' + (nowDate.$M + 1)) + '.' + nowDate.$y
        return { time, date, user: authUser.id}
    }

    return(
    <div style={{marginTop: '15px'}}> 
        <TableAllTask handleOpenFormViewTask={handleOpenFormViewTask} handleOpenFormNewTask={handleOpenFormNewTask} deleteTaskRequest={deleteTaskRequest} authUser={authUser} />
        <FormViewTask openFormViewTask={openFormViewTask} handleCloseFormViewTask={handleCloseFormViewTask} openFIdTask={openFIdTask} uploadFileTaskRequest={uploadFileTaskRequest} giveTimeDate={giveTimeDate} updateTaskCloseRequest={updateTaskCloseRequest} />
        <FormNewTask openFormNewTask={openFormNewTask} handleCloseFormNewTask={handleCloseFormNewTask} uploadFileNewTaskRequest={uploadFileNewTaskRequest} giveTimeDate={giveTimeDate} addNewTaskRequest={addNewTaskRequest} />
    </div>
    )
}

export default TaskManager