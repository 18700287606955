import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_EXPENSES = 'EXPENSES/SET_EXPENSES';
const SET_BACKDROP = 'EXPENSES/SET_BACKDROP';
const UPLOAD_IMG = 'EXPENSES/UPLOAD_IMG';

//Started props
let initialState = {
    expenses: [],
    isFetching: false,
    activeForm: false,
    backdrop: false,
    img: '',
};

//Reducers functions
const expensesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXPENSES:
            return {
                ...state,
                expenses: action.expenses
            }

        case SET_BACKDROP:
            return {
                ...state,
                backdrop: action.data
            }

        case UPLOAD_IMG:
            return {
                ...state,
                img: action.img
            }

        default:
            return state;
    }
}

//Action Creators functions
const setExpenses = (expenses) => {
    return { type: SET_EXPENSES, expenses }
}

const setBackdrop = (data) => {
    return { type: SET_BACKDROP, data }
}

const setImg = (img) => {
    return { type: UPLOAD_IMG, img }
}

//Thunk functions
export const getAllExpensesRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.getAllExpenses(obj)
        if (data.success === 1) {
            dispatch(setExpenses(data.expenses))
        } else {
            dispatch(setExpenses([]))
        }
    }
}

export const newExpenses = (obj, myCompany) => {
    return async (dispatch) => {
        const data = await ConnectToServer.addNewExpenses(obj)
        if (data.success === 1) {
            dispatch(getAllExpensesRequest({ mycompany: [myCompany] }))

        } else {
        }
    }
}

export const updateExpenses = (obj, myCompany) => {
    return async (dispatch) => {
        const data = await ConnectToServer.putExpenses(obj)
        if (data.success === 1) {
            dispatch(getAllExpensesRequest({ mycompany: [myCompany] }))
        } else {
            console.log(data);
        }
    }
}

export const deleteExpenses = (obj, myCompany) => {
    return async (dispatch) => {
        const data = await ConnectToServer.delExpenses(obj)
        if (data.success === 1) {
            dispatch(getAllExpensesRequest({ mycompany: [myCompany] }))
        } else {
            dispatch(getAllExpensesRequest({ mycompany: [myCompany] }))
        }
    }
}

export const uploadImgRequest = (img) => {
    return async (dispatch) => {
        dispatch(setBackdrop(true))
        const data = await ConnectToServer.putExpensesImg(img)
        if (data.success === 0) {
            dispatch(setBackdrop(false))
        } else {
            if (data.success === 1) {
                dispatch(setImg(data.img))
                dispatch(setBackdrop(false))
            }
        }
    }
}

export default expensesReducer;