import * as React from 'react';
import Fno910Container from '../../../Fno/Fno/Fno910Container';
import Fno913Container from '../../../Fno/Fno/Fno913Container';
import { Grid, Paper } from '@mui/material';

const FNOReportsPanel = ({ authUser, selectCompany }) => {
    // const fno910 = useSelector(state => state.fno910.fno910)
    // const fno913 = useSelector(state => state.fno913.fno913)

    return (<>
        <Grid container direction='row' spacing={2}>
            <Grid item xs={4}>
                <Paper sx={{ boxSizing: 'border-box', overflowY: 'auto', display: 'flex', flexDirection: 'column', position: 'fixed', height: '94%', maxWidth: "25%", pt: 2, pr: 3 }} >
                    {selectCompany.rejym === 0 ? <Fno910Container myCompany={selectCompany} /> : <Fno913Container myCompany={selectCompany} />}
                </Paper>
            </Grid>
            {/* <Grid item xs={3}>
            <Paper sx={{ boxSizing: 'border-box', overflowY: 'auto', display: 'flex', flexDirection: 'column', position: 'fixed', height: '94%', maxWidth: "20%", pt: 2, pr: 3 }} >
                    <Fno913Container />
                </Paper>
            </Grid> */}
        </Grid>
    </>)
}

export default FNOReportsPanel