import React from 'react';
import { Backdrop, Box, CircularProgress } from '@mui/material';
import Breadcrumb from '../Components/Breadcrumb/Breadcrumb';
import AppBarContainer from '../Components/AppBar/AppBarContainer';
import CardIP from './Fno/Components/CardIP';
import { useSelector } from 'react-redux';
import CardTOO from './Fno/Components/CardTOO';
import Fno910Container from './Fno/Fno910Container';
import Fno913Container from './Fno/Fno913Container';

const Fno = () => {
    const myCompany = useSelector(state => state.myCompany)

    return <>
        <Box >
            <AppBarContainer />
            <Breadcrumb name={"Налоговые формы"} />
            {myCompany.type === 0 ? <CardIP myCompany={myCompany} /> : <CardTOO myCompany={myCompany} />}
            {myCompany.rejym === 0 ? <Fno910Container myCompany={myCompany} /> : <Fno913Container myCompany={myCompany} /> }
        </Box>

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={false}
        >
            <CircularProgress color="primary" />
        </Backdrop>
    </>
}


export default Fno;
