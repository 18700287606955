import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useForm, Controller } from "react-hook-form";
import NewAddressDelivery from '../Forms/NewAddressDelivery';
import NewCompanyClient from '../Forms/NewCompanyClient';
import { useSelector } from 'react-redux';

export default function DialogNewData({ open, handleClose, idComponent, textTitle, clientsUpdate }) {

    const authUser = useSelector(state => state.auth.authUser)    

    const { control, handleSubmit, reset } = useForm({
        reValidateMode: "onBlur"
      })

    const handleOnSubmit = (event) => {

        const newClient = () => {
            const client = {...event, kbe: parseInt(event.kbe), idUserOwner: authUser.id, idMyCompany: authUser.mycompany[0] }
            clientsUpdate({ update: [], new: [client] }, authUser.mycompany[0])
            handleClose();
        }

        switch (idComponent) {
                case 1:                    
                    newClient()
                    reset()
                    break
                case 2:
                    console.log('Адрес доставки')
                    reset()
                    break
                default: 
                    console.log('default');                    
            }
        
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
                <DialogTitle>{textTitle}</DialogTitle>
                <DialogContent >
                    {idComponent===2?<NewAddressDelivery control={control} Controller={Controller} />: idComponent===1?<NewCompanyClient control={control} Controller={Controller} />:<></>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button type="submit">Добавить</Button>
                </DialogActions>
            </Box>
        </Dialog>
    
    );
}