import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import * as React from 'react';
import TableContent from './TableContent';



const InvocePDF = ({application, compony, myCompony}) => {
   
    const styles = StyleSheet.create({
        page: {
            padding: '20px',
            fontFamily: "Calibri",
        },
        table: {
            display: "table",
            width: "auto",
        },
        tableRow: {
            flexDirection: "row"
        },
        tableRowHeader: {
            flexDirection: "row",
            marginBottom: '30px'
        },
        tableCol70: {
            width: "70%",
        },
        tableColNo10: {
            width: "10%",
        },
        tableColNo5: {
            width: "5%",
        },
        tableColNo40: {
            width: "10%",
        },
        tableColNo50: {
            width: "50%",
            margin: 0,
            borderWidth: 1,
            borderRightWidth: 0,
            borderTopWidth: 0,
        },
        
        tableColNo25: {
            width: "25%",
            borderWidth: 1,
            borderRightWidth: 0,
            borderTopWidth: 0,
            margin: 0
        },
        tableCell: {
            margin: "auto",
            fontSize: 10,
            marginTop: 3,
            fontWeight: 'bold',
        },
        tableCellLeft: {
            fontSize: 10,
            marginTop: 3,
            fontWeight: 'bold',
        },
        tableText12: {
            fontWeight: 'bold',
            marginTop: 5,
            fontSize: 12,
            borderBottom: 1
        },
        tableText10: {
            fontWeight: 'bold',
            fontSize: 10,
            marginTop: 1
        },
        tableText8: {
            fontSize: 8
        },
        
        tableInvoce: {
            borderWidth: 1,
            borderLeftWidth: 0,
            borderBottomWidth: 0,
        },

        tableTextClient: {
            fontWeight: 'bold',
            marginTop: 20,
            fontSize: 12,
        },
        tableTextAddress: {
            marginTop: 2,
            fontSize: 12,
            maxWidth: '40%'
        },

        tableRowHeaderNumber: {
            flexDirection: "row",
        },
        tableCol50: {
            width: "50%",
            margin: 0,
        },
        tableCol15: {
            width: "15%",
            borderWidth: 1,
            margin: 0
        },
        tableColNo15: {
            width: "15%",
            margin: 0
        },
        tableText8Center: {
            fontSize: 8,
            margin: "auto",
            marginTop: 1,
        },
        tableText14Center: {
            fontSize: 14,
            margin: "auto",
            marginTop: 1,
            fontWeight: 'bold',
            textDecoration: 'underline'
        },
        tableText16CenterNoDecoration: {
            fontSize: 16,
            margin: "auto",
            marginTop: 1,
            fontWeight: 'bold',
        },

        tableTextContract: {
            marginTop: 20,
            marginBottom: 10,
            fontSize: 11,
        },
    })

    return (
        <Document>
            <Page size="A4" style={styles.page} >
                <View style={styles.table}>

                    <View style={styles.tableRowHeader}>
                        <View style={styles.tableColNo5}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                        <View style={styles.tableCol50}>
                            <Text style={styles.tableText12}>Поставщик: {myCompony.name}</Text>
                            <Text style={styles.tableText10}>Адрес: {myCompony.addressUyr} </Text>
                        </View>
                        <View style={styles.tableColNo40}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>

                    <Text style={styles.tableText8}>Образец платежного поручения</Text>
                    <View style={styles.tableInvoce}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColNo50}>
                                <Text style={styles.tableCellLeft}>Бенефициар: {myCompony.name}</Text>
                                <Text style={styles.tableCellLeft}>ИИН/БИН {myCompony.bin}</Text>
                            </View>
                            <View style={styles.tableColNo25}>
                                <Text style={styles.tableCell}>ИИК</Text>
                                <Text style={styles.tableCell}>{myCompony.iban}</Text>
                            </View>
                            <View style={styles.tableColNo25}>
                                <Text style={styles.tableCell}>Кбе</Text>
                                <Text style={styles.tableCell}>{myCompony.kbe}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColNo50}>
                                <Text style={styles.tableCellLeft}>Банк бенефициара:</Text>
                                <Text style={styles.tableCellLeft}> {myCompony.nameBank}</Text>
                            </View>
                            <View style={styles.tableColNo25}>
                                <Text style={styles.tableCell}>БИК</Text>
                                <Text style={styles.tableCell}>{myCompony.bik}</Text>
                            </View>
                            <View style={styles.tableColNo25}>
                                <Text style={styles.tableCell}>Код назначения платежа</Text>
                                <Text style={styles.tableCell}>{application.knp}</Text>
                            </View>
                        </View>
                    </View>

                    <Text style={styles.tableTextClient}>Плательщик: {"БИН/ИИН " + compony.bin + ", " + compony.name} </Text>
                    <Text style={styles.tableTextAddress}>{compony.addressUyr}</Text>

                    <View style={styles.tableRowHeaderNumber}>
                        <View style={styles.tableCol50}>

                        </View>
                        <View style={styles.tableColNo10}>

                        </View>
                        <View style={styles.tableCol15}>
                        <Text style={styles.tableText8Center}>Номер документа</Text>
                        </View>
                        <View style={styles.tableCol15}>
                        <Text style={styles.tableText8Center}>Дата составления</Text>
                        </View>
                        <View style={styles.tableColNo10}>

                        </View>
                    </View>
                    <View style={styles.tableRowHeaderNumber}>
                        <View style={styles.tableCol50}>

                        </View>
                        <View style={styles.tableColNo10}>
                        <Text style={styles.tableText16CenterNoDecoration}>Счет</Text>
                        </View>
                        <View style={styles.tableColNo15}>
                        <Text style={styles.tableText14Center}>{application.invoceNumber}</Text>
                        </View>
                        <View style={styles.tableColNo15}>
                        <Text style={styles.tableText14Center}>{application.invoceDate}</Text>
                        </View>
                        <View style={styles.tableColNo10}>

                        </View>
                    </View>

                    <Text style={styles.tableTextContract}>{'Основание: ' + application.contract}</Text>
                    <TableContent application={application} myCompony={myCompony} />

                </View>
            </Page>
        </Document>
    );
}

export default InvocePDF;