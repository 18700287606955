import ConnectToServer from "../API/ConnectToServer";


//Action type
const SET_ALL_TASK = 'TASK/SET_ALL_TASK'
const SET_FILES_CLOSED_TASK = 'TASK/SET_FILES_CLOSED_TASK'
const SET_BACKDROP = 'TASK/SET_BACKDROP';
const UPLOAD_FILE_TASK = 'TASK/UPLOAD_FILE';
const UPLOAD_FILE_NEW_TASK = 'TASK/UPLOAD_FILE_NEW_TASK'
const CLEAR_FILE_NEW_TASK = 'TASK/CLEAR_FILE_NEW_TASK'
const ALL_FILE_NEW_TASK = 'TASK/ALL_FILE_NEW_TASK'

//Started props
let initialState = {
    allTask: [],
    newTask: [],
    closeTask: [],
    filesClosedTask: [],
    filesNewTask: [],
    allFilesNewTask: [],
    isFetching: false,
    backdrop: false,
    file: '',
    activeForm: false
};

//Reducers functions
const taskReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_TASK:
            return {
                ...state,
                allTask: action.allTask
            }

        case SET_BACKDROP:
            return {
                ...state,
                backdrop: action.data
            }

        case UPLOAD_FILE_TASK:
            return {
                ...state,
                file: action.file
            }

        case SET_FILES_CLOSED_TASK:
            return {
                ...state,
                filesClosedTask: action.filesClosedTask
            }

        case UPLOAD_FILE_NEW_TASK:
            return {
                ...state,
                filesNewTask: [...state.filesNewTask, ...action.file]
            }

        case CLEAR_FILE_NEW_TASK:
            return {
                ...state,
                filesNewTask: action.file
            }

        case ALL_FILE_NEW_TASK:
            return {
                ...state,
                allFilesNewTask: action.files
            }

        default:
            return state;
    }
}

//Action Creators functions
const setALLTask = (allTask) => {
    return { type: SET_ALL_TASK, allTask }
}

const setBackdrop = (data) => {
    return { type: SET_BACKDROP, data }
}

const setFile = (file) => {
    return { type: UPLOAD_FILE_TASK, file }
}

const setFileClosed = (filesClosedTask) => {
    return { type: SET_FILES_CLOSED_TASK, filesClosedTask }
}

const setFileNewTask = (file) => {
    return { type: UPLOAD_FILE_NEW_TASK, file }
}

const setFileNew = (files) => {
    return { type: ALL_FILE_NEW_TASK, files }
}


//Thunk functions

export const getALLTaskRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getALLTask(obj)
        if (data.success === 1) {
            dispatch(setALLTask(data.tasks))
            dispatch(getFilesClosedTaskRequest({ idTask: data.tasks.filter((item) => item.uploadFile).map((item) => item.id) }))
            dispatch(getFilesNewTaskRequest({ idTask: data.tasks.filter((item) => item.file).map((item) => item.id) }))
        } else {
            console.log(data);
            dispatch(setALLTask([]))
        }
    }
}

//Обновляем состояние Задачи
export const updateTaskRequest = (obj, idUser) => {
    return async (dispatch) => {
        const data = await ConnectToServer.updateTask(obj)
        if (data.success === 1) {
            dispatch(getALLTaskRequest(idUser))
        } else {
            console.log(data);

        }
    }
}
//Закрываем задачу
export const updateTaskCloseRequest = (obj, idUser) => {
    return async (dispatch) => {
        const data = await ConnectToServer.updateTaskClose(obj)
        if (data.success === 1) {
            dispatch(getALLTaskRequest(idUser))
        } else {
            console.log(data);
        }
    }
}
//Возврат задачи на доработку
export const updateTaskReopenRequest = (obj, idUser) => {
    return async (dispatch) => {
        const data = await ConnectToServer.updateTaskReopen(obj)
        if (data.success === 1) {
            dispatch(getALLTaskRequest(idUser))
        } else {
            console.log(data);
        }
    }
}

export const uploadFileTaskRequest = (file, task_id, record) => {
    return async (dispatch) => {
        dispatch(setBackdrop(true))
        const data = await ConnectToServer.putFileTask(file, task_id)
        if (data.success === 1) {
            dispatch(setFile(data.img))
            dispatch(setUploadCloseFileTaskRequest({ ...record, url: data.img }))
            dispatch(setBackdrop(false))
        } else {
            console.log('ERROR UPLOAD FILE');
            dispatch(setBackdrop(false))
        }
    }
}

export const uploadFileNewTaskRequest = (file, task_id) => {
    return async (dispatch) => {
        dispatch(setBackdrop(true))
        const data = await ConnectToServer.putFileTask(file, task_id)

        if (data.success === 1) {
            dispatch(setFileNewTask([data.img]))
            dispatch(setBackdrop(false))
        } else {
            console.log('ERROR UPLOAD FILE');
            dispatch(setBackdrop(false))
        }

    }
}

export const setUploadCloseFileTaskRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.setUploadCloseFileTask(obj)
        if (data.success === 1) {
            dispatch(getALLTaskRequest({ idUser: obj.idUser }))
        } else {
            console.log(data);
        }
    }
}

export const getFilesClosedTaskRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getFilesClosedTask(obj)
        if (data.success === 1) {
            dispatch(setFileClosed(data.filesClosedTask))
        } else {
            console.log(data);
        }
    }
}

export const getFilesNewTaskRequest = (obj) => {
    console.log(obj);
    
    return async (dispatch) => {
        const data = await ConnectToServer.getFilesNewTask(obj)
        if (data.success === 1) {
            dispatch(setFileNew(data.filesNewTask))
        } else {
            console.log(data);
        }
    }
}

export const addNewTaskRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.setNewTask(obj)
        if (data.success === 1) {
            obj.fileNewTask.length === 0 ?
                dispatch(getALLTaskRequest({ idUser: obj.idUser })) :
                dispatch(addNewTaskFileRequest({
                    fileNewTask: obj.fileNewTask,
                    idTask: data.task,
                    timeCreate: obj.timeCreate,
                    dataCreate: obj.dataCreate,
                    idUser: obj.idUser
                }))
        } else {
            console.log(data);
        }
    }
}

export const addNewTaskFileRequest = (obj) => {
    return async (dispatch) => {        
        const data = await ConnectToServer.setNewTaskFile(obj)
        if (data.success === 1) {
            dispatch(getALLTaskRequest({ idUser: obj.idUser }))
        } else {
            console.log(data);
        }
    }
}

export const deleteTaskRequest = (obj) => {
    return async (dispatch) => {        
        const data = await ConnectToServer.deleteTask(obj)
        if (data.success === 1) {
            dispatch(getALLTaskRequest({ idUser: obj.idUser }))
        } else {
            console.log(data);
        }
    }
}


export default taskReducer;