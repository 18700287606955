import ConnectToServer from "../API/ConnectToServer";


//Action type
const SET_COMPANIES = 'COMPANIES/SET_COMPANIES'

//Started props
let initialState = {
    companies: [],
    isFetching: false,
    activeForm: false
};

//Reducers functions
const companiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANIES:
            return {
                ...state,
                companies: action.companies
            }
    //     case TOGGLE_IS_FETCHING:
    //         return {
    //             ...state,
    //             isFetching: action.isFetching
    //         }
    //     case TOGGLE_BE_FORM:
    //         return {
    //             ...state,
    //             activeForm: action.activeForm
    //         }
    //     case SET_USER_PROFILE_INFO:
    //         return {
    //             ...state,
    //             userProfileInfo: action.userProfileInfo
    //         }
        default:
            return state;
    }
}

//Action Creators functions
const setCompanies = (companies) => {
    return { type: SET_COMPANIES, companies }
}

// const setUserProfileInfo = (userProfileInfo) => {
//     return { type: SET_USER_PROFILE_INFO, userProfileInfo }
// }

// const toggleIsFetching = (isFetching) => {
//     return { type: TOGGLE_IS_FETCHING, isFetching }
// }

// export const toggleBeForm = (activeForm) => {
//     return { type: TOGGLE_BE_FORM, activeForm }
// }

//Thunk functions
export const getCompaniesRequest = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.getALLCompanies(obj)
        if (data.success === 1) {
            dispatch(setCompanies(data.companies))            
        } else {
            console.log(data.message)
            dispatch(setCompanies([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const clientsUpdate = (obj, mycomp) => {
    return async (dispatch) => {
        const data = await ConnectToServer.setClients(obj)
        if (data.success === 1) {
            //console.log(data);
           dispatch(getCompaniesRequest({ mycompany: [mycomp] }))
        } else {
            console.log(data.message)
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const clientsDelete = (obj, mycomp) => {
    return async (dispatch) => {
        const data = await ConnectToServer.delClients(obj)
        if (data.success === 1) {
            //console.log(data);
           dispatch(getCompaniesRequest({ mycompany: [mycomp] }))
        } else {
            console.log(data.message)
            //dispatch(toggleIsFetching(false))
        }
    }
}
export default companiesReducer;