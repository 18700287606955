import React from 'react';
import { Avatar, Card, CardActions, CardContent, CardHeader, Chip, Collapse, Divider, Grid, IconButton, List, ListItem, ListItemText, Stack, Typography, styled } from "@mui/material";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import { useState } from 'react';
import DialogPayTaxConfirmation from '../../../Forms/DialogPayTaxConfirmation';
//import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
//import AddRoundedIcon from '@mui/icons-material/AddRounded';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const tableMonth = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
const tableRimCount = ["Ⅰ", "Ⅱ", "Ⅲ", "Ⅳ", "Ⅴ", "Ⅵ", "Ⅶ"]
const colorStatus = ["rgb(100 250 0 / 20%)", "rgb(0 240 255 / 20%)", "rgb(255 0 0 / 20%)"]
const textStatus = ["", "", "Отменена"]
const iconStatus = ["", "", <ClearRoundedIcon sx={{ width: 15, height: 15 }} color='error' />]

const Fno910min = ({ fno, updatefno }) => {
  const [expanded, setExpanded] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const allM = [...fno.m1, ...fno.m2, ...fno.m3, ...fno.m4, ...fno.m5, ...fno.m6]

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  const closeBTN = () => {
    setText("Отменить отправку?")
    const kv = fno.season === 0 ? " 1 полугодие " : " 2 полугодие "
    setTitle("ФНО 910.00 -" + kv + fno.year)
    setOpen(true)
  }

  const dialogOpen = (id) => {
    updatefno(fno.id)
    setOpen(false)
  }

  return (<>
    <Grid item xs={12}>
      <Card sx={{ mt: 1 }}>
        <CardHeader
          sx={{ backgroundColor: colorStatus[fno.status], borderRadius: 3 }}
          avatar={<Stack >
            <Avatar sx={{ width: 75, height: 75, borderRadius: 2, bgcolor: '#b39ddb' }} aria-label="recipe" > 910.00 </Avatar>
            <Stack  direction="row" alignItems="center">
              <Stack>
                {iconStatus[fno.status]}
              </Stack>
              <Stack sx={{ minWidth: 0 }}>
                <Typography variant="h6" sx={{ fontSize: '10px', fontWeight: '700' }} color="text.secondary">
                {textStatus[fno.status]}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          }

          title={<>
            <Chip
              sx={{ border: 0 }}
              icon={<CalendarMonthRoundedIcon />}
              color="info"
              variant="outlined"
              label={
                <Typography variant="h6" fontSize={14} color="text.secondary">
                  {fno.season === 0 ? "1 полугодие " : "2 полугодие "}{fno.year + "г."}
                </Typography>
              } />
            <Chip
              sx={{ border: 0 }}
              variant="outlined"
              color="success"
              icon={<AccountBalanceWalletOutlinedIcon />}
              label={
                <Typography variant="h6" fontSize={14} color="text.secondary">
                  Доход {fno.total} тг.
                </Typography>
              } />
          </>}
          subheader={
            <Chip sx={{ pl: 1, pr: 1, pb: 3, pt: 3 }} label={
              <>

                <Typography variant="body2" color="text.secondary">
                  101202(ИПН) - {fno.ipn101202} тг.
                </Typography>

                <Divider sx={{ borderColor: '#fcbb42' }} />

                <Typography variant="body2" color="text.secondary">
                  103101(Соц. Налог) - {fno.cn103101} тг.
                </Typography>

              </>
            } variant="outlined" />
          }
          action={fno.status === 2 ?
            <></> :
            <IconButton onClick={closeBTN} aria-label="settings">
              <CancelPresentationRoundedIcon color='error' />
            </IconButton>
          }
        />

        <CardActions onClick={handleExpandClick} disableSpacing>
          <Chip
            sx={{ border: 0 }}
            label={
              <Typography variant="h6" fontSize={18}>
                Данные для заполнения ФНО
              </Typography>
            }
            color="secondary"
            variant="outlined" />
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon color="secondary" />
          </ExpandMore>
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ pt: 0 }} >
            <Grid container>
              <Grid item xs={12}>
                <Typography textAlign="left" sx={{ p: 0.5, fontWeight: '700' }} variant="h6" fontSize={16} color="text.secondary">
                  <Chip
                    sx={{ p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                    variant="outlined"
                    size="small"
                    label="910.00.001" /> Доход {fno.total} тг.
                </Typography>
              </Grid>
              {allM.map((item, index) =>
                <Grid key={index} item xs={6}>
                  <List dense={true} sx={{ p: 0.3 }}>
                    <ListItem
                      sx={{ p: 0, pr: 0, border: 2, borderColor: '#b39ddb', borderRadius: 3 }}
                    >

                      <ListItemText
                        sx={{ p: 0.3, pr: 0, m: 0 }}
                        primaryTypographyProps={{ fontSize: '1rem' }}
                        primary={<Typography variant="body2" color="text.secondary">
                          {index + 1} мес.
                          <Chip
                            sx={{ bgcolor: '#6d6d6d', p: 0, borderRadius: 0, ml: 0.5, mr: 0.5, "> span": { pl: '4px', pr: '4px' } }}
                            color="success"
                            size="small"
                            label={tableRimCount[index]} />
                          {tableMonth[item.month - 1] + " " + item.year + "г."}
                        </Typography>
                        }
                        secondary={<>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              variant="outlined"
                              size="small"
                              label=".010" />  Оклад {item.wage} тг.
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              variant="outlined"
                              size="small"
                              label=".011" /> Соц. Отчисления - {item.co011} тг.
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              variant="outlined"
                              size="small"
                              label=".013" /> ОПВ - {item.opw013} тг.
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              variant="outlined"
                              size="small"
                              label=".014" /> ОПВР - {item.year === 2023 ? 0 : item.opwr014} тг.
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              variant="outlined"
                              size="small"
                              label=".015" /> ВОСМС - {item.wosms015} тг.
                          </Typography>

                        </>}
                      />
                    </ListItem>
                  </List>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
    <DialogPayTaxConfirmation open={open} handleClose={handleClose} titleText={title} text={text} idPay={1} sentPayConfirmation={dialogOpen} />
  </>
  )
}

export default Fno910min;