import React from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  TextareaAutosize,
  TextField,
} from "@mui/material";
//import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import UploadButtonNewTask from '../Dashboard/components/UploadButtonNewTask';
import { useCallback } from 'react';
import { DatePicker, LocalizationProvider, ruRU } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useState } from 'react';

const myHelper = {
  text: {
    required: "Заполните поле"
  }
};


const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const convertDate = (nowDate) => {
  const time = (nowDate.$H > 9 ? nowDate.$H : '0' + nowDate.$H) + ':' + (nowDate.$m > 9 ? nowDate.$m : '0' + nowDate.$m)
  const date = nowDate.$D + '.' + (nowDate.$M + 1 > 9 ? nowDate.$M + 1 : '0' + (nowDate.$M + 1)) + '.' + nowDate.$y
  return { time, date }
}

const FormNewTask = ({ openFormNewTask, handleCloseFormNewTask, uploadFileNewTaskRequest, giveTimeDate, addNewTaskRequest }) => {

  const allCompany = useSelector(state => state.myCompany.allCompany)
  const fileNewTask = useSelector(state => state.task.filesNewTask)
  const dispatch = useDispatch()
  const [dataStart, setDataStart] = useState(dayjs(new Date()))
  const [dataFinish, setDataFinish] = useState(dayjs(new Date()))

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  });

  const clearNewFile = useCallback((file) => {
    dispatch({ type: 'TASK/CLEAR_FILE_NEW_TASK', file })
  }, [dispatch])

  const handleOnSubmit = (evt) => {
    
    console.log(fileNewTask);

    clearNewFile([])

    addNewTaskRequest({
      idUserCreator: giveTimeDate().user, 
      idUser: giveTimeDate().user, 
      idCompany: evt.customer.id, 
      title: evt.title, 
      timeCreate:giveTimeDate().time, 
      dataCreate:giveTimeDate().date, 
      timeBegin:giveTimeDate().time, 
      dateBegin: convertDate(dataStart).date, 
      timeFinish:giveTimeDate().time, 
      dataFinish: convertDate(dataFinish).date,
      captions: evt.captions, 
      file: fileNewTask.length === 0? false : true, 
      fileNewTask,
      uploadFile: false,
    })
    handleCloseFormNewTask()
  };

  return <Dialog
    open={openFormNewTask}
    onClose={handleCloseFormNewTask}
    scroll='body'
    aria-labelledby="customized-dialog-title"
  >
    <DialogTitle sx={{ m: 0, p: '5px', textAlign: 'center', borderBottom: '2px dotted #07139e', fontSize: '18px' }} color="#07139e" id="customized-dialog-title">
      Новая задача
    </DialogTitle>
    <DialogContent >
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)} sx={{ mt: 2 }} >
        <Box sx={{ mb: 1 }} >
          <Grid container spacing={1} sx={{ textAlign: 'center' }}>
            <Grid item xs={6}>
              <LocalizationProvider adapterLocale='ru' dateAdapter={AdapterDayjs} localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DatePicker
                  label='Дата начала выполнения'
                  openTo="day"
                  defaultValue={dayjs(new Date())}
                  slotProps={{ textField: { size: 'small', fullWidth: true } }}
                  onChange={(evt) => setDataStart(evt)}
                  rules={{
                    required: true
                  }}
                  minDate={dayjs(new Date())}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider adapterLocale='ru' dateAdapter={AdapterDayjs} localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DatePicker
                  label='Дата закрытия задачи'
                  openTo="day"
                  defaultValue={dayjs(new Date())}
                  slotProps={{ textField: { size: 'small', fullWidth: true } }}
                  onChange={(evt) => setDataFinish(evt)}
                  rules={{
                    required: true
                  }}
                  minDate={dayjs(new Date())}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="customer"
                defaultValue={'' || null}
                rules={{
                  required: true
                }}
                fullWidth
                render={({ field: { ref, onChange, ...field }, fieldState: { error } }) => (
                  <Autocomplete
                    disablePortal
                    fullWidth
                    value={field.value}
                    onChange={(event, newValue) => {
                      if (typeof newValue === 'string') {
                        onChange(newValue)
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input  
                        onChange(newValue.inputValue)
                      } else {
                        onChange(newValue)
                      }
                    }}
                    id="customersId"
                    options={allCompany}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === 'string') {

                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.name;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                    renderInput={(params) =>
                      <TextField
                        fullWidth
                        {...params}

                        size="small"
                        label="Выберите клиента"
                        error={error !== undefined}
                        helperText={error ? myHelper.text[error.type] : ""}
                      />}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="title"
                defaultValue=""
                rules={{
                  required: true
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="text"
                    fullWidth
                    size="small"
                    label="Краткое описание задачи"
                    error={error !== undefined}
                    helperText={error ? myHelper.text[error.type] : ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>

              <Controller
                control={control}
                name="captions"
                defaultValue=""
                rules={{
                  required: false
                }}
                render={({ field, fieldState: { error } }) => (
                  <Textarea
                    {...field}
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Подробное описание задачи"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={1} sx={{ textAlign: 'left' }}>
            <Grid item xs={6}>
              <UploadButtonNewTask uploadFileNewTaskRequest={uploadFileNewTaskRequest} giveTimeDate={giveTimeDate} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 1 }} >
          <Grid container spacing={1} sx={{ textAlign: 'center' }}>

            <Grid item xs={6}>
              <Button size="large" color="secondary" variant="outlined" type="submit" >Создать</Button>
            </Grid>
            <Grid item xs={6}>
              <Button size="large" color="secondary" variant="outlined" type="button" onClick={handleCloseFormNewTask} >Отмена</Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DialogContent>
  </Dialog >
}

export default FormNewTask