import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, CircularProgress, Divider, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvocePDF from '../Components/PDF/Invoce/InvocePDF';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';

export default function PaymentsBord() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const applicationState = useSelector(state => state.invoce.application)
  const componyState = useSelector(state => state.myCompany)
  const myCompony = useSelector(state => state.invoce.myCompony)
  const today = new Date()

  const application = applicationState.content.map((appl) => ({ ...applicationState, content: [appl], allTotal: appl.total, number: today.getHours().toString() + today.getMinutes().toString(), date: today.getDate().toString() + '.' + (today.getMonth() + 1).toString() + '.' + today.getFullYear().toString(), }))
  const compony = {
    name: componyState.name,
    addressUyr: componyState.addressUyr,
  }

  const open = Boolean(anchorElUser);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  }

  const handleCloseExitMenu = () => {
    setAnchorElUser(null);
  }


  return (
    <Box sx={{ width: '100%', typography: 'body1', textAlign: 'center' }} >
      <Button onClick={handleOpenUserMenu} color='error' variant="outlined" sx={{ width: '200px', marginTop: '16px' }}>
        Скачать счет
      </Button>

      <Menu
        sx={{ mt: '45px', pt: '0', pb: '0' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleCloseExitMenu}
      >
        <MenuList sx={{ textDecoration: 'none', pt: '0', pb: '0' }} dense>
          <Suspense fallback={<div>Loading...</div>}>
            {open ?
              <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<InvocePDF application={application[0]} compony={compony} myCompony={myCompony} />} fileName={'Счет №' + application[0].number + '.pdf'}>
                {({ loading }) =>
                  loading ? (<CircularProgress color="primary" />
                  ) : (
                    <MenuItem sx={{ textDecoration: 'none' }} >
                      <Typography sx={{ textDecoration: 'none' }} textAlign="center" >Скачать Счет на 1 мес. - 5000тг.</Typography>
                    </MenuItem>
                  )
                }
              </PDFDownloadLink>
              : <></>}
          </Suspense>

          <Divider sx={{ borderColor: '#fcbb42' }} variant="middle" component="li" />

          <Suspense fallback={<div>Loading...</div>}>
            {open ?
              <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<InvocePDF application={application[1]} compony={compony} myCompony={myCompony} />} fileName={'Счет №' + application[0].number + '.pdf'}>
                {({ loading }) =>
                  loading ? (<CircularProgress color="primary" />
                  ) : (
                    <MenuItem sx={{ textDecoration: 'none' }} >
                      <Typography sx={{ textDecoration: 'none' }} textAlign="center" >Скачать Счет на 7 мес. - 30000тг.</Typography>
                    </MenuItem>
                  )
                }
              </PDFDownloadLink>
              : <></>}
          </Suspense>

          <Divider sx={{ borderColor: '#fcbb42', mt: '0', mb: '0' }} variant="middle" />

          <Suspense fallback={<div>Loading...</div>}>
            {open ?
              <PDFDownloadLink sx={{ textDecoration: 'none' }} document={<InvocePDF application={application[2]} compony={compony} myCompony={myCompony} />} fileName={'Счет №' + application[0].number + '.pdf'}>
                {({ loading }) =>
                  loading ? (<CircularProgress color="primary" />
                  ) : (
                    <MenuItem sx={{ textDecoration: 'none' }} >
                      <Typography sx={{ textDecoration: 'none' }} textAlign="center" >Скачать Счет на 14 мес. - 60000тг.</Typography>
                    </MenuItem>
                  )
                }
              </PDFDownloadLink>
              : <></>
            }
          </Suspense>
        </MenuList>
      </Menu>
      <Typography textAlign="center" >Данный раздел пока в разработке, но Вы можете скачать Счет на оплату Услуг.</Typography>
    </Box>
  );
}