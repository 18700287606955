import * as React from 'react';
import CompanyProfile from '../../../Forms/CompanyProfile';

const CompanyProfilePanel = ({ authUser, selectCompany, updateMyCompanyRequest, uploadSealImgRequest, uploadSignatureImgRequest }) => {
    
    const handleCloseForm = () => {

    }   

    return (<>
        <CompanyProfile 
        uploadSealImgRequest={uploadSealImgRequest} 
        uploadSignatureImgRequest={uploadSignatureImgRequest} 
        authUser={authUser} 
        myCompony={selectCompany} 
        handleCloseForm={handleCloseForm} 
        updateMyCompanyRequest={updateMyCompanyRequest} />
    </>)
}

export default CompanyProfilePanel