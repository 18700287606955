import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_GROUPS = 'EXPENSGROUPE/SET_GROUPS';



//Started props
let initialState = {
    groups: [],
    colors: ['#1829ed80', '#2e7d3291', '#02b2af80', '#f116a880', '#b39ddb', '#ffe57f', '#d32f2fb3'],
    isFetching: false,
    activeForm: false
};

//Reducers functions
const expensGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GROUPS:
            return {
                ...state,
                groups: action.groups
            }
        
        default:
            return state;
    }
}

//Action Creators functions
const setGroups = (groups) => {
    return { type: SET_GROUPS, groups }
}

//Thunk functions
export const getAllExpensGroupsRequst = (obj) => {
    return async (dispatch) => {
        //dispatch(toggleIsFetching(true));
        const data = await ConnectToServer.getAllExpensGroups(obj)
        if (data.success === 1) {
            dispatch(setGroups(data.groups))
        } else {
            console.log(data);
            dispatch(setGroups([]))
        }
    }
}

export const newExpensGroups = (obj, myCompany) => {
    return async (dispatch) => {
        const data = await ConnectToServer.addNewExpensGroups(obj)
        if (data.success === 1) {
            dispatch(getAllExpensGroupsRequst({mycompany: [0,myCompany]}))
        } else {
            console.log(data);
        }
    }
}

export const updateExpensGroups = (obj, myCompany) => {
    return async (dispatch) => {
        const data = await ConnectToServer.putExpensGroups(obj)
        if (data.success === 1) {
            dispatch(getAllExpensGroupsRequst({mycompany: [0,myCompany]}))
        } else {
            console.log(data);
        }
    }
}

export default expensGroupsReducer;