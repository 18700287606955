import React from 'react';
import { Avatar, Card, CardActions, CardContent, CardHeader, Chip, Collapse, Grid, IconButton, List, ListItem, ListItemText, Stack, Typography, styled } from "@mui/material";
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import { useState } from 'react';
import DialogPayTaxConfirmation from '../../../Forms/DialogPayTaxConfirmation';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
//import AddRoundedIcon from '@mui/icons-material/AddRounded';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const season = ['1 квартал', '2 квартал', '3 квартал', '4 квартал']
const colorStatus = ["rgb(100 250 0 / 20%)", "rgb(0 240 255 / 20%)", "rgb(255 0 0 / 20%)"]
const textStatus = ["", "", "Отменена"]
const iconStatus = ["", "", <ClearRoundedIcon sx={{ width: 15, height: 15 }} color='error' />]
let stroka = 2

const Fno913min = ({ fno, kgdCode, updateFNO913 }) => {
  const [expanded, setExpanded] = useState(false);
  const [text, setText] = useState("");
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);
  const tax = fno.pointsCompany.map((p)=>p.kgd).map((p)=>kgdCode.filter((tax)=>tax.code === p)[0])
  const handleClose = () => {
    setOpen(false);
  };

  const getChip = (text, index) => {
    return <Chip
      key={text+index}
      sx={{ p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#00000042", "> span": { pl: 0.3, pr: 0.3, } }}
      variant="outlined"
      size="small"
      label={text} />
  }

  const getNumber = (s) => {
    let str = s
    for (let i = 6; i > s.length; i--) {
      str = '0'+ str
    }
    return str
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const closeBTN = () => {
    setText("Отменить отправку?")
    setTitle("ФНО 913.00 - " + season[fno.season] + " " + fno.year + "г.")
    setOpen(true)
  }

  const dialogOpen = () => {
    updateFNO913({id: fno.id, status: 2, idFNO200: fno.idFNO200}, fno.myCompany)
    setOpen(false)
  }

  return (<>
    <Grid item xs={12}>
      <Card sx={{ mt: 1 }}>
        <CardHeader
          sx={{ backgroundColor: colorStatus[fno.status], borderRadius: 3 }}
          avatar={<Stack >
            <Avatar sx={{ width: 75, height: 75, borderRadius: 2, bgcolor: '#b39ddb' }} aria-label="recipe" > 913.00 </Avatar>
            <Stack  direction="row" alignItems="center">
              <Stack>
                {iconStatus[fno.status]}
              </Stack>
              <Stack sx={{ minWidth: 0 }}>
                <Typography variant="h6" sx={{ fontSize: '10px', fontWeight: '700' }} color="text.secondary">
                {textStatus[fno.status]}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          }

          title={<Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0}
          >
            <Chip
              sx={{ border: 0 }}
              icon={<CalendarMonthRoundedIcon />}
              color="info"
              variant="outlined"
              label={
                <Typography variant="h6" fontSize={14} color="text.secondary">
                  { season[fno.season] + " " + fno.year + "г."} 
                </Typography>
              } />
            <Chip
              sx={{ border: 0 }}
              variant="outlined"
              color="success"
              icon={<AccountBalanceWalletOutlinedIcon />}
              label={
                <Typography variant="h6" fontSize={14} color="text.secondary">
                  Доход {fno.gross.toLocaleString("ru-RU")} тг.
                </Typography>
              } />
              <Chip
              sx={{ border: 0 }}
              variant="outlined"
              color="warning"
              icon={<AccountBalanceRoundedIcon />}
              label={
                <Typography variant="h6" fontSize={14} color="text.secondary">
                  (ИПН) - {fno.totalTax.toLocaleString("ru-RU")} тг.
                </Typography>
              } />
          </Stack>}
          action={fno.status === 2 ?
            <></> :
            <IconButton onClick={closeBTN} aria-label="settings">
              <CancelPresentationRoundedIcon color='error' />
            </IconButton>
          }
        />

        <CardActions onClick={handleExpandClick} disableSpacing>
          <Chip
            sx={{ border: 0 }}
            label={
              <Typography variant="h6" fontSize={18}>
                Данные для заполнения ФНО
              </Typography>
            }
            color="secondary"
            variant="outlined" />
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon color="secondary" />
          </ExpandMore>
        </CardActions>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent sx={{ pt: 0, px: 0, mx: 1 }} >
            <Grid container>
              <Grid item xs={12}>
                <Typography textAlign="left" sx={{ p: 0.5, fontWeight: '700' }} variant="h6" fontSize={12} color="text.secondary">
                  {getChip("913.00.001", 123)} <span style={{ width: '150px', display: 'inline-flex' }} >Облагаемый доход </span>{[...fno.gross.toString()].map((s, index) => getChip(s, index))} тг.
                </Typography>
                <Typography textAlign="left" sx={{ p: 0.5, fontWeight: '700' }} variant="h6" fontSize={12} color="text.secondary">
                  {getChip("913.00.002", 123)} <span style={{ width: '150px', display: 'inline-flex' }} >Расходы по работникам </span>{[...fno.totalWages.toString()].map((s, index) => getChip(s, index))} тг.
                </Typography>
                <Typography textAlign="left" sx={{ p: 0.5, fontWeight: '700' }} variant="h6" fontSize={12} color="text.secondary">
                  {getChip("913.00.003", 123)} <span style={{ width: '150px', display: 'inline-flex' }} >Численность работников </span>{[...fno.totalWorkers.toString()].map((s, index) => getChip(s, index))}
                </Typography>
                <Typography textAlign="left" sx={{ p: 0.5, fontWeight: '700' }} variant="h6" fontSize={12} color="text.secondary">
                  {getChip("913.00.004", 123)} <span style={{ width: '150px', display: 'inline-flex' }} >КПН/ИПН </span>{[...fno.totalTax.toString()].map((s, index) => getChip(s, index))} тг.
                </Typography>
              </Grid>
              {fno.pointsCompany.map((item, index) =>
                <Grid key={index} item xs={12}>
                  <List dense={true} sx={{ p: 0.3 }}>
                    <ListItem
                      sx={{ p: 0, pr: 0, border: 2, borderColor: '#b39ddb', borderRadius: 3 }}
                    >

                      <ListItemText
                        sx={{ p: 0.3, pr: 0, m: 0 }}
                        // primaryTypographyProps={{ fontSize: '1rem' }}
                        // primary={
                        // }
                        secondary={<>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="A" /> <span style={{ width: '150px', display: 'inline-flex' }} >СТРОКА № - </span>{[...getNumber((stroka++).toString())].map((s, index) => getChip(s, index))}
                          </Typography>
                          <Typography variant="subtitle2" color="text.secondary" textAlign="center" >
                            Информация о месте осуществления деятельности
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="B" /> <span style={{ width: '150px', display: 'inline-flex' }} >код УГД - </span>{[...item.kgd].map((s, index) => getChip(s, index))}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="C" /> <span style={{ width: '150px', display: 'inline-flex' }} >город (аул, село) - </span>{getChip(item.city, 123)}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="D" /> <span style={{ width: '150px', display: 'inline-flex' }} >улица (микрорайон) - </span>{getChip(item.street, 123)}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="E" /> <span style={{ width: '150px', display: 'inline-flex' }} >номер дома - </span>{getChip(item.numberHouse, 123)}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="F" /> <span style={{ width: '150px', display: 'inline-flex' }} >номер квартиры - </span>{getChip(item.numberRoom, 123)}
                          </Typography>
                          <Typography variant="subtitle2" color="text.secondary" textAlign="center" >
                            Исчисленные налоги
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="G" /> <span style={{ width: '150px', display: 'inline-flex' }} >СТАВКА, % - </span>{[...(tax[index].personalTax.toString() + ',00')].map((s, index) => s === ',' ? s : getChip(s, index))}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="H" /> <span style={{ width: '150px', display: 'inline-flex' }} >Облагаемого дохода - </span>{[...(fno.personalTotal[index].toString())].map((s, index) => getChip(s, index))}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="I" /> <span style={{ width: '150px', display: 'inline-flex' }} >Удельный вес дохода, % - </span>{[...fno.personalRatio[index]].map((s, index) => s === '.' ? ',' : getChip(s, index))}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="J" /> <span style={{ width: '150px', display: 'inline-flex' }} >Доход минус ЗП - </span>{[...fno.personalTotalReduction[index].toString()].map((s, index) => getChip(s, index))}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="K" /> <span style={{ width: '150px', display: 'inline-flex' }} >Сумма КПН/ИПН - </span>{[...fno.personalTax[index].toString()].map((s, index) => getChip(s, index))}
                          </Typography>
                        </>}
                      />
                    </ListItem>

                    <ListItem
                      sx={{ mt: 0.6,p: 0, pr: 0, border: 2, borderColor: '#b39ddb', borderRadius: 3 }}
                    >

                      <ListItemText
                        sx={{ p: 0.3, pr: 0, m: 0 }}
                        // primaryTypographyProps={{ fontSize: '1rem' }}
                        // primary={
                        // }
                        secondary={<>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="A" /> <span style={{ width: '150px', display: 'inline-flex' }} >СТРОКА № - </span>{[...getNumber((stroka++).toString())].map((s, index) => getChip(s, index))}
                          </Typography>
                          <Typography variant="subtitle2" color="text.secondary" textAlign="center" >
                            Информация о месте осуществления деятельности
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="B" /> <span style={{ width: '150px', display: 'inline-flex' }} >код УГД - </span>{[...item.kgd].map((s, index) => getChip(s, index))}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="C" /> <span style={{ width: '150px', display: 'inline-flex' }} >город (аул, село) - </span>{getChip(item.city, 321)}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="D" /> <span style={{ width: '150px', display: 'inline-flex' }} >улица (микрорайон) - </span>{getChip(item.street, 321)}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="E" /> <span style={{ width: '150px', display: 'inline-flex' }} >номер дома - </span>{getChip(item.numberHouse, 321)}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="F" /> <span style={{ width: '150px', display: 'inline-flex' }} >номер квартиры - </span>{getChip(item.numberRoom, 321)}
                          </Typography>
                          <Typography variant="subtitle2" color="text.secondary" textAlign="center" >
                            Исчисленные налоги
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="G" /> <span style={{ width: '150px', display: 'inline-flex' }} >СТАВКА, % - </span>{[...(tax[index].companyTax.toString() + ',00')].map((s, index) => s === ',' ? s : getChip(s, index))}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="H" /> <span style={{ width: '150px', display: 'inline-flex' }} >Облагаемого дохода - </span>{[...(fno.companyTotal[index].toString())].map((s, index) => getChip(s, index))}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="I" /> <span style={{ width: '150px', display: 'inline-flex' }} >Удельный вес дохода, % - </span>{[...fno.componyRatio[index]].map((s, index) => s === '.' ? ',' : getChip(s, index))}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="J" /> <span style={{ width: '150px', display: 'inline-flex' }} >Доход минус ЗП - </span>{[...fno.companyTotalReduction[index].toString()].map((s, index) => getChip(s, index))}
                          </Typography>
                          <Typography variant="body2" fontSize={11} color="text.secondary">
                            <Chip
                              sx={{ minWidth: '16px', p: 0, m: 0.1, borderRadius: 0, fontSize: "11px", borderColor: "#000", "> span": { pl: 0.3, pr: 0.3, } }}
                              color="secondary"
                              size="small"
                              label="K" /> <span style={{ width: '150px', display: 'inline-flex' }} >Сумма КПН/ИПН - </span>{[...fno.companyTax[index].toString()].map((s, index) => getChip(s, index))}
                          </Typography>
                        </>}
                      />
                    </ListItem>
                  </List>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
    <DialogPayTaxConfirmation open={open} handleClose={handleClose} titleText={title} text={text} idPay={1} sentPayConfirmation={dialogOpen} />
  </>
  )
}

export default Fno913min;