import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  DataGrid,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useEffect } from 'react';


function CustomFooter({allTotal, orders, sumOrders, notPaid, notESF}) {
  return (
    <TableContainer sx={{ minHeight: 30, maxWidth: '900px' }} component={Paper}>
      <Table size="small" aria-label="spanning table">
        <TableBody>
          <TableRow sx={{borderTop: '1px solid', backgroundColor: 'lightgray'}}>
            <TableCell align="right" width={260} sx={{p: '6px 10px'}} >Итого</TableCell>
            <TableCell align="right" width={110} sx={{p: '6px 10px'}} >{orders.toLocaleString("ru-RU")}</TableCell>
            <TableCell align="right" width={120} sx={{p: '6px 10px'}} >{sumOrders.toLocaleString("ru-RU")}</TableCell>
            <TableCell align="right" width={110} sx={{color: 'red', p: '6px 10px'}} >{notPaid.toLocaleString("ru-RU")}</TableCell>
            <TableCell align="right" width={150} sx={{color: 'blue', p: '6px 10px'}} >{notESF.toLocaleString("ru-RU")}</TableCell>
            <TableCell align="right" width={160} sx={{color: 'green', p: '6px 10px'}} >{allTotal.toLocaleString("ru-RU")}</TableCell>
          </TableRow>          
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const CustomNoRowsOverlay = () => {
  return (
    <Typography variant="hoverline" gutterBottom display="block" color="#000000" sx={{ pt: "15px", textAlign: "center" }}>
      Нет записи
    </Typography>
  )
}



export default function TableDashboard({row, allApplications, allCompanies}) {

  const [rows, setRows] = React.useState(row);

  let allTotal = 0
  let orders = 0
  let sumOrders = 0
  let notPaid = 0
  let notESF = 0

  if (rows.length > 0) {
    allTotal = rows.reduce((a, b) => a + b.total, 0)
    orders = rows.reduce((a, b) => a + b.orders, 0)
    sumOrders = rows.reduce((a, b) => a + b.sumOrders, 0)
    notPaid = rows.reduce((a, b) => a + b.notPaid, 0)
    notESF = rows.reduce((a, b) => a + b.notESF, 0)
  }

  useEffect(()=>{
    setRows(row)
  }, [row, allApplications, allCompanies])
  
  const [rowModesModel, setRowModesModel] = React.useState({});
 

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    //setAllTotal(AllTotal + newTotal)    
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Наименование',
      width: 250,
      disableColumnMenu: true,
      hideSortIcons: true,
      editable: false,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'orders',
      headerName: 'Кол. Заявок',
      type: 'number',
      width: 110,
      editable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'right',
      hideSortIcons: true,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'sumOrders',
      headerName: 'Сумма Заявок',
      type: 'number',
      width: 120,
      align: 'right',
      headerAlign: 'center',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'notPaid',
      headerName: 'Не оплачено',
      type: 'number',
      width: 110,
      align: 'right',
      headerAlign: 'center',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'notESF',
      headerName: 'Не выписали ЭСФ',
      type: 'number',
      width: 150,
      align: 'right',
      headerAlign: 'center',
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'total',
      headerName: 'Сумма поступлений',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      width:  160,
      editable: false,
      disableColumnMenu: true,
      hideSortIcons: true,
      align: 'right',
      type: 'number',

    },    
  ];

  return (
    <Box
      sx={{
        
        maxWidth: '900px',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'primary',
        },
        '& .super-app-theme--header': {
          borderBottom: 2,
          borderRadius: 0
        },
      }}
    >
      <DataGrid        
        sx={{border: 0}}
        rows={rows}
        columns={columns}
        
        onProcessRowUpdateError={(error) => console.log(error)}
        columnVisibilityModel={{
          // idCont: false,
          // plu: false,
          // unit: false,
        }}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        density="compact"
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}

        slots={{
         
          footer: CustomFooter,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
          footer: { allTotal, orders, sumOrders, notPaid, notESF },
        }}
      />

    </Box>
  );
}