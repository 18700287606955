import React from 'react';
import { useForm, Controller } from "react-hook-form";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CardHeader,
  Checkbox,
  CircularProgress,
  //Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  styled,
} from "@mui/material"
import { useSelector } from 'react-redux'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'

const myHelper = {
  email: {
    required: "Email is Required",
    pattern: "Invalid Email Address"
  },
  text: {
    required: "Заполните поле"
  }
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const CompanyProfile = ({ authUser, myCompony, handleCloseForm, updateMyCompanyRequest, uploadSealImgRequest, uploadSignatureImgRequest }) => {

  const [checked, setChecked] = React.useState(false);
  //const [checkedInvoce, setCheckedInvoce] = React.useState(false);

  const backdrop = useSelector(state => state.myCompany.backdrop)
  const mzpSattings = useSelector(state => state.globalSattings.mzp)

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // const handleChangeInvoce = (event) => {
  //   setCheckedInvoce(event.target.checked);
  // };

  const { control, handleSubmit } = useForm({
    reValidateMode: "onBlur"
  })

  const handleOnSubmit = (evt) => {
    let kbe = 19
    const type = parseInt(evt.type)
    if (type !== 0) {
      kbe = 17
    }
    updateMyCompanyRequest({ ...evt, kbe, type, idUser: authUser.id, userKey: authUser.userKey, id: myCompony.id, uploadCompany: authUser.mycompany, seal: myCompony.seal, signature: myCompony.signature })
  }

  const uploadSealImg = (e) => {
    if (e.target.files.length) { uploadSealImgRequest(e.target.files[0]) }
  }

  const uploadSignatureImg = (e) => {
    if (e.target.files.length) { uploadSignatureImgRequest(e.target.files[0]) }
  }

  return (
    <div className="App">
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="type"
              defaultValue={myCompony.type}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="ИП"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="ТОО"
                  />
                </RadioGroup>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="rejym"
              defaultValue={myCompony.rejym}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value={0}
                    control={<Radio />}
                    label="Упрощенный режим"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Общеустановленный режим"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Розничный налог режим"
                  />
                </RadioGroup>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="name"
              defaultValue={myCompony.name}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  size='small'
                  label="Наименование компании"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="bin"
              defaultValue={myCompony.bin}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="ИИН/БИН"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="addressUyr"
              defaultValue={myCompony.addressUyr}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  size='small'
                  label="Юр.Адрес"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="iban"
              defaultValue={myCompony.iban}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  size='small'
                  label="Расчетный счет"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="bik"
              defaultValue={myCompony.bik}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  size='small'
                  label="БИК"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="nameBank"
              defaultValue={myCompony.nameBank}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  fullWidth
                  size='small'
                  label="Банк"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}

              name="email"
              defaultValue={myCompony.email}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="email"
                  size='small'
                  fullWidth
                  label="Email"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="phone"
              defaultValue={myCompony.phone}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Телефон"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="director"
              defaultValue={myCompony.director}
              rules={{
                required: true
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Фамилия и инициалы директора"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="buh"
              defaultValue={myCompony.buh}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Фамилия и инициалы бухгалтера(если есть)"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'left' }}>
            <Controller
              control={control}
              name="mzp"
              defaultValue={myCompony.mzp}
              render={({ field }) => (
                <FormControlLabel control={<Checkbox size="small" checked={field.value} />} label={"Установить Заработную плату по ставке МЗП " + mzpSattings + "тг."} {...field} />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="signatory"
              defaultValue={myCompony.signatory}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Ф.И.О.подписанта док.(если есть)"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              control={control}
              name="sposition"
              defaultValue={myCompony.sposition}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  type="text"
                  size='small'
                  fullWidth
                  label="Должность подписанта док.(если есть)"
                  error={error !== undefined}
                  helperText={error ? myHelper.text[error.type] : ""}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <CardHeader
              sx={{ p: "3px", textAlign: 'left' }}
              avatar={<Avatar src={myCompony.seal.length === 0 ? null : myCompony.seal} aria-label="recipe"> <ImageNotSupportedIcon /> </Avatar>}
              title={
                <Button component="label" variant="contained" size="small" startIcon={<CloudUploadIcon />}>
                  Загрузить печать
                  <VisuallyHiddenInput onChange={uploadSealImg} type="file" />
                </Button>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <CardHeader
              sx={{ p: "3px", textAlign: 'left' }}
              avatar={<Avatar src={myCompony.signature.length === 0 ? null : myCompony.signature} aria-label="recipe" variant="rounded" > <ImageNotSupportedIcon /> </Avatar>}
              title={
                <Button component="label" variant="contained" size="small" startIcon={<CloudUploadIcon />}>
                  Загрузить подпись
                  <VisuallyHiddenInput onChange={uploadSignatureImg} type="file" />
                </Button>
              }
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'left' }} container spacing={2} >
            <Typography variant="hoverline" gutterBottom display="block" color="#07139e" sx={{ textAlign: 'left' }}>
              Установить номер первого документа:
            </Typography>

            {/* <Grid item xs={12} sx={{ textAlign: 'left' }}>
              <Checkbox
                checked={checkedInvoce}
                onChange={handleChangeInvoce}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Controller
                control={control}
                name="nextNumberInvoce"
                defaultValue={myCompony.nextNumberInvoce}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="number"
                    disabled={!checkedInvoce}
                    size='small'
                    label="№ Заявка/Счет"
                    sx={{ width: '200px' }}
                    error={error !== undefined}
                    helperText={error ? myHelper.text[error.type] : ""}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} sx={{ textAlign: 'left' }}>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Controller
                control={control}
                name="nextCountDoc"
                defaultValue={myCompony.nextCountDoc}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    type="number"
                    disabled={!checked}
                    size='small'
                    label="№ ЭСФ/АВР/Накладная"
                    sx={{ width: '200px' }}
                    error={error !== undefined}
                    helperText={error ? myHelper.text[error.type] : ""}
                  />
                )}
              />
            </Grid>
            
          </Grid>

          <Grid item xs={authUser.account !== 2 ? 6 : 12}>
            <Button type="submit" variant="outlined">Сохранить</Button>
          </Grid>
          <Grid item xs={6}>
            {authUser.account !== 2 ? <Button onClick={handleCloseForm} type="button" variant="outlined">Отмена</Button> : null}
          </Grid>

        </Grid>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}

export default CompanyProfile