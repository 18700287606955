import { StyleSheet, Text, View } from '@react-pdf/renderer';
import * as React from 'react';



const AVRTableContent = ({ avr, sumWithContentAmount, sumWithContentSumm }) => {

    const styles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableSum: {
            display: "table",
            width: "auto",
        },
        tableRowSmall: {
            flexDirection: "row",
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row",
        },
        tableCol5: {
            width: "5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol2626: {
            width: "26.26%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol965: {
            width: "9.65%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol1946: {
            width: "19.46%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol63: {
            width: "6.3%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol95: {
            width: "9.5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol1133: {
            width: "11.33%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol125: {
            width: "12.5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,   
        },
        tableCol3333: {
            width: "33.33%",
        },
        tableCol6667: {
            width: "66.67%",
        },
        tableCol100: {
            width: "100%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            height: '32px'
        },
        tableColHight: {
            width: "100%",
            height: '32px'
        },
        tableCol285: {
            width: "28.5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol34: {
            width: "34%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol375: {
            width: "37.5%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
        },
        tableCell: {
            width: '100%',
            textAlign: 'center',
            fontSize: 10, 
            verticalAlign: 'sub',  
            marginTop: 'auto',
            marginBottom: '2px'
        },
        tableCellName: {
            width: '100%',
            textAlign: 'left',
            fontSize: 10, 
            verticalAlign: 'sub', 
            marginTop: 'auto',
            marginBottom: '2px'
        },
        tableCellSummRight: {
            width: '100%',
            textAlign: 'right',
            fontSize: 10, 
            verticalAlign: 'sub',  
            marginTop: 'auto',
            marginBottom: '2px',
            paddingRight: '2px'
        },
        tableCellSumm:{
            width: '100%',
            textAlign: 'right',
            fontSize: 10, 
            verticalAlign: 'sub',  
            marginTop: 'auto',
            marginBottom: '3px',
            paddingRight: '2px'
        },
        tableCellTop: {
            width: '100%',
            textAlign: 'center',
            fontSize: 10, 
            verticalAlign: 'sub',           
            paddingBottom: '2px',
            margin: 'auto',
        },
        tableColLeftBorder: {
            borderLeftWidth: 1
        }
    })

    return (
        <View>
            <View style={styles.table}>
                {/* шапка таблицы */}
                <View style={styles.tableRow}>
                    <View style={styles.tableCol5}>
                        <Text style={styles.tableCellTop}>Номер по порядку</Text>
                    </View>
                    <View style={styles.tableCol2626}>
                        <Text style={styles.tableCellTop}>Наименование работ (услуг) (в разрезе их подвидов в соответствии с технической спецификацией, заданием, графиком выполнения работ (услуг) при их наличии)</Text>
                    </View>
                    <View style={styles.tableCol965}>
                        <Text style={styles.tableCellTop}>Дата выполнения работ (оказания услуг)</Text>
                    </View>
                    <View style={styles.tableCol1946}>
                        <Text style={styles.tableCellTop}>Сведения об отчете о научных исследованиях, маркетинговых, консультационных и прочих услугах (дата, номер, количество страниц) (при их наличии)</Text>
                    </View>
                    <View style={styles.tableCol63}>
                        <Text style={styles.tableCellTop}>Единица измерения</Text>
                    </View>
                    <View style={styles.tableCol3333}>
                        <View style={styles.tableCol100} >
                            <Text style={styles.tableCellTop}>Выполнено работ (оказано услуг)</Text>
                        </View>
                        <View style={styles.tableRowSmall}>
                            <View style={styles.tableCol285}>
                                <View style={styles.tableColHight} >
                                    <Text style={styles.tableCellTop}>количество</Text>
                                </View>
                            </View>
                            <View style={styles.tableCol34}>
                                <View style={styles.tableColHight} >
                                    <Text style={styles.tableCellTop}>цена за единицу</Text>
                                </View>
                            </View>
                            <View style={styles.tableCol375}>
                                <View style={styles.tableColHight} >
                                    <Text style={styles.tableCellTop}>стоимость</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.tableRow}>
                    <View style={styles.tableCol5}>
                        <Text style={styles.tableCell}>1</Text>
                    </View>
                    <View style={styles.tableCol2626}>
                        <Text style={styles.tableCell}>2</Text>
                    </View>
                    <View style={styles.tableCol965}>
                        <Text style={styles.tableCell}>3</Text>
                    </View>
                    <View style={styles.tableCol1946}>
                        <Text style={styles.tableCell}>4</Text>
                    </View>
                    <View style={styles.tableCol63}>
                        <Text style={styles.tableCell}>5</Text>
                    </View>
                    <View style={styles.tableCol95}>
                        <Text style={styles.tableCell}>6</Text>
                    </View>
                    <View style={styles.tableCol1133}>
                        <Text style={styles.tableCell}>7</Text>
                    </View>
                    <View style={styles.tableCol125}>
                        <Text style={styles.tableCell}>8</Text>
                    </View>
                </View>
                
                {/* Данные таблицы */}
                {avr.map((cont, index) =>
                    <View key={cont.id} style={styles.tableRow}>
                        <View style={styles.tableCol5}>
                            <Text style={styles.tableCell}>{index + 1}</Text>
                        </View>
                        <View style={styles.tableCol2626}>
                            <Text style={styles.tableCellName}>{cont.name}</Text>
                        </View>
                        <View style={styles.tableCol965}>
                        </View>
                        <View style={styles.tableCol1946}>
                        </View>
                        <View style={styles.tableCol63}>
                            <Text style={styles.tableCell}>{cont.unit}</Text>
                        </View>
                        <View style={styles.tableCol95}>
                            <Text style={styles.tableCellSummRight}>{cont.amount}</Text>
                        </View>
                        <View style={styles.tableCol1133}>
                            <Text style={styles.tableCellSummRight}>{cont.price},00</Text>
                        </View>
                        <View style={styles.tableCol125}>
                            <Text style={styles.tableCellSummRight}>{cont.total},00</Text>
                        </View>
                    </View>
                )}
            </View>
            {/* итого */}
            <View style={styles.tableSum}>                
                <View style={styles.tableRow}>
                    <View style={styles.tableCol6667}>
                        <Text style={styles.tableCellSumm}>Итого</Text>
                    </View>
                    <View style={styles.tableCol95}>
                        <View style={styles.tableColLeftBorder} >
                        <Text style={styles.tableCellSummRight}>{sumWithContentAmount}</Text>
                        </View>
                    </View>                    
                    <View style={styles.tableCol1133}>
                        <Text style={styles.tableCell}>х</Text>
                    </View>
                    <View style={styles.tableCol125}>
                        <Text style={styles.tableCellSummRight}>{sumWithContentSumm},00</Text>
                    </View>                    
                </View>
            </View>
        </View>
    );
}

export default AVRTableContent;