import * as React from 'react';
import { CardMedia, Typography } from '@mui/material'

const IMGMessage = ({ message }) => {
    return (
        <a href={message.src} target="_blank" rel="noreferrer" download>
            <CardMedia
                sx={{ height: 64, width: 64, borderRadius: 2 }}
                image={message.src}
                title="img"
            />
            <Typography color="text.secondary" variant="body2" >
                {message.name}
            </Typography>
        </a>
    );
}

export default IMGMessage;