import * as React from 'react';
import TableUnitsTovarUsluga from '../../../Components/TableUnitsTovarUsluga';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import { useEffect } from 'react';

const ProductPanel = ({ contentsUpdate, contentsDelete, myCompany }) => {
    const [dataPST, setDataProductServiceForm] = useState([]);
    const [delContents, setDelContent] = useState([]);
    const [buttonHiden, setButtonHiden] = useState(false)
    const newContents = dataPST.filter((cont) => cont.idContent === 0 && cont.update).map((c)=>({...c, idMyCompany: myCompany.id}))
    const updateContents = dataPST.filter((cont) => cont.idContent !== 0 && cont.update).map((c)=>({...c, idMyCompany: myCompany.id}))
    const [reloadTable, setreloadTable] = React.useState(false)

    useEffect(()=>{
        if (updateContents.length !== 0 || newContents.length !== 0) {
            setButtonHiden(true)
        }
        if (delContents.length !== 0) {
            setButtonHiden(true)
        }
        
    }, [setButtonHiden, dataPST, delContents, updateContents.length, newContents.length, delContents.length])

    const setDelContents = (cont) => {
        delContents.push(cont.id)
        setDelContent(delContents)
    }

    const submitProductServiceForm = () => {        

        if (updateContents.length !== 0 || newContents.length !== 0) {
            contentsUpdate({ update: updateContents, new: newContents }, myCompany.id)
        }
        if (delContents.length !== 0) {
            contentsDelete({ delContents: delContents }, myCompany.id);
        }
        setButtonHiden(false)
        setDataProductServiceForm([])
        setDelContent([])
    }

    const cancelСhanges = () =>{
        setButtonHiden(false)
        setDataProductServiceForm([])
        setDelContent([])
        reloadTable?setreloadTable(!reloadTable):setreloadTable(!reloadTable)
    }

    return (
        <Box
            sx={{
                height: '85vh',
                width: '100%',
                mt: '5px',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                flex: 1
            }}
        >
            <Button onClick={submitProductServiceForm} sx={{ml: 1}} variant="outlined" disabled={!buttonHiden}  startIcon={<SaveAsOutlinedIcon />} >Сохранить</Button>
            <Button onClick={cancelСhanges} sx={{ml: 1}} variant="outlined" disabled={!buttonHiden}  startIcon={<SaveAsOutlinedIcon />} >Отменить изменения</Button>
            <Box sx={{ height: '100% !important', width: "100% !important", mt: '5px' }}>
                <TableUnitsTovarUsluga setDataProductServiceForm={setDataProductServiceForm} setDelContents={setDelContents} reloadTable={reloadTable} />
            </Box>
        </Box>
    )
}

export default ProductPanel