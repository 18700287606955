import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
//import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
//import AssignmentReturnedRoundedIcon from '@mui/icons-material/AssignmentReturnedRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import AllOutRoundedIcon from '@mui/icons-material/AllOutRounded';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import ShoppingBasketRoundedIcon from '@mui/icons-material/ShoppingBasketRounded';
import { Badge, Paper, styled } from '@mui/material';
import { useSelector } from 'react-redux';

const TabBarButton = styled(BottomNavigationAction)({
    color: '#bdbdbd',
    "&.Mui-selected": { color: "#07139e" },

});

export default function NavBar({ handleChange, value }) {
    const chats = useSelector(state => state.chat.chats_id)
    const authUser = useSelector(state => state.auth.authUser)
    const masterBasket = useSelector(state => state.shop.masterBasket)

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, borderRadius: 16 }} elevation={3}>
            <BottomNavigation sx={{ border: '1px', borderColor: '#fff', color: "#07139ec9" }} value={value} onChange={handleChange}>
                { authUser.shopOrders? <TabBarButton
                    label="Заявки"
                    value="orders"
                    icon={<Badge color="primary" variant="dot" invisible={!masterBasket.length}> <ShoppingBasketRoundedIcon /> </Badge>}
                    color='secondary'
                    sx={{ borderTopLeftRadius: 16, borderBottomLeftRadius: 16 }}
                /> : null}
                <TabBarButton
                    label="Операции"
                    value="recents"
                    icon={<AssignmentRoundedIcon />}
                    color='secondary'
                    sx={{ borderTopLeftRadius: 16, borderBottomLeftRadius: 16 }}
                />
                <TabBarButton
                    label="Расходы"
                    value="expenses"
                    icon={<AllOutRoundedIcon />}

                />
                { chats.length > 0 ? <TabBarButton
                    label="Чат"
                    value="chat"
                    icon={<ForumOutlinedIcon />}

                />: null}
                {/* <TabBarButton
                    label="Архив"
                    value="archive"
                    icon={<AssignmentReturnedRoundedIcon />}

                /> */}
                {/* <TabBarButton
                    label="Удаленные"
                    value="delete"
                    icon={<DeleteSweepRoundedIcon />}

                /> */}
                {/* <BottomNavigationAction
                    label="Nearby"
                    value="nearby"
                    icon={<LocationOnIcon />}
                /> */}
                <TabBarButton
                    label="Отчеты"
                    value="folder"
                    sx={{ borderTopRightRadius: 16, borderBottomRightRadius: 16 }}
                    icon={<AssessmentRoundedIcon />}
                />
            </BottomNavigation>
        </Paper>
    );
}