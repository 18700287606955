import * as React from 'react';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import { useEffect } from 'react';
import TableUnitsClients from '../../../Components/TableUnitsClients';

const ClientsPanel = ({ clientsUpdate, clientsDelete, myCompany }) => {
    const [dataClients, setDataClientsForm] = React.useState([]);
    const [delClients, setDelClient] = React.useState([]);
    const [buttonHiden, setButtonHiden] = useState(false)
    const newClients = dataClients.filter((cont) => cont.idClient === 0 && cont.update).map((c)=>({...c, idMyCompany: myCompany.id}))
    const updateClients = dataClients.filter((cont) => cont.idClient !== 0 && cont.update).map((c)=>({...c, idMyCompany: myCompany.id}))
    const [reloadTable, setreloadTable] = React.useState(false)

    useEffect(()=>{
        if (updateClients.length !== 0 || newClients.length !== 0) {
            setButtonHiden(true)
        }
        if (delClients.length !== 0) {
            setButtonHiden(true)
        }
        
    }, [setButtonHiden, dataClients, delClients, delClients.length, updateClients.length, newClients.length])

    const setDelClients = (client) => {
        delClients.push(client.id)
        setDelClient(delClients)
    }

    const submitProductServiceForm = () => {        

        if (updateClients.length !== 0 || newClients.length !== 0) {
            clientsUpdate({ update: updateClients, new: newClients }, myCompany.id)
        }
        if (delClients.length !== 0) {
            clientsDelete({ delClients: delClients }, myCompany.id);
        }
        setButtonHiden(false)
        setDataClientsForm([])
        setDelClient([])
    }

    const cancelСhanges = () =>{
        setButtonHiden(false)
        setDataClientsForm([])
        setDelClient([])
        reloadTable?setreloadTable(!reloadTable):setreloadTable(!reloadTable)
    }

    return (
        <Box
            sx={{
                height: '85vh',
                width: '100%',
                mt: '5px',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                flex: 1
            }}
        >
            <Button onClick={submitProductServiceForm} sx={{ml: 1}} variant="outlined" disabled={!buttonHiden}  startIcon={<SaveAsOutlinedIcon />} >Сохранить</Button>
            <Button onClick={cancelСhanges} sx={{ml: 1}} variant="outlined" disabled={!buttonHiden}  startIcon={<SaveAsOutlinedIcon />} >Отменить изменения</Button>
            <Box sx={{ height: '100% !important', width: "100% !important", mt: '5px' }}>
                <TableUnitsClients setDataClientsForm={setDataClientsForm} setDelClients={setDelClients} reloadTable={reloadTable} />
            </Box>
        </Box>
    )
}

export default ClientsPanel