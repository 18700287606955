import * as React from 'react';
import PropTypes from 'prop-types';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import AllOutRoundedIcon from '@mui/icons-material/AllOutRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
    Collapse,
    NativeSelect,
    Avatar,
    Drawer,
    Box,
    Divider,
    FormControl,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Typography,
    Badge,
    Stack,
} from '@mui/material';
import logo from '../../Img/logo512.png'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const drawerWidth = 240;

function SideBar({ window1, mobileOpen, handleDrawerToggle, setContent, words, myCompany, authUser, downloadData }) {

    const [open, setOpen] = React.useState(false);
    // const [openUserMenu, setOpenUserMenu] = React.useState(false);
    // const [openFNOMenu, setOpenFNOMenu] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedCompany, setSelectedCompany] = useState(myCompany.id);
    const newTask = useSelector(state => state.task.newTask)
    
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index)
        setContent(index)
    };

    const navigate = useNavigate();

    const handleExitUser = () => {
        localStorage.clear("finupkzuser")
        navigate("/")
        window.location.reload()
    };


    const handleClick = () => {
        setOpen(!open);
    };

    // const handleClickFNOMenu = (event) => {
    //     setOpenFNOMenu(!openFNOMenu);
    // };

    // const handleClickUserMenu = (event) => {
    //     setOpenUserMenu(!openUserMenu);
    // };

    const selectCompony = (evt) => {
        setSelectedCompany(evt.target.value);
        downloadData([parseInt(evt.target.value)])
        setSelectedIndex(0)
        setContent(0)
    }

    const drawer = (<>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '48px', p: 1, boxShadow: 1 }}>
            <Avatar variant="rounded" src={logo} sx={{ width: 48, height: 48 }} />
            <Typography
                variant="h6"
                noWrap
                color='primary'
                sx={{
                    ml: 1,
                    display: { md: 'flex' },
                    fontWeight: 700,
                    letterSpacing: '.1rem',
                    textDecoration: 'none',
                    lineHeight: '1.3',
                    fontSize: '0.8rem'
                }}
            >
                online
                <br />
                бухгалтер
            </Typography>
        </Box>

        <div style={{ padding: '16px', paddingTop: '4px' }}>
            <Box >
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={0}
                >
                    <IconButton variant="outlined" edge="end" color='info' size="large" >
                        <Badge badgeContent={4} color="primary">
                            <AssignmentIndOutlinedIcon color="secondary" />
                        </Badge>
                    </IconButton>
                    <IconButton variant="outlined" edge="end" color='info' size="large" >
                        <Badge badgeContent={4} color="primary">
                            <CalendarMonthOutlinedIcon color="secondary" />
                        </Badge>
                    </IconButton>
                    <IconButton variant="outlined" edge="end" color='info' size="large" >
                        <Badge badgeContent={4} color="primary">
                            <MailOutlineRoundedIcon color="secondary" />
                        </Badge>
                    </IconButton>
                    <IconButton variant="outlined" edge="end" color='info' size="large" >
                        <Badge badgeContent={newTask.filter((item)=>item.state===0).length > 0 ? newTask.filter((item)=>item.state===0).length: 0} color="primary">
                            <AssignmentOutlinedIcon color="secondary" />
                        </Badge>
                    </IconButton>
                </Stack>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton variant="soft" color="primary" size="small">
                    <BusinessRoundedIcon />
                </IconButton>
                {myCompany.allCompany.length > 1 ?
                    <FormControl fullWidth>
                        <NativeSelect
                            value={selectedCompany}
                            onChange={selectCompony}
                            sx={{ border: 0 }}
                            disableUnderline
                        >
                            {myCompany.allCompany.map((сom) => <option key={сom.id} value={сom.id}>{сom.name}</option>)}
                        </NativeSelect>
                    </FormControl>

                    // <FormControl size="small" fullWidth>
                    //     <InputLabel id="demo-select-small-label">Компания</InputLabel>
                    //     <Select
                    //         labelId="demo-select-small-label"
                    //         id="demo-select-small"
                    //         value={0}
                    //         label="Период"
                    //     >
                    //         <MenuItem value={0}>1 полугодие</MenuItem>
                    //         <MenuItem value={1}>2 полугодие</MenuItem>
                    //     </Select>
                    // </FormControl> :
                    : <Typography sx={{ fontWeight: '600' }} color='secondary' level="title-lg">{myCompany.name}</Typography>}
            </Box>



            <Divider sx={{ borderColor: '#fcbb42' }} />
            <List component="nav" sx={{ mt: '25px' }}>
                <ListItem disablePadding>
                    <ListItemButton
                        selected={selectedIndex === 0}
                        onClick={(event) => handleListItemClick(event, 0)}
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}>
                        <ListItemIcon sx={{ minWidth: '28px' }}>
                            <DashboardRoundedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.dashboard}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}
                    >
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <AssignmentRoundedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.orders}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}
                        selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2)}
                    >
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <AllOutRoundedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.expenses}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}
                        selected={selectedIndex === 4}
                        onClick={(event) => handleListItemClick(event, 4)}
                    >
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <ForumOutlinedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.chats}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}
                        selected={selectedIndex === 5}
                        onClick={(event) => handleListItemClick(event, 5)}
                    >
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <FormatListBulletedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.taskManager}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}
                        selected={selectedIndex === 21}
                        onClick={(event) => handleListItemClick(event, 21)}
                    >
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <GroupRoundedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.users}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                {/* <ListItem disablePadding>
                    <ListItemButton
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}
                        onClick={handleClickUserMenu}>
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <GroupRoundedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.users}
                            </Typography>
                        </ListItemText>
                        {openUserMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openUserMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 21}
                            onClick={(event) => handleListItemClick(event, 21)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.users}
                            </Typography>
                        </ListItemButton> */}
                {/* <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 22}
                            onClick={(event) => handleListItemClick(event, 22)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.myProfile}
                            </Typography>
                        </ListItemButton> */}
                {/* </List>
                </Collapse> */}
                <ListItem disablePadding>
                    <ListItemButton sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }} onClick={handleClick}>
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <ArticleRoundedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.listSettings}
                            </Typography>
                        </ListItemText>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 31}
                            onClick={(event) => handleListItemClick(event, 31)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.company}
                            </Typography>
                        </ListItemButton>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 32}
                            onClick={(event) => handleListItemClick(event, 32)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.goods}
                            </Typography>
                        </ListItemButton>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 33}
                            onClick={(event) => handleListItemClick(event, 33)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.clients}
                            </Typography>
                        </ListItemButton>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 34}
                            onClick={(event) => handleListItemClick(event, 34)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.clientAddress}
                            </Typography>
                        </ListItemButton>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 35}
                            onClick={(event) => handleListItemClick(event, 35)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.points}
                            </Typography>
                        </ListItemButton>
                    </List>
                </Collapse>
                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}
                        selected={selectedIndex === 41}
                        onClick={(event) => handleListItemClick(event, 41)}
                    >
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <AccountBalanceRoundedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.fnoReports}
                            </Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
                {/* <ListItem disablePadding>
                    <ListItemButton
                        sx={{ pt: 0.6, pb: 0.6, borderRadius: '5px' }}
                        onClick={handleClickFNOMenu}>
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                            <AccountBalanceRoundedIcon color='secondary' sx={{ width: '18px', opacity: '0.6' }} />
                        </ListItemIcon>
                        <ListItemText >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.fnoReports}
                            </Typography>
                        </ListItemText>
                        {openFNOMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>
                <Collapse in={openFNOMenu} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 41}
                            onClick={(event) => handleListItemClick(event, 41)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.fno910}
                            </Typography>
                        </ListItemButton>
                        <ListItemButton
                            sx={{ pl: 4, pt: 0.5, pb: 0.5, borderRadius: '5px' }}
                            selected={selectedIndex === 42}
                            onClick={(event) => handleListItemClick(event, 42)}
                        >
                            <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >
                                {words.fno913}
                            </Typography>
                        </ListItemButton>
                    </List>
                </Collapse> */}
            </List>
        </div>
        <Divider sx={{ borderColor: '#fcbb42', mt: 'auto', ml: '16px', mr: '16px' }} />
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexGrow: 0, p: '16px' }}>

            <Avatar
                variant="outlined"
                sx={{ width: '30px', height: '30px' }}
                src={authUser.img}
            />
            <Box sx={{ minWidth: 0, flex: 1 }}>
                <Typography sx={{ fontSize: '0.875rem', fontWeight: '500' }} >{authUser.lastName + ' ' + authUser.firstName.slice(0, 1) + '.'}</Typography>
                <Typography sx={{ fontSize: '0.75rem', fontWeight: '500' }} >{authUser.login}</Typography>
            </Box>
            <IconButton onClick={handleExitUser} size="sm" variant="plain" color="neutral" title={words.exit}>
                <LogoutRoundedIcon />
            </IconButton>
        </Box>
    </>
    );

    const container = window1 !== undefined ? () => window1().document.body : undefined;

    return (
        <Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, bgcolor: 'background.paper' }}
            aria-label="mailbox folders"
        >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box>
    );
}

SideBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default SideBar;