//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import TaskManager from './TaskManager';
import { addNewTaskRequest, deleteTaskRequest, updateTaskCloseRequest, uploadFileNewTaskRequest, uploadFileTaskRequest } from '../../../Redux/taskReducer';

class TaskManagerContainer extends React.PureComponent {

    render() {
        return (<TaskManager
            uploadFileTaskRequest={this.props.uploadFileTaskRequest}
            uploadFileNewTaskRequest={this.props.uploadFileNewTaskRequest}
            addNewTaskRequest={this.props.addNewTaskRequest}
            deleteTaskRequest={this.props.deleteTaskRequest}
            updateTaskCloseRequest={this.props.updateTaskCloseRequest}
        />)
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export default connect(mapStateToProps, {
    uploadFileTaskRequest,
    uploadFileNewTaskRequest,
    addNewTaskRequest,
    deleteTaskRequest,
    updateTaskCloseRequest

})(TaskManagerContainer)
