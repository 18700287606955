//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import DashboardPanel from './DashboardPanel';
import { setUploadCloseFileTaskRequest, updateTaskCloseRequest, updateTaskReopenRequest, updateTaskRequest, uploadFileTaskRequest } from '../../Redux/taskReducer';

class DashboardPanelContainer extends React.PureComponent {

    render() {
        return (<DashboardPanel                     
                    updateTaskRequest={this.props.updateTaskRequest}
                    updateTaskCloseRequest={this.props.updateTaskCloseRequest}
                    updateTaskReopenRequest={this.props.updateTaskReopenRequest}
                    uploadFileTaskRequest={this.props.uploadFileTaskRequest}
                    setUploadCloseFileTaskRequest={this.props.setUploadCloseFileTaskRequest}
                />)
    }
}

const mapStateToProps = (state) => {
    return {   
    }
};

export default connect(mapStateToProps, {     
    updateTaskRequest,
    updateTaskCloseRequest,
    updateTaskReopenRequest,
    uploadFileTaskRequest,
    setUploadCloseFileTaskRequest
})(DashboardPanelContainer)
