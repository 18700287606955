import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, IconButton, Stack } from '@mui/material';
import ProductListComponent from './ProductListComponent';

const ProductOrderClient = ({ viewDrdersClients }) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getColor = (status) => {
        switch (status) {
            case 1:
                return '#05a5059e'

            default:
                return '#ff050587';
        }
    }

    const getStatus = (status) => {
        switch (status) {
            case 1:
                return 'Принят'

            default:
                return 'Создан';
        }
    }

    const fragment = (prod) => {
        return <React.Fragment>
          <Typography variant="caption" gutterBottom sx={{ display: 'block' }} >
            Количество: {prod.amount} шт.
          </Typography>
          <Typography variant="caption" gutterBottom sx={{ display: 'block' }} >
            Цена: {prod.price} тг.
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: 'text.primary', display: 'inline' }}
          >
            СУММА: {prod.price * prod.amount} тг.
          </Typography>
        </React.Fragment>
      }
    
    //   const action = (id) => {
    //     return <IconButton onClick={() => delProductInBasket(id)} edge="end" aria-label="delete">
    //       <DeleteIcon fontSize='large' color='error' />
    //     </IconButton>
    //   }
    
      const addProduct = (item) => {
        
      }

    return (
        <div>
            {viewDrdersClients.map((ord) =>
                <Accordion key={ord.id} expanded={expanded === ord.id} onChange={handleChange(ord.id)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={ord.id + "-content"}
                        id={ord.id + "-header"}
                    >

                        <Avatar sx={{ bgcolor: getColor(ord.status), mr: 2 }} >
                            #{ord.id}
                        </Avatar>
                        <Stack sx={{ justifyContent: "center", alignItems: "flex-start", }} >
                            <Typography variant="caption" textAlign={'left'} sx={{ color: 'text.secondary' }}>{ord.date + "/" + ord.time}</Typography>
                            <Typography variant="caption" textAlign={'left'} sx={{ color: 'text.secondary' }}>Сумма: {ord.products.map((bs) => bs.amount * bs.price).reduce(function (a, b) { return a + b }, 0)} тг. Статус: {getStatus(ord.status)}</Typography>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ProductListComponent products={ord.products} addProduct={()=>{}} fragment={fragment} action={()=>{}} />
                    </AccordionDetails>
                </Accordion>
            )
            }
        </div>
    );
}

export default ProductOrderClient;