import ConnectToServer from "../API/ConnectToServer";

//Action type
const SET_ALL_PRODUCTS = 'SHOP/SET_ALL_PRODUCTS';
const SET_PRODUCTS_ORDERS_CLIENT = 'SHOP/SET_PRODUCTS_ORDERS_CLIENT';
const SET_ORDERS_CLIENT = 'SHOP/SET_ORDERS_CLIENT';
const SET_ALL_PRODUCT_GROUPS = 'SHOP/SET_ALL_PRODUCT_GROUPS';
const SET_IN_BASKET = 'SHOP/SET_IN_BASKET';

//Started props
let initialState = {
    isFetching: false,
    allProducts: [],
    groupsProduct: [],
    productOrdersClients: [],
    ordersClients: [],
    masterBasket: [],
    productOrdersShop: [],
    ordersShop: [],
};

//Reducers functions
const shopReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ALL_PRODUCTS:
            return {
                ...state,
                allProducts: action.shopProducts
            }

        case SET_ALL_PRODUCT_GROUPS:
            return {
                ...state,
                groupsProduct: action.shopGroups
            }

        case SET_IN_BASKET:
            return {
                ...state,
                masterBasket: action.products
            }

        case SET_PRODUCTS_ORDERS_CLIENT:
            return {
                ...state,
                productOrdersClients: action.orderProducts
            }

        case SET_ORDERS_CLIENT:
            return {
                ...state,
                ordersClients: action.orders
            }

        default:
            return state;
    }
}

//Action Creators functions
const setProducts = (shopProducts) => {
    return { type: SET_ALL_PRODUCTS, shopProducts }
}

const setGroups = (shopGroups) => {
    return { type: SET_ALL_PRODUCT_GROUPS, shopGroups }
}

const setClientOrders = (orders) => {
    return { type: SET_ORDERS_CLIENT, orders }
}

const setClientOrderProducts = (orderProducts) => {
    return { type: SET_PRODUCTS_ORDERS_CLIENT, orderProducts }
}

//Thunk functions
export const shopProductsRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getALLShopProducts(obj)
        if (data.success === 1) {
            dispatch(setProducts(data.shopProducts))
        } else {
            console.log(data.message)
            dispatch(setProducts([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const shopGroupsRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getALLShopGroups(obj)
        if (data.success === 1) {
            dispatch(setGroups(data.shopGroups))
        } else {
            console.log(data.message)
            dispatch(setGroups([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const getAllOrdersClientsRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.getALLShopOrdersClients(obj)
        if (data.success === 1) {
            console.log(data);
            dispatch(setClientOrders(data.orders))
            dispatch(setClientOrderProducts(data.orderProducts))
        } else {
            console.log(data.message)
            //dispatch(setGroups([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export const addNewOrdersShopRequest = (obj) => {
    return async (dispatch) => {
        const data = await ConnectToServer.setNewOrdersShop(obj)
        if (data.success === 1) {
            console.log(data);
            alert('Заказ #' + data.order + ' оформлен!')
            dispatch(getAllOrdersClientsRequest({ idUser: obj.idUser }))
        } else {
            console.log(data.message)
            //dispatch(setGroups([]))
            //dispatch(toggleIsFetching(false))
        }
    }
}

export default shopReducer;